// import 'antd/dist/antd.css';
import React from 'react';
import s from './FaqCard.module.scss'

import Faq from "../../Faq";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "../../../../../constants/phones";

const FaqCard = () => {

    const questions = [{
        id: "1",
        question: 'Когда начисляются мои бонусы?',
        answer: 'Бонусы начисляются в момент, как ваш друг оформил займ в нашей компании'
    }, {
        id: "2",
        question: 'Какую выгоду получает приглашенный друг?',
        answer: 'Ваш друг получит первый месяц пользования займом по ставке от 2.3 до 5% в месяц'
    }, {
        id: "3",
        question: 'Как посмотреть кто зарегистрировался по моей ссылке?',
        answer: 'На странице клуб 100 друзей в разделе история операций'
    }, {
        id: "4", question: 'Как вывести бонусы?', answer: `Вы можете обменять бонусы на рубли в кассах наших офисов. 
        <br/>1 бонус = 1 рубль
        <br/>Рабочее время офисов:
        <br/>пн-пт 09:30–19:00
        <br/>сб-вс 10:00–17:00
        <br/>по вопросам <a href="${VI_OFFICE_PHONE_URL}">${VI_OFFICE_PHONE}</a>`
    }]

    return (<div className={s.cardContainer}>
            <Faq questions={questions}/>
        </div>);

}

export default FaqCard
