import React from "react";
import s from './OnlineLoanFinal.module.scss'
import SuccessImg from "../../../img/success.svg";
const FinalChecked = ({children}) => {
    return <div className={s.finalChecked}>
        <img src={SuccessImg} alt=""/>
        {children}
    </div>
}
export default FinalChecked
