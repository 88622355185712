import React from 'react'
import s from './OnlineLoanBanner.module.scss'
import passIcon from '../../../img/pass.svg'
import docsIcon from '../../../img/docs.svg'
import carIcon from '../../../img/car.svg'
import {useHistory} from "react-router-dom";
import {backReq} from "../../../helpers";
import onlineLoanStore from "../../../store/onlineLoanStore";
import {ReactComponent as ShapeFigure} from './img/shape-figure.svg'
import {ReactComponent as ShapeFigureMobile} from './img/shape-figure-mobile.svg'
import {ReactComponent as ShapeSymbol} from './img/shape-symbol.svg'
import {ReactComponent as ShapeSymbolMobile} from './img/shape-symbol-mobile.svg'
import {ReactComponent as ShapeSymbolDark} from './img/shape-symbol-dark.svg'
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";

const OnlineLoanBanner = ({dark=false, isProcessingLoan=false}) => {
    const history = useHistory();

    const createOrContinueOnlineLoan = async () => {
        try {
            if (!isProcessingLoan) {
               const response = await backReq('online_loan:create')
                if (response.errors){
                    elementsStore.showSnackbar(errorsParser(response.errors))
                    return false
                }
            }
            await onlineLoanStore.refreshOfferExist()
            history.push('/online-loan')
        } catch (e) {
            console.warn(e)
        }
    }

    return <div className={[s.onlineLoanBanner, dark ? s['onlineLoanBanner--dark'] : ''].join(' ')}>
        {dark
          ? <>
              <ShapeSymbolDark className={s.shapeSymbol}/>
          </>
          : <>
              <ShapeFigure className={s.shapeFigure}/>
              <ShapeFigureMobile className={s.shapeFigure__mobile}/>

              <ShapeSymbol className={s.shapeSymbol}/>
              <ShapeSymbolMobile className={s.shapeSymbol__mobile}/>
          </>
        }
        <div className={s.wrapper} onClick={createOrContinueOnlineLoan}>
            <h2 className={s.header}>
                Оформите <span>займ</span> онлайн
            </h2>
            <div className={s.textWrapper}>
                <div className={s.listWrapper}>
                    <h3>Вы</h3>
                    <ul>
                        <li>
                            <div className={s.circle}></div>
                            <span>Гражданин РФ</span>
                        </li>
                        <li>
                            <div className={s.circle}></div>
                            <span>В возрасте от 18&nbsp;до&nbsp;65 лет</span>
                        </li>
                        <li>
                            <div className={s.circle}></div>
                            <span>В собственности легковой автомобиль</span>
                        </li>
                    </ul>
                </div>
                <div className={s.listWrapper}>
                    <h3><span>Вам</span> понадобится</h3>
                    <ul className={s.iconWrapper}>
                        <li><img src={passIcon} alt="passIcon"/>
                            <span>Паспорт</span>
                        </li>
                        <li><img src={docsIcon} alt="docsIcon"/>
                            <span>ПТС и СТС</span>
                        </li>
                        <li><img src={carIcon} alt="carIcon"/>
                            <span>Фото авто</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <button
                  className={s.loanButton}
                >
                    { isProcessingLoan ?  <span>Продолжить&nbsp;оформление</span> :'Оформите за 20 минут'}
                </button>
            </div>
        </div>
    </div>
}
export default OnlineLoanBanner
