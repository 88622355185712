import React from 'react'

import s from './styles.module.scss'

import {dateToDMYHM} from "../../../utils/formattedDate";

const NotificationCondensed = ({type, date, text}) => { // types: info, success, error
    return <div className={`${s.condensed} ${s[type]}`}>
        <span>{dateToDMYHM(date)}</span>
        <p>{text}</p>
    </div>
}

export default NotificationCondensed
