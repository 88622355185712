import _ from 'lodash'

// Авторизация c экрана логина
export const wsLogIn = _.debounce(() => {
    const token = localStorage.getItem('sessionToken')
    if (!token) return
    window.viWSSend({
      action: 'login',
      token
    })
}, 2000)

