import React from 'react';
import s from './OnlineLoanPersonal.module.scss'
import BaseButton from "../../atoms/BaseButton";
import Eyes from './img/eyes.png'
import appStore from "../../../store/app";


const AccountExistsModal = () => {
  return <div className={s.onlineLoanPersonalModal}>
    <img className={s['onlineLoanPersonalModal__img']} src={Eyes} alt="icon"/>
    <h2>Кажется клиент с такими данными уже есть...</h2>
    <p>
      Проверьте правильность указанных данных, либо попробуйте восстановить доступ к своему профилю (по ссылке произойдет выход из Личного кабинета).
      <span>Если у Вас возникли сложности, свяжитесь по телефону горячей линии 8 (800) 700-63-31.</span>
    </p>
    <div className={s.buttonWrapper}>
      <BaseButton
        type="green"
        size="big"
        text={<><div style={{fontSize: '18px', marginBottom: '4px'}}>Восстановить пароль</div> <div style={{fontSize: '13px', color: '#ffffffb3'}}>Произойдет выход из Личного кабинета</div></>}
        onClick={()=>appStore.logout(true)}
      />
    </div>
  </div>
}

export default AccountExistsModal;
