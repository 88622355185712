import s from "./BalanceCard.module.scss";
import BaseButton from "../../../../atoms/BaseButton";
import React from "react";
import {observer} from "mobx-react";
import appStore from "../../../../../store/app";
import elementsStore from "../../../../../store/elementsStore";
import {yandexMetrikaReachGoal} from "../../../../../utils/yandex-metrika";
import PlanetComponent from './icons/planet'
import HomeComponent from './icons/home'

const AddFriendModal = observer(({closeModal}) => {

    const link = appStore?.referralInfo?.link
    const referralLinkLocal = "friends.vashinvestor.ru/" + link
    const referralLinkRemote = "on.vashinvestor.ru/" + link

    const openNotification = () =>
        elementsStore.showSnackbar('Ссылка скопирована в буфер обмена', 'approved')

    const onShareLocal = () => {
        yandexMetrikaReachGoal('podelitsya_ssylkoi_100druzei_online')
        navigator.clipboard.writeText("https://" + referralLinkLocal);
        openNotification()
        closeModal()
    }

    const onShareRemote = () => {
        yandexMetrikaReachGoal('podelitsya_ssylkoi_100druzei')
        navigator.clipboard.writeText("https://" + referralLinkRemote);
        openNotification()
        closeModal()
    }

    return <div className={s.inviteModal}>
        <div className={s.inviteModal__row}>
            <p className={s.inviteModal__rowTitle}>
                <HomeComponent /> Отправьте ссылку на займ в офисе
            </p>
            <p className={s.inviteModal__text}>Мы пригласим друга в офис для займа</p>
            <div className={s.inviteModal__container}>
                <div className={s.inviteModal__link}>
                    {referralLinkLocal}
                </div>
                <div style={{maxWidth: 128}}>
                    <BaseButton
                        type="green"
                        size="default-change"
                        text={"Скопировать"}
                        onClick={onShareLocal}
                />
                </div>
            </div>
        </div>
        <div className={s.inviteModal__divider}><p className={s.inviteModal__dividerText}>ИЛИ</p></div>
        <div className={s.inviteModal__row}>
            <p className={s.inviteModal__rowTitle}>
                <PlanetComponent /> Отправьте ссылку на займ онлайн
            </p>
            <p className={s.inviteModal__text}>Займ на сайте без приезда в офис</p>
            <div className={s.inviteModal__container}>
                <div className={s.inviteModal__link}>
                    {referralLinkRemote}
                </div>
                <div style={{maxWidth: 128}}>
                    <BaseButton
                        type="green"
                        size="default-change"
                        text={"Скопировать"}
                        onClick={onShareRemote}
                    />
                </div>
            </div>
        </div>
    </div>
})
export default AddFriendModal
