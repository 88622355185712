import React from "react";
import s from "./OnlineLoanPersonal.module.scss";
import BaseButton from "../../atoms/BaseButton";

const CheckLimitModal = ({closeModal}) => {
  return <div className={s.onlineLoanPersonalModal}>
      <p>Вы исчерпали количество доступных проверок в этот день.
          Три новые попытки будут завтра с 00:00 часов.
      </p>
      <div className={s.buttonWrapper}>
          <BaseButton
              type="green"
              size="default-change"
              text={"Понятно"}
              onClick={closeModal}
          />
      </div>
  </div>
}
export default CheckLimitModal
