import React from "react";
import s from "./OnlineLoanIncome.module.scss";
import NativeSelect from "../../molecules/NativeSelect";
import BaseInput from "../../atoms/BaseInput";
import PhoneInput from "../../atoms/PhoneInput";
import {maritalStatusTemplate} from "../../../utils/templates/martialStatusTemplate";
import DadataInput from "../../atoms/DadataInput";

const IncomeForm = ({incomeForm, setIncomeForm, fields}) => {

    const {
        employmentStatusValues=[],
        fieldOfActivityValues=[],
    } = fields

    const {
        employmentStatus,
        fieldOfActivity,
        maritalStatus,
        employmentPosition ,
        company,
        workPhone,
        companyAddress,
        income,
        additionalIncome,
        additionalIncomeSource
    } = incomeForm

  return <div className={s.inputsGroup}>
      <div>
          <NativeSelect
              placeholder="Статус трудоустройства *"
              defaultValue={employmentStatus}
              options={employmentStatusValues}
              onChanged={(x) => setIncomeForm({...incomeForm, employmentStatus : x})}
              onSearch={null}
          />
      </div>
      <div>
          <NativeSelect
              placeholder="Сфера деятельности *"
              defaultValue={fieldOfActivity}
              options={fieldOfActivityValues}
              onChanged={(x) => setIncomeForm({...incomeForm, fieldOfActivity : x})}
              onSearch={null}
          />
      </div>
      <div>
          <NativeSelect
              placeholder="Семейное положение *"
              options={maritalStatusTemplate}
              defaultValue={maritalStatus?.label || ""}
              withLabel={true}
              onSearch={null}
              onChanged={(x) => setIncomeForm({...incomeForm, maritalStatus : x})}
          />
      </div>
      <div>
          <BaseInput
              placeholder="Должность"
              type={"text"}
              defaultValue={employmentPosition}
              value={(x) => setIncomeForm({...incomeForm, employmentPosition : x})}
          />
      </div>
      <div>
          <BaseInput
              placeholder="Наименование компании *"
              defaultValue={company}
              value={(x) => setIncomeForm({...incomeForm, company : x})}
          />
      </div>
      <div>
          <PhoneInput
              placeholder="Рабочий телефон"
              defaultValue={workPhone}
              value={(x) => setIncomeForm({...incomeForm, workPhone : x})}
          />
      </div>
      <div className={s.wideColumn}>
          <DadataInput
              placeholder="Фактический адрес компании *"
              defaultValue={companyAddress}
              value={ x => setIncomeForm({...incomeForm, companyAddress : x})}
          />
      </div>
      {/*<div>*/}
      {/*    /!*empty*!/*/}
      {/*</div>*/}
      <div className={s.afterWideColumn}>
          <BaseInput
              placeholder="Основной доход семьи, ₽ *"
              defaultValue={income}
              type={"number"}
              value={(x) => setIncomeForm({...incomeForm, income : x})}
          />
      </div>
      <div>
          <BaseInput
              placeholder="Дополнительный доход, ₽"
              defaultValue={additionalIncome}
              type={"number"}
              value={(x) => setIncomeForm({...incomeForm, additionalIncome : x})}
          />
      </div>
      <div>
          <BaseInput
              placeholder="Источник доп. дохода"
              defaultValue={additionalIncomeSource}
              value={(x) => setIncomeForm({...incomeForm, additionalIncomeSource : x})}
          />
      </div>

  </div>
}

export default IncomeForm

