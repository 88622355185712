import React from 'react'
import {observer, inject} from 'mobx-react'

import {formatMoney} from '../../../helpers'
import paymentModule from "../../../modules/payment";
import ModalContainer from "../../atoms/ModalContainer";
import s from "./PayStatusModal.module.scss";
import {dateToDMYHM} from "../../../utils/formattedDate";
import BaseMessage from "../../atoms/BaseMessage";
import {toJS} from "mobx";
import {VI_OFFICE_PHONE} from "../../../constants/phones";
@inject('appStore')
@observer
class PayStatusModal extends React.Component {
    closePaidStatusModal = ({orderId, loanId}) => {
        document.body.style.overflow = null
        this.props.appStore.setRead(orderId)
        this.props.appStore.setLoanDetailIdToUpdate(loanId)
    }

    rawAlertSuccessPay() {
        return (
            <span>Ваш платеж успешно зачислен! <br/>Благодарим Вас, что вносите платежи вовремя.</span>
        )
    }

    getText(status, amount) {
        switch (status) {
            case 10:
                return `Ваш платеж на сумму ${formatMoney(amount)} ₽ находится в обработке`
            case 20:
                return `Ваш платеж на сумму ${formatMoney(amount)} ₽ успешно зачислен!`
            case 30:
                return `Ваш платеж на сумму ${formatMoney(amount)} ₽ завершился ошибкой`
            default:
                return `Ваш платеж на сумму ${formatMoney(amount)} ₽ находится в обработке`
        }
    }

    getType(status) {
        switch (status) {
            case 10:
                return `warning`
            case 20:
                return `success`
            case 30:
                return `error`
            default:
                return `warning`
        }
    }

    getResultText(status, errMsg, overRepay) {
        switch (status) {
            case 10:
                return `Обычно обработка платежа занимает до 1 минуты, пожалуйста подождите. Если статус платежа в течении 30 минут после оплаты не будет изменен на "Успешно выполнен" - позвоните в Контакт-центр ${VI_OFFICE_PHONE} (бесплатно).`
            case 20:
                if (overRepay) {
                    return `Ваш платеж успешно зачислен! Благодарим Вас, что вносите платежи вовремя. \nПри досрочном гашении дата платежа по займу будет изменена в течении 1 суток. С новым графиком платежей можно ознакомиться на странице займа в вкладке График платежей.`
                }
                return `Ваш платеж успешно зачислен! Благодарим Вас, что вносите платежи вовремя.`
            case 30:
                return `${errMsg ? errMsg : 'Внутренняя ошибка сервиса'}`
            default:
                return `Обычно обработка платежа занимает до 1 минуты, пожалуйста подождите. Если статус платежа в течении 30 минут после оплаты не будет изменен на "Успешно выполнен" - позвоните в Контакт-центр ${VI_OFFICE_PHONE} (бесплатно).`
        }
    }
    getTitle(type, entityId, title) {
        return paymentModule.getPaymentResultText(type, entityId, title)
    }
    render() {
        const {payments} = toJS(this.props.appStore)
        return (
            payments.map(x => {
                const {
                    amount,
                    createdIso,
                    entityId,
                    message,
                    status,
                    type,
                    title,
                    overRepay
                } = x
                return <ModalContainer
                    key={entityId+status}
                    showModal={ true }
                    closeModal={ () => this.closePaidStatusModal(x) }
                    title={ this.getTitle(type, entityId, title) }
                >
                    <div className={s.status}>
                        <span> { dateToDMYHM(createdIso) } </span>
                        <p> { this.getResultText(status, message, overRepay) } </p>
                        <BaseMessage type={ this.getType(status)} centered={true} size={'biggest'}>
                            {this.getText(status, amount)}
                        </BaseMessage>
                    </div>
                </ModalContainer>
            })
        )
    }
}

export default PayStatusModal
