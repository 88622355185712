import s from "./BaseLoans.module.scss";
import BaseLoansTags from "./BaseLoansTags";
import BaseProgress from "../../atoms/BaseProgress";
import formattedMoney from "../../../utils/formattedMoney";
import React from "react";

const BaseLoansProgress =({loan}) => {
    const {typePayment, gps, isTaxLate, isClosed, progressLoan, allSum, loanSum} = loan
    return <div className={s.loans__progress}>
        <div className={s.loans__progressTop}>
            <span className={s["loans__progressTop--text"]}>{isClosed ? 'Займ закрыт' : 'Основной долг'}</span>
            <div
                className={[s.loans__props, s.loans__grayText, s["loans__progressTop--props"]].join(" ")}>
                <BaseLoansTags typePayment={typePayment} closed={isClosed} gps={gps}/>
            </div>
        </div>
        <BaseProgress percent={progressLoan} red={isTaxLate}/>
        <div className={s.loans__progressBottom}>
            <span>Из {formattedMoney(allSum)} ₽</span>
            {!isClosed &&
              <div className={s.loans__remains}>
                <span
                  className={s["loans__remains--sum"]}>
                    {formattedMoney(loanSum ?? 0)} ₽
                </span>
                <span className={s["loans__remains--divider"]}>&nbsp;-&nbsp;</span>
                <span className={s["loans__remains--text"]}> Остаток без %</span>
              </div>
            }
        </div>
    </div>;
}
export default BaseLoansProgress
