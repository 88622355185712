import s from "./BaseLoans.module.scss";
import BaseLoansTags from "./BaseLoansTags";
import {dateToDMY} from "../../../utils/formattedDate";
import getBankType from "../../../utils/getBankType";
import React from "react";

const LoanAttributesInfo = ({loan}) => {
    const {typePayment, isClosed, gps, ln, mfo, openDate } =loan
    return <>
        <div className={s.loans__bottom}>
            <div className={[s.loans__props, s.loans__grayText].join(' ')}>
                <BaseLoansTags typePayment={typePayment} isClosed={isClosed} gps={gps}/>
            </div>
        </div>
        <div className={[s.loans__number, s.loans__grayText].join(' ')}>
            {ln && <span><span
                className={s.loans__textDesktop}>Договор</span> № {ln} от {dateToDMY(openDate)}</span>}
            <div className={s.loans__mfo}>{getBankType(mfo)}</div>
        </div>
    </>

}
export default LoanAttributesInfo
