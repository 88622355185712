import React, { useState } from "react";
import s from './Autocomplete.module.scss'

const Autocomplete = ({suggestions, disabled, defaultValue, value, placeholder, remote=false }) => {
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);
    const [isShow, setIsShow] = useState(false);

    const onChange = e => {
        const input =  e.currentTarget.value
        let newFilteredSuggestions
        if (remote){
            newFilteredSuggestions = suggestions;
        } else {
            newFilteredSuggestions = suggestions.filter(
                suggestion =>
                    suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
            );
        }
        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
        value(e.currentTarget.value)
    };

    const onClick = e => {
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        value(e.currentTarget.innerText)
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) { // enter key
            setActive(0);
            setIsShow(false);
            value(filtered[active])
        }
        else if (e.keyCode === 38) { // up arrow
            return (active === 0) ? null : setActive(active - 1);
        }
        else if (e.keyCode === 40) { // down arrow
            return (active - 1 === filtered.length) ? null : setActive(active + 1);
        }
    };

    const renderAutocomplete = () => {
        const activeArray = remote ? suggestions : filtered
        if (isShow && defaultValue) {
            if (activeArray.length) {
                return (
                    <ul className={s.optionWrapper}>
                        {activeArray.map((suggestion, index) => {
                            let className = [s.option];
                            if (index === active) {
                                className.push('active')
                                // className = "active";
                            }
                            return (
                                <li className={className.join(' ')} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="no-autocomplete">
                        {/*<em>Not found</em>*/}
                    </div>
                );
            }
        }
        return <></>;
    }

    const wrapperClasses = [s.inputWrapper]
    if (defaultValue) wrapperClasses.push(s.activePlaceholder)
    if (disabled) wrapperClasses.push(s.disabledPlaceholder)

    return (
        <div className={s.container} >
            <div className={wrapperClasses.join(' ')}>
                <div className={s.placeholder}>{placeholder}</div>
                <input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={defaultValue}
                    disabled={disabled}
                    className={s.input}
                    autoComplete="off"
                />

            </div>
            {renderAutocomplete()}
        </div>
    );
}

export default Autocomplete;
