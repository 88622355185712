import React, {useState} from "react";
import ModalPortal from "../../atoms/ModalPortal";
import s from "./OnlineLoanSubject.module.scss";

const SpecialEquipment = () => {

    const [modal, setModal] = useState(false)

    return <>
        <span className={s.special_equipment}
            onClick={() => setModal(true)}>Хотите оформить займ под спец. технику?</span>
        <ModalPortal
            title="Займ под спецтехнику"
            showModal={modal}
            closeModal={() => setModal(false)}>
            <div>
                <p className={s.paragraph}>
                    В настоящий момент оформление займов под спецтехнику доступно только в
                    <a className={s.messageLink} href={'/contacts'} target={'_blank'}> наших офисах</a>.
                </p>
                <p className={s.paragraph}>
                    Приглашаем Вас к нам, мы оперативно подберем оптимальное финансовое решение!
                </p>
               <p>
                   Горячая линия: <b>8 (800) 700-63-31</b>
               </p>
            </div>
        </ModalPortal>
    </>
}
export default SpecialEquipment
