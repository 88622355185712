import React from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import {Link} from "react-router-dom";
import {VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL} from "../../../../constants/phones";


const ModalReject = ({onClose}) => {
  return (
    <div className={s.loanModal}>
      <div className={s.modalSpacer}>
        <p className={s.modalText}>К сожалению, мы не можем оформить онлайн займ (в дистанционном режиме) - приглашаем Вас в один из&nbsp;
          <Link to={'/contacts'}>наших офисов</Link>,
          мы оперативно подберем оптимальное финансовое решение!</p>
        <p className={s.modalText}>Горячая линия: <a href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a></p>
      </div>
      <BaseButton text={'Понятно'} onClick={onClose}/>
    </div>
  )
}
export default ModalReject
