import React from 'react'
import RepeaterSection from "../Repeater/RepeaterSection";

const PageRepeater = () => {
    return <div className="container">
        <RepeaterSection/>
    </div>
}

export default PageRepeater
