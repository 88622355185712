import React, { Component } from 'react'
import s from './NotFound.module.scss'
import BaseButton from '../atoms/BaseButton';
import { Redirect } from 'react-router-dom'
class PageLoans extends Component {
  constructor() {
    super()
    this.state = {
      redirect: false
    }
  }
  redirectToMain() {
    this.setState({
      redirect: true
    })
  }
  render() {
    return <div className="container">
      <div className={s.error}>
        <h2>404</h2>
        <p>Ошибка. Такой страницы не существует. Вернитесь на главную</p>
        <div>
          <BaseButton
              size="big"
              type="green"
              text="На главную"
              onClick={ () => this.redirectToMain()}
          />
          { this.state.redirect && <Redirect to="/" />}
        </div>
      </div>
    </div>
  }
}

export default PageLoans
