import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import NotificationIcon from './img/notification.svg'
import NotificationCondensed from './NotificationCondensed'
import s from './Notification.module.scss'
import BaseButton from '../../components/atoms/BaseButton';
import BaseTooltip from "../../components/atoms/BaseTooltip";

const NOTIFICATIONS_PAGE_PATH = '/notifications'

const NotificationsBar = inject("appStore")(observer((props) => {
  const { appStore } = props
  const { fetchUnreadNotifications, markAllAsReaded } = appStore

  const { unreadNotifications, unreadNotificationsCount } = toJS(appStore);

  const [close, setClose] = useState(false)

  useEffect(() => {
    if (close) {
      setClose(false)
    }
    fetchUnreadNotifications();
  }, [close, fetchUnreadNotifications, unreadNotificationsCount])

  const history = useHistory();

  const handleClick = () => {
    setClose(true)
    markAllAsReaded()
    history.push(NOTIFICATIONS_PAGE_PATH)
  }

  const iconAfter = <React.Fragment>
    <span>Уведомления</span>
    { unreadNotificationsCount > 0 &&
    <i><span>{unreadNotificationsCount}</span></i>
    }
  </React.Fragment>

  return <div className={s.notification}>
    <BaseTooltip icon={NotificationIcon} iconAfter={iconAfter} close={close}>
      {
        unreadNotificationsCount > 0
            ? unreadNotifications.slice(0, 2).map((notification) => (
                <div className={s.line} key={notification.id}>
                  <NotificationCondensed
                      type={notification.type}
                      date={notification.createdIso}
                      text={notification.text}
                  />
                </div>
            ))
            : <span>Новых уведомлений нет</span>
      }

      <div className={s.showAll}>
        <BaseButton type="shadow" text="Все уведомления" onClick={handleClick}/>
      </div>
    </BaseTooltip>
  </div>
}))

export default NotificationsBar
