export const baseCloudSrc = require(`./../../img/upload-cloud.svg`)
export const carPhotosTemplate = [
    {type: "car_front", baseSrc: require("./../../img/car-1.svg"), title: "* Фото авто со стороны капота", src: null},
    {type: "car_back", baseSrc: require("./../../img/car-2.svg"), title: "* Фото авто со стороны багажника", src: null},
    {type: "car_left", baseSrc: require("./../../img/car-3.svg"), title: "* Фото авто с левого бока", src: null},
    {type: "car_right", baseSrc: require("./../../img/car-4.svg"), title: "* Фото авто с правого бока", src: null},
    {
        type: "car_selfie",
        baseSrc: require("./../../img/car-5.svg"),
        title: "* Ваше фото на фоне авто, чтобы был виден гос. номер",
        src: null
    },
    {
        type: "car_vin",
        baseSrc: require("./../../img/car-6.svg"),
        title: "* Фото ВИН (идентификационной таблички)",
        src: null
    },
    {type: "car_odometer", baseSrc: require("./../../img/car-7.svg"), title: "* Фото одометра", src: null},
]

export const eptsPhotosTemplate = [
    {type: "e_pts", baseSrc: baseCloudSrc, title: "* Выписка электронного ПТС", src: null},
    {
        type: "e_pts_addition",
        baseSrc: baseCloudSrc,
        title: "* Дополнение к выписке",
        src: null
    },
]
export const ptsPhotosTemplate = [
    {type: "pts_front", baseSrc: baseCloudSrc, title: "* Лицевой разворот", src: null},
    {type: "pts_back", baseSrc: baseCloudSrc, title: "* Внутренний разворот", src: null},
]
export const stsPhotosTemplate = [
    {type: "sts_front", baseSrc: baseCloudSrc, title: "* Лицевая сторона", src: null},
    {type: "sts_back", baseSrc: baseCloudSrc, title: "* Оборотная сторона", src: null}
]

export const passportPhotosTemplate = [
    {
        type: "passport_main",
        baseSrc: baseCloudSrc,
        title: "Основной разворот паспорта (2-3 страницы)",
        disabled: true,
        src: null
    },
    {
        type: "passport_registration",
        baseSrc: baseCloudSrc,
        title: "Страница с текущим штампом вашего места регистрации (адрес прописки)",
        disabled: true,
        src: null
    },
    {
        type: "passport_additional",
        baseSrc: baseCloudSrc,
        title: "Дополнительное фото (при необходимости)",
        src: null
    },
]

export const personalPhotosTemplate = [
    {
        type: "passport_registration",
        baseSrc: baseCloudSrc,
        title: "* Страница с пропиской (текущее место регистрации) ",
        src: null,
    },
]
export const passportTemplate = [
    {
        type: "passport_main",
        baseSrc: baseCloudSrc,
        title: "Основной разворот паспорта (2-3 страницы)",
        src: null
    },
]
