import React, {Component} from 'react'
import {backReq} from '../../helpers'
import BaseCards from "../../components/organisms/BaseCards";
import BaseSkeleton from "../../components/atoms/BaseSkeleton";
import BaseAutoPayment from "../../components/organisms/BaseAutoPayment";

class SettingsPage extends Component {
    state = {
        loading: true,
        cards: [],
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => Promise.all([
        backReq('get_card_list'),
        backReq('get_auto_payment'),
    ]).then(([res, autoPayment]) => {
        if (!res || !autoPayment) return;

        const response = {
            loading: false,
            cards: res.data.cards,
            autoPayment,
        }
        this.setState(response)
        return response
    })

    updateCardName = (name, token) => {
        backReq('set_card_name', {name, token})
            .then(() => {
                this.setState(({cards}) => {
                    const idx = cards.findIndex((el) => el.token === token)

                    const oldItem = cards[idx]
                    const newItem = {...oldItem, name}

                    return {
                        cards: [...cards.slice(0, idx), newItem, ...cards.slice(idx + 1)]
                    }
                })
            })
    }

    deleteCard = (token) => {
        backReq('remove_card', {token})
            .then(() => {
                this.setState(({cards}) => {
                    const idx = cards.findIndex((el) => el.token === token)

                    return {
                        cards: [...cards.slice(0, idx), ...cards.slice(idx + 1)]
                    }
                })
            })
        setTimeout(() => {
            this.getAutoPayment()
        }, 1000)
    }

    async getAutoPayment() {
        const result = await backReq('get_auto_payment')
        this.setState({
            autoPayment: result
        })
    }

    setAsyncState = (newState) => {
        new Promise((resolve) => this.setState(newState, resolve))
    }

    async setTab(tab) {
        await this.setAsyncState({
            activeTab: tab
        })
        this.fetchData()
    }

    render() {
        const {cards, loading, autoPayment} = this.state

        if (loading) return <BaseSkeleton/>

        return (
            <div className="container">
                <BaseCards
                    cards={cards}
                    autoPayment={autoPayment?.data}
                    fetchData={this.fetchData}
                    updateCardName={this.updateCardName}
                    deleteCard={this.deleteCard}
                />
                <BaseAutoPayment
                    autoPayment={autoPayment?.data}
                    extra={true} fetchCardList={this.fetchData}
                    cards={cards}
                />
            </div>
        )
    }
}

export default SettingsPage
