import React, {Component} from 'react'

import s from './LoanList.module.scss'
import BaseTabs from "../../atoms/BaseTabs";
import BaseLoans from "../BaseLoans";
import {inject, observer} from "mobx-react";
import {getQuery} from "../../../utils/getQuery";

@inject('appStore')
@observer
class LoanList extends Component {
  state = {
    loansTabsActive: getQuery()?.type ? getQuery().type : 'open'
  }

  render() {
    const {appStore, autoPaymentStatus, padding} = this.props
    const {loansTabsActive} = this.state

    const loansTabs = {
      open: `<span>Открытые<b>${appStore.openedLoans.length}</b></span>`
    }
    if (appStore.closedLoans.length > 0) loansTabs.close = `<span>Закрытые<b>${appStore.closedLoans.length}</b></span>`

    return (
      <div className={[s.loans, padding ? s.padding : null].join(' ')}>
        {window.location.pathname === '/' ?
          <h2 className="title">Мои займы</h2>
          :
          <h1 className="title">Мои займы</h1>
        }
        <div className={s.loans__tabs}>
          <BaseTabs active={loansTabsActive} list={loansTabs}
                    change={(value) => this.setState({loansTabsActive: value})}/>
        </div>

        <BaseLoans
          loans={appStore[loansTabsActive === 'open' ? 'openedLoans' : 'closedLoans']}
          autoPayment={loansTabsActive === 'open' ? autoPaymentStatus : null}
        />
      </div>
    )
  }
}

export default LoanList
