import React, {useEffect, useState} from 'react'
import ErrorContainer from "../../molecules/ErrorContainer";
import s from "./OnlineLoanIncome.module.scss";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import onlineLoanStore from "../../../store/onlineLoanStore";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {backReq} from "../../../helpers";
import ContactPersonForm from "./ContactPersonForm";
import IncomeForm from "./IncomeForm";
import ExtraForm from "./ExtraForm";
import elementsStore from "../../../store/elementsStore";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../../Repeater/helpers";
import {onlineLoanStepsArray} from "../../../constants/onlineLoan";
import {errorsParser} from "../../../utils/errorsParser";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";
import {passportPhotosTemplate} from "../../../utils/templates/photoArrayTemplates";
import {getMaritalStatusByValue} from "../../../utils/templates/martialStatusTemplate";
import {trimmedPhoneWithPlus} from "../../../utils/formattedPhone";
import {getPlainPhone} from "../../../helpers";
import BaseMessage from "../../atoms/BaseMessage";

class ContactPersonModel {
    contact = ''
    fio = ''
    phone = ''
    address = ''
}

const RegistrationIncome = () => {

    const [isIncomeDataError, setIsIncomeDataError, incomeDataContainerRef] = useErrorContainer()
    const [isContactDataError, setIsContactDataError, contactDataContainerRef] = useErrorContainer()
    const [isExtraDataError, setIsExtraDataError, extraDataContainerRef] = useErrorContainer()
    const [isPhotoDataError, setIsPhotoDataError, photoDataContainerRef] = useErrorContainer()

    const [incomeForm, setIncomeForm] = useState({})
    const [contactForms, setContactForms] = useState([new ContactPersonModel()])
    const [extraForm, setExtraForm] = useState({
        isHaveCredit: false,
        isHaveAddHouse: false,
        isHaveAddTransport: false,
    })

    const [externalFields, setExternalFields] = useState({})
    const [passportPhotos, setPassportPhotos] = useState(passportPhotosTemplate)

    useRefreshOnlineLoanDetail(insertData)

    async function insertData() {
        const loan = onlineLoanStore?.loanDetail ?? {}
        setIncomeForm({
            ...loan,
            maritalStatus: getMaritalStatusByValue(loan.maritalStatus)
        })
        setExtraForm({...loan})
        setContactForms(loan.contacts.length ? loan.contacts : [new ContactPersonModel()])
    }

    useEffect(() => {
        backReq('online_loan:field_values').then(({data}) => setExternalFields(data))
    }, [])

    const addContactForms = () => {
        const newContactForms = contactForms.slice();
        newContactForms.push(new ContactPersonModel());
        setContactForms(newContactForms);
    }

    const updateContactForms = (index, el) => {
        const newForms = [...contactForms]
        newForms[index] = el
        setContactForms(newForms)
    }

    async function validate() {
        if (!_isValidIncome()) {
            setIsIncomeDataError(true)
            elementsStore.showSnackbar('Заполните поля отмеченные звездочкой')
            return false
        }
        if (!_isValidContact()) {
            setIsContactDataError(true)
            elementsStore.showSnackbar('Добавьте контакт')
            return false
        }
        if (!_isValidExtra()) {
            setIsExtraDataError(true)
            elementsStore.showSnackbar('Введите все данные')
            return false
        }
        if (!_isValidPhoto()) {
            setIsPhotoDataError(true)
            elementsStore.showSnackbar('Загрузите страницу паспорта с регистрацией')
            return false
        }

        return (await nextStep())
    }

    const _isValidIncome = () => {
        const {
            employmentStatus,
            fieldOfActivity,
            maritalStatus,
            company,
            companyAddress,
            income
        } = incomeForm
        return employmentStatus && company.trim() && companyAddress.trim() && fieldOfActivity && income && maritalStatus
    }
    const _isValidContact = () => {
        let isValid = true

        for (const contact of contactForms) {
            if (!contact.fio) continue;

            for (const key in contact) {
                if (!contact[key]) {
                    return false
                }
            }
            if (!isValid) return false
        }
        return true
    }
    const _isValidExtra = () => {
        const {
            // isHaveCredit,
            isHaveAddHouse,
            isHaveAddTransport,
            addHouse,
            addTransport
        } = extraForm
        let isValid = true
        // if (isHaveCredit){
        //     isValid = creditCount && creditSum && creditMonthSum && creditCompany
        // }
        if (isHaveAddHouse) isValid = !!addHouse;
        if (isHaveAddTransport) isValid = !!addTransport;

        return isValid
    }
    const _isValidPhoto = () => {
        return passportPhotos.every(item => {
            if (item.type === passportPhotosTemplate[2].type) return true;
            return item.src
        })
    }

    const sendContacts = async () => {
        const contacts = []
        const backendContacts = onlineLoanStore.loanDetail?.contacts ?? []
        for (const contact of contactForms) {
            if (!contact.fio) continue
            const phone = trimmedPhoneWithPlus(contact.phone)
            const isLoadedContact = backendContacts.some(backendContact => {
                return backendContact.phone === phone
            } )
            if (isLoadedContact) continue;
            contacts.push({...contact, phone})
        }
        if (contacts.length) {
            return await backReq('online_loan:add_contact_persons', {contacts})
        } else {
            return {errors: false}
        }
    }

    async function nextStep() {
        const stepError = await checkCurrentStep(onlineLoanStepsArray[4], onlineLoanStore)
        if (stepError) {
            elementsStore.showSnackbar(errorsParser(stepError))
            return false
        }
        let response

        response = await sendContacts()
        if (response?.errors) {
            elementsStore.showSnackbar(errorsParser(response.errors))
            return false
        }
        response = await backReq("online_loan:personal_data", {
            "employmentStatus": incomeForm.employmentStatus,
            "fieldOfActivity": incomeForm.fieldOfActivity,
            "maritalStatus": incomeForm.maritalStatus.value,
            "employmentPosition": incomeForm.employmentPosition,
            "company": incomeForm.company,
            "workPhone": getPlainPhone(incomeForm.workPhone, { format: 'withPlus' }),
            "companyAddress": incomeForm.companyAddress,
            "income": incomeForm.income,
            "additionalIncome": incomeForm.additionalIncome,
            "additionalIncomeSource": incomeForm.additionalIncomeSource,
            "isHaveCredit": extraForm.isHaveCredit,
            ...(extraForm.isHaveCredit && {
                "creditCount": extraForm.creditCount,
                "creditSum": extraForm.creditSum,
                "creditMonthSum": extraForm.creditMonthSum,
                "creditCompany": extraForm.creditCompany,
                "isCreditOnLastMonth": extraForm.isCreditOnLastMonth,
            }),
            isHaveAddHouse: extraForm.isHaveAddHouse,
            ...(extraForm.isHaveAddHouse && {
                "addHouse": extraForm.addHouse,
            }),
            isHaveAddTransport: extraForm.isHaveAddTransport,
            ...(extraForm.isHaveAddTransport && {
                "addTransport": extraForm.addTransport,
            }),
        })
        if (response?.errors) {
            elementsStore.showSnackbar(errorsParser(response.errors))
            return false
        }
        const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[5], onlineLoanStore)
        if (nextStepErrors) {
            elementsStore.showSnackbar(errorsParser(nextStepErrors))
        }
    }

    return <div>
        <ErrorContainer innerRef={incomeDataContainerRef} hasError={isIncomeDataError}>
            <div className={s.titleWrapper}>
                <div className={s.title}>Сведение о работе и доходах</div>
                <div className={s.message}>
                    <BaseMessage type="warning" shadow={true} centered={false} close={false}>
                        Полностью заполненные поля увеличивают шансы на одобрение заявки
                    </BaseMessage>
                </div>
            </div>
            <IncomeForm fields={externalFields} incomeForm={incomeForm} setIncomeForm={setIncomeForm}/>
        </ErrorContainer>
        <ErrorContainer innerRef={contactDataContainerRef} hasError={isContactDataError}>
            <div className={s.title}>Укажите дополнительные контактные лица</div>
            <div className={s.wrapper}>
                {contactForms.map((form, index) =>
                    <ContactPersonForm
                        key={index}
                        contactForm={form}
                        setContactForm={updateContactForms.bind(this, index)}
                    />)
                }
                <div>
                    <p onClick={addContactForms} className={s.mainColor}>+ Добавить контакт</p>
                </div>
            </div>
        </ErrorContainer>
        <ErrorContainer innerRef={extraDataContainerRef} hasError={isExtraDataError}>
            <div className={s.title}>Дополнительные сведения</div>
            <ExtraForm fields={externalFields} extraForm={extraForm} setExtraForm={setExtraForm}/>
        </ErrorContainer>

        <ErrorContainer innerRef={photoDataContainerRef} hasError={isPhotoDataError}>
            <div className={s.title}>Фотографии паспорта</div>
            <div className={s.photos}>
                <OnlineLoanPhotoArray photoArray={passportPhotos} updateFunction={setPassportPhotos}/>
            </div>
        </ErrorContainer>
        <OnlineLoanButtons callback={validate} store={onlineLoanStore}/>
    </div>
}

export default RegistrationIncome
