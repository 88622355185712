export async function loadPdfSrc(url) {
    if (!url) return
    if (url.includes('1cloud')) {
        return await fetch(url)
            .then(_createPdfSrcFromResponse)
    }
    // const sessionToken = localStorage.getItem("sessionToken")
    return await fetch(url, {
        method: "GET",
    })
        .then(_createPdfSrcFromResponse)
}

async function _createPdfSrcFromResponse(res) {
    return res.blob().then(pdfBlob => {
        const newBlob = new Blob([pdfBlob], {type: 'application/pdf'});
        return URL.createObjectURL(newBlob)
    })
}
