import React from 'react';
import s from './StarRating.module.scss'
import Star from "./Star";
import elementsStore from "../../../store/elementsStore";
import {observer} from "mobx-react";

const StarRating = observer(() => {
  const STAR_LENGTH = 5

  function starsArray() {
    let starsArray = []
    for (let i = 0; i < STAR_LENGTH; i++) {
      starsArray.push(
        <div onClick={() => elementsStore.setStars(i + 1)}>
          <Star
            filled={elementsStore.stars > i}
            key={i + elementsStore.stars}
          />
        </div>
      )
    }
    return starsArray
  }

  return (<div className={s.starRating}>
      {starsArray()}
    </div>
  )
})

export default StarRating
