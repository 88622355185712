import s from "./ClubStatus.module.scss";
import React from "react";
import {observer} from "mobx-react";
import appStore from "../../../../../store/app";

const ClubStatus = observer (() => {

    const friends = appStore?.referralInfo?.data?.loans?.length

    function getStatusByFriends() {
        if (friends < 5) {
            return 'BRONZE'
        }
        if (friends < 10) {
            return 'SILVER'
        }
        return 'GOLD'
    }

    function getColorByStatus() {
        if (friends < 5) {
            return s.bronze
        }
        if (friends < 10) {
            return s.silver
        }
        return s.gold
    }


    return <div className={s.card__header}>
        <div className={[getColorByStatus()].join(' ')}>
            <span className={s.card__subtitle}>
            {getStatusByFriends()}
                </span>
        </div>
    </div>
})
export default ClubStatus
