import BaseInput from "../../atoms/BaseInput";
import s from "./PayModal.module.scss";
import React from "react";
import {isValidEmail} from "../../../utils/validators";
import {observer} from "mobx-react";
import appStore from "../../../store/app";

const PayModalEmail = observer (({setEmail, emailError, setEmailError}) => {
    const {profile} = appStore

    const onChangeEmail = val => {
        if (!val) setEmailError(false)
        setEmail(val)
    }
    const emailDefaultValue =
        profile && profile.paymentNotificationEmail
            ? profile.paymentNotificationEmail
            : null


    const checkEmail = (email) => {
        if (!email) {
            setEmailError(false)
            return
        }
        const isValid = isValidEmail(email)
        setEmailError(!isValid)
        setEmail(email)
    }
  return <>
      <BaseInput
          placeholder="E-mail"
          type="text"
          value={onChangeEmail}
          defaultValue={emailDefaultValue}
          onBlur={(e) => checkEmail(e.target.value)}
      />
      {emailError && (
          <div className={s.emailError}>
              Введите корректный email{' '}
          </div>
      )}
  </>
})
export default PayModalEmail
