import {useState, useEffect, useRef} from 'react';

function useErrorContainer() {
  const [isError, setIsError] = useState(false);

  const containerRef = useRef(null)

  useEffect(() => {
    if (!isError) {
      return
    }
    if (document.documentElement.clientWidth >= 768){

      containerRef.current.scrollIntoView({
        block: "center" ,
        inline: "nearest",
        behavior: "smooth"
      })
    } else {
      containerRef.current.style.position = 'relative'
      containerRef.current.style.top = '-75px'
      containerRef.current.scrollIntoView({
        block: "start" ,
        inline: "nearest",
        behavior: "smooth"
      })
      containerRef.current.style.top = '0'
    }

    setTimeout(() => setIsError(false), 5000)
  }, [isError])

  return [isError, setIsError, containerRef]
}

export default useErrorContainer
