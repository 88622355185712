import React from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import {VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL} from "../../../../constants/phones";


const ModalError = ({onClose}) => {
  return (
    <div className={s.loanModal}>
      <div className={s.modalTextWrapper}>
        <div className={s.modalText}>
          <p>К сожалению, во время перевода денежных средств произошла ошибка.</p>
          <p>В ближайшее время мы с Вами свяжемся.</p>
        </div>
      </div>
      <label> Горячая линия: <a href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a></label>
      <BaseButton text={'Понятно'} onClick={onClose}/>
    </div>)
}
export default ModalError
