import React, {useEffect, useState} from "react";

import s from "./RepeaterSettings.module.scss";

import BaseButton from "../../atoms/BaseButton";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import InputSlider from "../../atoms/InputSlider";
import BaseTooltip from "../../atoms/BaseTooltip";
import {backReq, formatMoney} from "../../../helpers";
import LockedInput from "../../atoms/BaseInput/LockedInput";
import {observer} from "mobx-react";
import {calculateMonthlyPayment} from "../../../utils/onlineLoanMonthlyPayment";
import ChangeOfferModal from "./ChangeOfferModal";
import MoreMoneyModal from "./MoreMoneyModal";
import {errorsParser} from "../../../utils/errorsParser";
import useErrorContainer from "../../../hooks/useErrorContainer";
import repeatStore from "../../../store/repeatStore";
import {
    carPhotosTemplate,
    ptsPhotosTemplate,
    stsPhotosTemplate
} from "../../../utils/templates/photoArrayTemplates";
import {MIN_LOAN, repeaterStepsArray} from "../../../constants/onlineLoan";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../helpers";
import elementsStore from "../../../store/elementsStore";
import BaseCurrency from "../../atoms/BaseCurrency";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import NativeSelect from "../../molecules/NativeSelect";
import {isMobile} from "../../../utils/isMobile";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";

const RepeaterSettings = observer(() => {
    const [offers, setOffers] = useState([])
    const [selectedOffer, setSelectedOffer] = useState()
    const [selectedOfferCarName, setSelectOfferCarName] = useState()
    const [money, setMoney] = useState(MIN_LOAN)
    const [accept, setAccept] = useState(true)
    const [duration, setDuration] = useState(2)
    const [goals, setGoals] = useState([])
    const [goal, setGoal] = useState('')
    const [monthlyPayment, setMonthlyPayment] = useState(0)
    const [isCarChosen, setIsCarChosen] = useState(false)
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowMoneyModal, setIsShowMoneyModal] = useState(false)
    const [placeholder, setPlaceholder] = useState('')
    const [sentencePhoto, setSentencePhoto] = useState(false)

    const [carPhotos, setCarPhotos] = useState(carPhotosTemplate)
    const [ptsPhotos, setPtsPhotos] = useState(ptsPhotosTemplate)
    const [stsPhotos, setStsPhotos] = useState(stsPhotosTemplate)

    const [isSettingError, setIsSettingError, settingContainerRef] = useErrorContainer()
    const [isOfferError, setIsOfferError, offerContainerRef] = useErrorContainer()

    useEffect(() => {
        async function fetchData() {
            await repeatStore.refreshOfferExist()
        }

        fetchData().then(insertData)

        controlPlaceholder()

        window.addEventListener('resize', controlPlaceholder)
        return () => {
            window.removeEventListener('resize', controlPlaceholder)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setMoney(MIN_LOAN)
    }, [selectedOffer])

    useEffect(() => {
        if (money => MIN_LOAN && money <= selectedOffer?.availableAmount) {
            setMonthlyPayment(calculateMonthlyPayment(money, duration))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [money, duration, selectedOffer?.availableAmount])

    const controlPlaceholder = () => {
        isMobile() ? setPlaceholder('Желаемая сумма') : setPlaceholder('Укажите желаемую сумму')
    }

    function chooseOffer(offer) {
        if (!offer) return
        setSelectedOffer(offer)
        setSelectOfferCarName(offer.name)

        setMoney(MIN_LOAN)
    }

    async function insertData() {
        try {
            if (repeatStore.onlineLoanOrder && repeatStore.onlineLoanOrder.offer) {
                setSelectedOffer(repeatStore.onlineLoanOrder.offer)
                setSelectOfferCarName(repeatStore.onlineLoanOrder.offer?.name)
                setGoal(repeatStore.onlineLoanOrder.loanPurpose)
                setDuration(repeatStore.onlineLoanOrder.loanTerm ?? duration)
                setMoney(repeatStore.onlineLoanOrder.desiredAmount ?? money)
                setIsCarChosen(!!repeatStore?.onlineLoanOrder?.offer?.vin)
            }
            const response = await backReq("online_loan:offer_list")
            setOffers(response.data)
            const selectInfo = await backReq("online_loan:field_values")
            const selectInfoData = selectInfo.data
            setGoals(selectInfoData.loanPurposeValues)
        } catch (e) {
            console.warn(e)
        }
    }

    async function updateSettings() {
        try {
            const response = await backReq("online_loan:loan_params", {
                "desiredAmount": money,
                "loanTerm": duration,
                "loanPurpose": goal
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return response.errors
            }
            return false
        } catch (e) {
            return e.message
        }
    }

    async function validate() {
        if (!selectedOffer || !isCarChosen) {
            setIsOfferError(true)
            elementsStore.showSnackbar(errorsParser('Выберите автомобиль'))
            return false
        }
        if (!goal) {
            setIsSettingError(true)
            elementsStore.showSnackbar(errorsParser('Выберите цель займа'))
            return false;
        }
        return (await nextStep())
    }

    async function nextStep() {
        try {
            const stepError = await checkCurrentStep(repeaterStepsArray[0])
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }
            const settingErrors = await updateSettings()
            if (settingErrors) return false;
            const nextStepErrors = await moveToNextOnlineLoanStep(repeaterStepsArray[1])
            if (nextStepErrors) {
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e.errors))
            return false
        }
    }

    async function chooseLoanSubject() {
        if (!selectedOffer.id) return
        try {
            const response = await backReq("online_loan:set_offer", {
                offerId: selectedOffer.id
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return false
            }
            const isSettingHasErrors = await updateSettings()
            if (isSettingHasErrors) return false;
            setIsCarChosen(true)
        } catch (e) {
            return false
        }

    }

    function changeCarApprove() {
        setIsCarChosen(false)
        setIsShowModal(false)
        setMoney(MIN_LOAN)
    }

    function openChangeModal() {
        setIsShowModal(true)
        const allPhotos = [...carPhotos, ...ptsPhotos, ...stsPhotos]
        setSentencePhoto(!!allPhotos.find(item => item.src))
    }

    return <div className={s.settings}>
        <ErrorContainer hasError={isOfferError} innerRef={offerContainerRef}>
            <div className={s.settings__group}>
                <div className={s.settings__title}>Предмет залога</div>
                <div className={s.settings__subjects}>
                    <div className={s.settings__auto}>Мои авто</div>
                </div>
                <div className={s.settings__select}>
                    <NativeSelect
                        placeholder="Автомобиль"
                        defaultValue={selectedOfferCarName}
                        onSearch={null}
                        options={offers}
                        withLabel={true}
                        customLabel={"name"}
                        onChanged={(x) => chooseOffer(x)}
                        disabled={isCarChosen}
                    />

                </div>
                <div className={s.settings__available}>
                    <span
                        className={s.settings__minor}>Вам доступно&nbsp;</span>{formatMoney(selectedOffer?.availableAmount || 0)}
                    <BaseCurrency size={12}/>
                </div>
                <LockedInput
                    placeholder="VIN"
                    value={selectedOffer?.vin}
                    disabled={true}
                />
                <LockedInput
                    placeholder="Номер кузова"
                    value={selectedOffer?.bodyNumber}
                    disabled={true}
                />
                <LockedInput
                    placeholder="Гос. номер"
                    value={selectedOffer?.regNum}
                    disabled={true}
                />
                <div className={s.settings__minor}>
                    * убедитесь, что предоставленная информация актуальна, и вы до сих пор являетесь собственником авто,
                    в
                    обратном случае обратитесь в наш контакт центр
                </div>
                <div className={s.settings__accept}>
                    <BaseCheckbox value={accept} onChange={() => setAccept(!accept)} disabled={isCarChosen} inversion>
                        Подтверждаю, что выбранный автомобиль:&lt;br&gt;
                        1. Находится у меня в собственности, в обратном случае обратитесь в наш контакт центр &lt;a
                        style="white-space: nowrap" href="tel:88007006331"&gt;8 (800) 700-63-31&lt;/a&gt; &lt;br&gt;
                        2. Не находится в обременении в виде залога
                    </BaseCheckbox>
                </div>
                {/*<div className={s.settings__message}>*/}
                {/*  <BaseMessage type="error">*/}
                {/*    Внимание! Данный автомобиль участвовал в договоре займа другого клиента. При одобрении заявки мы свяжемся с*/}
                {/*    вами для выяснения дополнительной информации*/}
                {/*  </BaseMessage>*/}
                {/*</div>*/}
                <div className={s.settings__button}>
                    <BaseButton
                        text={isCarChosen ? "Выбрано" : "Выбрать"}
                        size="default-change"
                        type={'green'}
                        disabled={!accept || isCarChosen || !selectedOfferCarName}
                        onClick={chooseLoanSubject}
                    />
                    {isCarChosen &&
                        <p onClick={openChangeModal}>Изменить </p>
                    }
                </div>
            </div>
        </ErrorContainer>
        <ErrorContainer innerRef={settingContainerRef} hasError={isSettingError}>
            <div className={s.settings__group}>
                <div className={s.settings__title}>Параметры займа</div>
                <div className={s.settings__slider}>
                    <InputSlider
                        placeholder={placeholder}
                        onChange={(x) => setMoney(x)}
                        value={money}
                        min={MIN_LOAN}
                        max={selectedOffer?.availableAmount}
                        step={5000}
                    />
                </div>
                <div
                    className={s.settings__more}
                    onClick={() => setIsShowMoneyModal(true)}
                >
                    Хочу сумму больше
                </div>
                <div className={s.settings__duration}>
                    <p><b>Срок займа</b></p>
                    <div className={s.settings__checkboxWrapper}>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox value={duration === 2} onChange={() => setDuration(2)} inversion>
                                2 месяца
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 2 месяца с возможностью
                                дальнейшей пролонгации.
                                График платежей строится исходя из оплаты только процентов, размер погашения основного
                                долга клиентом осуществляется самостоятельно. По окончании срока действия договора
                                клиент
                                может полностью погасить заём или же продлить его.
                            </BaseTooltip>
                        </div>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox value={duration === 12} onChange={() => setDuration(12)} inversion>
                                12 месяцев
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 12 месяцев. Общая сумма
                                ежемесячных выплат остается
                                фиксированной до конца срока кредитования. Одна часть средств идет на погашение
                                основного долга,
                                другая на погашение процентов.
                            </BaseTooltip>
                        </div>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox value={duration === 24} onChange={() => setDuration(24)} inversion>
                                24 месяца
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 24 месяца. Общая сумма
                                ежемесячных выплат остается фиксированной
                                до конца срока кредитования. Одна часть средств идет на погашение основного долга,
                                другая на погашение процентов.
                            </BaseTooltip>
                        </div>
                    </div>
                </div>
                <div className={s.settings__calc}>
                    <div>
                        <p>Ставка *</p>
                        <h4>7%</h4>
                    </div>
                    <div>
                        <p>Ежемесячный платеж *</p>
                        <h4>{formatMoney(monthlyPayment)}
                            <div className={s.svgWrapper}>&thinsp;
                                <BaseCurrency size={21} color={'dark'}/>
                            </div>
                        </h4>
                    </div>
                </div>
                <div className={s.settings__minor}>
                    * условия выдачи займа могут отличаться, исходя из анализа документов и состояния вашего автомобиля
                </div>
                <div className={s.settings__select}>
                    <NativeSelect
                        placeholder="Цель получения займа"
                        defaultValue={goal}
                        options={goals}
                        onSearch={null}
                        onChanged={(x) => setGoal(x)}
                        listTop={true}
                    />
                </div>
            </div>
        </ErrorContainer>
        <OnlineLoanButtons
            callback={validate}/>
        <ChangeOfferModal
            change={changeCarApprove}
            showModal={isShowModal}
            sentencePhoto={sentencePhoto}
            close={() => setIsShowModal(false)}/>
        <MoreMoneyModal
            showModal={isShowMoneyModal}
            close={() => setIsShowMoneyModal(false)}/>
        <div className={s.settings__hide}>
            <OnlineLoanPhotoArray photoArray={carPhotos} updateFunction={setCarPhotos}/>
            <OnlineLoanPhotoArray photoArray={ptsPhotos} updateFunction={setPtsPhotos}/>
            <OnlineLoanPhotoArray photoArray={stsPhotos} updateFunction={setStsPhotos}/>
        </div>

    </div>
})

export default RepeaterSettings
