import {backReq} from "../helpers";
import constants from "../constants";

const resultTextMap = {
    loan: loanTitleHandler,
    service: serviceTitleHandler
}

function loanTitleHandler(entityId) {
    return `Статус платежа по займу <br/> № ${entityId}`
}

function serviceTitleHandler(entityId, title) {
    // const [loanId, serviceId] = entityId.split(':')
    const [, serviceId] = entityId.split(':')
    return `Статус платежа по услуге ${title} (${serviceId})`
}

const getPaymentResultText = (type, entityId, title) => {
    if (resultTextMap[type]) {
        return resultTextMap[type](entityId, title);
    }
}

const pay = async({type, amount, id, email, phone, token, url, getToken, ctx}) => {
    try {
        if (!amount) return {success: false, error: 'Некорректная сумма платежа'};
        const handler = handlers[type];
        if (!handler) return {success: false, error: 'Внутреняя ошибка сервера'};
        return handler({amount, id, email, phone, token, url, getToken, type, ctx});
    } catch (e) {
        return {success: false, error: e.message};
    }

}

//функции разделены целенаправленно, так как возможен сценарий изменения логики. Функции не должны зависеть друг от друга
const _loanPaymentHandler = async({amount, id, email, phone, token, url, getToken, ctx}) => {
    if (!id) return {success: false, error: 'Займ не найден'};
    const paymentResult = await backReq('create_pay', {
        loanId: id,
        amount,
        date: Date.now(),
        email,
        phone,
        token,
        url,
        getToken,
        ...ctx,
    });
    if (paymentResult.status === constants.payment.status.success && (
        paymentResult.data.paymentStatus === constants.payment.statusCode.redirect || paymentResult.data.paymentStatus === constants.payment.statusCode.error)
    ) {
        window.location.href = paymentResult.data.url;
        return {success: true, error: ''};
    }
    if (paymentResult.status === constants.payment.status.error || (paymentResult.errors && paymentResult.errors.detail)) {
        const e = paymentResult.errors && paymentResult.errors.detail
        return {success: false, error: e};
    }
    return {success: true, error: ''};
}

const _servicePaymentHandler = async({amount, id, email, phone, token, url, getToken, ctx}) => {
    if (!id) return {success: false, error: 'Услуга не найдена'};
    const paymentResult = await backReq('create_pay', {
        serviceId: id,
        amount,
        date: Date.now(),
        email,
        phone,
        token,
        url,
        getToken,
        ...ctx,
    });
    if (paymentResult.status === constants.payment.status.success && (
        paymentResult.data.paymentStatus === constants.payment.statusCode.redirect || paymentResult.data.paymentStatus === constants.payment.statusCode.error)
    ) {
        window.location.href = paymentResult.data.url;
        return {success: true, error: ''};
    }
    if (paymentResult.status === constants.payment.status.error || (paymentResult.errors && paymentResult.errors.detail)) {
        const e = paymentResult.errors && paymentResult.errors.detail
        return {success: false, error: e};
    }
    return {success: true, error: ''};
}

const _periodPaymentHandler = async({amount, id, email, phone, token, url, getToken, type, ctx}) => {
    if (!id) return {success: false, error: 'Услуга не найдена'};
    const paymentResult = await backReq('create_pay', {
        loanId: id,
        amount,
        date: Date.now(),
        email,
        phone,
        token,
        url,
        getToken,
        type,
        ...ctx,
    });
    if (paymentResult.status === constants.payment.status.success) {
        window.location.href = paymentResult.data.url;
        return {success: true, error: ''};
    }
    if (paymentResult.status === constants.payment.status.error || (paymentResult.errors && paymentResult.errors.detail)) {
        const e = paymentResult.errors && paymentResult.errors.detail
        return {success: false, error: e};
    }
    return {success: true, error: ''};
}

const showPaymentError = (appStore, message) => {
    appStore.showNotification(true, {text: message, type: constants.payment.status.error})
}

const handlers = {
    "loan": _loanPaymentHandler,
    "service": _servicePaymentHandler,
    "monthly": _periodPaymentHandler,
    "part": _periodPaymentHandler,
    "full": _periodPaymentHandler,
}
export default {
    pay,
    showPaymentError,
    getPaymentResultText,
    constants: constants.payment,
}
