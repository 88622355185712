import NoData from "components/atoms/NoData";
import ContentContainer from "components/molecules/ContentContainer";
import OnlineLoanCard from "components/organisms/OnlineLoanCard";
import React from "react";

import s from "./OnlineLoanList.module.scss";

const OnlineLoanList = ({ loanList }) => {
	if (!loanList.length)
		return (
			<ContentContainer>
				<NoData text="Нет данных" />
			</ContentContainer>
		);

	const renderLoans = loanList.map((loan, index) => (
		<OnlineLoanCard key={loan?.offer?.offerId + index || index} {...loan} />
	));

	return <div className={s.list}>{renderLoans}</div>;
};

export default OnlineLoanList;
