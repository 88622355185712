import React, { useEffect, useState } from "react";
import { backReq } from "../../../../helpers";
import elementsStore from "../../../../store/elementsStore";
import { errorsParser } from "../../../../utils/errorsParser";
import formattedMoney from "../../../../utils/formattedMoney";
import BaseButton from "../../../atoms/BaseButton";
import BaseInput from "../../../atoms/BaseInput";
import ModalPortal from "../../../atoms/ModalPortal";
import s from "./../OnlineLoanCard.module.scss";

const ModalReduce = ({ onClose, loan }) => {
	const MIN_DESIRED_SUM = 30_000;
	const MAX_DESIRED_SUM = 300_000;
	const [sum, setSum] = useState();
	const [refusePopup, setRefusePopup] = useState(false);

	useEffect(() => {
		if (loan?.maxAmount) setSum(Number.parseInt(loan.maxAmount));
	}, [loan]);

	const chooseOffice = async () => {
		const response = await backReq("online_loan:in_office", {
			id: loan.id,
		});
		if (response.errors) {
			elementsStore.showSnackbar(errorsParser(response.errors));
			return false;
		}
		setRefusePopup(false);
		onClose();
	};
	async function agreedToReducedRate() {
		try {
			const response = backReq("online_loan:modify_amount", {
				desiredAmount: sum,
				id: loan.id,
			});
			if (response.errors) {
				elementsStore.showSnackbar(errorsParser(response.errors));
				return false;
			}
			elementsStore.showSnackbar(
				"Изменения по заявке приняты. Ожидайте обновления статуса",
				"onCheck",
			);
			onClose();
		} catch (e) {
			elementsStore.showSnackbar(errorsParser(e));
			return false;
		}
	}

	return (
		<div className={s.loanModal}>
			<div className={s.modalTextWrapper}>
				<p className={s.modalText}>
					Уважаемый клиент, мы готовы выдать займ в онлайн режиме до{" "}
					<b>{formattedMoney(loan.maxAmount)}</b> рублей.
				</p>
				<p className={s.modalText}>Уточните сумму займа:</p>
			</div>
			<div className={s.modalInput}>
				<BaseInput
					placeholder={"Сумма, руб."}
					defaultValue={sum}
					type={"currency"}
					min={loan?.minAmount ?? MIN_DESIRED_SUM}
					max={loan?.maxAmount ?? MAX_DESIRED_SUM}
					value={(e) => setSum(e)}
					onBlur={(e) =>
						e.target.value < loan?.minAmount ? setSum(loan?.minAmount) : null
					}
				/>
			</div>
			<p className={s.modalText}>
				Далее мы подпишем простой электронной подписью пакет документов (договор
				займа) и переведем денежные средства на указанную Вами банковскую карту
				**** {loan?.cardNumber}.
			</p>
			<div className={s.modalSpacer}>
				<BaseButton onClick={agreedToReducedRate} text={"Подтвердить сумму"} />
			</div>
			<ModalPortal
				showModal={refusePopup}
				closeModal={() => setRefusePopup(false)}
				size="small"
				title={"Оформление в офисе"}
			>
				<div className={s.loanModal}>
					<p className={s.modalText}>
						{" "}
						В течение нескольких минут Вы можете получить на банковскую карту до{" "}
						{loan?.desiredAmount} рублей.
					</p>
					<div className={s.modalHalfSpacer} />
					<div className={s.buttonWrapper}>
						<BaseButton onClick={chooseOffice} text={"Я приеду в офис"} />
						<BaseButton
							onClick={() => setRefusePopup(false)}
							text={"Получить деньги онлайн"}
						/>
					</div>
				</div>
			</ModalPortal>
		</div>
	);
};

export default ModalReduce;
