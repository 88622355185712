import React, {useState} from "react"
import s from "./OnlineLoanFinal.module.scss"
import {backReq, getFormattedPhone} from "../../../helpers";
import RepeaterSumInfo from "../../molecules/RepeaterSumInfo";
import {calculateMonthlyPayment} from "../../../utils/onlineLoanMonthlyPayment";
import {observer} from "mobx-react";
import repeatStore from "../../../store/repeatStore";
import {errorsParser} from "../../../utils/errorsParser";
import ModalPortal from "../../atoms/ModalPortal";
import elementsStore from "../../../store/elementsStore";
import {renderYYMMDDToDDMMYY} from "../../../utils/formattedDate";
import {useHistory} from "react-router-dom";
import formattedMoney from "../../../utils/formattedMoney";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import FinalContainerHeader from "./FinalContainerHeader";
import FinalChecked from "./FinalChecked";
import BaseMessage from "../../atoms/BaseMessage";
import onlineLoanStore from "../../../store/onlineLoanStore";
import appStore from "../../../store/app";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {getMaritalLabelByValue} from "../../../utils/templates/martialStatusTemplate";
import {FIRST_LOAN_PERCENT} from "../../../constants/onlineLoan";
import getYaId from "../../../utils/getYaId";

const carInfoTemplate = [
    {title: "Автомобиль", value: ""},
    {title: "VIN", value: ""},
    {title: "Номер кузова", value: ""},
    {title: "Гос. номер", value: ""},
]
const personalInfoTemplate = [
    {title: "ФИО", value: ""},
    {title: "Город", value: ""},
    {title: "Дата рождения", value: ""},
    {title: "Паспорт РФ", value: ""},
    {title: "Номер телефона", value: ""},
    {title: "Email", value: ""},
]

const offerInfoTemplate = [
    {title: "Сумма, ₽ *", value: "", className: []},
    {title: "Период, мес. *", value: "", className: []},
    {title: "Ежемесячный платеж, ₽ *", value: "", className: [s.half]},
    {title: "Цель получения займа", value: "", className: []},
]

const RegistrationFinal = observer(() => {
    const [carInfo, setCarInfo] = useState(carInfoTemplate)
    const [personalInfo, setPersonalInfo] = useState(personalInfoTemplate)
    const [personalInfoAdditional, setPersonalInfoAdditional] = useState(personalInfoTemplate)
    const [offerInfo, setOfferInfo] = useState(offerInfoTemplate)
    const [incomeInfo, setIncomeInfo] = useState(offerInfoTemplate)
    const [additionalContactInfo, setAdditionalContactInfo] = useState(offerInfoTemplate)
    const [cardNumber, setCardNumber] = useState()
    const [modal, setModal] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [isApprovedDataError, setIsApprovedDataError, isApprovedContainerRef] = useErrorContainer()

    useRefreshOnlineLoanDetail(setOffer)
    const history = useHistory()

    const maskedIndexAndPassportSeries = (index) => {
        if (!index) return ''
        return  (index.toString()?.match(/.{1,2}/g)).join(' ')
    }

    async function setOffer() {
        if (!onlineLoanStore?.loanDetail) return
        const loan = onlineLoanStore.loanDetail

        setOfferInfo([
            {title: "Желаемая сумма", value: formattedMoney(loan.desiredAmount), currency: true},
            {title: "Срок займа", value: loan.loanTerm + ' мес'},
            {
                title: "Ежемесячный платеж",
                value: formattedMoney(calculateMonthlyPayment(loan.desiredAmount, loan.loanTerm, FIRST_LOAN_PERCENT)),
                className: s.rightHalf,
                currency: true
            },
            {title: "Цель получения займа", value: loan.loanPurpose, className: s.row},
        ])
        setPersonalInfo([
            {title: "ФИО", value: `${loan.surname} ${loan.name} ${loan.patronymic}`, className: s.leftHalf},
            {title: "Пол", value: loan.gender === 'm' ? 'Мужской' : 'Женский'},
            {title: "Дата рождения", value: renderYYMMDDToDDMMYY(loan.birthDate)},
            {title: "Город рождения", value: loan.birthPlace},
            {title: "Гражданство", value: loan.citizenship},
            {title: "Паспорт", value: `${maskedIndexAndPassportSeries(loan.passportSeries)} ${loan.passportNumber}`},
            {title: "Дата выдачи", value: renderYYMMDDToDDMMYY(loan.passportDate)},
            ...(loan.snils ? [{title: "СНИЛС", value: loan.snils}] : []),
            ...(loan.inn ? [{title: "ИНН", value: loan.inn}] : [])
        ])
        setPersonalInfoAdditional([
            {title: "Кем выдан", value: loan.passportIssuedBy, className: s.wide},
            {title: "Код подразделения", value: loan.divisionCode},
            {
                title: `Адрес регистрации ${loan.registrationIsActual ? '(Совпадает с адресом проживания)' : ''}`,
                value: loan.registration,
                className: s.row
            },
            { title: "Индекс проживания", value: maskedIndexAndPassportSeries(loan.registrationIndex)},

            ...(!loan.registrationIsActual ? [
                {title: `Адрес фактического проживания`, value: loan.actualRegistration, className: s.row},
                {title: "Индекс проживания", value: maskedIndexAndPassportSeries(loan.registrationIsActual ? loan.registrationIndex : loan.actualRegistrationIndex)},
            ] : []),

            {title: "Мобильный телефон", value: getFormattedPhone(appStore?.profile?.phone || '')},
            {title: "Email", value: loan.orderEmail},
        ])
        setCarInfo([
            {title: "Автомобиль", value: `${loan.carMark} ${loan.carModel}, ${loan.colorBody}, ${loan.type}, ${loan.releaseYear}г., ${loan.engineVolume}`, className: s.row},
            {title: "Гос. номер", value: loan.regNum},
            {title: "VIN", value: loan.vin},
            {title: "Номер кузова", value: loan.bodyNumber},
            ...(loan.ptsNumber ? [
                {title: "Номер ПТС", value: loan.ptsNumber},
                {title: "Дата выдачи", value: renderYYMMDDToDDMMYY(loan.ptsDate)},
                {title: "Кем выдан", value: loan.ptsIssued}
            ] : []),
            ...(loan.eptsNumber ? [
                {title: "Номер ПТС", value: loan.eptsNumber},
                {title: "Дата формирования выписки *", value: loan.eptsDate},
                {title: "Кем оформлен *", value: loan.eptsIssued}
            ] : []),
            {title: "СТС", value: `${loan.stsSeries} ${loan.stsNumber}` },
            {title: "Дата выдачи ", value: renderYYMMDDToDDMMYY(loan.stsDate)},
            {title: "Кем выдан ", value: loan.stsIssued}
        ])
        setIncomeInfo([
            {title: "Статус трудоустройства", value: loan.employmentStatus},
            ...(loan.employmentPosition ? [{title: "Должность", value: loan.employmentPosition}] : []),
            {title: "Семейное положение", value: getMaritalLabelByValue(loan.maritalStatus)},
            {title: "Компания", value: loan.company, className: s.wide},
            ...(loan.workPhone ? [{title: "Рабочий телефон", value: getFormattedPhone(loan.workPhone || '')}] : []),
            {title: "Основной доход семьи", value: formattedMoney(loan.income), currency: true},
            ...(loan.additionalIncome ? [{title: "Дополнительный доход", value: formattedMoney(loan.additionalIncome), currency: true}] : []),
            ...(loan.additionalIncomeSource ? [{title: "Источник доп. дохода", value: loan.additionalIncomeSource}] : []),
        ])
        const contacts = []
        loan.contacts.forEach(contact => {
            if (contact.contact) contacts.push({title: "Кем является", value: contact.contact})
            if (contact.fio) contacts.push({title: "ФИО", value: contact.fio})
            if (contact.phone) contacts.push({title: "Номер телефона", value: getFormattedPhone(contact.phone)})
            if (contact.address) contacts.push({title: "Адрес проживания", value: contact.address, className: s.row})
        })
        setAdditionalContactInfo(contacts)

        setCardNumber("**** " + loan.cardNumber)
    }

    async function makeRequest() {
        if (!isApproved){
            setIsApprovedDataError(true)
            elementsStore.showSnackbar(errorsParser('Подтвердите введенные данные'))
            return
        }
        const response = await elementsStore.callSmsAction(()=>backReq("online_loan:get_code"))
        if (response.errors){
            elementsStore.showSnackbar(errorsParser(response.errors))
            return
        }
        setModal(true)
    }

    const completeOnlineLoan = async () => {
        elementsStore.setCheckingSmsCode(true)
        try {
            const response = await backReq("online_loan:send_order", {
                id: onlineLoanStore.loanDetail.id,
                code: elementsStore.smsCode,
                url: localStorage.getItem('utm'),
                yandex_id: getYaId()
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return false
            }
            elementsStore.setSmsCodeConfirmed(true)
            setTimeout(() => {
                repeatStore.setEndOfRegistrationModal(true)
                history.push("loans-request")
                elementsStore.smsInputRestoreDefault()
            }, 300)

        } catch (e) {
            console.warn(e)
        } finally {
            elementsStore.setCheckingSmsCode(false)
        }
    }

    const renderSumInfo = (fields) => fields.map(field => <div
        key={field.title + field.value}
        className={field.className}>
        <RepeaterSumInfo
            title={field.title}
            value={field.value}
            currency={field.currency}
        />
    </div>)

    const hasAdditionalContacts = onlineLoanStore.loanDetail?.contacts?.length || false


    return (<div>
        <ErrorContainer>
            <FinalContainerHeader
                title={"Параметры займа"}
                step={0}
                store={onlineLoanStore}
            />
            <div className={s.fourColumn}>
                {renderSumInfo(offerInfo)}
            </div>
        </ErrorContainer>
        <ErrorContainer>
            <FinalContainerHeader
                title={"Персональные данные"}
                step={1}
                store={onlineLoanStore}
            />
            <div className={s.fourColumn}>
                {renderSumInfo(personalInfo)}
            </div>
            <div className={s.finalCheckedWrapper}>
                {onlineLoanStore.loanDetail.haveForeignPassport &&
                    <FinalChecked>У меня есть заграничный паспорт</FinalChecked>}
                {onlineLoanStore.loanDetail.noProsecuted &&
                    <FinalChecked> Не находился под судом или следствием</FinalChecked>}
                {onlineLoanStore.loanDetail.noCriminal &&
                    <FinalChecked>Не привлекался к уголовной ответственности</FinalChecked>}
            </div>
            <div className={s.fourColumn}>
                {renderSumInfo(personalInfoAdditional)}
            </div>
        </ErrorContainer>

        <ErrorContainer>
            <FinalContainerHeader
                title={"Предмет залога (автомобиль)"}
                step={5} store={onlineLoanStore}
            />
            <div className={s.threeColumn}>
                {renderSumInfo(carInfo)}
            </div>
        </ErrorContainer>
        <ErrorContainer>
            <FinalContainerHeader
                title={"Сведения о доходах и работе"}
                step={4} store={onlineLoanStore}
            />
            <div className={s.threeColumn}>
                {renderSumInfo(incomeInfo)}
            </div>
        </ErrorContainer>
        { hasAdditionalContacts &&
            <ErrorContainer>
                <FinalContainerHeader
                    title={"Дополнительные контакты и сведения"}
                    step={4}
                    store={onlineLoanStore}
                />
                <div className={s.threeColumn}>
                    {renderSumInfo(additionalContactInfo)}
                </div>
            </ErrorContainer>
        }
        <ErrorContainer>
            <FinalContainerHeader
                title={"Банковская карта для получения займа"}
                step={6}
                store={onlineLoanStore}
            />
            <div className={s.threeColumn}>
                <div className={s.row}>
                    <RepeaterSumInfo
                        title={"Карта"}
                        value={cardNumber}
                    />
                </div>
            </div>
        </ErrorContainer>

        <div className={s.infoWrapper}>
            <BaseMessage type="warning" shadow={true} centered={false} close={false} size={'biggest'}>
                <div>
                    * условия выдачи займа могут отличаться, исходя из анализа документов и состояния вашего автомобиля
                </div>
            </BaseMessage>
            <div className={s.checkboxWrapper}>
                <ErrorContainer hasError={isApprovedDataError} innerRef={isApprovedContainerRef}  >
                    <BaseCheckbox
                        value={isApproved}
                        onChange={() => setIsApproved(!isApproved)}
                        inversion
                    >
                        Подтверждаю, что указанные мною данные проверены, являются точными и верными
                    </BaseCheckbox>
                </ErrorContainer>
            </div>
        </div>
        <ModalPortal
            title="Подписание заявки"
            showModal={modal}
            closeModal={() => setModal(false)}>
            <div>
                <div className={s.final__modal}>
                    Для отправки заявки вам необходимо подписать ее простой электронной подписью. Для этого вам
                    необходимо ввести код из SMS, который отправлен на
                    телефон {
                    appStore?.profile?.phone
                      ? <span style={{whiteSpace: 'nowrap'}}>{getFormattedPhone(appStore?.profile?.phone)}.</span>
                      :  "указанный в заявке"
                    }
                </div>
                <ConfirmCodeInput onEntered={completeOnlineLoan} onSend={makeRequest}/>
            </div>
        </ModalPortal>
        <OnlineLoanButtons callback={makeRequest} store={onlineLoanStore}/>
    </div>)
})

export default RegistrationFinal
