import {backReq} from "../../helpers";

export const fineBackendRequest = (name, data) =>
    backReq(name, data).then((res) => {
        if (!res || !res.status)
            return Promise.reject('Неизвестная ошибка');

        if (res.status === 'error')
            return Promise.reject((res.errors && res.errors[Object.keys(res.errors)[0]]) || 'Неизвестная ошибка');

        return Promise.resolve(res.data);
    });
export const getCarVendors = (title) => fineBackendRequest('get_mark_list', { title }).then((data) => data.map((vendor) => ({ value: vendor.id, label: vendor.title })));
export const getCarModels = (mark, title) => fineBackendRequest('get_model_list', { mark, title }).then((data) => data.map((model) => ({ value: model.id, label: model.title })));
export const getLoanLimit = (model, releaseYear) => fineBackendRequest('calculate_lkk_loan', { model, releaseYear });

const CURRENT_YEAR = new Date().getFullYear()

export const YEARS = new Array(30).fill(0).map((_, index) => {
    const value = (CURRENT_YEAR - index).toString()
    return {
        value,
        label: value
    }
})
