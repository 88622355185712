import React, {useState} from "react";
import BaseButton from "../../atoms/BaseButton";
import s from "./RepeaterModeration.module.scss";
import ModerationTabs from "./ModerationTabs";
import {observer} from "mobx-react";
import appStore from "../../../store/app";

const RepeaterModeration = observer(() => {
    const [isOpen, setIsOpen] = useState(false)
    const isOnlineLoanAdmin = appStore?.profile?.isOnlineLoanAdmin
    if (!isOnlineLoanAdmin) return null;
    const initButton =
        <div className={[s.repeaterModeration, s.buttonsLayout].join(' ')}>
            <BaseButton text="Управление" onClick={() => setIsOpen(true)}></BaseButton>
        </div>
    return (
        isOpen ? <ModerationTabs close={() => setIsOpen(false)}/> : initButton
    )
})
export default RepeaterModeration
