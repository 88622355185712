import React from "react";
import BaseCollapse from "../../../atoms/BaseCollapse";
import s from "./Faq.module.scss";

const Faq = ({questions }) => {

    if (!questions || !questions.length) return null

    const panels = questions.map(({ question, answer }, i) => {
        return <React.Fragment key={question}>
            {i > 0 && <hr/>}
            <BaseCollapse head={question}>
                { answer }
            </BaseCollapse>
        </React.Fragment>
    })

    return (
        <div className={s.faq}>
            <h2 className="title">Вопрос-ответ</h2>
            <div className={s.wrapper}>
                {panels}
            </div>
        </div>
    )

}

export default Faq
