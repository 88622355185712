import React, {useEffect, useState} from 'react';
import s from './SelectionPayment.module.scss'
import appStore from "../../../../store/app";
import {observer} from "mobx-react";
import {formatMoney} from "../../../../helpers";
import constants from "../../../../constants";
import {MAX_CFR_PAY_AMOUNT} from "../constants";
import TooltipWrapper from "../../../atoms/TooltipWrapper/TooltipWrapper";

const SelectionPayment = observer(({setSelectedPeriod, selectedPeriod}) => {
    const [active, setActive] = useState('')
    const {full, part} = appStore.currentEntityForPay.parts
    const paymentType = constants.payment.type
    const clickHandler = (active, period) => {
        setActive(active)
        setSelectedPeriod(period)
        appStore.setCurrentEntityForPayAmount(period.minSum ?? (period.fullSum <= MAX_CFR_PAY_AMOUNT ? period.fullSum : MAX_CFR_PAY_AMOUNT))
        appStore.currentEntityForPay.type = active
    }
    useEffect(() => {
        clickHandler(paymentType.part, part)
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (selectedPeriod.fullSum) setActive(paymentType.full)
        else setActive(paymentType.part)
        // eslint-disable-next-line
    }, [selectedPeriod])

    return (
        <div className={s.wrapper}>
            <button onClick={() => clickHandler(paymentType.part, part)}
                    className={[s.button, active === paymentType.part ? s['button--active'] : ''].join(' ')}>
                {part.minSum ? `от ${formatMoney(part.minSum)} ₽` : <span>&nbsp;</span>}
                <span>Частичное погашение</span>
            </button>
            <TooltipWrapper
                placement={'top'}
                content={'Для сумм более 1 000 000 руб только частичное погашение'}
                active={full.fullSum > MAX_CFR_PAY_AMOUNT}
            >
                <button disabled={full.fullSum > MAX_CFR_PAY_AMOUNT}
                        onClick={() => clickHandler(paymentType.full, full)}
                        className={[s.button, active === paymentType.full ? s['button--active'] : ''].join(' ')}>
                    {full.fullSum ? `${formatMoney(full.fullSum)} ₽` : <span>&nbsp;</span>}
                    <span>Полное погашение</span>
                </button>
            </TooltipWrapper>
        </div>
    );
});

export default SelectionPayment;
