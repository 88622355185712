import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as NoDataIcon} from './img/no-data.svg'
import s from './NoData.module.scss'
import BaseButton from "../BaseButton";
import LoanOrderModal from "../../../containers/LoanOrderModal";

const props = {
    subtitle: PropTypes.string,
    icon: PropTypes.string,
    buttonText: PropTypes.string,
}

const NoData = props => {

    const [showLoanModal, setShowLoanModal] = useState(false)

    return (
        <div className={s.noData}>
            {props.icon
                ? <img alt={'no-data'} className={s.noDataIcon} src={require(`./img/no-data-${props.icon}.png`)}/>
                : <div className={s.noDataSvg}><NoDataIcon /></div>
            }
            <p className={s.noDataTitle}>{ props.title ? props.title : 'Нет данных' }</p>
            <p className={s.noDataSubtitle}>{ props.subtitle }</p>
            {props.buttonText && <div className={s.noDataButton}>
                <BaseButton
                    type="green"
                    size="mini"
                    text={props.buttonText}
                    plus
                    onClick={() => setShowLoanModal(true)}
                />
            </div>}
            <LoanOrderModal
                showModal={ showLoanModal }
                closeModal={ () => setShowLoanModal(false)}
            />
        </div>
    )
}

NoData.propTypes = props

export default NoData
