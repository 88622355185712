import React, {useState} from 'react';
import InputMask from "react-input-mask"
import s from './ConfirmCodeInput.module.scss'
import {observer} from "mobx-react";
import elementsStore from "../../../store/elementsStore";
import ArrowSpinIcon from "../ConfirmCodeInput/img/arrow-spin.svg";
import SuccessIcon from "../ConfirmCodeInput/img/success.svg";
import ErrorIcon from "../ConfirmCodeInput/img/error.svg";
import BaseButton from "../BaseButton";

const ConfirmCodeInput = observer(({onEntered, onSend}) => {
    const [isFullInput, setIsFullInput] = useState(false)

    const onChange = (event) => {
        elementsStore.setSmsCode(event.target.value)
        onEnteredCode()
    }

    const onEnteredCode = () => {
        let smsCodeLength = elementsStore?.smsCode.replace(/-/g, '').length
        if (smsCodeLength >= 6 && !isFullInput) {
            onEntered()
            setIsFullInput(true)
        } else if (smsCodeLength < 6 && isFullInput) {
            setIsFullInput(false)
        }
    }
    const arrowSpinClasses = `${s.ConfirmCodeInput__icon} ${elementsStore.checkingSmsCode && s['ConfirmCodeInput__icon--loader']}`
    const successIconClasses = `${s.ConfirmCodeInput__icon} ${elementsStore.smsCodeConfirmed && !elementsStore.checkingSmsCode && s['ConfirmCodeInput__icon--active']}`
    const errorIconClasses = `${s.ConfirmCodeInput__icon} ${!elementsStore.smsCodeConfirmed && !elementsStore.checkingSmsCode && elementsStore?.smsCode.replace(/-/g, '').length >= 6 && s['ConfirmCodeInput__icon--active']}`
    return (
        <div className={s.ConfirmCodeInput}>
            <div className={s.ConfirmCodeInput__wrapper}>
                <div
                    className={[
                        s.ConfirmCodeInput__placeholder,
                        s[`${elementsStore.smsCode ? 'ConfirmCodeInput__placeholder--active' : ''}`]
                    ].join(' ')}
                >
                    Код из SMS
                </div>
                <InputMask
                    mask={'999999'}
                    className={s.ConfirmCodeInput__field}
                    value={elementsStore.smsCode}
                    onChange={onChange}
                    autoComplete="off"
                    type="tel"
                    maskChar='-'
                />
                <img
                    alt=''
                    className={arrowSpinClasses}
                    src={ArrowSpinIcon}
                />
                <img
                    alt=''
                    className={successIconClasses}
                    src={SuccessIcon}
                />
                <img
                    alt=''
                    className={errorIconClasses}
                    src={ErrorIcon}
                />
            </div>
            {elementsStore.smsCodError && <div className={s.ConfirmCodeInput__error}
                                               dangerouslySetInnerHTML={{__html: elementsStore.smsCodError}}/>}
            <span className={s.ConfirmCodeInput__timer}>
        {elementsStore.smsResendTimer
            ? `Получить новый код можно через ${elementsStore.smsResendTimer} сек.`
            : <BaseButton
                text="Выслать SMS"
                type="bony"
                onClick={onSend}
            />
        }
      </span>

        </div>
    );
})

export default ConfirmCodeInput;
