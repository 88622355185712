import s from "./BalanceCard.module.scss";
import {ReactComponent as InvitedIcon} from "../../img/invite.svg";
import {ReactComponent as ProfileIcon} from "../../img/profile.svg";
import React, {useState} from "react";
import BaseButton from "../../../../atoms/BaseButton";
import ClubStatus from "../ClubStatus";
import {isMobile} from "../../../../../utils/isMobile";
import {observer} from "mobx-react";
import appStore from "../../../../../store/app";
import AddFriendModal from "./AddFriendModal";
import ModalContainer from "../../../../atoms/ModalContainer";
import WithdrawalBonusesModal from "./WithdrawalBonusesModal";
import formattedMoney from "../../../../../utils/formattedMoney";
import {useHistory} from "react-router-dom";
import HelpIcon from "../../../../../img/help.svg";
import {yandexMetrikaReachGoal} from "../../../../../utils/yandex-metrika";

const BalanceCard = observer(() => {

    const [isShowAddFriendModal, setIsShowAddFriendModal] = useState(false);
    const [isShowWithdrawalModal, setIsShowWithdrawalModal] = useState(false);

    const history = useHistory()

    const friends = appStore.referralInfo?.data?.loans?.length ?? 0

    const openDrawer = () => {
        appStore.setIsReferralDrawerVisible(true)
        yandexMetrikaReachGoal('opisanie_100druzei')
    }

    const showAddFriendModal = () => {
        setIsShowAddFriendModal(true)
        yandexMetrikaReachGoal('priglasit_druga_100druzei')
    }
    const showWithdrawalModal = () => setIsShowWithdrawalModal(true)

    const isMainPage = window.location.pathname === '/'

    return (
        <div className={s.card}>
            <div className={s.card__title}>
                <div className={s.card__header}>
                    <div className={s.card__titleText}>Ваш баланс</div>
                    <div className={s.card__status}>
                    {window.location.pathname === '/' && <ClubStatus/>}
                    <div className={s.card__questionIcon} onClick={openDrawer}>
                        <img
                            src={HelpIcon}
                            alt=""
                        />
                    </div>
                    </div>
                </div>
                <div className={s.card__subtitle}>
                    {formattedMoney(appStore.referralInfo.bonusBalance)}
                </div>
            </div>
            <div className={s.card__cell}>
                <div className={s.card__cellKey}>
                    <div className={s.card__cellIcon}><InvitedIcon style={{
                        maxWidth: '24px',
                    }}/></div>
                    <div className={s.card__cellTitle}>Друзей привели</div>
                </div>
                <div className={s.card__cellValue}>{friends}</div>
            </div>
            {/*<div className={s.card__hr}/>*/}
            {/*<div className={s.card__cell}>*/}
            {/*    <div className={s.card__cellKey}>*/}
            {/*        <div className={s.card__cellIcon}><LoadingIcon style={{*/}
            {/*            maxWidth: '24px',*/}
            {/*        }}/></div>*/}
            {/*        <div className={s.card__cellTitle}>Ожидают займ</div>*/}
            {/*    </div>*/}
            {/*    <div className={s.card__cellValue}>{friends}</div>*/}
            {/*</div>*/}

            {isMainPage ?
                <div className={s.cardButton__wrapper}>
                    <BaseButton
                        type="green"
                        text="Зайти в клуб"
                        onClick={() => history.push('/club')}>

                    </BaseButton>
                    {isMobile() ? <div className={s.icon__wrapper} onClick={showAddFriendModal}>
                            <ProfileIcon></ProfileIcon>
                        </div> :
                        <BaseButton
                            type="inverted"
                            text="Пригласить друга"
                            onClick={showAddFriendModal}
                        >
                        </BaseButton>
                    }
                </div>
                :
                <div className={s.cardButtonClub__wrapper}>
                    <BaseButton
                        text="Пригласить друга"
                        onClick={showAddFriendModal}
                    />

                    <BaseButton
                        type="inverted"
                        text="Вывести бонусы"
                        onClick={showWithdrawalModal}
                    />

                </div>
            }

            <ModalContainer
                showModal={isShowAddFriendModal}
                closeModal={() => setIsShowAddFriendModal(false)}
                title={'Позовите друга удобным способом'}
                closeOnBlur={true}
            >
                <AddFriendModal
                    closeModal={() => setIsShowAddFriendModal(false)}
                />
            </ModalContainer>
            <ModalContainer
                showModal={isShowWithdrawalModal}
                closeModal={() => setIsShowWithdrawalModal(false)}
                title={'Вывод бонусов'}
                closeOnBlur={true}
                size={'small'}
            >
                <WithdrawalBonusesModal
                    closeModal={() => setIsShowWithdrawalModal(false)}
                />
            </ModalContainer>
        </div>
    )
})

export default BalanceCard
