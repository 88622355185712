import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import s from "../../../components/Repeater/RepeaterBanner/RepeaterBanner.module.scss";
import OnlineLoanCard from "../../../components/organisms/OnlineLoanCard";
import RepeaterBanner from "../../../components/Repeater/RepeaterBanner";
import {loanStatuses} from "../../../constants/onlineLoan";
import OnlineLoanBanner from '../../../components/PageOnlineLoan/OnlineLoanBanner/OnlineLoanBanner'
import {repeaterExistsHandler} from "../../../components/Repeater/helpers";
import onlineLoanStore from "../../../store/onlineLoanStore";
import appStore from "../../../store/app";

const Banner = observer(() => {
    const [component, setComponent] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (!appStore.onlineLoanOrderExist) return

        async function render() {
            try {
                setIsLoading(true)
                await renderInit(appStore.onlineLoanOrderExist)
            } catch (e) {
                setIsError(true)
            } finally {
                setIsLoading(false)
            }
        }

        render()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStore.onlineLoanOrderExist])

    const renderInit = async (onlineLoanOfferExist) => {
        const {
            isOfferExist = undefined,
            isFirstOrderAllowed = undefined,
            onlineLoanOrder = {}
        } = onlineLoanOfferExist
        let localComponent;
        if (!isOfferExist && !isFirstOrderAllowed) {
            localComponent = null
        } else if (isActiveLoan(onlineLoanOrder)) {
            localComponent = renderResultComponent(onlineLoanOrder)
        } else if (isFirstOrderAllowed) {
            localComponent = await renderFirstTimeComponent(onlineLoanOrder)
        } else {
            localComponent = await renderRepeaterComponent({isOfferExist, onlineLoanOrder})
        }
        setComponent(localComponent)
    }

    const renderFirstTimeComponent = async (onlineLoanOrder) => {
        await onlineLoanStore.setOnlineLoanOrder(onlineLoanOrder)
        return <OnlineLoanBanner isProcessingLoan={onlineLoanOrder?.id ?? false}/>
    }

    const renderRepeaterComponent = async ({isOfferExist, onlineLoanOrder}) => {
        await repeaterExistsHandler({isOfferExist, onlineLoanOrder})
        return isProcessingLoan(isOfferExist, onlineLoanOrder) ? <RepeaterBanner/> : null
    }

    const renderResultComponent = (onlineLoanOrder) => {
        const onlineLoanTitle = onlineLoanTitleByStatus(onlineLoanOrder?.status)
        return (
            <div className={s.banner}>
                <h2 className="title">{onlineLoanTitle}</h2>
                <OnlineLoanCard isBanner {...onlineLoanOrder} />
            </div>
        )
    }

    const isActiveLoan = (onlineLoanOrder) => {
        if (!onlineLoanOrder?.status) return false;
        const activeStatus = [loanStatuses.onCheck, loanStatuses.approved, loanStatuses.needInfo, loanStatuses.decreaseAmount, loanStatuses.modifiedAmount]
        return activeStatus.some(item => item === onlineLoanOrder.status)
    }

    const isProcessingLoan = (isOfferExist, onlineLoanOrder) => {
        if (isOfferExist && onlineLoanOrder?.status === loanStatuses.inProcess) return true;
        return !!(isOfferExist && !onlineLoanOrder?.id);
    }

    const onlineLoanTitleByStatus = (status) => {
        switch (status) {
            case (loanStatuses.onCheck):
                return 'Заявка на рассмотрении'
            case (loanStatuses.modifiedAmount):
                return 'Заявка одобрена с изменением суммы'
            case (loanStatuses.needInfo):
                return 'Необходимо скорректировать информацию в заявке'
            case (loanStatuses.approved):
                return 'Вам одобрен займ!'
            default :
                return 'Вам одобрен займ!'
        }
    }

    if (isLoading || isError) {
        return null
    }

    return <div>
        {component}
    </div>

})
export default Banner
