export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const isValidEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
};

export const validatePhone = (phone) => {
  const operatorCode = phone[4]
  if ([0, 1, 2, 7].some(number => number === +operatorCode)) {
    return 'Проверьте код оператора или региона — он может начинаться на 3, 4, 5, 6, 8, 9'
  } else if (phone.slice(4, 6) === '89') {
    return 'Проверьте код оператора или региона'
  }
  return false
}
export function isItemHasSrc(item) {
    return Boolean(item.src)
}
export function isItemSrcEmpty(item) {
    return !isItemHasSrc(item)
}
