import React from 'react'

import bs from './BaseSelect.module.scss'
import s from '../BaseInput/BaseInput.module.scss'
import Arrow from "../AutoSelect/img/arrow.svg";

class BaseSelect extends React.Component {
  constructor(props) {
    super();
    this.props = props
    this.initialType = this.props.type
    this.selectRef = React.createRef();
    this.state = {
      showItems: false,
      selected: '',
      multipleValue: []
    };
  }

  componentDidMount() {
    this.setState({
      selectedType: this.state.selectedType,
      inputField: this.props.defaultValue
    })
  }

  showItems(value) {
    this.setState({
      showItems: value,
    })
  }

  handleChange(event) {
    this.setState({
      selectedType: this.state.selectedType,
      inputField: event.target.value,
      showItems: false,
    })

    if (this.props.withLabel){
      const index = this.props.options.findIndex(option => {
        if (this.props.customLabel){
          return option[this.props.customLabel] === event.target.value
        } else {
          return option.label === event.target.value
        }
      })
      this.props.onChanged(this.props.options[index])
      return
    }

    if(this.props.multiple) {
      this.setState(() => ({multipleValue: ['']}))
      let selectedArr = []
      let selectedNode = event.target.selectedOptions
      for(let i = 0; i < selectedNode.length; i++) {
        if(selectedNode[i].value.length) {
          selectedArr.push(selectedNode[i].value)
        }
      }
      this.setState(() => ({multipleValue: [...selectedArr]}))
      this.props.onChanged(selectedArr)
    } else {
      this.props.onChanged(event.target.value)
    }
  }

  onSelectBlur = () => {
      this.showItems(false)
      if (this.props.onBlur && typeof this.props.onBlur === 'function') {
          this.props.onBlur()
      }
  }

  render() {
    const wrapperClasses = [s.inputWrapper]
    if (this.state.inputField || this.props.defaultValue || this.state.multipleValue.length) wrapperClasses.push(s.activePlaceholder)
    if (this.state.showItems) {
      wrapperClasses.push(s.activeIcon)
    }

    return (
      <div className={wrapperClasses.join(' ')} style={this.props.style}>
        <div className={s.placeholder}>{this.props.placeholder}</div>
        {this.props.multiple && <span className={s.multipleValueNative}>{this.props.defaultValue.join(', ')}</span>}
        <select
          disabled={this.props.disabled}
          multiple={this.props.multiple}
          className={`${s.input} ${bs.selectStyles} ${this.props.multiple ? s.selectInputNative : ''}`}
          value={this.props.defaultValue}
          style={{height: this.props.height, fontSize: this.props.fontSize, fontWeight: this.props.fontWeight}}
          onChange={(e) => this.handleChange(e)}
          onFocus={()=>this.showItems(true)}
          onBlur={this.onSelectBlur}
          ref={this.selectRef}
        >
          <option disabled selected></option>
          {
            this.props.options.map(option => {
              const optionValue = this.props.withLabel ? this.props.customLabel ? option[this.props.customLabel] : option.label : option
              return (
                <option
                  key={optionValue}
                  className={s.option}
                  value={optionValue}
                  onClick={() => this.showItems(false)}
                >
                  {optionValue}
                </option>
              )
            })
          }
          }
        </select>
        <img className={[s.icon, s['icon--chevron']].join(' ')} src={Arrow} alt=''/>
      </div>
    )

  }
}

export default BaseSelect
