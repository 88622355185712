import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { backReq, sendAvatarPhoto } from "../../../helpers";
import appStore from "../../../store/app";
import { loadImageSrc } from "../../../utils/imageLoader";
import BaseButton from "../../atoms/BaseButton";
import { ReactComponent as PlaceholderImg } from "./placeholder.svg";
import s from "./profilePhoto.module.scss";

export const ProfilePhoto = observer(() => {
	const inputRef = useRef();

	const [src, setSrc] = useState("#");

	const transferFocus = () => inputRef.current.click();

	const updateAvatar = async () => {
		const formData = new FormData();
		formData.append("file", inputRef.current.files[0]);
		await sendAvatarPhoto(formData);
		await backReq("user").then((res) => appStore.setProfile(res.data));
		await getAvatar();
	};

	const getAvatar = async () => {
		let blobURL = "";
		try {
			blobURL = await loadImageSrc(appStore.profile.avatar, true);
		} catch (e) {
			console.warn(e);
		}
		setSrc(blobURL);
	};

	useEffect(() => {
		async function fetchData() {
			await getAvatar();
		}
		fetchData();
	}, []);

	return (
		<div className={s.profilePhoto}>
			{appStore.profile.avatar ? (
				<>
					<div className={s.imageWrapper}>
						<picture>
							<img src={src} alt="avatar" />
						</picture>
					</div>
					<BaseButton type="bony" text="Изменить" onClick={transferFocus} />
				</>
			) : (
				<>
					<div className={s.imageWrapper}>
						<PlaceholderImg />
					</div>
					<BaseButton
						type="bony"
						text="Добавить фото"
						onClick={transferFocus}
						plus
					/>
				</>
			)}
			<input
				type="file"
				ref={inputRef}
				className={s.inputFile}
				onChange={updateAvatar}
			/>
		</div>
	);
});
