import React, {useState} from 'react'
import ErrorContainer from "../../molecules/ErrorContainer";
import s from "./OnlineLoanSubject.module.scss";
import BaseTabs from "../../atoms/BaseTabs";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import onlineLoanStore from "../../../store/onlineLoanStore";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../../Repeater/helpers";
import {onlineLoanStepsArray} from "../../../constants/onlineLoan";
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";
import {backReq} from "../../../helpers";
import BaseMessage from "../../atoms/BaseMessage";
import SubjectForm from "./SubjectForm";
import PtsForm from "./PtsForm";
import EptsForm from "./EptsForm";
import StsForm from "./StsForm";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import SpecialEquipment from "./SpecialEquipment";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";
import {
    carPhotosTemplate,
    eptsPhotosTemplate,
    ptsPhotosTemplate,
    stsPhotosTemplate,
} from "../../../utils/templates/photoArrayTemplates";
import {
    dateToYMDHyphenated,
} from "../../../utils/formattedDate";


const RegistrationSubject = () => {
    const [subjectForm, setSubjectForm] = useState({})
    const [ptsForm, setPtsForm] = useState({})
    const [eptsForm, setEptsForm] = useState({})
    const [stsForm, setStsForm] = useState({})
    const [isCarApprove, setIsCarApprove] = useState(false)

    const [carPhotos, setCarPhotos] = useState(carPhotosTemplate)
    const [ptsPhotos, setPtsPhotos] = useState(ptsPhotosTemplate)
    const [eptsPhotos, setEptsPhotos] = useState(eptsPhotosTemplate)
    const [stsPhotos, setStsPhotos] = useState(stsPhotosTemplate)
    const [activeTab, setActiveTab] = useState("pts")

    const ptsTabs = {pts: "ПТС", epts: "ЭПТС"}

    const [isSubjectFormError, setIsSubjectFormError, subjectFormContainerRef] = useErrorContainer()
    const [isCarPtsError, setIsCarPtsError, carPtsContainerRef] = useErrorContainer()
    const [isCarStsError, setIsCarStsError, carStsContainerRef] = useErrorContainer()
    const [isCarPhotoError, setIsCarPhotoError, carPhotoContainerRef] = useErrorContainer()
    const [isCarPrivateError, setIsCarPrivateError, isCarPrivateContainerRef] = useErrorContainer()

    useRefreshOnlineLoanDetail(insertData)

    async function insertData() {
        const loan = onlineLoanStore?.loanDetail ?? {}
        setSubjectForm(loan)
        setPtsForm({...loan, ptsDate: dateToYMDHyphenated(loan.ptsDate) ?? ''})
        setEptsForm({...loan, eptsDate: dateToYMDHyphenated(loan.eptsDate) ?? ''})
        setStsForm({...loan, stsDate: dateToYMDHyphenated(loan.stsDate) ?? ''})
        setIsCarApprove(loan.isCarApprove)
    }

    const isPhotoLoaded = (photoArray) => {
        return photoArray.every(item => {
            if (item.type === 'e_pts_addition') return true;
            return item.src
        })
    }

    async function validate() {
        if (_isSubjectFormInvalid()) {
            setIsSubjectFormError(true)
            elementsStore.showSnackbar(errorsParser('Заполните все поля автомобиля'))
            return false
        }
        if (_isPtsFormInvalid()) {
            setIsCarPtsError(true)
            elementsStore.showSnackbar(errorsParser('Заполните все поля ПТС'))
            return false
        }
        const isPtsPhotoLoaded = activeTab === 'pts' ? isPhotoLoaded(ptsPhotos) : isPhotoLoaded(eptsPhotos)
        if (!isPtsPhotoLoaded) {
            setIsCarPtsError(true)
            elementsStore.showSnackbar(errorsParser('Загрузите фото ПТС'))
            return false
        }

        const {stsSeries, stsNumber, stsDate, stsIssued} = stsForm
        if (!(stsSeries && stsNumber && stsDate && stsIssued)) {
            setIsCarStsError(true)
            elementsStore.showSnackbar(errorsParser('Заполните все поля СТС'))
            return false
        }
        if (!isPhotoLoaded(stsPhotos)) {
            setIsCarStsError(true)
            elementsStore.showSnackbar(errorsParser('Загрузите фото СТС'))
            return false
        }
        if (!isPhotoLoaded(carPhotos)) {
            setIsCarPhotoError(true)
            elementsStore.showSnackbar(errorsParser('Загрузите фото автомобиля'))
            return false
        }

        if (!isCarApprove) {
            elementsStore.showSnackbar(errorsParser('Подтвердите что автомобиль у Вас в собственности и не обременен'))
            setIsCarPrivateError(true)
            return false
        }
        return (await nextStep())
    }

    const _isSubjectFormInvalid = () => {
        const {
            carMark, carModel, releaseYear, type, colorBody, engineVolume, vin, bodyNumber, regNum
        } = subjectForm
        let subjectFieldsArray = [carMark, carModel, releaseYear, type, colorBody, engineVolume, vin, bodyNumber, regNum]
        return subjectFieldsArray.some(field => !field || !field.toString().trim())
    }

    const _isPtsFormInvalid = () => {
        let ptsArray = activeTab === 'pts' ?
            [ptsForm.ptsNumber, ptsForm.ptsDate, ptsForm.ptsIssued]
            :
            [eptsForm.eptsNumber, eptsForm.eptsDate, eptsForm.eptsIssued]
        return ptsArray.some(field => !field || !field.toString().trim())
    }

    async function nextStep() {
        try {
            const stepError = await checkCurrentStep(onlineLoanStepsArray[5], onlineLoanStore)
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }

            const pts = activeTab === 'pts' ? {
                "ptsNumber": ptsForm.ptsNumber,
                "ptsDate": new Date(ptsForm.ptsDate),
                "ptsIssued": ptsForm.ptsIssued,
            } : {
                "eptsNumber": eptsForm.eptsNumber,
                "eptsDate": new Date(eptsForm.eptsDate),
                "eptsIssued": eptsForm.eptsIssued,
            }
            let response = await backReq("online_loan:loan_subject", {

                "carMark": subjectForm.carMark,
                "carModel": subjectForm.carModel,
                "releaseYear": subjectForm.releaseYear,
                "type": subjectForm.type,
                "colorBody": subjectForm.colorBody,
                "engineVolume": subjectForm.engineVolume,
                "vin": subjectForm.vin,
                "bodyNumber": subjectForm.bodyNumber,
                "regNum": subjectForm.regNum,

                ...pts,

                "stsSeries": stsForm.stsSeries,
                "stsNumber": stsForm.stsNumber,
                "stsDate": new Date(stsForm.stsDate),
                "stsIssued": stsForm.stsIssued,
                "isCarApprove": isCarApprove,
            })
            if (response?.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return false
            }
            const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[6], onlineLoanStore)
            if (nextStepErrors) {
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
        } catch (e) {
            console.warn('err', e)
            return false
        }
    }

    return <div>
        <ErrorContainer innerRef={subjectFormContainerRef} hasError={isSubjectFormError}>
            <div className={s.titleWrapper}>
                <div className={s.title}>Добавление легкового авто</div>
                <SpecialEquipment/>
            </div>
            <div className={s.messageWrapper}>
                <BaseMessage type="warning" shadow={true} centered={false} close={false} size={'big'}>
                    Автомобиль обязательно должен находиться у Вас в собственности. Для оформления по Ген.
                    доверенности или Договору купли-продажи обратитесь в
                    <a className={s.messageLink} href={'/contacts'} target={'_blank'}> ближайший офис «Ваш инвестор».</a>
                </BaseMessage>
            </div>
            <SubjectForm subjectForm={subjectForm} setSubjectForm={setSubjectForm}/>
        </ErrorContainer>

        <ErrorContainer innerRef={carPtsContainerRef} hasError={isCarPtsError}>
            <div className={s.ptsTabs}>
                <BaseTabs onlineLoan={true} active={activeTab} list={ptsTabs} change={(x) => setActiveTab(x)}
                          size={'big'} viewActive={'text'}/>
            </div>
            { activeTab === "pts" ?
                <div>
                    <PtsForm ptsForm={ptsForm} setPtsForm={setPtsForm}/>
                    <div className={[s.photoWrapper, s.wrapper].join(' ')}>
                        <OnlineLoanPhotoArray photoArray={ptsPhotos} updateFunction={setPtsPhotos}/>
                    </div>
                </div>
                :
                <div>
                    <EptsForm eptsForm={eptsForm} setEptsForm={setEptsForm}/>
                    <div className={[s.photoWrapper, s.wrapper].join(' ')}>
                        <OnlineLoanPhotoArray photoArray={eptsPhotos} updateFunction={setEptsPhotos}/>
                    </div>
                </div>
            }
        </ErrorContainer>

        <ErrorContainer innerRef={carStsContainerRef} hasError={isCarStsError}>
            <div className={s.title}>CТС</div>
            <div className={s.wrapper}>
                <StsForm stsForm={stsForm} setStsForm={setStsForm}/>
                <div className={[s.photoWrapper, s.wrapper].join(' ')}>
                    <OnlineLoanPhotoArray photoArray={stsPhotos} updateFunction={setStsPhotos}/>
                </div>
            </div>
        </ErrorContainer>

        <ErrorContainer innerRef={carPhotoContainerRef} hasError={isCarPhotoError}>
            <div className={s.title}>Фотографии автомобиля</div>
            <div className={s.wrapper}>
                <div className={s.photoWrapper}>
                    <OnlineLoanPhotoArray photoArray={carPhotos} updateFunction={setCarPhotos}/>
                </div>
            </div>
        </ErrorContainer>

        <ErrorContainer innerRef={isCarPrivateContainerRef} hasError={isCarPrivateError}>
            <BaseCheckbox inversion value={isCarApprove} onChange={() => setIsCarApprove(!isCarApprove)}>
                <p className={s.checkboxTitle}>Подтверждаю, что данный автомобиль:</p>
            </BaseCheckbox>
            <div className={s.subCheckbox}>
                <p>1. Находится у меня в собственности (в противном случае обратитесь в наш контакт-центр <b>8 (800)
                    700-63-31</b> )</p>
                <p>2. Не находится под обременением в виде залога </p>
            </div>
        </ErrorContainer>

        <OnlineLoanButtons callback={validate} store={onlineLoanStore}/>
    </div>
}

export default RegistrationSubject
