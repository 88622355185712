import React from 'react'
import s from './GoBack.module.scss'
import { useHistory } from 'react-router-dom'

import {ReactComponent as IconArrow} from '../../../img/arrow.svg'

const GoBack = ({ to }) => {
  const history = useHistory()
  return (
    <div className={ s.back } onClick={ () => history.push(to) }>
        <IconArrow/>
        Назад
    </div>
  )
}

export default GoBack
