import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from "react-dom";

import HelpIcon from '../../../img/help.svg'

import s from './BaseTooltip.module.scss'
import {stopPropagation} from "../../../utils/stopPropagation";

const BaseTooltip = ({ icon, size, iconAfter, children, close, repeaterStyle= false }) => {
    const [active, setActive] = useState(false)
    const [show, setShow] = useState(false)
    const [tooltipStyle, setTooltipStyle] = useState(null)

    const refTooltip = useRef()
    const refContent = useRef()

    const Portal = ({children}) => ReactDOM.createPortal(children, document.getElementById('root-tooltip'))

    const toggle = () => {
        setActive(!active)
        setPosition()
    }

    const hide = () => {
        setActive(false)
    }

    const setPosition = () => {
        if (refTooltip.current && refContent.current) {
            const tooltipRect = refTooltip.current.getBoundingClientRect()
            const {left, right, bottom, width} = tooltipRect

            const contentRectWidth = refContent.current.getBoundingClientRect().width
            const contentRectWidthHalf = contentRectWidth / 2

            const {innerWidth} = window

            const leftEdge = 17


            const style = {
                i: {
                    top: `${bottom + 6}px`,
                    left: `${left + 7}px`
                },
                div: {
                    top: `${bottom + 12}px`,
                    left: right + contentRectWidthHalf < innerWidth && left - contentRectWidthHalf > leftEdge
                        ? `${left - contentRectWidthHalf + width / 2}px`
                        : `${innerWidth - right > left ? leftEdge : innerWidth - contentRectWidth - leftEdge}px` // - 27
                }
            }

            setTooltipStyle(style)
        }
    }

    useEffect(() => {
        if (close) {
            hide()
        }

        if (!active) return

        setPosition()

        const eventHandler = size === 'big' ? setPosition : hide

        const timer = setTimeout(setShow(true), 1);
        window.addEventListener('scroll', eventHandler)
        return () => {
            clearTimeout(timer)
            window.removeEventListener("scroll", eventHandler)
        }
    }, [active, close, size])

    const classes = [s.tooltip]
    if (show) classes.push(s.show)
    if (size) classes.push(s[size])

    return <React.Fragment>
        <div
            className={s.line}
            style={{position: 'relative', display: 'flex', alignItems: 'center'}}
            onClick={() => toggle()}
        >
            <img className={s.image}
                 src={icon ? icon : HelpIcon} alt=""
                 ref={refTooltip}/>
                {iconAfter}
        </div>
        {
            active && <Portal>
                <div className={classes.join(' ')} onClick={() => hide()}>
                    <i style={tooltipStyle?.i}/>
                    <div className={repeaterStyle ? s.repeaterStyle : ''} ref={refContent} style={tooltipStyle?.div} onClick={e => stopPropagation(e)}>
                        {children}
                    </div>
                </div>
            </Portal>
        }
    </React.Fragment>
}

export default BaseTooltip
