import React from "react";

export const errorIcon = <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">n" +
    "<path d="M21.3991 18.0625L11.6491 1.1875C11.5038 0.936719 11.253 0.8125 10.9999 0.8125C10.7467 0.8125 10.4936 0.936719 10.3506 1.1875L0.600643 18.0625C0.312362 18.5641 0.673299 19.1875 1.24986 19.1875H20.7499C21.3264 19.1875 21.6874 18.5641 21.3991 18.0625ZM10.2499 7.75C10.2499 7.64687 10.3342 7.5625 10.4374 7.5625H11.5624C11.6655 7.5625 11.7499 7.64687 11.7499 7.75V12.0625C11.7499 12.1656 11.6655 12.25 11.5624 12.25H10.4374C10.3342 12.25 10.2499 12.1656 10.2499 12.0625V7.75ZM10.9999 16C10.7055 15.994 10.4252 15.8728 10.2191 15.6625C10.013 15.4522 9.89759 15.1695 9.89759 14.875C9.89759 14.5805 10.013 14.2978 10.2191 14.0875C10.4252 13.8772 10.7055 13.756 10.9999 13.75C11.2943 13.756 11.5746 13.8772 11.7806 14.0875C11.9867 14.2978 12.1021 14.5805 12.1021 14.875C12.1021 15.1695 11.9867 15.4522 11.7806 15.6625C11.5746 15.8728 11.2943 15.994 10.9999 16Z" fill="#FF4D4F"/>n" +
    "</svg>
export const greenCheckIcon= <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#4CAF50"/>
    <path d="M32 19L21 30L16 25" stroke="white" strokeWidth="4" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>
export const yellowCheckIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#FFB800"/>
    <path d="M32 19L21 30L16 25" stroke="white" strokeWidth="4" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>
export const hourglassIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#FFB800"/>
    <path
        d="M32 14H17C17 14 17.759 17.5808 19 19.5C20.1935 21.3459 23 23.46 23 23.46V25C23 25 20.2068 26.6496 19 28.5C17.8512 30.2615 17 34 17 34H32C32 34 30.4047 30.2922 29 28.5C27.6372 26.7613 25 25 25 25V23.46C25 23.46 27.665 21.2463 29 19.5C30.486 17.5563 32 14 32 14Z"
        stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
export const redCrossIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#FF3F42"/>
    <path d="M31 17L17 31" stroke="white" strokeWidth="4" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M17 17L31 31" stroke="white" strokeWidth="4" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>
export const rubIcon = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#4CAF50"/>
    <circle cx="24" cy="24" r="21" stroke="white" strokeOpacity="0.3" strokeWidth="2"
            strokeLinecap="round"/>
    <path
        d="M24 3C28.5475 3 32.9722 4.47618 36.6088 7.20662C40.2454 9.93706 42.8974 13.7742 44.1662 18.1412C45.4349 22.5082 45.2518 27.169 43.6443 31.423C42.0369 35.677 39.092 39.2942 35.2524 41.7309C31.4127 44.1676 26.8859 45.2921 22.3524 44.9353C17.8188 44.5785 13.5236 42.7597 10.1124 39.7523C6.70128 36.745 4.3585 32.7116 3.43632 28.2585C2.51414 23.8055 3.06238 19.1734 4.99863 15.0586"
        stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path
        d="M27.4777 12H20.2441C19.4981 12 18.8934 12.5948 18.8934 13.3284V23.383H17.3507C16.6047 23.383 16 23.9778 16 24.7114C16 25.445 16.6047 26.0398 17.3507 26.0398H18.8934V29.0745H17.3507C16.6047 29.0745 16 29.6693 16 30.403C16 31.1366 16.6047 31.7314 17.3507 31.7314H18.8934V34.6716C18.8934 35.4052 19.4981 36 20.2441 36C20.99 36 21.5947 35.4052 21.5947 34.6716V31.7314H27.4777C28.2236 31.7314 28.8284 31.1366 28.8284 30.403C28.8284 29.6693 28.2236 29.0745 27.4777 29.0745H21.5947V26.0398H27.4777C31.4134 26.0398 34.6153 22.8907 34.6153 19.0199C34.6152 15.0534 31.3301 12 27.4777 12ZM27.4777 23.383H21.5947V14.6568H27.4777C29.9091 14.6568 31.9139 16.5857 31.9139 19.02C31.9138 21.4258 29.9239 23.383 27.4777 23.383Z"
        fill="white"/>
</svg>
