import s from "./RepeaterConfirmation.module.scss";
import Success from "../../../img/success.svg";
import RedCross from "../../../img/red-cross.svg";
import IdxProfile from "../../../img/idxProfile.svg";
import React, {useEffect, useState} from "react";
import CameraIcon from "../../../img/camera.svg";
import BaseButton from "../../atoms/BaseButton";
import repeatStore from "../../../store/repeatStore";
import {backReq} from "../../../helpers";
import {observer} from "mobx-react";
import elementsStore from "../../../store/elementsStore";

const Idx = observer(() => {
    const [isLoading, setIsLoading] = useState(true);
    const [loanId, setLoanId] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        const loanId = repeatStore.loanDetail.id
        setLoanId(loanId)
        return ()=> repeatStore.setIsLivelinessCheckInProgress(false)
        //eslint-disable-next-line
    }, [repeatStore.loanDetail.id])

    useEffect(() => {
        async function init() {
            if (!loanId) return;
            const {livelinessCheck} = await getResults()
            if (livelinessCheck) {
                repeatStore.setLivelinessCheck("real")
                setIsLoading(false)
            } else {
                repeatStore.setLivelinessCheck(null)
                setIsLoading(true)
            }
        }
        init()
        //eslint-disable-next-line
    }, [loanId]);

    const getIdxUrl = async () => {
        const {data, status} = await backReq('idx:get_url', {id: loanId})
        if (status !== 'success' || !data.url) {
            elementsStore.showSnackbar('Ошибка при инициализации, попробуйте позже')
            return
        }
        setUrl(data.url)
        repeatStore.setIsLivelinessCheckInProgress(true)
        setIsLoading(false)
    }

    const getResults = async () => {
        const {data} = await backReq('online_loan:get_liveliness_check_result', {id: loanId})
        return data
    }

    const retry = async () =>{
        repeatStore.setLivelinessCheck(null)
        await getIdxUrl()
    }

    const PhotoIcon = <div className={s.arrowLoader}>
        <img src={CameraIcon} onClick={getIdxUrl} alt={'LoaderArrows'}/>
    </div>

    const SuccessInfo = <div className={s.elevationWrapper}>
        <img src={Success} alt="success-icon"/>
        <p className={s.success}>Проверка пройдена</p>
    </div>

    const ErrorInfo = <div className={s.elevationWrapper}>
        <img src={RedCross} alt="red-cross-icon"/>
        <p className={s.error}>Проверка не пройдена</p>
        <BaseButton type="green" onClick={retry} text={'Пройти заново'}></BaseButton>
    </div>

    const onLoad = () => {
        window.addEventListener("message", function(event) {
            // if( event.origin === "https://liveness-test.iidx.ru") {
                eventHandler(event.data)
                // return;
            // }
        }, false);
    }
    const eventHandler = (messageType) =>{
        /* для отладки сообщений из iframe
         типы сообщений:
             verilive_start
             verilive_success
             verilive_fail
             verilive_error
        */
        // console.warn('messageType', messageType)
        switch (messageType){
            case 'verilive_success':
                repeatStore.setIsLivelinessCheckInProgress(false)
                repeatStore.setLivelinessCheck('real')
                // дублирует сокет, но тут разные источники - бек idx и бек idpowers
                return
            case 'verilive_fail':
            case 'verilive_error':
                repeatStore.setLivelinessCheck('attack')
                // attack - для отображения состояния ошибки с кнопкой для повторной отправки.
                // Чистый UX без логики бека/idx
                repeatStore.setIsLivelinessCheckInProgress(false)
                //  В сокете только прошел/не прошел. Fail тут - общая ошибка, +- это логика есть в сокете,
                //  но тут она тоже нужна.
                return
            default:
                return;
        }
    }
    const resultInfo = repeatStore.livelinessCheck === 'real' ?
        SuccessInfo :
        repeatStore.livelinessCheck === 'attack' ?
            ErrorInfo :
            null

    return <div className={!repeatStore.isLivelinessCheckInProgress ?s.grow : ''}>
        {repeatStore.isLivelinessCheckInProgress ? <iframe
                style={{background: '#0f0c2b'}}
                className={s.iframeWrapper}
                title={'idx'}
                width={460}
                height={345}
                src={url}
                frameBorder={0}
                sandbox="allow-popups allow-forms allow-modals allow-presentation allow-same-origin allow-scripts"
                allow="autoplay *; camera *; microphone *; midi *"
                onLoad={onLoad}
            /> :
            <div className={s.photoWrapper}>
                <img src={IdxProfile} alt="idxFace"/>
            </div>
        }
        {isLoading ? PhotoIcon : resultInfo}

    </div>
})
export default Idx
