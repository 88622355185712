import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {inject, observer} from 'mobx-react'
import appStore from '../../../store/app'
import {backReq, getActualDomain} from '../../../helpers'
import s from './PayModal.module.scss'
import ModalContainer from '../../atoms/ModalContainer'
import BaseButton from '../../atoms/BaseButton'
import PaymentCardSelect from '../../atoms/PaymentCardSelect'
import {toJS} from 'mobx'
import paymentModule from '../../../modules/payment'
import BaseCheckbox from '../../atoms/BaseCheckbox'
import AmountInput from "./AmountInput";
import {isValidEmail} from "../../../utils/validators";
import CollapsePayModal from "./CollapsePayModal";
import formattedMoney from "../../../utils/formattedMoney";
import SelectionPayment from "./SelectionPayment";
import CfrAmountInput from "./CfrAmountInput";
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";
import saveLog from "../../../utils/saveLog";
import PayModalMessage from "./PayModalMessage";
import PayModalEmail from "./PayModalEmail";

const PayModal = inject('appStore')(
    observer((props) => {
        const {
            cards,
            isShowPayModal,
            isMfoVi,
            currentEntityForPay,
            currentEntityForPayAmount,
            profile,
            setCards,
            closePayModal
        } = props.appStore

        const history = useHistory()

        const [isNoSaveCard, setIsNoSaveCard] = useState(false)
        const [selectedCardToken, setSelectedCardToken] = useState('Empty')
        const [partialPay, setPartialPay] = useState(false)

        const [loading, setLoading] = useState(false)
        const [email, setEmail] = useState(null)
        const [emailError, setEmailError] = useState(false)

        const [selectedPeriod, setSelectedPeriod] = useState({})
        const [isOverpay, setIsOverpay] = useState(false)

        useEffect(() => {
            const next = toJS(currentEntityForPay?.parts?.next) ?? {}
            setSelectedPeriod(next)
        },[currentEntityForPay])

        useEffect(()=> {
            history.listen(() => {
                closePayModal()
                setLoading(false)
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            document.body.style.overflow = isShowPayModal ? 'hidden' : null
            if (isShowPayModal) {
                fetchCards()
                defineIsOverpay()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isShowPayModal])

        const getDefaultCard = (cards) => {
            if (!cards || !cards.length) {
                return 'Empty'
            }
            const defaultCard = cards.find((x) => x.default)

            if (defaultCard) return defaultCard.token;

            return cards[0].token
        }

        const fetchCards = () => {
            setCards([])
            backReq('get_card_list')
                .then((res) => {
                    if (res?.data && res.data.cards && !res.data.cards.length) {
                        setSelectedCardToken('Empty')
                        return
                    }
                    setCards(res?.data?.cards)
                    setSelectedCardToken(getDefaultCard(res?.data?.cards))
                })
                .catch(e => {
                    console.warn(e)
                    setSelectedCardToken('Empty')
                })
        }

        const defineIsOverpay = () => {
            const parts = toJS(currentEntityForPay?.parts)
                if (!parts) {
                    setIsOverpay(false)
                    return
                }
            const {part, full} = parts
            if (hasNegative(part) || hasNegative(full)) {
                setIsOverpay(true)
            } else {
                setIsOverpay(false)
            }
        }

        const hasNegative = (obj) =>{
            for(let key in obj){
                if (obj[key] && obj[key] < 0){
                    return true
                }
            }
            return false
        }

        if (!currentEntityForPay) return null
        const {
            ln,
            id,
            type,
            ctx,
            paymentTitle,
            paymentText,
        } = currentEntityForPay


        const closeModal = async () => {
            closePayModal()
            document.body.style.overflow = null
            setPartialPay(false)
        }

        const pay = async () => {
            if (email && !isValidEmail(email)) {
                elementsStore.showSnackbar(errorsParser('Email не валиден'))
                return false
            }

            let data = {
                id,
                amount: currentEntityForPayAmount,
                url: getActualDomain() + `/loans`,
                email: email ? email : profile.email,
                type,
                ctx
            }

            data.get_token = isNoSaveCard && selectedCardToken === 'Empty'

            if (selectedCardToken !== 'Empty') {
                data.token = selectedCardToken
            }
            setLoading(true)

            try {
                const payment = await paymentModule.pay(data)
                if (!payment.success) {
                    paymentModule.showPaymentError(appStore, payment.error)
                }
                if (payment.success) {
                    closePayModal();
                }
            } catch (error) {
                console.log(error)
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 10000)
            }
        }

        // render

        if (!currentEntityForPayAmount || !selectedCardToken || emailError) {
            saveLog('pay_modal:check_status', {
                loanId: ln,
                action: 'оплата займа',
                payAmountInput: currentEntityForPayAmount,
                selectedCardToken: selectedCardToken,
                isAmount: false,
                userPhone: profile ? profile.phone : 'Не найдено'
            }).catch(e => console.log('unable to connect to rollbar', e))

        }

        const textButton = () => {
            if (isMfoVi) {
                return !loading
                    ? 'Внести платеж'
                    : 'Переход в систему оплаты'
            } else {
                let amountSource = appStore.currentEntityForPayAmount
                const fee = !!appStore.loans?.find(x => x.mountlyPayment === amountSource)?.fee
                if (!appStore.currentEntityForPayAmountInRange){
                    amountSource = appStore.currentEntityForPayAmount > appStore.currentEntityForPayMaxAmount ? appStore.currentEntityForPayMaxAmount : appStore.currentEntityForPayMinAmount
                }
                const [rubles, kopecks] = String(amountSource).split('.')
                return !loading
                    ? <>
                        <span>Оплатить {formattedMoney(rubles)}<span>{kopecks ? `.${kopecks}` : ''}</span> ₽</span>
                        {fee && <div>Может взиматься комиссия 0,8 - 2%</div>}
                    </>
                    : 'Переход в систему оплаты'
            }
        }

        const titleModal = () => {
            if (!isMfoVi) {
                return (partialPay || isOverpay) ? '' : 'Оплата займа по графику'
            } else {
                return paymentTitle + ' ' + paymentText
            }
        }

        return (
            <ModalContainer
                showModal={isShowPayModal}
                closeModal={closeModal}
                title={titleModal()}
            >
                <div className={[s.wrapper, partialPay || isOverpay ? s['wrapper--partial'] : ''].join(' ')}>
                    {partialPay && <h2 className={s.title}>Частичное или полное погашение</h2>}
                    {isOverpay && <h2 className={s.title}>Оплата займа по графику</h2>}
                    <div className={[s.input, isMfoVi ? s.inputMfoVi : '' ].join(' ')}>
                        {isMfoVi ?
                            <AmountInput/> :
                            <CfrAmountInput
                                selectedPeriod={selectedPeriod}
                            />
                        }
                    </div>
                    {!isMfoVi && partialPay && <div className={s.buttonsSelection}>
                        <SelectionPayment selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
                    </div>
                    }
                    {!isMfoVi && <div className={s.collapse}>
                        <CollapsePayModal
                            isOverpay={isOverpay}
                            partialPay={partialPay}
                            period={selectedPeriod}
                            setPartialPay={() => setPartialPay(true)}
                        />
                    </div>}
                    {!isMfoVi && <h4 className={s.titleData}>Данные плательщика</h4>}
                    {cards.length > 0 && (
                        <div className={s.cards}>
                            <PaymentCardSelect
                                options={toJS(cards)}
                                onChanged={(x) => setSelectedCardToken(x.token)}
                                default={selectedCardToken}
                            />
                        </div>
                    )}
                    <div className={s.emailWrapper}>
                        <PayModalEmail
                            setEmail={setEmail}
                            emailError={emailError}
                            setEmailError={setEmailError}
                        />
                    </div>
                    {selectedCardToken === 'Empty' && (
                        <div className={s.checkbox}>
                            <BaseCheckbox
                                inversion
                                value={isNoSaveCard}
                                onChange={() => setIsNoSaveCard(!isNoSaveCard)}
                            >
                                Не сохранять карту в Личном кабинете
                            </BaseCheckbox>
                        </div>
                    )}
                    <div className={s.message}>
                        <PayModalMessage
                            partialPay={partialPay}
                            isOverpay={isOverpay}
                        />
                    </div>
                    <div className={s.button}>
                        <BaseButton
                            size="big"
                            type="green"
                            text={textButton()}
                            onClick={() => pay()}
                            disabled={
                                !currentEntityForPayAmount ||
                                !selectedCardToken ||
                                emailError
                            }
                            loading={loading}
                            btnId="create-payment"
                        />
                    </div>
                </div>
            </ModalContainer>
        )
    })
)

export default PayModal


