import React, {useEffect, useState} from "react";

import s from "./RepeaterPrivacy.module.scss";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import {observer} from "mobx-react";
import BaseButton from "../../atoms/BaseButton";
import BaseCollapse from "../../atoms/BaseCollapse";
import {backReq, getFormattedPhone} from "../../../helpers";
import appStore from "../../../store/app";
import {errorsParser} from "../../../utils/errorsParser";
import ModalPortal from "../../atoms/ModalPortal";
import elementsStore from "../../../store/elementsStore";
import {checkCurrentStep, moveToNextOnlineLoanStep, validateStep} from "../helpers";
import useErrorContainer from "../../../hooks/useErrorContainer";
import repeatStore from "../../../store/repeatStore";
import {repeaterStepsArray} from "../../../constants/onlineLoan";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import successIcon from "../../../img/success.svg";
import {toJS} from "mobx";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";

const full = [
  {
    head: "Присоединяюсь к <a href='./docs/Соглашение о ПЭП ЦФР ВИ_30_11_2021.pdf' target='_blank'>Соглашению об электронном взаимодействии</a>",
    text: "Настоящее Соглашение определяет условия осуществления обмена информацией в электронной форме между Сторонами, в том числе при заключении договора займа и договора залога в виде электронного документа, а также требования к конфиденциальности."
  },
  {
    head: "Даю <a href='./docs/Согласие на обработку ПДн.pdf' target='_blank'>согласие на обработку персональных данных</a>",
    text: "Обработка персональных данных субъектов осуществляется исключительно в целях обеспечения соблюдения законодательства о персональных данных, трудового и гражданского законодательства, с целью оказания качественных услуг клиентам."
  },
  {
    head: "<a href='./docs/Согласие на предоставление в Бюро кредитных историй.pdf' target='_blank'>Согласие на предоставление и получение информации в Бюро кредитных историй</a>",
    text: "Кредитные истории включают информацию о платежной дисциплине (срок и сумма погашения кредита/займа, наличие просроченной задолженности)."
  }
]

const RepeaterPrivacy = (observer(() => {
  const profile = appStore?.profile
  const [modal, setModal] = useState(false)
  const [user, setUser] = useState({
    fio:"",
    birthDate:new Date().getTime(),
  })

  const [isAgreementsError, setIsAgreementsError, agreementsContainerRef ] = useErrorContainer()
  const [fetchDataErrors] = useRefreshLoanDetail(setOfferSmsInfo)
  async function setOfferSmsInfo() {
    try {
      setUser(repeatStore.loanDetail?.offer || user)
      if(!repeatStore.loanDetail && !repeatStore.loanDetail.stepsDone) {
        elementsStore.setSmsCodeConfirmed(false)
        return
      }
      const isConfirmed = toJS(repeatStore.loanDetail).stepsDone.find(step => step === repeaterStepsArray[1])
      elementsStore.setSmsCodeConfirmed(!!isConfirmed)
    }catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (fetchDataErrors) elementsStore.showSnackbar(errorsParser(fetchDataErrors));
  },[fetchDataErrors])

  async function sendSms() {
      const response = await elementsStore.callSmsAction(()=>backReq("online_loan:get_code"))
      if (response.errors){
          elementsStore.showSnackbar(errorsParser(response.errors))
          return
      }
    setModal(true)
  }

  async function confirmAgreements() {
    elementsStore.setCheckingSmsCode(true)
    try {
      const stepError = await checkCurrentStep(repeaterStepsArray[1])
      if (stepError) {
        elementsStore.showSnackbar(errorsParser(stepError))
        return false
      }
      const response = await backReq("online_loan:confirm_agreements", {
        "code": elementsStore.smsCode,
        "electronicInteractionAgreement": true,
        "personalDataAgreement": true,
        "creditHistoryAgreement": true
      })
      if (response.code === 200) {
        elementsStore.setSmsCodeConfirmed(true)
        setTimeout(() => {
          setModal(false)
        }, 300)
      } else {
        elementsStore.showSnackbar(errorsParser(response?.errors || JSON.stringify(response)))
        return false
      }
    } catch (e) {
      console.warn("error", e.message)
      return false
    } finally {
      elementsStore.setCheckingSmsCode(false)
    }
  }

  async function nextStep() {
    try {
      const validateStepResponse = await validateStep('agreements')
      if (validateStepResponse.errors) {
        elementsStore.showSnackbar("Необходимо подписать соглашения")
        setIsAgreementsError(true)
        return false
      }
      const nextStepErrors = await moveToNextOnlineLoanStep(repeaterStepsArray[2])
      if (nextStepErrors){
        elementsStore.showSnackbar(errorsParser(nextStepErrors))
      }
      elementsStore.smsInputRestoreDefault()
    } catch (e) {
      return false
    }
  }

  return <div className={s.privacy}>
    <div className={s.privacy__head}>Я, {user.fio}, дата рождения {new Date(user.birthDate).toLocaleDateString("ru-RU")}:</div>
    {
      full.map((line, index) =>
        <ErrorContainer key={index}>
          <BaseCollapse chevron={true} head={<BaseCheckbox
            value={true}
            disabled={true}
            inversion
            sizeText='big'
            color="primary">
            <div> <span dangerouslySetInnerHTML={{__html: line.head}}/></div>
          </BaseCheckbox>}>
            <div>{line.text}</div>
          </BaseCollapse>
        </ErrorContainer>
      )
    }
    <ErrorContainer hasError={isAgreementsError} innerRef={agreementsContainerRef}>
      <div className={s.privacy__signature}>
        <h6>Подписание соглашений через простую электронную подпись</h6>
        <p>Для подписания вам необходимо ввести код из SMS. SMS будет отправлено
          на {profile?.phone ?  getFormattedPhone(profile?.phone) : "номер, который привязан к вашему аккаунту"}</p>

        { repeatStore.loanDetail.electronicInteractionAgreement || elementsStore.smsCodeConfirmed ?
          <div className={s.privacy__signed}>
            <img src={successIcon} alt="successIcon"/>
            Подписано
          </div>
          : <BaseButton
            text={"Получить код"}
            type={'green'}
            onClick={sendSms}
          />
        }
      </div>
    </ErrorContainer>
    <ModalPortal
      title="Подписание соглашений"
      showModal={modal}
      closeModal={() => setModal(false)}>
      <div className={s.privacy__modal}>
          <div className={s.textCode}>
              <p>Мы отправили код доступа на номер <span
                  className={s.phone}>{profile?.phone ? getFormattedPhone(profile.phone) : ''}.</span></p>
              <p>Введите код из SMS:</p>
          </div>
        <ConfirmCodeInput onEntered={confirmAgreements} onSend={sendSms}/>
      </div>
    </ModalPortal>
    <OnlineLoanButtons callback={nextStep}/>
  </div>
}))

export default RepeaterPrivacy
