import React, {useState} from 'react'
import ErrorContainer from "../../molecules/ErrorContainer";
import s from "./OnlineLoanPersonal.module.scss";
import useErrorContainer from "../../../hooks/useErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import onlineLoanStore from "../../../store/onlineLoanStore";
import BaseMessage from "../../atoms/BaseMessage";
import PersonalForm from "./PersonalForm";
import PassportForm from "./PassportForm";
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../../Repeater/helpers";
import {
    BACKEND_CHECKING_CLIENT_DATA_ERROR,
    BACKEND_CHECKING_LIMIT_ERROR,
    onlineLoanStepsArray
} from "../../../constants/onlineLoan";
import {backReq} from "../../../helpers";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import OnlineLoanPersonalModals from "./OnlineLoanPersonalModals";
import {observer} from "mobx-react";
import {dateToYMDHyphenated} from "../../../utils/formattedDate";
import {validateEmail} from "../../../utils/validators";

const RegistrationPersonal = observer (() => {

    const [isShowChangeModal, setIsShowChangeModal] = useState(false)
    const [isShowExistUserModal, setIsShowExistUserModal] = useState(false)
    const [isConfirmChange, setIsConfirmChange] = useState(false)

    const [personalForm, setPersonalForm] = useState({
        isNameNotChanged: true,
    })
    const [passportForm, setPassportForm] = useState({
        noCriminal: false,
        noProsecuted: false,
    })

    const [isPersonalDataError, setIsPersonalDataError, personalDataContainerRef] = useErrorContainer()
    const [isPassportDataError, setIsPassportDataError, passportDataContainerRef] = useErrorContainer()

    useRefreshOnlineLoanDetail(insertData)

    const steps = onlineLoanStore?.loanDetail?.stepsDone ?? []
    const isDataApproved = steps.includes('client_data') ?? false

    const setGender = (loan) => {
        if(loan.gender === 'none' || !loan.gender) {
            return ''
        }
        return loan.gender === 'm' ? 'Мужской' : 'Женский'
    }

    async function insertData() {
        const loan = onlineLoanStore?.loanDetail ?? {}
        setPersonalForm({
            ...loan,
            gender:  setGender(loan),
            birthDate: dateToYMDHyphenated(loan.birthDate) ?? ''
        })
        setPassportForm({
            ...loan,
            passportDate: dateToYMDHyphenated(loan.passportDate) ?? ''
        })
    }

    async function validate() {
        if (onlineLoanStore.isDataOnCheck){
            elementsStore.showSnackbar(errorsParser('Данные на проверке'))
            return false
        }
        const {
            surname, name, patronymic, prevSurname, prevName, prevPatronymic,
            isNameNotChanged, gender, birthDate, birthPlace, citizenship, orderEmail
        } = personalForm
        let personalFieldsArray = [surname, name, patronymic, gender, birthDate, birthPlace, citizenship, orderEmail]
        if (!isNameNotChanged) {
            personalFieldsArray = [...personalFieldsArray, prevName, prevSurname, prevPatronymic]
        }
        if (personalFieldsArray.some(field => !field.trim())) {
            setIsPersonalDataError(true)
            elementsStore.showSnackbar(errorsParser('Заполните все поля'))
            return false
        }
        if (!validateEmail(orderEmail)){
            setIsPersonalDataError(true)
            elementsStore.showSnackbar(errorsParser('Email не валиден'))
            return false
        }
        const {
            passportSeries, passportNumber, passportIssuedBy, passportDate, divisionCode,
            registration, registrationIndex, registrationIsActual, actualRegistration, actualRegistrationIndex,
        } = passportForm
        let passportFieldsArray = [passportSeries, passportNumber, passportIssuedBy, passportDate, divisionCode, registration, registrationIndex]
        if (!registrationIsActual) {
            passportFieldsArray = [...passportFieldsArray, actualRegistration === undefined ? '' : actualRegistration, actualRegistrationIndex]
        }

        if (passportFieldsArray.some(field => !field.trim())) {
            setIsPassportDataError(true)
            elementsStore.showSnackbar(errorsParser('Заполните все поля'))
            return false
        }
        if (passportSeries.toString().length < 4
            || passportNumber.toString().length < 6
            || registrationIndex.length < 6
            || divisionCode.length < 7
        ){
            setIsPassportDataError(true)
            elementsStore.showSnackbar(errorsParser('Необходимо заполнить паспортные данные'))
            return false
        }
        return (await nextStep())
    }

    async function nextStep() {
        try {
            const stepError = await checkCurrentStep(onlineLoanStepsArray[1], onlineLoanStore, true)
            if (stepError) {
                const errorMessage = errorsParser(stepError)
                if (errorMessage.trim() === BACKEND_CHECKING_CLIENT_DATA_ERROR) onlineLoanStore.setIsDataOnCheck(true);
                elementsStore.showSnackbar(errorMessage)
                return false
            }
            let response = await backReq("online_loan:client_data", {
                surname: personalForm.surname,
                name: personalForm.name,
                patronymic: personalForm.patronymic,
                isNameNotChanged: personalForm.isNameNotChanged,
                prevSurname: personalForm.prevSurname,
                prevName: personalForm.prevName,
                prevPatronymic: personalForm.prevPatronymic,
                ...(personalForm.additionalPhone && {
                    additionalPhone: personalForm.additionalPhone,
                }),
                gender: personalForm.gender === 'Мужской' ? 'm' : 'f',
                birthDate: personalForm.birthDate,
                birthPlace: personalForm.birthPlace,
                citizenship: personalForm.citizenship,
                inn: personalForm.inn,
                snils: personalForm.snils,
                orderEmail: personalForm.orderEmail,
                //passport Form
                passportSeries: passportForm.passportSeries,
                passportNumber: passportForm.passportNumber,
                passportIssuedBy: passportForm.passportIssuedBy,
                passportDate: passportForm.passportDate,
                divisionCode: passportForm.divisionCode,
                registration: passportForm.registration,
                registrationIndex: passportForm.registrationIndex,
                registrationIsActual: passportForm.registrationIsActual,
                ...(!passportForm.registrationIsActual && {
                    actualRegistration: passportForm.actualRegistration,
                    actualRegistrationIndex: passportForm.actualRegistrationIndex,
                }),
                noCriminal: passportForm.noCriminal,
                noProsecuted: passportForm.noProsecuted
            })
            if (response?.errors) {
                const errorMessage = (errorsParser(response.errors)).trim()
                if (errorMessage === BACKEND_CHECKING_LIMIT_ERROR) {
                    onlineLoanStore.setIsCheckLimit(true)
                    onlineLoanStore.setShowCheckLimitModal(true)
                } else {
                    onlineLoanStore.setIsCheckLimit(false)
                    elementsStore.showSnackbar(errorMessage)
                }
                return false
            }
            if (!isDataApproved) {
                onlineLoanStore.setIsDataOnCheck(true)
                return false
            }
            const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[2], onlineLoanStore)
            if (nextStepErrors) {
                const errorMessage = (errorsParser(nextStepErrors)).trim()
                if (errorMessage === BACKEND_CHECKING_CLIENT_DATA_ERROR) onlineLoanStore.setIsDataOnCheck(true);
                return false
            }
        } catch (e) {
            console.warn('err', e)
            return false
        }
    }

    onlineLoanStore.setNextFrom2Step(nextStep)

    const openChangeInfoWarningModal = () => {
        if (!isDataApproved || isConfirmChange) return;
        setIsShowChangeModal(true)
    }
    const closeModal = () => {
        setIsShowChangeModal(false)
        setIsShowExistUserModal(false)
        onlineLoanStore.setShowCheckLimitModal(false)
    }
    const confirmChange = () => {
        setIsConfirmChange(true)
        setIsShowChangeModal(false)
    }
    return (<div>
       <OnlineLoanPersonalModals
           closeModal={closeModal}
           confirmChange={confirmChange}
           isCheckLimitModal={onlineLoanStore.showCheckLimitModal}
           isShowChangeModal={isShowChangeModal}
           isShowExistUserModal={isShowExistUserModal}
           isCheck1C={onlineLoanStore.isCheck1C}
       />
        <ErrorContainer innerRef={personalDataContainerRef} hasError={isPersonalDataError}>
            <div>
            <div className={s.titleWrapper}>
                <p className={s.title}>Основные данные</p>
            </div>
            <PersonalForm
                personalForm={personalForm}
                setPersonalForm={setPersonalForm}
                disabled={onlineLoanStore.isDataOnCheck}
                openChangeInfoWarningModal={openChangeInfoWarningModal}
                isConfirmChange={isConfirmChange}
                isDataApproved={isDataApproved}
            />
            </div>
        </ErrorContainer>

        <ErrorContainer innerRef={passportDataContainerRef} hasError={isPassportDataError}>
            <div>
                <div className={[s.titleWrapper, s['titleWrapper--withMessage']].join(' ')}>
                    <p className={s.title}>Паспортные данные</p>
                    <div className={s.message}>
                        <BaseMessage type="warning" shadow={true} centered={false} close={false}>
                            Будьте внимательны при заполнении этих данных
                        </BaseMessage>
                    </div>
                </div>
                <PassportForm
                    passportForm={passportForm}
                    setPassportForm={setPassportForm}
                    disabled={onlineLoanStore.isDataOnCheck}
                    openChangeInfoWarningModal={openChangeInfoWarningModal}
                />
            </div>
        </ErrorContainer>
        <div>
            <OnlineLoanButtons isDataOnCheck={onlineLoanStore.isDataOnCheck} callback={validate} store={onlineLoanStore}/>
        </div>
    </div>)
})

export default RegistrationPersonal
