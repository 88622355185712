import React from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import {renderRuMoscowDateTime, renderYYMMDDToDDMMYY} from "../../../../utils/formattedDate";
import formattedMoney from "../../../../utils/formattedMoney";
import LoanDocs from "../../../molecules/LoanDocs";
import {useHistory} from "react-router-dom";
import {loanStatuses} from "../../../../constants/onlineLoan";
const ModalInfo = ({onClose, loan}) => {

  const history = useHistory()

  const moveToLoanPage = () => {
    if (loan.loanId){
      history.push(`/loans/${loan.loanId}`)
    } else {
      history.push(`/loans/`)
    }
  }

  return (
    <div className={s.loanModal}>

      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Дата и время отправки заявки</span>
        <p className={s.modalInfoDate} >{renderRuMoscowDateTime(loan?.offer?.created ?? loan?.changeStatusDate )} (МСК)</p>
      </div>
      {loan.completeDate && loan.status === loanStatuses.complete?
        <div className={s.modalInfoTextWrapper}>
          <span className={s.modalTitle}>Дата и время получения займа</span>
          <p className={s.modalInfoDate}>{renderRuMoscowDateTime(loan.completeDate)} (МСК)</p>
        </div>
        : null
      }
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Продукт</span>
        <p>Наименование: <b>"Онлайн-займ"</b></p>

      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Параметры займа</span>
        <p> Сумма займа: <b>{formattedMoney(loan?.desiredAmount)} рублей</b></p>
        <p> Срок займа: <b>{loan?.loanTerm} {loan?.loanTerm === 12 ? 'месяцев' : 'месяца'}</b></p>
      </div>

       <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Персональные данные</span>
        <p> ФИО: <b>{loan?.offer?.fio ?? loan?.fio} </b></p>
        <p> Дата рождения: <b> {renderYYMMDDToDDMMYY(loan?.offer?.birthDate ?? loan?.birthDate )}</b></p>
        <p> Паспорт РФ: <b>{loan?.offer?.passportSeries ?? loan.passportSeries } {loan?.offer?.passportNumber ?? loan.passportNumber }</b></p>
        <p> Номер телефона: <b>{loan?.offer?.phone ?? loan.phone }</b></p>
        <p> Email: <b>{loan?.orderEmail }</b></p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Предмет залога</span>
        <p>Авто: <b>{loan?.offer?.name  ?? loan.name}</b></p>
        <p>ВИН: <b>{loan?.offer?.vin ?? loan.vin}</b></p>
        <p>Гос. номер: <b>{loan?.offer?.regNum ?? loan.regNum}</b></p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Способ получения займа</span>
        <p>На банковскую карту: <b> **** {loan?.cardNumber}</b></p>
      </div>
      <h4>Подписанные документы</h4>
      <LoanDocs loan={loan}/>
      {loan.loanId ?
        <div className={s.buttonWrapper}>
          <BaseButton onClick={onClose} text={'Закрыть'} />
          <BaseButton onClick={moveToLoanPage} text={'К странице займа'} />
        </div>
      : <BaseButton text={"Закрыть"} onClick={onClose}/>
      }

    </div>
  )
}

export default ModalInfo
