import React, { useState } from 'react'
import PropTypes from 'prop-types'

import s from './ContactList.module.scss'
import NoData from '../../../components/atoms/NoData'
import YMap from '../../../components/molecules/YMap'
import ContentContainer from '../../../components/molecules/ContentContainer';
import AutoSelect from "../../../components/atoms/AutoSelect";
import {ResizeContext} from "../../../providers/ResizeProvider";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "../../../constants/phones";

const props = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired
}

const ContactList = ({ contacts }) => {

  const [currentCity, setCurrentCity] = useState('Новосибирск')

    const changeCity = (x) => {
      if (x) {
          setCurrentCity(x)
      }
    }

  const cities = contacts.map(item => item.city)

  const renderCity = (city, index) => {
    return <span key={index} onClick={() => setCurrentCity(city)} className={currentCity === city ? s.active : null}>
              {city}
            </span>
  }

  const getOfficiesByCity = (city) => {
    const contact = contacts.find(item => item.city === city)
    if (contact) return contact.officeList
  }

  const officies = getOfficiesByCity(currentCity)

  const renderOfficies = () => {
    if (!officies) return null

    return officies.map(item => <ContentContainer key={item.zipCode}>
        <article>
            <b>
                {item.zipCode && `${item.zipCode}, `}
                {item.address}
            </b>
            <p>
              <a key={item} href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a>
            </p>
            <p>
                <span>Пн-Пт {item.weekdaysSchedule}</span>
                <span>Сб {item.saturdaySchedule}</span>
                <span>Вс {item.sundaySchedule}</span>
            </p>
        </article>
    </ContentContainer>)
  }

  if (!contacts.length) return <NoData text={'Нет данных'} />

  return (
    <div className={s.contacts}>
        <h1 className="title">Контакты</h1>
        <ResizeContext.Consumer>
            {({ mobile }) => (
                mobile
                    ? <AutoSelect
                        placeholder="Город"
                        theme="gray"
                        options={cities}
                        onChanged={(x) => {changeCity(x)}}
                        onSearch={null}
                        defaultValue={currentCity}
                    />
                    : <div className={s.contacts__list}>
                        {cities.map((city, i) => renderCity(city, i))}
                    </div>
            )}
        </ResizeContext.Consumer>
      {/*<BaseSelect*/}
      {/*    options={cities}*/}
      {/*    onChanged={(x) => {setCurrentCity(x)}}*/}
      {/*    style={{ width: '100%' }}*/}
      {/*    default={currentCity}*/}
      {/*/>*/}
      <div className={s.contacts__cards}>
        {renderOfficies()}
      </div>
      <div className={s.contacts__map}>
        <YMap officies={officies} />
      </div>
    </div>
  )
}

ContactList.propTypes = props

export default ContactList
