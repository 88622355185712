import React, {useState, useEffect} from 'react';

import s from "./RegistrationModal.module.scss";

import {validatePhone} from "../../../utils/validators";
import ModalContainer from "../../atoms/ModalContainer";
import PhoneInput from "../../atoms/PhoneInput";
import BaseButton from "../../atoms/BaseButton";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import BaseInput from "../../atoms/BaseInput";
import StrongPasswordLine from "../../atoms/StrongPasswordLine";
import {privacyPolicy} from "../../Footer/links";

import { getPlainPhone } from '../../../helpers'
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";
import elementsStore from "../../../store/elementsStore";


const RegistrationModal = ({registrationModalVisible, hideRegistrationModal, loading, registration, registrationResult, prevStepRegistrationModal, registrationModalStep, showRecoveryFromRegistration}) => {
  const [phone, setPhone] = useState()
  const [validatePhoneText, setValidatePhoneText] = useState()
  const [password, setPassword] = useState()
  const [title, setTitle] = useState('Регистрация')
  const [checkPersonal, setCheckPersonal] = useState(false)
  const [checkNews, setCheckNews] = useState(false)
  const [isDisabledButtonFirstStep, setIsDisabledButtonFirstStep] = useState(false)
  const [registrationResultLocal, setRegistrationResultLocal] = useState(registrationResult)

  // useEffect(() => {
  //   registrationModalStep === 3 && registration('create_client', {
  //     phone: getPlainPhone(phone, {format: 'withPlus'}),
  //     code: elementsStore.smsCode,
  //     permToProcess: checkPersonal,
  //     permToNews: checkNews
  //   })
  // },[registrationModalStep])

  useEffect(() => {
    setRegistrationResultLocal(registrationResult)
  },[registrationResult])

  useEffect(() => {
    if(registrationModalStep === 1 || registrationModalStep === 3) {
      setTitle('Регистрация')
    }
    if(registrationModalStep === 2) {
      setTitle('Подтверждение номера телефона')
    }
    if(registrationModalStep === 4) {
      setTitle('Поздравляем! 🎉 ')
    }
  },[registrationModalStep])

  const goToSecondStep = () => {
    setRegistrationResultLocal('')
    if(validatePhone(phone)) {
      setValidatePhoneText(validatePhone(phone))
    } else {
      setValidatePhoneText('')
      registration('send_phone_code', {phone: getPlainPhone(phone, {format: 'withPlus'})})
      setIsDisabledButtonFirstStep(true)
    }
  }

  const disabledButton = () => {
    if(registrationModalStep === 1 && phone && checkPersonal) {
      return (phone && !getPlainPhone(phone, { format: 'withPlus' }).match(/^\+7\d\d\d\d\d\d\d\d\d\d$/)) || isDisabledButtonFirstStep
    }
    if(registrationModalStep === 3 && password) {
      const checkLetterInPassword = /[^0-9]+/g
      const checkNumberInPassword = /\d/g
      return password.length < 6 || !checkLetterInPassword.test(password) || !checkNumberInPassword.test(password)
    }
    return true
  }

  const onChangePhone = (value) => {
    setPhone(value)
    setIsDisabledButtonFirstStep(false)
  }

  const checkConfirmCode = () => {
    registration('check_phone_code', {phone: getPlainPhone(phone, {format: 'withPlus'}), code: elementsStore.smsCode})
  }

  const sendConfirmCode = () => {
    registration('send_phone_code', {
        phone: getPlainPhone(phone, {format: 'withPlus'}),
        forCreateClient: true
    })
  }

  const createPassword = async () => {
    await registration('create_client', {
      phone: getPlainPhone(phone, {format: 'withPlus'}),
      code: elementsStore.smsCode,
      permToProcess: checkPersonal,
      permToNews: checkNews
    })
    await registration('restore_with_code', {phone: getPlainPhone(phone, {format: 'withPlus'}), code: elementsStore.smsCode, newPassword: password})
  }


  let stepOne = <>
    <div className={s.modal}>
      <p className={s.modal__text}>
        Укажите ваш номер телефона — на него придет SMS c кодом подтверждения
      </p>
      <div>
        <div className={s.modal__input}>
          <PhoneInput
            type="number"
            placeholder="Номер телефона"
            value={(value) => onChangePhone(value)}
            defaultValue={phone}
          />
          {registrationResultLocal?.result === 'error' &&
            <div className={s.modal__errorMessage}>Аккаунт с таким номером уже зарегистрирован.
              Попробуйте <span onClick={() => showRecoveryFromRegistration(phone)} style={{cursor: 'pointer', textDecoration: 'underline'}}>восстановить пароль.</span></div>
          }
          {validatePhoneText && <div className={s.modal__errorMessage}>{validatePhoneText}</div>}
        </div>
        <div style={{marginTop: '40px', marginBottom: '24px'}}>
          <BaseCheckbox
            value={checkPersonal}
            onChange={() => setCheckPersonal(!checkPersonal)}
            inversion
            sizeText={'small'}
          >
            <span>
              Я даю согласие на обработку Персональных данных на основании <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">Политики конфиденциальности.</a>
            </span>
          </BaseCheckbox>
        </div>
        <BaseCheckbox
          value={checkNews}
          onChange={() => setCheckNews(!checkNews)}
          inversion
          sizeText={'small'}
        >
          Я даю согласие на получение новостей и информации об акциях компании.
        </BaseCheckbox>
      </div>
      <div  className={s.modal__btn}>
        <BaseButton
          text="Продолжить"
          type="green"
          size="big"
          loading={loading}
          onClick={goToSecondStep}
          disabled={disabledButton()}
        />
      </div>
    </div>
  </>

  let stepTwo = <>
    <div className={s.modal}>
      <p className={s.modal__text}>
        Мы отправили код доступа на номер <span style={{whiteSpace: 'nowrap'}}>{phone}</span> <br/>
        Введите код из SMS:
      </p>
      <div className={s.modal__input}>
        <ConfirmCodeInput
          onEntered={checkConfirmCode}
          onSend={sendConfirmCode}
        />
      </div>
    </div>
  </>

  let stepThree = <>
    <div className={s.modal}>
      <p className={s.modal__text}>
        Установите пароль для Личного кабинета
      </p>
      <div>
        <div className={s.modal__input}>
          <BaseInput
            type="password"
            placeholder="Придумайте пароль"
            value={(x) => setPassword( x) }
          />
        </div>
        <div className={s.modal__input}>
          <StrongPasswordLine value={password}/>
        </div>
        <div  className={s.modal__btn}>
          <BaseButton
            text="Продолжить"
            type="green"
            size="big"
            loading={loading}
            disabled={disabledButton()}
            onClick={createPassword}
          />
        </div>
      </div>
    </div>
  </>

  let stepFour = <>
    <div className={s.modal}>
      <p>
        Вы успешно зарегистрировались! <br/><br/>
        Через несколько секунд будет выполнен автоматический переход в Ваш Личный кабинет.
      </p>
    </div>
  </>
  return (
    <>
      <ModalContainer
        showModal={ registrationModalVisible }
        closeModal={hideRegistrationModal}
        title={title}
        btnBack={registrationModalStep === 2 }
        onClickBack={prevStepRegistrationModal}
      >
        {registrationModalStep === 1 && stepOne}
        {registrationModalStep === 2 && stepTwo}
        {registrationModalStep === 3 && stepThree}
        {registrationModalStep === 4 && stepFour}
      </ModalContainer>
    </>
  );
};

export default RegistrationModal;
