import React, {useEffect, useState} from 'react'
import {NavLink, Redirect, useLocation} from 'react-router-dom'
import {toJS} from 'mobx'
import {observer, inject} from 'mobx-react'

import ConfirmModal from '../atoms/ConfirmModal'

import {ReactComponent as IconCircle} from './img/circle.svg'
import {ReactComponent as IconDash} from './img/dash.svg'
import {ReactComponent as IconLogout} from './img/logout.svg'
import {ReactComponent as IconMail} from './img/mail.svg'
import {ReactComponent as IconPay} from './img/pay.svg'
import {ReactComponent as IconPercent} from './img/percent.svg'
import {ReactComponent as IconStar} from './img/star.svg'
import {ReactComponent as IconUser} from '../../img/user.svg'
import {ReactComponent as IconSend} from './img/send.svg'

import s from './Sidebar.module.scss'
import {isMobile} from "../../utils/isMobile";
import {yandexMetrikaReachGoal} from "../../utils/yandex-metrika";
import ButtonPreloader from "../atoms/ButtonPreloader/ButtonPreloader";

const Sidebar = inject('appStore')(
    observer((props) => {
        const {appStore} = props
        const {sidebarVisible, changeSidebarVisible, logout} = appStore

        const [show, setShow] = useState(false)
        const [logoutModal, setLogoutModal] = useState(false)
        const [isClubPage, setIsClubPage] = useState(false)
        const location = useLocation();

        useEffect(() => {
            setIsClubPage(location.pathname.includes('club'))
        }, [location])

        useEffect(() => {
            setShow(sidebarVisible)
            document.body.style.overflow = sidebarVisible ? 'hidden' : null
        }, [sidebarVisible])

        const onLogout = (_) => {
            logout()
            changeSidebarVisible(false)

            return <Redirect to="/login"/>
        }

        const loans = toJS(appStore.loans)

        const confirmModal = (
            <ConfirmModal
                center={true}
                title="Точно хотите выйти?"
                onOk={() => onLogout()}
                onClose={() => setLogoutModal(false)}
                showModal={logoutModal}
            />
        )

        const activeLoans = loans && !isMobile() && (
            <ul>
                {loans
                    .filter((loan) => loan.active === 1)
                    .map(({ln, statusLoan, isSideLoan}, i) => (
                        <li key={i}>
                            <NavLink
                                to={`/loans/${ln}`}
                                activeClassName={s.active}
                                className={s.link}
                                onClick={() => changeSidebarVisible(false)}
                            >
                                Займ № {ln} {isSideLoan ? '(c)' : ''}
                            </NavLink>
                        </li>
                    ))}
            </ul>
        )

        const logoutLine = (
            <div className={s.logout} onClick={() => setLogoutModal(true)}>
                <IconLogout/>
                Выйти
            </div>
        )

        const pages = [
            {
                link: '/',
                name: 'Рабочий стол',
                icon: <IconDash/>
            },
            {
                link: '/loans',
                name: 'Мои займы',
                icon: <IconPercent/>,
                extra: activeLoans
            },
            {
                link: '/loans-request',
                name: 'Заявки на займ',
                icon: <IconSend/>
                // TODO: add active online loan
                // extra: activeLoans
            },
            {
                link: '/profile',
                name: 'Мой профиль',
                icon: <IconUser/>
            },
            {
                link: '/settings',
                name: 'Карты',
                icon: <IconPay/>
            },
            {
                link: '/club',
                name: 'Клуб «100 друзей»/кэшбек',
                icon: <IconCircle/>,
                hr: true,
            },
            {
                link: '/news',
                name: 'Новости и спецпредложения',
                icon: <IconStar/>
            },
            {
                link: '/contacts',
                icon: <IconMail/>,
                name: 'Контакты',
            }
        ]

        const classes = [s.sidebar]

        if (show) classes.push(s.show)

        const navLinkClick = (link) => () => {
            changeSidebarVisible(false)
            if (link.includes('club')) {
                yandexMetrikaReachGoal('click_club_100druzei')
            }
        }

        return (
            <div className={classes.join(' ')}>
                {confirmModal}
                <nav>
                    {pages.map((page) => {
                        const isClubLink = page.link.includes('club')
                        return (
                            <React.Fragment key={page.link}>
                                {page.hr && <hr/>}
                                <NavLink
                                    exact
                                    to={page.link}
                                    activeClassName={s.active}
                                >
                                    {(isClubLink && !isClubPage) ?
                                        <ButtonPreloader>
                                            <span className={[s.link, s.friends].join(' ')}  onClick={navLinkClick(page.link)}>
                                                {page.icon} {page.name}
                                            </span>
                                        </ButtonPreloader>
                                        : <span className={s.link} onClick={navLinkClick(page.link)}>
                                            {page.icon} {page.name}
                                          </span>
                                    }
                                </NavLink>
                                {page.extra ?? null}
                            </React.Fragment>
                        )
                    })}
                    <hr className={s['sidebar--only-mobile']}/>
                </nav>
                <div className={s['sidebar--only-mobile']}>{logoutLine}</div>
            </div>
        )
    })
)

export default Sidebar
