import React from "react";
import {isMobile} from "../../../utils/isMobile";
import BaseSelect from "../../atoms/BaseSelect";
import AutoSelect from "../../atoms/AutoSelect";

const NativeSelect = (props)=>{
    return isMobile() ?
        <BaseSelect
            {...props}
        />
        :
        <AutoSelect
            {...props}
        />
}
export default NativeSelect
