import React from "react";
import s from './OnlineLoanPrivacy.module.scss'
import BaseInput from "../../atoms/BaseInput";
import NativeSelect from "../../molecules/NativeSelect";

export const relativeSelect = ['Да', 'Нет']

const PublicOfficialCollapse = ({publicOfficialForm, setPublicOfficialForm, notPublicPerson= true}) => {
    const {
        publicPersonCategory,
        relativePublicPerson,
        relativePublicPersonCategory,
        relativePublicPersonRelationship
    } = publicOfficialForm


    return <div className={s.publicOfficialCollapse} onClick={e=>e.stopPropagation()}>
        <p>Настоящее Соглашение определяет условия осуществления обмена информацией в электронной форме между Сторонами, в том числе при заключении договора займа и договора залога в виде электронного документа, а также требования к конфиденциальности.</p>
        {!notPublicPerson && <div className={[s.inputsGroup, s.inputsGroup__main].join(" ")}>
            <div>
                <BaseInput
                    defaultValue={publicPersonCategory}
                    value={v => setPublicOfficialForm({...publicOfficialForm, publicPersonCategory: v})}
                    placeholder="К какой категории Вы относитесь? *"
                />
            </div>
            <div>
                <NativeSelect
                    placeholder="Ваши родственники ПДЛ*? *"
                    options={relativeSelect}
                    defaultValue={relativePublicPerson}
                    onSearch={null}
                    onChanged={v => setPublicOfficialForm({...publicOfficialForm, relativePublicPerson: v})}
                />
            </div>
            {relativePublicPerson === relativeSelect[0] && <>
                <div>
                    <BaseInput
                        defaultValue={relativePublicPersonCategory}
                        value={v => setPublicOfficialForm({...publicOfficialForm, relativePublicPersonCategory: v})}
                        placeholder="К какой категории ПДЛ* относится Ваш родственник? *"
                    />
                </div>
                <div>
                    <BaseInput
                        defaultValue={relativePublicPersonRelationship}
                        value={v => setPublicOfficialForm({...publicOfficialForm, relativePublicPersonRelationship: v})}
                        placeholder="Какая Ваша степень родства с ПДЛ* ? *"
                    />
                </div>
            </>}
        </div>
        }
        <p className={s.note}>* Публичное должностное лицо (ПДЛ) — лицо, постоянно, временно или по специальному полномочию осуществляющие функции представителя власти либо выполняющие организационно-распорядительные, административно-хозяйственные функции в государственных органах, органах местного самоуправления, государственных и муниципальных учреждениях, государственных корпорациях, государственных компаниях, государственных и муниципальных унитарных предприятиях, акционерных обществах, контрольный пакет акций которых принадлежит Российской Федерации, субъектам Российской Федерации или муниципальным образованиям, а также в Вооруженных Силах Российской Федерации, других войсках и воинских формированиях Российской Федерации. (требование согласно Федерального Закона № 115-ФЗ)</p>
    </div>
}
export default PublicOfficialCollapse
