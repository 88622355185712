import BaseMoneyInput from "../../../atoms/BaseMoneyInput";
import React, {useEffect, useState} from "react";
import {MAX_CFR_PAY_AMOUNT} from "../constants";
import {observer} from "mobx-react";
import appStore from "../../../../store/app";

const CfrAmountInput = observer (({selectedPeriod}) => {
    const [payAmountInput, setPayAmountInput] = useState()
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        defineMin()
        defineMax()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPeriod])

    useEffect(() => {
        appStore.setCurrentEntityForPayAmount(payAmountInput)
        const parts = appStore.currentEntityForPay?.parts

        if (parts?.part.minSum && payAmountInput >= parts.part.minSum){
            let localRequirePayments = 0
            for (const key in parts.part){
                const ignoreKeys = ['userSum', 'minSum', 'mainDebt', 'maxSum']
                if (ignoreKeys.includes(key)) continue;
                localRequirePayments += parts.part[key]
            }
            parts.part.mainDebt = (payAmountInput - localRequirePayments).toFixed(2)
            parts.part.userSum = payAmountInput
        }
        if (parts?.full.fullSum && parts?.full.fullSum > MAX_CFR_PAY_AMOUNT){
            parts.full.userSum = MAX_CFR_PAY_AMOUNT
            // вычисление макс если бек пришлет больше 1000000 - это ограничение системы
            if (!parts.full.mainDebt || parts.full.mainDebt > MAX_CFR_PAY_AMOUNT ) {
                let othersSum = 0
                for (let key in parts.full) {
                    if (key === 'userSum' || key === 'fullSum') continue
                    othersSum += parts.full[key]
                }
                parts.full.mainDebt = MAX_CFR_PAY_AMOUNT - othersSum
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[payAmountInput])

    useEffect(() => {
      if (appStore.currentEntityForPayAmount) setPayAmountInput(appStore.currentEntityForPayAmount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appStore.currentEntityForPayAmount])

    const defineMin = () => {
        const currentAmount = selectedPeriod.sum ?? selectedPeriod.minSum ?? selectedPeriod.fullSum ?? 0
        setPayAmountInput(currentAmount)
        if (selectedPeriod.minSum){
            // appStore.currentEntityForPay.parts.part.mainDebt = 1
            appStore.currentEntityForPay.parts.part.userSum = appStore.currentEntityForPay.parts.part.minSum
            appStore.setCurrentEntityForPayMinAmount(selectedPeriod.minSum)
        } else {
            appStore.setCurrentEntityForPayMinAmount(0)
        }
    }

    const defineMax = () => {
        if (selectedPeriod.sum){
            appStore.setCurrentEntityForPayMaxAmount(selectedPeriod.sum)
            return;
        }
        if (selectedPeriod.fullSum) {
            appStore.setCurrentEntityForPayMaxAmount(Math.min(MAX_CFR_PAY_AMOUNT, selectedPeriod.fullSum))
            return;
        }
        if (selectedPeriod.maxSum) {
            appStore.setCurrentEntityForPayMaxAmount(Math.min(MAX_CFR_PAY_AMOUNT, selectedPeriod.maxSum))
        }
    }

    useEffect(() => {
        setError(false)
        const digitPayAmountInput = parseFloat(payAmountInput)
        if (digitPayAmountInput > appStore.currentEntityForPayMaxAmount) {
            setError(true)
            setErrorMessage(`Сумма платежа не может быть выше ${appStore.currentEntityForPayMaxAmount}`)
        }
        if (digitPayAmountInput < appStore.currentEntityForPayMinAmount) {
            setError(true)
            setErrorMessage(`Сумма платежа не может быть ниже ${appStore.currentEntityForPayMinAmount}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payAmountInput, appStore.currentEntityForPayMinAmount, appStore.currentEntityForPayMaxAmount])
    // sum maybe str after parseFloat.toFixed()
    // eslint-disable-next-line
    const isDisabled = selectedPeriod.sum || selectedPeriod["sum"] == 0

    return <BaseMoneyInput
        placeholder={selectedPeriod.sum ? "Сумма оплаты по графику, ₽" : "Введите сумму, ₽"}
        onChange={(x) => setPayAmountInput(x)}
        value={payAmountInput}
        min={appStore.currentEntityForPayMinAmount}
        max={appStore.currentEntityForPayMaxAmount}
        disabled={isDisabled || selectedPeriod.fullSum}
        error={error}
        errorMessage={errorMessage}
    />
})
export default CfrAmountInput
