import React from 'react'
import s from './BaseCheckbox.module.scss'
import {ReactComponent as IconCheck} from './check.svg'
import {ReactComponent as IconCheckInversion} from './check-inversion.svg'

const BaseCheckbox = ({value, onChange, children, color = 'secondary', disabled = false, inversion, sizeText='default'}) => {
    const component = React.isValidElement(children) ?
        <div>
            {children}
        </div>
        :
        <span
          className={s[color]}
          dangerouslySetInnerHTML={{__html: children}}
        />

    return <label className={s.checkbox}>
        <input key={Math.random()}
               type="checkbox"
               checked={value}
               onChange={onChange}
               readOnly={!onChange}
               disabled={disabled}
               className={inversion && s.inversion}
        />
        <div className={[s.text_wrapper, s[`text_wrapper--${sizeText}`]].join(' ')}>
            <i className={inversion && s.inversion}>{inversion ? <IconCheckInversion /> : <IconCheck/>}</i>
            {component}
        </div>
    </label>
}

export default BaseCheckbox
