import React from "react";
import s from "./Payments.module.scss";
import ContentContainer from "../../molecules/ContentContainer";
import PaymentsScheduleTable from "../../organisms/PaymentsScheduleTable";
import {ReactComponent as IconChevron} from '../../../img/chevron.svg';
import LoadMoreButton from "../../molecules/LoadMoreButton";
import NoData from "../../atoms/NoData";

export default class Payments extends React.Component{

    state = {
        showPaymentSchedule: false
    }

    togglePaymentsSchedule = () => {
        this.setState({showPaymentSchedule: !this.state.showPaymentSchedule})
    }

    render() {
        const {hideLoans, payments, paginationPayments, loadingMore, loadMorePayments, closed} = this.props

        if (hideLoans || closed ) return null

        return (
            <div className={s.payments}>
                <h2 className="title" onClick={() => {this.togglePaymentsSchedule()}}>
                    График платежей
                    <span className={this.state.showPaymentSchedule ? s.active : null}><IconChevron/></span>
                </h2>
                {
                    this.state.showPaymentSchedule &&
                        <div className={s.payments__list}>
                            <ContentContainer>
                                {this.props.payments.length ?
                                    <>
                                        <PaymentsScheduleTable payments={payments}/>
                                        <div className={s.payments__button}>
                                            <LoadMoreButton
                                                dataLength={payments.length}
                                                onLoadMore={loadMorePayments}
                                                loadingMore={loadingMore}
                                                total={paginationPayments.total}
                                            />
                                        </div>
                                    </>
                                    : <NoData text={'Нет данных'}/>
                                }
                            </ContentContainer>
                        </div>
                }
            </div>
        )
    }
}
