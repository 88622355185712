import {useEffect, useState} from 'react';
import onlineLoanStore from "../store/onlineLoanStore";
import elementsStore from "../store/elementsStore";
import {errorsParser} from "../utils/errorsParser";


function useRefreshOnlineLoanDetail(insertDataFunc) {
    const [errors, setErrors] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true)
                if (onlineLoanStore.loanDetail.id)
                    // don`t wait for better ux, we already have data
                    onlineLoanStore.refreshLoanDetail().then(insertDataFunc)
                else {
                    await onlineLoanStore.refreshLoanDetail()
                }
            } catch (e) {
                setErrors({errors: e.message})
                throw new Error('fetch data error')
            } finally {
                setIsLoading(false)
            }
        }

        fetchData().then(insertDataFunc).catch(e => console.warn(e))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (errors) elementsStore.showSnackbar(errorsParser(errors));
    }, [errors])

    return [isLoading, errors]
}

export default useRefreshOnlineLoanDetail
