import BaseLoansOverdueInfo from "./BaseLoansOverdueInfo";
import s from "./BaseLoans.module.scss";
import TooltipWrapper from "../../atoms/TooltipWrapper/TooltipWrapper";
import BaseButton from "../../atoms/BaseButton";
import React from "react";
import formattedMoney from "../../../utils/formattedMoney";
import appStore from "../../../store/app";
import constants from "../../../constants";
import saveLog from "../../../utils/saveLog";
import {toJS} from "mobx";
import {VI_OFFICE_PHONE} from "../../../constants/phones";

const LoanActionsInfo = ({loan}) => {
    const {mfo,isTaxLate, nextPaymentSum, isSign, paymentAllowed,
        hideLoans, loanSum, ln, isBlocked, isMonthOverPaid, isExtensionAgreementNeed
    } = loan

    const {profile = {}} = toJS(appStore)

    const [rubles, kopecks] = String(nextPaymentSum).split('.')

    const sumLabel = () => {
        if (isBlocked) return 'Займ заблокирован'
        const text = <span>{formattedMoney(rubles)}<span>{kopecks ? `.${kopecks}` : ''}</span> ₽</span>
        if ((mfo === 'vi' && isTaxLate) || isBlocked) return text
        if (!isSign) return text
        if (!paymentAllowed && !hideLoans) return 'Обработка платежа'
        return text
    }

    const isLoading = () => {
        if ((isTaxLate && mfo ==='vi') || hideLoans || isBlocked) return false
        return !paymentAllowed && isSign;
    }

    const openSignDocsModal = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        await appStore.setCurrentEntityForPay(loan)
        await appStore.setDocsForSignModal(loan.docs)
        await appStore.showSignDocModal()
    }

    const pay = (item, e, hideLoans) => {
        if (!hideLoans) {
            e.preventDefault()
            e.stopPropagation()
            const payload = {
                paymentTitle: 'Оплатить займ',
                paymentText: item.ln,
                id: item.ln,
                ctx: {},
                ...item,
                type: item.mfo === 'vi' ? constants.payment.type.loan : constants.payment.type.monthly
            }
            appStore.setDocsForSignModal(item.docs ?? [])
            appStore.openPayModalFor(payload)
        }
    }

    const sendToRollbar = async (data, profile, ln) => {
        try {
            if (data && profile && ln) {
                await saveLog('opened_loans:click', {
                    loanId: ln,
                    action: 'открытие модального окна',
                    isButtonDisabled: data.isDisabled,
                    userPhone: profile.phone
                })
            }
        } catch (error) {
            console.log('unable to connect to rollbar')
        }
    }

    const disabled = () => {
        return isTaxLate
            || hideLoans
            || !paymentAllowed
            || !isSign
            || loanSum === 0
            || isMonthOverPaid
            || isBlocked
    }

    const tooltipContent = !isSign ? 'Сначала необходимо подписать документы' : `Обратитесь в офис или по номеру ${VI_OFFICE_PHONE}`

    if (isExtensionAgreementNeed){
        return null
    }

    return <div className={s.loans__wrapperPayment}>
        <BaseLoansOverdueInfo loan={loan} />
        <div
            className={[s.loans__buttons, !paymentAllowed && s['loans__buttons--wide']].join(' ')}>
            <TooltipWrapper
                placement={'top'}
                content={tooltipContent}
                active={!isSign || isBlocked || isMonthOverPaid}
            >
                <BaseButton
                    type="green-pay"
                    size="medium"
                    chevron
                    disabled={disabled()}
                    text={sumLabel()}
                    onClick={(e) => pay(loan, e, hideLoans)}
                    btnId="pay-modal-open"
                    onPaymentClick={(e) => sendToRollbar(e, profile, ln)}
                    loading={isLoading()}
                />
            </TooltipWrapper>
            {!isSign && <div className={s['loans__buttons--sign']}>
                <BaseButton
                    type='sign'
                    text='Подписать документы'
                    onClick={openSignDocsModal}
                />
            </div>}
        </div>
    </div>
}
export default LoanActionsInfo
