import React from 'react'

import {backReq} from "../../../../helpers";
import ButtonsLayout from "../ButtonsLayout";
import elementsStore from "../../../../store/elementsStore";
import {observer} from "mobx-react";

const LoanTab = observer(() => {
    const loanId = elementsStore.moderatedId
    const loanButtons = [
        {
            text: 'Активировать заявку',
            request: () => backReq('online_manager:activate_offer')
        }, {
            text: 'Сбросить до начального состояния прохождение заявки',
            request: () => backReq('online_manager:reset',{id: loanId})
        }, {
            text: 'Обновить условия для заявки',
            request: () => backReq('online_manager:update',{id: loanId} )
        }, {
            text: 'Заблокировать заявку',
            request: () => backReq('online_manager:block_offer')
        },
        {
            text: 'Шаг 2 limit',
            request: () => backReq('online_manager:unblock_online_loan',{
                id: loanId,
                "step": "client_data",
                "unblockStep": false,
            })
        },
        {
            text: 'Шаг 2 !limit',
            request: () => backReq('online_manager:unblock_online_loan', {
                id: loanId,
                "step": "client_data",
                "unblockStep": true,
            }),

        },
        {
            text: 'Шаг 2 разблокировка сокета',
            request: () => backReq('online_manager:unblock_online_loan', {
                id: loanId,
                "step": "client_data",
                "unblockStepChanging": true,
            }),

        },
        {
            text: 'Шаг 2 блокировка сокета',
            request: () => backReq('online_manager:unblock_online_loan', {
                id: loanId,
                "step": "client_data",
                "unblockStepChanging": false,
            }),

        },
    ]

    return <React.Fragment>
        <ButtonsLayout buttons={loanButtons}></ButtonsLayout>
    </React.Fragment>
})
export default LoanTab
