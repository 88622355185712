import React from 'react';
import { Alert } from 'antd'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import s from './styles.module.scss'
import ContentContainer from '../../components/molecules/ContentContainer';
import LoadMoreButton from '../../components/molecules/LoadMoreButton';
import NotificationCondensed from "../NotificationsBar/NotificationCondensed";
import BaseSkeleton from "../../components/atoms/BaseSkeleton";

@inject('appStore')
@observer
class NotificationsPage extends React.PureComponent {
  dismissNotificationsError = () => this.props.appStore.dismissNotificationsError();

  componentDidMount () {
    if (toJS(this.props.appStore).notifications.length === 0) {
      this.props.appStore.fetchNotifications()
    }

    // this.props.appStore.markAllAsReaded();
  }

  render () {
    const { notifications, notificationsLoading, notificationsError, hasNotificationsLeft } = toJS(this.props.appStore)

    if (notificationsLoading && notifications.length === 0) return <BaseSkeleton/>

    return <div className="container">
      <section className={s.notifications}>
        <h2 className="title">Уведомления</h2>
        <ContentContainer>
          {notifications.map((notification) => (
              <article key={notification.id}>
                <NotificationCondensed
                    type={notification.type}
                    text={notification.text}
                    date={notification.createdIso}
                />
              </article>
          ))}

          {notificationsError && <Alert
              message="Произошла ошибка"
              description="Мы не смогли обработать Ваш запрос."
              type="error"
              closable
              onClose={this.dismissNotificationsError}
          />}

          {hasNotificationsLeft && <div className={s.notifications__button}>
            <LoadMoreButton onLoadMore={ () => this.props.appStore.fetchNotifications() }/>
          </div>}
        </ContentContainer>
      </section>
    </div>
  }
}

export default NotificationsPage
