import React, {useEffect, useRef, useState} from 'react';
import s from './CollapseLoanCard.module.scss'
import {
    VI_OFFICE_PHONE,
    VI_OFFICE_PHONE_URL,
    VI_OFFICE_REPEATER_PHONE,
    VI_OFFICE_REPEATER_PHONE_URL
} from "../../../constants/phones";

const text = {
    grace: {
        firstP: 'По Вашему займу началось использование Льготного периода. Во избежание  начисления штрафов напоминаем о необходимости внесения платежа.',
        secondP: `Если у Вас возникли трудности при оплате, свяжитесь с нами по бесплатному номеру <a  href="${VI_OFFICE_REPEATER_PHONE_URL}"><b>${VI_OFFICE_REPEATER_PHONE}</b></a>`,
        icon: 'warning',
    },
    overdue: {
        firstP: '<b>Уважаемый клиент!</b>',
        secondP: 'Оплата по займу просрочена, во избежание дальнейшего начисления штрафов необходимо срочно внести платеж.',
        thirdP: `Если у Вас возникли трудности при оплате, свяжитесь с нами по бесплатному номеру <a  href="${VI_OFFICE_REPEATER_PHONE_URL}"><b>${VI_OFFICE_REPEATER_PHONE}</b></a>`,
        icon: 'fail',
    },
    onlyOffice: {
        firstP: '<b>Уважаемый клиент!</b>',
        secondP: 'Внесение платежей по Вашим займам производится только в <a style="color: #4CAF50" href="/contacts">офисах</a> Компании.',
        thirdP: `Горячая линия: <a  href="${VI_OFFICE_PHONE_URL}"><b>${VI_OFFICE_PHONE}</b></a>`,
        icon: 'fail',
    },
    extensionAgreement: {
        firstP: '<b>Уважаемый клиент!</b>',
        secondP: 'Мы подготовим для вас дополнительное соглашение о пролонгации в день окончания вашего договора, в котором обновится сумма и график на 1 месяц',
        icon: 'info',
    },

}

const CollapseLoanCard = ({isGracePeriod, hideLoans, isTaxLate, isExtensionAgreementNeed}) => {
    const [show, setShow] = useState(false)
    const [type, setType] = useState('grace')
    const [height, setHeight] = useState('')
    const [whiteSpace, setWhiteSpace] = useState(true)

    const ghostText = useRef()

    useEffect(() => {
        window.addEventListener('resize', controlHeight)
        if (isTaxLate || hideLoans) {
            setType('onlyOffice')
        } else if (isExtensionAgreementNeed) {
            setType('extensionAgreement')
        } else {
            setType(isGracePeriod ? 'grace' : 'overdue')
        }
        return () => window.removeEventListener('resize', controlHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggle = () => {
        setShow(!show)
        setHeight(ghostText.current.getBoundingClientRect().height + 'px')
        if (!whiteSpace) {
            setTimeout(() => {
                setWhiteSpace(true)
            }, 200)
        } else {
            setWhiteSpace(false)
        }
    }

    const controlHeight = () => {
        setHeight(ghostText.current.getBoundingClientRect().height + 'px')
    }

    const setText = () => {
        if (isTaxLate || hideLoans) return text["onlyOffice"]
        return text[type]
    }

    const getLabel = () => {
        return Object.values(setText()).map((item, index) => (
            <p
                key={index}
                style={{whiteSpace: whiteSpace && 'nowrap'}}
                dangerouslySetInnerHTML={{__html: item}}/>
        ))
    }

    const getCollapseText = () => {
        const entries = Object.entries(setText())
        return entries.map(([key, value], index) => {
            if (key === 'icon') return null
            return (
            <p
                key={index}
                dangerouslySetInnerHTML={{__html: value}}
            />)}
        )
    }

    const iconType = text[type].icon ?? 'fail'

    return (
        <div className={[s.collapse, show ? s.active : '', s[`collapse--${type}`]].join(' ')}>
            <div className={s.collapse__container}>
                <img className={s.collapse__img} src={require(`../../../img/${iconType}.svg`)} alt=''/>
                <div className={s.collapse__text} style={{height: show && height}}>
                    {getLabel()}
                    <div className={s.collapse__ghost} ref={ghostText}>
                        {getCollapseText()}
                    </div>
                </div>
                <div onClick={toggle} className={s.collapse__chevron}/>
            </div>
        </div>
    );
};

export default CollapseLoanCard;
