import s from "./OperationsCard.module.scss";
import {ReactComponent as AddFoundsIcon} from "../../img/checked.svg";
import React, {useEffect, useState} from "react";
import appStore from "../../../../../store/app";
import {observer} from "mobx-react";
import formattedMoney from "../../../../../utils/formattedMoney";

const OperationsCard = observer(() => {

    const [operations, setOperations] = useState([]);

    useEffect(() => {
        const referralInfo = appStore?.referralInfo
        if (referralInfo?.data) {
            const localOperations = []
            const loans = referralInfo?.data?.loans ?? []
            loans.forEach(loan => {
                loan.transactions.forEach(transaction => {
                    localOperations.push({
                        phone: loan.phone,
                        number: loan.number,
                        amount: transaction.amount
                    })
                })
            })
            setOperations(localOperations)
        }
        //eslint-disable-next-line
    }, [appStore.referralInfo]);

    const securePhone = (phone) => {
        return "+7 " + phone.substring(1, 3) + '********' + phone.substring(8, 11)
    }

    return (
        <article className={s.card}>
            <div className={s.card__title}>
                <div className={s.card__header}>
                    <div className={s.card__titleText}>История операций</div>
                </div>
            </div>
            {operations.length
                ? <div className={s.transactionList}>
                    {operations.map(operation => {
                        const isPositive = operation.amount > 0
                        return <div key={operation.number} className={s.transaction}>
                            <div className={s.transactionInfo}>
                                <div className={s.transactionIcon}>
                                    <AddFoundsIcon style={{
                                        width: '30px',
                                        height: '30px',
                                        transform: 'rotate(180deg)'
                                    }}/>
                                </div>
                                <div className={s.transactionPhone}>
                                    {securePhone(operation.phone)}
                                </div>
                            </div>
                            <div className={[s.transactionAmount, isPositive ? s.positive : s.negative].join(' ')}>
                                {isPositive ? '+' + formattedMoney(operation.amount) : formattedMoney(operation.amount)}
                            </div>
                        </div>
                    })}
                </div>
                : <p className={s.card__description}>У вас пока нет истории операций</p>
            }
        </article>
    )
})
export default OperationsCard
