import React from 'react'
import {observer, inject} from 'mobx-react'

import ModalContainer from '../../components/atoms/ModalContainer';
import BaseInput from '../../components/atoms/BaseInput';
import BaseButton from '../../components/atoms/BaseButton';
import BaseMessage from "../../components/atoms/BaseMessage";

import s from './styles.module.scss'
import PersonaInfo from "./PersonaInfo";
import CarInfo from "./CarInfo";
import {fineBackendRequest} from "./helpers";
import PolicyAgreement from "../../components/atoms/PolicyAgreement";
const MIN_AMOUNT = 1000
const MAX_AMOUNT = 1000000
@inject('appStore')
@observer
class LoanOrderModal extends React.PureComponent {

    state = {
        phoneValid: true,
        amount: null,
        step: 'form',
        successModal: false,
        loading: false,
        overAmount: false
    }

    closeLoanOrderModal = () => {
        this.props.appStore.setLoanOrderModalVisible(false)
        this.props.closeModal()

    }
    /* Будет в сторе */
    proceed = () => {
        this.setState({
            loading: true
        })
        this.createOrder(
            this.props.appStore.loanOrderModalName,
            this.state.amount,
            this.props.appStore.loanOrderModalModel,
            this.props.appStore.loanOrderModalYear
        )
            .then(() => {
                this.setState({
                    step: 'success',
                    successModal: true
                })
                //отправка цели в Метрику
                window.ym && window.ym(48781889, 'reachGoal', 'order_load');
                this.props.appStore.clearLoanOrderModalData()
            }).catch((error) => {
            // this.setError(error);
        }).finally(() => {
            this.setState({
                loading: false,
                amount: ''
            })
        })

    }
    createOrder = async (profile, amount, model, releaseYear,) => {
        const url =  window.location.pathname.includes('login') ? 'create_lkk_order' : 'create_lkk_order_auth'
        return fineBackendRequest(url, {
            surname: '',
            name: profile,
            email: profile.email,
            phone: this.props.appStore.loanOrderModalPhone,
            patronymic: '',
            desiredAmount: amount,
            model,
            releaseYear,
        })
    }

    setAmount = (x) => this.setState({
        amount: x,
        overAmount: x > MAX_AMOUNT
    })

    closeModal() {
        this.clearLoanData()
        this.props.closeModal()
    }

    formatAmount(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    closeSuccessModal() {
        this.closeLoanOrderModal()
        this.setState({
            successModal: false
        })
    }

    render() {
        const {showModal} = this.props
        const {step, successModal} = this.state
        return <React.Fragment>
            <ModalContainer
                showModal={showModal}
                closeModal={() => this.closeLoanOrderModal()}
                title="Новый займ"
            >
                <div className={s.wrapper}>
                    <PersonaInfo/>
                    <CarInfo/>
                    <div className={[s.form__label, s['form__label--mt']].join(' ')}>Сумма займа</div>
                    <div>
                        <BaseInput
                            placeholder="Сумма займа, руб"
                            defaultValue={this.state.amount}
                            type="number"
                            value={(x) => this.setAmount(x)}
                        />
                        {this.state.overAmount && (
                            <div className={s.red} style={{marginTop: '12px'}}>
                                Введите сумму до 1 000 000 руб.
                            </div>
                        )}
                    </div>
                    <div className={s.form__bottom}>
                        <BaseButton
                            type="green"
                            size="big"
                            text="Создать заявку"
                            onClick={() => this.proceed()}
                            disabled={
                                this.state.amount < MIN_AMOUNT ||
                                this.state.amount > MAX_AMOUNT ||
                                !this.state.amount ||
                                !this.props.appStore.loanOrderModalName ||
                                !this.props.appStore.loanOrderModalPhone ||
                                !this.props.appStore.loanOrderModalYear ||
                                !this.props.appStore.loanOrderModalIsPhoneValid
                            }
                            loading={this.state.loading}
                        />
                    </div>
                    <div className={s.form__policyAgreement}>
                        <PolicyAgreement buttonName={'Создать заявку'}/>
                    </div>
                </div>
            </ModalContainer>
            {
                step === 'success' &&
                <ModalContainer
                    showModal={successModal}
                    closeModal={() => this.closeSuccessModal()}
                >
                    <BaseMessage type='success'>
                        Заявка успешно отправлена
                        {/*<br/>В ближайшее время мы с Вами свяжемся. Подробности по телефону:<br/><a href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a>*/}
                    </BaseMessage>
                </ModalContainer>
            }
        </React.Fragment>
    }
}

export default LoanOrderModal
