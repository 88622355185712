import React, {memo, useContext} from 'react'
import {formatMoney} from '../../../helpers'
import LoadMoreButton from '../../molecules/LoadMoreButton'
import s from './History.module.scss'
import ContentContainer from '../../molecules/ContentContainer';
import {dateToDMY, dateToHM} from "../../../utils/formattedDate";

import SuccessIcon from '../../../img/success.svg'
import WarningIcon from '../../../img/warning.svg'
import ErrorIcon from '../../../img/error.svg'
import {ResizeContext} from "../../../providers/ResizeProvider";

const History = ({history, loadingMore, pagination, loadMore}) => {
    const {total} = pagination
    if (!history) return <div className="skeleton"/>
    if (!history.length) return null

    const getStatus = (statusCode, message, sideLoanFullName) => {
        const appendix = sideLoanFullName ? ` (${sideLoanFullName})` : ''
        const statuses = {
            10: {
                text: 'В обработке' + appendix,
                icon: WarningIcon,
                color: 'orange'
            },
            20: {
                text: 'Успешно выполнен' + appendix,
                icon: SuccessIcon,
                color: 'green'
            },
            30: {
                text: 'Отказ' + (message ? ': ' + message : '') + appendix,
                icon: ErrorIcon,
                color: 'red'
            },
            0: {
                text: 'Неизвестно' + appendix,
                icon: WarningIcon,
                color: 'orange'
            }
        }

        return statuses[statusCode]
    }

    const ColumnsHeaders = () => {
        const {desktop} = useContext(ResizeContext)
        if (!desktop) return null
        return <div key="Table" className={[s.history__row, s.history__row_head].join(' ')}>
            {[
                'Статус',
                'Дата',
                'Время',
                'Сумма, ₽',
                'Способ платежа',
                'Назначение платежа',
                'Примечание'
            ].map((item, index) => {
                return <span key={item + index} className={s.mini}>{item}</span>
            })}
        </div>

    }
    const DesktopRow = memo(({fields}) => <div className={[s.history__row, s.history__line].join(' ')}>
            {Object.values(fields).map((fieldValue, index) => {
                return <React.Fragment key={'desktopRows' + index}>
                    {fieldValue}
                </React.Fragment>
            })}
        </div>
    )
    const MobileRow = memo(({fields}) => <div className={[s.history__flex, s.history__line].join(' ')}>
        {fields.icon}
        <div>
            <div className={[s.history__flex, s.mini].join(' ')}>
                {fields.date}
                {fields.time}
            </div>
            {fields.title}
            <div className={s.history__flex}>
                {fields.status}
                {fields.amount}
            </div>
            {fields.place}
        </div>
    </div>)

    const TableRow = ({fields}) => {
        const {desktop} = useContext(ResizeContext)
        return desktop
            ? <DesktopRow fields={fields}/>
            : <MobileRow fields={fields}/>
    }

    const Table = () => {
        const {desktop} = useContext(ResizeContext)
        const tableRows = history.map(({createdIso, amount, place, status, message, fullName, title}, index) => {
            const main = getStatus(status, message, fullName)
            const fields = {
                icon: <img src={main.icon} alt=""/>,
                date: <span>{dateToDMY(createdIso)}</span>,
                time: <span className={s.right}>{dateToHM(createdIso)}</span>,
                amount: <span
                    className={[s.right, s[main.color]].join(' ')}>{formatMoney(amount)} {desktop ? '' : '₽'}</span>,
                place: <span className={s.mini}>{place}</span>,
                title: <span>{title}</span>,
                status: <span className={s[main.color]}>{main.text}</span>
            }

            return <TableRow key={createdIso + index} fields={fields}/>
        })
        return <div className={s.history__table}>
            <ColumnsHeaders/>
            {tableRows}
        </div>
    }
    return <section className={s.history}>
        <React.Fragment>
            <h2 className="title">История платежей</h2>
            <ContentContainer>
                <Table/>
                {
                    history.length < total &&
                    <div className={s.history__button}>
                        <LoadMoreButton
                            dataLength={history.length}
                            onLoadMore={loadMore}
                            loadingMore={loadingMore}
                            total={total}
                        />
                    </div>
                }
            </ContentContainer>
        </React.Fragment>

    </section>
}

export default History
