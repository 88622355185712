import { observer } from "mobx-react";
import React, { Component } from "react";
import { backReq } from "../../helpers";
import appStore from "../../store/app";
import BaseSkeleton from "../atoms/BaseSkeleton";
import BaseAutoPayment from "../organisms/BaseAutoPayment";
import Info from "./Info";
import Top from "./Top";

@observer
class PageProfile extends Component {
	state = { autoPayment: null };

	componentDidMount() {
		appStore.changeSidebarVisible(false);
		this.fetchData();
	}
	fetchData() {
		Promise.all([backReq("get_auto_payment")]).then((res) => {
			this.setState({ autoPayment: res[0]?.data });
		});
	}

	render() {
		if (!appStore.profile) return <BaseSkeleton />;

		const { surname, name, patronymic, phone } = appStore.profile;

		return (
			<div className="container">
				<Top name={name} />
				<Info
					surname={surname}
					name={name}
					patronymic={patronymic}
					phone={phone}
				/>
				<BaseAutoPayment autoPayment={this.state.autoPayment} />
			</div>
		);
	}
}

export default PageProfile;
