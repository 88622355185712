import React, {useEffect, useState} from "react"

import BaseCheckbox from "components/atoms/BaseCheckbox"
import EditImg from "./../../../img/edit.svg"
import s from "./RepeaterFinal.module.scss"
import {backReq, getFormattedPhone} from "../../../helpers";
import RepeaterSumInfo from "../../molecules/RepeaterSumInfo";
import {calculateMonthlyPayment} from "../../../utils/onlineLoanMonthlyPayment";
import {observer} from "mobx-react";
import repeatStore from "../../../store/repeatStore";
import {errorsParser} from "../../../utils/errorsParser";
import ModalPortal from "../../atoms/ModalPortal";
import elementsStore from "../../../store/elementsStore";
import BasePhotoPreviewUpload from "../../atoms/BasePhotoPreviewUpload";
import {renderYYMMDDToDDMMYY} from "../../../utils/formattedDate";
import {useHistory} from "react-router-dom";
import formattedMoney from "../../../utils/formattedMoney";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import {updateUploadPhotoArray} from "../helpers";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {baseCloudSrc} from "../../../utils/templates/photoArrayTemplates";
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";
import onlineLoanStore from "../../../store/onlineLoanStore";
import getYaId from "../../../utils/getYaId";

const carInfoTemplate = [
    {title: "Автомобиль", value: ""},
    {title: "VIN", value: ""},
    {title: "Номер кузова", value: ""},
    {title: "Гос. номер", value: ""},
]
const personalInfoTemplate = [
    {title: "ФИО", value: ""},
    {title: "Гражданство", value: ""},
    {title: "Дата рождения", value: ""},
    {title: "Паспорт РФ", value: ""},
    {title: "Номер телефона", value: ""},
    {title: "Email", value: ""},
]

const offerInfoTemplate = [
    {title: "Сумма, ₽*", value: ""},
    {title: "Период, мес. *", value: ""},
    {title: "Ежемесячный платеж, ₽*", value: ""},
    {title: "Цель получения займа", value: ""},
]
const photoArrayTemplate = [
    {type: "liveness", src: baseCloudSrc, title: "Ваше селфи"},
    {
        type: "passport_main",
        src: baseCloudSrc,
        title: "Основной разворот паспорта (2-3 страницы)"
    },
    {
        type: "car_selfie",
        src: baseCloudSrc,
        title: "Ваше фото на фоне авто, чтобы был виден гос. номер"
    },
]

const RepeaterFinal = observer(() => {
    const [carInfo, setCarInfo] = useState(carInfoTemplate)
    const [personalInfo, setPersonalInfo] = useState(personalInfoTemplate)
    const [offerInfo, setOfferInfo] = useState(offerInfoTemplate)
    const [cardNumber, setCardNumber] = useState()
    const [photos, setPhotos] = useState(photoArrayTemplate)
    const [modal, setModal] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [isApprovedDataError, setIsApprovedDataError, isApprovedContainerRef] = useErrorContainer()
    const [fetchDataErrors] = useRefreshLoanDetail(setOffer)
    const history = useHistory()

    useEffect(() => {
        if (fetchDataErrors) elementsStore.showSnackbar(errorsParser(fetchDataErrors));
    }, [fetchDataErrors])

    async function setOffer() {
        if (!repeatStore.loanDetail.offer) return
        setCarInfo([
            {title: "Автомобиль", value: repeatStore.loanDetail?.offer?.name},
            {title: "VIN", value: repeatStore.loanDetail.offer.vin},
            {title: "Номер кузова", value: repeatStore.loanDetail.offer.bodyNumber},
            {title: "Гос. номер", value: repeatStore.loanDetail.offer.regNum},
        ])
        setPersonalInfo([
            {title: "ФИО", value: repeatStore.loanDetail.offer.fio},
            {title: "Гражданство", value: repeatStore.loanDetail.offer.citizenship},
            {title: "Дата рождения", value: renderYYMMDDToDDMMYY(repeatStore.loanDetail.offer.birthDate)},
            {
                title: "Паспорт РФ",
                value: `${repeatStore.loanDetail.offer.passportSeries} ${repeatStore.loanDetail.offer.passportNumber}`
            },
            {title: "Номер телефона", value:  getFormattedPhone(repeatStore.loanDetail.offer.phone)},
            {title: "Email", value: repeatStore.loanDetail.orderEmail},
        ])
        setOfferInfo([
            {title: "Сумма, ₽*", value: formattedMoney(repeatStore.loanDetail.desiredAmount)},
            {title: "Срок займа *", value: repeatStore.loanDetail.loanTerm},
            {
                title: "Ежемесячный платеж, ₽*",
                value: formattedMoney(calculateMonthlyPayment(repeatStore.loanDetail.desiredAmount, repeatStore.loanDetail.loanTerm))
            },
            {title: "Цель получения займа", value: repeatStore.loanDetail.loanPurpose},
        ])
        setCardNumber("**** " + repeatStore.loanDetail.cardNumber)
        await insertLoadedPhoto(repeatStore.loanDetail)
    }

    async function insertLoadedPhoto(data) {
        if (!data.photos) return
        const loadedPhotoArray = await updateUploadPhotoArray(data.photos, photos)
        setPhotos(loadedPhotoArray)
    }

    async function makeRequest() {
        if (!isApproved) {
            setIsApprovedDataError(true)
            elementsStore.showSnackbar(errorsParser('Подтвердите введенные данные'))
            return
        }
        const response = await elementsStore.callSmsAction(()=>backReq("online_loan:get_code"))
        if (response.errors){
            elementsStore.showSnackbar(errorsParser(response.errors))
            return
        }
        setModal(true)
    }

    const completeOnlineLoan = async () => {
        elementsStore.setCheckingSmsCode(true)
        try {
            const response = await backReq("online_loan:send_order", {
                id: onlineLoanStore.loanDetail.id,
                code: elementsStore.smsCode,
                url: localStorage.getItem('utm'),
                yandex_id: getYaId()
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return false
            }
            elementsStore.setSmsCodeConfirmed(true)
            setTimeout(() => {
                repeatStore.setEndOfRegistrationModal(true)
                history.push('loans-request')
                elementsStore.smsInputRestoreDefault()
                yandexMetrikaReachGoal('form_finish')
            }, 300)
        } catch (e) {
            console.warn(e)
        } finally {
            elementsStore.setCheckingSmsCode(false)
        }
    }

    return (
        <div>
            <ErrorContainer>
                <div className={s.title}>Параметры займа</div>
                <div className={s.infoWrapper}>
                    {offerInfo.map((item) =>
                        <RepeaterSumInfo
                            title={item.title}
                            value={item.value}
                            key={item.title + item.value}
                        />
                    )}
                </div>
                <div className={s.infoText}>
          <span>
              * условия выдачи займа могут отличаться, исходя из
              анализа документов и состояния вашего автомобиля
          </span>
                </div>
                <button type="button" className={s.actionBtn}>
                    <img src={EditImg} alt=""/>
                    <span
                        className={s.actionBtn__text}
                        onClick={() => repeatStore.setCurrentStep(0)}
                    >
            Изменить параметры займа
          </span>
                </button>
            </ErrorContainer>

            <ErrorContainer>
                <div className={s.title}>Персональные данные</div>
                <div className={s.infoWrapper}>
                    {personalInfo.map((item) =>
                        <RepeaterSumInfo
                            title={item.title}
                            value={item.value}
                            key={item.title + item.value}
                        />
                    )}
                </div>

                <div className={s.photoWrapper}>
                    <BasePhotoPreviewUpload
                        disabled={true}
                        src={photos[0].src}
                        title={'Ваше селфи'}
                    />
                    <BasePhotoPreviewUpload
                        disabled={true}
                        src={photos[1].src}
                        title={'Основной разворот паспорта (2-3 страницы)'}
                    />
                </div>
                <button type="button" className={s.actionBtn}>
                    <img src={EditImg} alt=""/>
                    <span className={s.actionBtn__text} onClick={() => repeatStore.setCurrentStep(3)}>
            Изменить персональные данные
          </span>
                </button>
            </ErrorContainer>

            <ErrorContainer>
                <div className={s.title}>Предмет залога (автомобиль)</div>

                <div className={`${s.infoWrapper} ${s.carInfoWrapper}`}>
                    {carInfo.map((item) =>
                        <RepeaterSumInfo
                            title={item.title}
                            value={item.value}
                            key={item.title + item.value}
                        />
                    )}
                </div>

                <div className={s.photoWrapper}>
                    <BasePhotoPreviewUpload
                        disabled={true}
                        src={photos[2].src}
                        title={'Ваше фото на фоне авто, чтобы был виден гос.номер'}
                    />
                </div>
                <button type="button" className={s.actionBtn}>
                    <img src={EditImg} alt=""/>
                    <span className={s.actionBtn__text} onClick={() => repeatStore.setCurrentStep(4)}>
              Изменить предмет залога
          </span>
                </button>
            </ErrorContainer>

            <ErrorContainer>
                <div className={s.title}>
                    Банковская карта для получения займа
                </div>

                <p className={s.text}>
                    Выплата производится на банковскую карту.
                </p>

                <div className={s.infoWrapper}>
                    <div className={s.info}>
                        <RepeaterSumInfo
                            title={"Карта"}
                            value={cardNumber}
                        />
                    </div>
                </div>

                <button type="button" className={s.actionBtn}>
                    <img src={EditImg} alt=""/>
                    <span className={s.actionBtn__text} onClick={() => repeatStore.setCurrentStep(5)}>
            Изменить банковскую карту
          </span>
                </button>
            </ErrorContainer>

            <div className={s.checkboxWrapper}>
                <ErrorContainer hasError={isApprovedDataError} innerRef={isApprovedContainerRef}>
                    <BaseCheckbox
                        value={isApproved}
                        onChange={() => setIsApproved(!isApproved)}
                        inversion
                    >
                        Указанные данные мною проверены, являются точными и верными
                    </BaseCheckbox>
                </ErrorContainer>
            </div>
            <ModalPortal
                title="Подписание заявки"
                showModal={modal}
                closeModal={() => setModal(false)}>
                <div className={s.final__modal}>
                    <div>
                        Для отправки заявки вам необходимо подписать ее простой электронной подписью. Для этого вам
                        необходимо ввести код из SMS, который отправлен на
                        телефон { getFormattedPhone(repeatStore.loanDetail?.offer?.phone) ?? 'указанный в заявке'}.
                    </div>
                    <ConfirmCodeInput onEntered={completeOnlineLoan} onSend={makeRequest}/>
                </div>
            </ModalPortal>
            <OnlineLoanButtons callback={makeRequest}/>
        </div>
    )
})

export default RepeaterFinal
