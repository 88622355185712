import React, {Component} from 'react'

import s from './ContentContainer.module.scss'

export default class ContentContainer extends Component {
  render () {
    const size = this.props.size || 'default'

    return (
      <div className={[s.wrapper, s[`wrapper--${size}`]].join(' ')}>
        {this.props.children}
      </div>
    )
  }
}
