import React, {useEffect, useReducer, useState} from "react";
import BasePhotoPreviewUpload from "../../atoms/BasePhotoPreviewUpload";
import {uploadOnlineLoanPhoto} from "../../../utils/imageLoader";
import {observer} from "mobx-react";
import onlineLoanStore from "../../../store/onlineLoanStore";
import repeatStore from "../../../store/repeatStore";
import {updateUploadPhotoArray} from "../../Repeater/helpers";
import {backReq} from "../../../helpers";
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";

const OnlineLoanPhotoArray = observer(({photoArray = [], updateFunction}) => {
// PhotoModel = {
//     src: '',
//     title:'',
//     type: '',
//     disabled: false,
//     errorText: '',
//     isCorrect: false
// }
//     photoArray = PhotoModel[]

    const [localArray, setLocalArray] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
     setLocalArray(JSON.parse(JSON.stringify(photoArray)))
    },[photoArray])

    useEffect(() => {
        const photoArray = onlineLoanStore?.loanDetail?.photos?.length ? onlineLoanStore.loanDetail.photos
            : repeatStore?.loanDetail?.photos?.length ? repeatStore.loanDetail.photos
            : []
        loadPhotoFromDatabase(photoArray)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[onlineLoanStore.loanDetail.photos, repeatStore.loanDetail.photos])

    const loadPhotoFromDatabase = async (photos) => {
        let loadedPhotoArray = await updateUploadPhotoArray(photos, photoArray)
        updateFunction(loadedPhotoArray)
    }

    function addFile(type) {
        return async function (event) {
            try {
                const {blobURL, id} = await uploadOnlineLoanPhoto(type, event.target.files[0])
                const updatePhotoIndex = localArray.findIndex(item => type === item.type)
                localArray[updatePhotoIndex].src = blobURL
                localArray[updatePhotoIndex].id = id
                localArray[updatePhotoIndex].errorText = ''
                updateFunction(localArray)
                setLocalArray(localArray)
                forceUpdate();
            } catch (e) {
                console.warn("error on send file", e.message)
            }
        }
    }

    const removePhoto = async ({id}) => {
        if (!id) return
        try {
            const sendResponse = await backReq('online_loan:delete_photo', {id})
            if (sendResponse.errors) {
                elementsStore.showSnackbar(errorsParser(sendResponse.errors))
                return
            }
            const updatePhotoIndex = localArray.findIndex(item => item.id === id)
            localArray[updatePhotoIndex].src = null
            localArray[updatePhotoIndex].id = null
            updateFunction(localArray)
            setLocalArray(localArray)
            forceUpdate();
        } catch (e) {
            console.warn(e)
        }
    }

    return localArray.map(item =>
        <BasePhotoPreviewUpload
            key={item.type}
            src={item.src}
            baseSrc={item.baseSrc}
            title={item.title}
            disabled={item.disabled}
            errorText={item.errorText}
            isCorrect={item.isCorrect}
            onLoad={addFile(item.type)}
            onDelete={()=>removePhoto(item)}
        />)
})
export default OnlineLoanPhotoArray
