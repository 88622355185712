import React from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import formattedMoney from "../../../../utils/formattedMoney";
import {VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL} from "../../../../constants/phones";


const ModalConfirmation = ({loan={desiredAmount: 0} ,onClose}) => {

  return(
    <div className={s.loanModal}>
      <div className={s.modalTextWrapper}>
      <p className={s.modalText}>
        Денежные средства по Договору займа в размере <b>{formattedMoney(loan.desiredAmount)}</b> рублей поступят Вам на банковскую карту в течение 30 минут.
      </p>
      <p className={s.modalText}>По окончанию перевода средств мы проинформируем Вас в Личном кабинете и SMS сообщением.</p>
      </div>
      <p className={s.modalText}>
        Горячая линия: <a href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a>
      </p>
      <div className={s.modalHalfSpacer}></div>
      <BaseButton text={'Понятно'} onClick={onClose}/>
    </div>)
}
export default ModalConfirmation
