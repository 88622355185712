import { inject, observer } from "mobx-react";
import React, { createRef, useState } from "react";
import {
	backReq,
	getActualDomain,
	getFormattedPhone,
	updatePassportPhoto,
} from "../../../helpers";
import appStore from "../../../store/app";
import elementsStore from "../../../store/elementsStore";
import { checkPasswordStrong } from "../../../utils/checkPasswordStrong";
import { isValidEmail } from "../../../utils/validators";
import BaseButton from "../../atoms/BaseButton";
import BaseHoneypot from "../../atoms/BaseHoneypot";
import BaseInput from "../../atoms/BaseInput";
import BaseMessage from "../../atoms/BaseMessage";
import ModalContainer from "../../atoms/ModalContainer";
import StrongPasswordLine from "../../atoms/StrongPasswordLine";
import ContentContainer from "../../molecules/ContentContainer";
import RepeaterSumInfo from "../../molecules/RepeaterSumInfo";
import { ProfilePhoto } from "../ProfilePhoto";
import s from "./Info.module.scss";

@inject("appStore")
@observer
class Info extends React.Component {
	state = {
		showAlertModal: false,
		alertText: "",
		alertType: "",
		showAlertModalEmail: false,
		showAlertModalStatus: false,
		showAlertModalPassport: false,
		isFocusEmail: false,
		passwordCurrent: "",
		defaultPasswordValue: null,
		passwordNew: "",
		passwordNewConfirmation: "",
		newEmail: "",
		passportData: [],
	};

	inputRef = createRef();
	transferFocus = () => this.inputRef.current.click();

	editEmail = (value) => {
		this.setState({ newEmail: value.trim() });
	};

	saveEmail = (value) => {
		const isValid = isValidEmail(this.state.newEmail);
		if (!isValid) {
			elementsStore.showSnackbar("Email не валиден", "error", 2000);
			return;
		}
		backReq("change_email", {
			email: this.state.newEmail,
			url: `${getActualDomain()}/profile`,
		})
			.then((res) => {
				if (res.status === "success") {
					this.setState({
						newEmail: value.trim(),
						isFocusEmail: false,
						showAlertModalEmail: false,
						showAlertModalStatus: {
							text: "Мы отправили на Вашу почту письмо с подтверждением, перейдите по ссылке в письме, чтобы подтвердить ваш email",
							type: "success",
						},
					});
				}
				if (res.status === "error") {
					this.setState({
						isFocusEmail: false,
						showAlertModalEmail: false,
						showAlertModalStatus: {
							text: res.errors.detail,
							type: "error",
						},
					});
				}
			})
			.then(() => backReq("user"))
			.then((res) => appStore.setProfile(res.data));
	};

	// Изменение полей пароля
	changePasswordField = (name, value) => {
		this.setState((_) => ({ [name]: value }));
	};

	setAsyncState = (newState) =>
		new Promise((resolve) => this.setState(newState, resolve));
	// Сохранение нового пароля
	savePassword = (_) => {
		const passwordStrength = checkPasswordStrong(this.state.passwordNew);
		if (passwordStrength < 3) {
			elementsStore.showSnackbar(
				"Пароль не отвечает требованиям безопасности",
				"error",
				2000,
			);
			return;
		}

		backReq("change_password", {
			oldPassword: this.state.passwordCurrent,
			newPassword: this.state.passwordNew,
		}).then(async (res) => {
			await this.setAsyncState({
				defaultPasswordValue: null,
				passwordCurrent: "",
				passwordNew: "",
				passwordNewConfirmation: "",
				showAlertModal: false,
				...(res.status !== "success" && {
					showAlertModalStatus: {
						text: `Ошибка. ${res.errors?.detail}`,
						type: res.status,
					},
				}),
			});
		});
	};

	handleAddPassportFile = async (e) => {
		const files = [...e.target.files];

		this.setState({
			passportData: [
				...this.state.passportData,
				...files.slice(0, 4 - this.state.passportData.length),
			],
		});

		e.target.value = "";
	};

	handleSendNewPassportData = async () => {
		const formData = new FormData();
		for (const file of this.state.passportData) {
			formData.append("file", file);
		}

		updatePassportPhoto(formData)
			.then((res) => {
				this.setState({ showAlertModalPassport: false, passportData: [] });
			})
			.catch((err) => {
				this.setState({
					showAlertModalStatus: {
						text: "Произошла ошибка. Попробуй, пожалуйста, позже.",
						type: "error",
					},
				});
			});
	};

	confirmEmail = () => {
		backReq("change_email", {
			email: appStore.profile?.emailForConfirmation,
			url: `${getActualDomain()}/profile`,
		}).then((res) => {
			if (res.status === "success") {
				this.setState({
					showAlertModalEmail: false,
					showAlertModalStatus: {
						text: "Мы отправили на Вашу почту письмо с подтверждением, перейдите по ссылке в письме, чтобы подтвердить ваш email",
						type: "success",
					},
				});
			}

			if (res.status === "error") {
				this.setState({
					showAlertModalStatus: {
						text: res.errors.detail,
						type: "error",
					},
				});
			}
		});
	};

	render() {
		const { surname, name, patronymic, phone } = this.props;

		const fullName = name
			? `${surname} ${name} ${patronymic}`
			: "Анонимный клиент";

		const { passwordCurrent, passwordNew, passwordNewConfirmation } =
			this.state;

		const email =
			appStore.profile?.emailForConfirmation ?? appStore.profile?.email ?? "";

		const isEmailConfirmed =
			appStore.profile?.email && !appStore.profile?.emailForConfirmation;

		return (
			<section className={s.info}>
				<h2 className="title">Основные данные</h2>
				<ContentContainer>
					<div className={s.wrapper}>
						<div className={s.photoWrapper}>
							<ProfilePhoto />
						</div>
						<div className={s.infoWrapper}>
							<RepeaterSumInfo title={"ФИО"} value={fullName} />
							<RepeaterSumInfo
								title={"Номер телефона"}
								value={phone ? getFormattedPhone(phone) : "не указан"}
							/>
							<div>
								<RepeaterSumInfo
									title={"Email"}
									value={email ? email : "не указан"}
									editFunc={() => this.setState({ showAlertModalEmail: true })}
									isConfirmed={isEmailConfirmed}
									textOverflow={true}
								/>
								{appStore.profile?.emailForConfirmation ? (
									<div className={s.emailConfirmationButton}>
										<BaseButton
											text={"Подтвердить Email"}
											onClick={this.confirmEmail}
										/>
									</div>
								) : null}
							</div>
							<RepeaterSumInfo
								title={"Пароль"}
								value={"*************"}
								editFunc={() => this.setState({ showAlertModal: true })}
							/>
							<RepeaterSumInfo
								title={"Изменились паспортные данные?"}
								value={
									<BaseButton
										size="default"
										type="green"
										onClick={() =>
											this.setState({ showAlertModalPassport: true })
										}
										text="Загрузить новые данные"
										plus
									/>
								}
							/>
						</div>
					</div>
				</ContentContainer>

				<ModalContainer
					title="Загрузка новых данных паспорта"
					showModal={this.state.showAlertModalPassport}
					closeModal={() =>
						this.setState({ showAlertModalPassport: false, passportData: [] })
					}
				>
					<BaseButton
						type="bony"
						text="Прикрепить файл"
						onClick={this.transferFocus}
						style={{ paddingBottom: 8 }}
						disabled={this.state.passportData.length >= 4}
						plus
					/>
					<RepeaterSumInfo
						title={"Максимум 4 файла, размером до 5 мб. Формат jpg, png, pdf"}
						value={""}
					/>
					<input
						type="file"
						accept=".jpg,.jpeg,.png,.pdf,.heic"
						ref={this.inputRef}
						style={{ display: "none" }}
						onChange={this.handleAddPassportFile}
						multiple
					/>
					{this.state.passportData.length ? (
						<div
							style={{
								width: "100%",
								marginBottom: 16,
								display: "grid",
								gridTemplateColumns: "repeat(4, 1fr)",
								gap: 8,
							}}
						>
							{this.state.passportData.map((file, i) => (
								<PassportFilePreview
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={i}
									file={file}
									fileIndex={i}
									onClick={() => {
										const updatePassportData = [...this.state.passportData];
										updatePassportData.splice(i, 1);

										this.setState({ passportData: updatePassportData });
									}}
								/>
							))}
						</div>
					) : null}
					<BaseButton
						text="Отправить"
						onClick={() => this.handleSendNewPassportData()}
						disabled={!this.state.passportData.length}
					/>
				</ModalContainer>

				<ModalContainer
					title="Изменить e-mail"
					showModal={this.state.showAlertModalEmail}
					closeModal={() => this.setState({ showAlertModalEmail: false })}
				>
					<BaseHoneypot type="text" />
					<div>
						<BaseInput
							placeholder="Новый e-mail"
							type="email"
							value={(x) => this.editEmail(x)}
						/>
					</div>
					<div style={{ marginTop: "32px" }}>
						<BaseButton
							type="green"
							size="big"
							text="Сохранить"
							onClick={() => this.saveEmail(this.state.newEmail)}
						/>
					</div>
				</ModalContainer>

				<ModalContainer
					title="Изменить пароль"
					showModal={this.state.showAlertModal}
					closeModal={() => this.setState({ showAlertModal: false })}
				>
					<BaseHoneypot type="text" />
					<BaseHoneypot type="password" />
					<div>
						<BaseInput
							placeholder="Текущий пароль"
							type="password"
							value={(x) => this.changePasswordField("passwordCurrent", x)}
							defaultValue={this.state.defaultPasswordValue}
						/>
					</div>

					<div style={{ marginTop: "24px" }}>
						<BaseInput
							type="password"
							value={(x) => this.changePasswordField("passwordNew", x)}
							placeholder="Новый пароль"
							defaultValue={this.state.defaultPasswordValue}
						/>
					</div>
					<div style={{ marginTop: "24px" }}>
						<StrongPasswordLine value={this.state.passwordNew} />
					</div>
					<div style={{ marginTop: "24px" }}>
						<BaseInput
							type="password"
							value={(x) =>
								this.changePasswordField("passwordNewConfirmation", x)
							}
							placeholder="Подтвердите пароль"
							defaultValue={this.state.defaultPasswordValue}
						/>
					</div>
					<div style={{ marginTop: "32px" }}>
						<BaseButton
							type="green"
							size="big"
							text="Сохранить"
							onClick={() => this.savePassword()}
							disabled={
								!passwordCurrent ||
								!passwordNew ||
								!passwordNewConfirmation ||
								passwordNew !== passwordNewConfirmation ||
								passwordNew.length < 6 ||
								passwordNewConfirmation < 6
							}
						/>
					</div>
				</ModalContainer>

				<ModalContainer
					showModal={this.state.showAlertModalStatus}
					closeModal={() => this.setState({ showAlertModalStatus: false })}
				>
					<BaseMessage type={this.state.showAlertModalStatus.type}>
						{this.state.showAlertModalStatus.text}
					</BaseMessage>
				</ModalContainer>
			</section>
		);
	}
}

const PassportFilePreview = ({ file, fileIndex, onClick }) => {
	const [isHover, setIsHover] = useState(false);

	const isPdf = file.type.includes("pdf");

	return (
		<button
			style={{ position: "relative", height: 100, padding: 8, borderRadius: 8 }}
			onClick={onClick}
			type="button"
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			{isHover ? (
				<div
					style={{
						position: "absolute",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						background: "rgba(255, 255, 255, 0.70)",
						backdropFilter: "blur(5px)",
						borderRadius: 8,
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						fill="#FF3F42"
						viewBox="0 0 256 256"
					>
						<path d="M216,48H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM192,208H64V64H192ZM80,24a8,8,0,0,1,8-8h80a8,8,0,0,1,0,16H88A8,8,0,0,1,80,24Z" />
						<title>delete</title>
					</svg>
				</div>
			) : null}
			{isPdf ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="40"
					height="40"
					fill="#000000"
					viewBox="0 0 256 256"
				>
					<path d="M224,152a8,8,0,0,1-8,8H192v16h16a8,8,0,0,1,0,16H192v16a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8h32A8,8,0,0,1,224,152ZM92,172a28,28,0,0,1-28,28H56v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8H64A28,28,0,0,1,92,172Zm-16,0a12,12,0,0,0-12-12H56v24h8A12,12,0,0,0,76,172Zm88,8a36,36,0,0,1-36,36H112a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h16A36,36,0,0,1,164,180Zm-16,0a20,20,0,0,0-20-20h-8v40h8A20,20,0,0,0,148,180ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.69L160,51.31Z" />
					<title>pdf</title>
				</svg>
			) : (
				<img
					style={{
						borderRadius: 8,
						objectFit: "cover",
						height: "100%",
						width: "100%",
					}}
					src={URL.createObjectURL(file)}
					alt=""
				/>
			)}
		</button>
	);
};

export default Info;
