import React from "react";
import s from './OnlineLoanSubject.module.scss'
import BaseInput from "../../atoms/BaseInput";

const PtsForm = ({ptsForm, setPtsForm}) => {

    const {ptsNumber, ptsDate, ptsIssued} = ptsForm

    return <div className={s.inputsGroup}>
        <div>
            <BaseInput
                placeholder="Номер ПТС *"
                defaultValue={ptsNumber}
                value={v => setPtsForm({...ptsForm, ptsNumber: v})}
            />
        </div>
        <div>
            <BaseInput
                type={'date'}
                placeholder="Дата выдачи *"
                defaultValue={ptsDate}
                value={v => setPtsForm({...ptsForm, ptsDate: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Кем выдан *"
                defaultValue={ptsIssued}
                value={v => setPtsForm({...ptsForm, ptsIssued: v})}
            />
        </div>
    </div>
}
export default PtsForm
