import s from "./BaseLoans.module.scss";
import {dateToDM} from "../../../utils/formattedDate";
import getDays from "../../../utils/getDays";
import getDaysLabel from "../../../utils/getDaysLabel";
import React from "react";

const BaseLoansOverdueInfo = ({loan}) => {
    const {nextPaymentDate, isOverduePayment, isGracePeriod, gracePeriodEndDate, isTaxLate, mfo, hideLoans} = loan
    const vi = mfo === 'vi'
    const leftDaysLabel = (date, subtraction=0 ) => {
        const daysLeft = getDays(date) - subtraction
        const prefix = daysLeft % 10 === 1 ? 'остался' : 'осталось'
        const postfix = getDaysLabel(daysLeft)
        return `${prefix} ${daysLeft} ${postfix}`
    }

    const infoComponent = () => {
        const handler = hideLoans ?  hideLoansHandler : vi ? viTextHandler : cfrTextHandler
        return handler()
    }
    const isBeforeNextPaymentDate = new Date().getTime() <= Date.parse(nextPaymentDate)
    const nextPaymentDateText = <h3 key={'overduePaymentNextPaymentDate'}>{dateToDM(nextPaymentDate)}</h3>
    const countdownInfo = <span key={'gracePeriodOverduePayment'} className={s.loans__countdown}>
                                                &nbsp;({leftDaysLabel(nextPaymentDate, 1)})
                                            </span>

    const hideLoansHandler = () => isBeforeNextPaymentDate ? [nextPaymentDateText, countdownInfo] : nextPaymentDateText

    const viTextHandler = () => isTaxLate ? nextPaymentDateText : [nextPaymentDateText, countdownInfo]

    const cfrTextHandler = () => {
        let info = []
        if (!isOverduePayment && nextPaymentDate) {
            info.push(nextPaymentDateText)
        }
        if (!isGracePeriod && !isOverduePayment) {
            info.push(countdownInfo)
        }
        if (isGracePeriod) {
            info.push( <span key={'gracePeriod'}
                             className={[s.loans__countdown, s["loans__countdown--grace"]].join(" ")}>
                                                &nbsp;({leftDaysLabel(gracePeriodEndDate)})
                                            </span>)
        }
        if (isOverduePayment) {
            const leftDays = getDays(gracePeriodEndDate)
            const fixedLeftDays = leftDays + 1 // avoid 0 days
            info.push( <span key={'overduePayment'}
                             className={[s.loans__countdown, s["loans__countdown--overdue"]].join(" ")}>
                                                Платеж просрочен на {fixedLeftDays} {getDaysLabel(fixedLeftDays)}
                                            </span>)
        }
        return info
    }

    const tagComponent = () => {
        if (vi || hideLoans){
            return null
        }
        if (isGracePeriod){
        return<span className={[s.loans__dateTag, s["loans__dateTag--grace"]].join(" ")}>
                                                Льготный период
                                          </span>
        }
        if (isOverduePayment) {
        return <span className={[s.loans__dateTag, s["loans__dateTag--overdue"]].join(" ")}>
                                              Будут начислены штрафы!
                                          </span>
        }
        return null
    }

    return <div className={s.loans__datePayment}>
        <span className={s.loans__grayText}>Дата ближайшего платежа</span>
        <div className={s.loans__datePaymentDate}>
            {infoComponent()}
        </div>
        {tagComponent()}
    </div>;
}

export default BaseLoansOverdueInfo
