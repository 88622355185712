import React from 'react'

import s from './BaseShare.module.scss'

export default class BaseShare extends React.Component {
  render() {
      const {link, title, description, image} = this.props

    const share = {
      'vk': 'https://vk.com/share.php?url=$Link&title=$Title&description=$Description&image=$Image&noparse=true',
      // 'fb': 'https://www.facebook.com/sharer/sharer.php?s=100&p%5Btitle%5D=$Title&p%5Bsummary%5D=$Description&p%5Burl%5D=$Link&p%5Bimages%5D%5B0%5D=$Image',
      'ok': 'https://connect.ok.ru/offer?url=$Link&title=$Title',
      'tg': 'https://telegram.me/share/url?url=$Link&text=$Title'
    }

    const links = Object.entries(share).map(([key, value]) => {
        return <a key={key} href={value.replace('$Link', link).replace('$Title', title).replace('$Description', description).replace('$Image', image)}>
            <img src={require(`../../../img/social/${key}.svg`)} alt=""/>
        </a>
      })

    return (
        <div className={s.share}>
          <p>Поделиться:</p>
          {links}
        </div>
    )
  }
}
