import React from "react";
import s from "./OnlineLoanIncome.module.scss";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import BaseInput from "../../atoms/BaseInput";
import NativeSelect from "../../molecules/NativeSelect";

const ExtraForm = ({extraForm, setExtraForm, fields}) => {
    const {
        isHaveCredit,
        isHaveAddHouse,
        isHaveAddTransport,
        creditCount,
        creditSum,
        creditMonthSum,
        creditCompany,
        isCreditOnLastMonth = false,
        addHouse,
        addTransport
    } = extraForm
    const { additionalAutoValues=[], additionalEstateValues =[]} = fields

    return <div className={s.checkboxGroup}>
        <div className={s.row}>
            <BaseCheckbox
                inversion
                value={isHaveCredit}
                onChange={() => setExtraForm({...extraForm, isHaveCredit: !isHaveCredit})}
            >
                Есть действующие кредиты
            </BaseCheckbox>
        </div>
        {isHaveCredit && <div className={s.insideCheckboxGroup}>
            <div>
                <BaseInput
                    placeholder='Количество действующих кредитов'
                    type={'number'}
                    defaultValue={creditCount}
                    value={v => setExtraForm({...extraForm, creditCount: v})}
                />
            </div>
            <div>
                <BaseInput
                    placeholder='Общая сумма задолженности, ₽'
                    type={'number'}
                    defaultValue={creditSum}
                    value={v => setExtraForm({...extraForm, creditSum: v})}
                />
            </div>
            <div>
                <BaseInput
                    placeholder='Сумма ежемесячных выплат, ₽'
                    type={'number'}
                    defaultValue={creditMonthSum}
                    value={v => setExtraForm({...extraForm, creditMonthSum: v})}
                />
            </div>
            <div className={s.wideColumn}>
                <BaseInput
                    placeholder='Организация - кредитор'
                    defaultValue={creditCompany}
                    value={v => setExtraForm({...extraForm, creditCompany: v})}
                />
            </div>
            <div>
                <BaseCheckbox
                    inversion
                    value={isCreditOnLastMonth}
                    onChange={() => setExtraForm({...extraForm, isCreditOnLastMonth: !isCreditOnLastMonth})}
                >
                    Обращался в банк за кредитом за последний месяц
                </BaseCheckbox>
            </div>
        </div>}

        <div className={s.row}>
            <BaseCheckbox
                inversion
                value={isHaveAddHouse}
                onChange={() => setExtraForm({...extraForm, isHaveAddHouse: !isHaveAddHouse})}
            >
                Есть доп. недвижимость в собственности
            </BaseCheckbox>
        </div>
        {isHaveAddHouse && <div className={s.insideCheckboxGroup}>
            <div className={s.wideColumn}>
                <NativeSelect
                    multiple
                    options={additionalEstateValues}
                    placeholder='Доп. недвижимость в собственности *'
                    defaultValue={addHouse}
                    onChanged={v => setExtraForm({...extraForm, addHouse: v})}
                    onSearch={null}
                />
            </div>
        </div>}
        <div className={s.row}>
            <BaseCheckbox
                inversion
                value={isHaveAddTransport}
                onChange={() => setExtraForm({...extraForm, isHaveAddTransport: !isHaveAddTransport})}
            >
                Есть доп. транспорт в собственности
            </BaseCheckbox>
        </div>
        {isHaveAddTransport && <div className={s.insideCheckboxGroup}>
            <div className={s.wideColumn}>
                <NativeSelect
                    multiple
                    options={additionalAutoValues}
                    placeholder='Доп. транспорт в собственности *'
                    defaultValue={addTransport}
                    onChanged={v => setExtraForm({...extraForm, addTransport: v})}
                    onSearch={null}
                />
            </div>
        </div>}


    </div>


}
export default ExtraForm
