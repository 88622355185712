import React, {useEffect, useState} from "react";
import s from './LoanDocs.module.scss'
import ModalContainer from "../../atoms/ModalContainer";
import {backReq} from "../../../helpers";
import BaseButton from "../../atoms/BaseButton";
import DocIcon from "../img/doc.svg";
import CardDoc from "../../organisms/SignDocModal/CardDoc";

const LoanDocs = ({ln}) => {

    const [popup, setPopup] = useState(false)
    const [docs, setDocs] = useState([])

    useEffect(() => {
        async function fetch() {
            const loanDocsResponse = await backReq('client_signed_loan_docs', {ln})
            if (loanDocsResponse.errors) {
                setDocs([])
                return
            }
            setDocs(loanDocsResponse.data)
        }
        fetch()
    }, [ln])

    const renderDocs = () => docs.map(doc => <CardDoc doc={doc} key={doc.url}/>)

    return <>
        {docs.length ?
        <div className={s.loanDocs} onClick={() => setPopup(true)}>
            <img className={s.loanDocs__image} src={DocIcon} alt=''/>
            Документы
            <span className={s.loanDocs__DocsNumber}>
                <span>
                    {docs.length}
                </span>
            </span>
        </div>
            : null
        }
        <ModalContainer
            showModal={popup}
            closeModal={() => setPopup(false)}
            size="small"
            title={'Документы'}
        >
            <ul className={s.loanDocs__list}>
                {renderDocs()}
            </ul>
            <div className={s.loanDocs__buttonWrapper}>
                <BaseButton
                    text={'Закрыть'}
                    type="green"
                    onClick={() => setPopup(false)}
                />
            </div>
        </ModalContainer>

    </>
}

export default LoanDocs
