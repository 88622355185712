import React from "react";
import ReactDOM from "react-dom";
import ModalContainer from "../../atoms/ModalContainer";
import ConfirmChangeModal from "./ConfirmChangeModal";
import UserExistModal from "./UserExistModal";
import CheckLimitModal from "./CheckLimitModal";
import EyesImg from "../../../img/eyes.svg";
import AccountExistsModal from "./AccountExistsModal";

const OnlineLoanPersonalModals = ({
                                      isShowChangeModal,
                                      isShowExistUserModal,
                                      isCheckLimitModal,
                                      closeModal,
                                      confirmChange,
                                      isCheck1C
                                  }) => {
    const existUserTitle = <div style={{display: 'flex', justifyContent: 'center', height: '50px'}}>
        <img src={EyesImg} alt=""/>
    </div>
    const Portal = ({children}) => ReactDOM.createPortal(children, document.getElementById('root-modal'))
    return <Portal>
        <ModalContainer
            showModal={isShowChangeModal}
            closeModal={closeModal}
            title={'Изменение данных'}
            closeOnBlur={false}
        >
            <ConfirmChangeModal closeModal={closeModal} confirmChange={confirmChange}/>
        </ModalContainer>
        <ModalContainer
            showModal={isShowExistUserModal}
            closeModal={closeModal}
            title={existUserTitle}
        >
            <UserExistModal/>
        </ModalContainer>
        <ModalContainer
            showModal={isCheckLimitModal}
            closeModal={closeModal}
            title={'Лимит проверок'}
        >
            <CheckLimitModal closeModal={closeModal}/>
        </ModalContainer>
        <ModalContainer
          showModal={!isCheck1C}
          closeModal={closeModal}
        >
          <AccountExistsModal closeModal={closeModal}/>
        </ModalContainer>
    </Portal>
}
export default OnlineLoanPersonalModals
