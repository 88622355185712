import React, { Component } from 'react'

import { backReq } from '../../helpers'

import ContactList from './ContactList'
import BaseSkeleton from "../../components/atoms/BaseSkeleton";

class ContactsPage extends Component {
  state = {
    loading: true,
    contacts: []
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    backReq('get_contacts')
      .then(({ data }) => this.setState({contacts: data}))
      .finally(() => this.setState({loading: false}))
  }

  render() {
    const { loading, contacts } = this.state

    if (loading) return <BaseSkeleton/>

    return <div className="container">
      <ContactList contacts={contacts}/>
    </div>
  }
}

export default ContactsPage
