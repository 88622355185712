import dispatchMessage from './messageDispatcher'
import {webSocketURL} from "../config";

// =====================
// URL для REST-запросов
// =====================

const wsURL = webSocketURL

// ==================
// Работа с WebSocket
// ==================

const wsStart = wsURL => {
  const ws = new WebSocket(wsURL)

  // Приконнектились
  ws.onopen = _ => {
    console.log('connected')
  }

  // Вывод возникшей ошибки
  ws.onerror = e => console.log('error: ' + e.message)

  // Реконнект
  ws.onclose = _ => {
    console.log('disconnected')

    // appStore.clearExternalServicesLoadingState(toJS(appStore).orderDetails.id)
    setTimeout(_ => wsStart(wsURL), 3000)
  }

  // Функция отправки сообщения
  window.viWSSend = data => {
    if (ws.readyState === 1) {
      ws.send(JSON.stringify(data))
    }
  }

  // Получили сообщение
  ws.onmessage = e => {
    const res = JSON.parse(e.data)
    dispatchMessage(res)
  }
}



// Первый коннект
wsStart(wsURL)
