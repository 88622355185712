import React, {useState} from 'react'
import s from './ClubDrawer.module.scss'
import {Drawer} from 'antd'
import 'antd/dist/antd.css';
import './drawerStyleOverride.css';
import {ReactComponent as ClubBanner} from '../../img/banner-body.svg'
import {ReactComponent as MobileClubBanner} from '../../img/banner-mobile.svg'
import {ReactComponent as Step1Icon} from '../../img/step1.svg'
import {ReactComponent as Step2Icon} from '../../img/step2.svg'
import {ReactComponent as Step3Icon} from '../../img/step3.svg'
import FaqCard from "../FaqCard";
import BaseCollapse from "../../../../atoms/BaseCollapse";
import AddFriendModal from "../BalanceCard/AddFriendModal";
import ModalContainer from "../../../../atoms/ModalContainer";
import {yandexMetrikaReachGoal} from "../../../../../utils/yandex-metrika";

const ClubDrawer =({visible, onClose}) => {

    const [isShowAddFriendModal, setIsShowAddFriendModal] = useState(false);

    const statuses = [
        {
            name: 'BRONZE',
            textColor: 'white',
            color: '#DA8970',
            backgroundColor: '#FFE2D9',
            title: 'до 5 000 ₽ за друга',
            body: `Бронзовый статус автоматически присваивается вам при выдаче <span class="${s.bold}">первого</span> займа вашем другу`,
            description: <React.Fragment>
                <span>Если друг занял до 99 999 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 2 000 ₽</span>
                <br/>
                <span>Если друг занял от 100 000 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 5 000 ₽</span>
            </React.Fragment>
        },
        {
            name: 'SILVER',
            textColor: 'white',
            color: '#878B90',
            backgroundColor: 'rgba(135, 139, 144, 0.1)',
            title: 'до 7 000 ₽ за друга',
            body: `Серебряный статус присваивается вам с <span class="${s.bold}">пятым</span> другом, оформившим займ`,
            description: <React.Fragment>
                <span>Если друг занял до 99 999 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 2 500 ₽</span>
                <br/>
                <span>Если друг занял от 100 000 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 7 000 ₽</span>
            </React.Fragment>
        },
        {
            name: 'GOLD',
            textColor: 'white',
            color: '#FFB800',
            backgroundColor: 'rgba(255, 184, 0, 0.15)',
            title: 'до 9 000 ₽ за друга',
            body: `Золотой статус присваивается вам после того, как займ оформит <span class="${s.bold}">десятый</span> приведённый вами друг`,
            description: <React.Fragment>
                <span>Если друг занял до 99 999 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 4 000 ₽</span>
                <br/>
                <span>Если друг занял от 100 000 ₽</span>
                <span className={[s.bold, s.block].join(' ')}>Ваше вознаграждение: 9 000 ₽</span>
            </React.Fragment>
        }
    ]

    const steps = [
        {
            icon: <Step1Icon width={64} height={64}/>,
            title: <p><span style={{color: '#4CAF50'}}>1.</span> Пригласите</p>,
            info: 'Отправь другу предложение займа с выгодным условием от 2.3% в месяц'
        },
        {
            icon: <Step2Icon width={64} height={64}/>,
            title: <p><span style={{color: '#4CAF50'}}>2.</span> Дождитесь</p>,
            info: 'Дождитесь оформления займа вашим другом'
        },
        {
            icon: <Step3Icon width={64} height={64}/>,
            title: <p><span style={{color: '#4CAF50'}}>3.</span> Получите</p>,
            info: 'Получите денежное вознаграждение за рекомендацию'
        }
    ]

    const isMobile = window.screen.width < 600;

    const openAddFriendModal = () => {
        setIsShowAddFriendModal(true)
        yandexMetrikaReachGoal('priglasit_druga_100druzei')
    }

    return (
        <Drawer
            title={'О клубе 100 друзей'}
            placement={'right'}
            visible={visible}
            onClose={onClose}
            width={isMobile ? 340 : 588}
        >
            <div className={s.drawerContent}>
                <div>
                {
                    isMobile ?
                        <MobileClubBanner style={{maxWidth: '100%'}}/> :
                        <ClubBanner style={{maxWidth: '100%'}}/>
                }
                    <button className={[s.sectionButton, s.sectionButtonMargin].join(' ')} onClick={openAddFriendModal}>
                        Пригласить друга
                    </button>
                </div>
                <section>

                    <h3 className={s.sectionHeader}>Чем больше друзей, тем больше вознаграждение!</h3>
                    <div className={s.sectionContent}>
                        {statuses.map(status => (
                            <div key={status.name} className={s.statusCard} style={{
                                backgroundColor: status.backgroundColor,
                            }}>
                                <div className={s.statusCard__name} style={{
                                    color: status.textColor,
                                    backgroundColor: status.color
                                }}>
                                    {status.name}
                                </div>
                                <h4 className={s.statusCard__title}>
                                    {status.title}
                                </h4>
                                <p className={s.statusCard__body} dangerouslySetInnerHTML={{__html: status.body}}>
                                </p>
                                <div className={s.statusCard__collapseWrapper}>
                                    <BaseCollapse
                                        chevron={true}
                                        head={'Подробнее'}
                                    >
                                        {status.description}
                                    </BaseCollapse>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
                <section>
                    <h3 className={s.sectionHeader}>Получать вознаграждения с нами — <span
                        style={{color: '#4CAF50'}}>легко</span></h3>
                    {steps.map(step => (
                        <div className={s.step} key={step.info}>
                            <div className={s.step__icon}>{step.icon}</div>
                            <div className={s.step__body}>
                                <h4 className={s.step__body__title}>{step.title}</h4>
                                <div className={s.step__body__info}>{step.info}</div>
                            </div>
                        </div>
                    ))}
                    <button className={s.sectionButton} onClick={openAddFriendModal}>
                        Пригласить друга
                    </button>
                </section>
                <div className={s.faqCard__wrapper}>
                    <FaqCard/>
                </div>
            </div>
            <ModalContainer
                showModal={isShowAddFriendModal}
                closeModal={() => setIsShowAddFriendModal(false)}
                title={'Позовите друга удобным способом'}
                closeOnBlur={true}
            >
                <AddFriendModal closeModal={() => setIsShowAddFriendModal(false)}/>
            </ModalContainer>
        </Drawer>
    )
}

export default ClubDrawer
