import s from "./BaseClub.module.scss";
import BalanceCard from "./NewClub/BalanceCard";
import StatusCard from "./NewClub/StatusCard";
import OperationsCard from "./NewClub/OperationsCard";
import React, {useEffect, useState} from "react";
import FaqCard from "./NewClub/FaqCard";
import {observer} from "mobx-react";
import appStore from "../../../store/app";
import {isMobile} from "../../../utils/isMobile";
import ClubDrawer from "./NewClub/ClubDrawer";
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";

const BaseClub = observer(({padding}) => {

    const [loading, setLoading] = useState(true);
    const isMainPage = window.location.pathname === '/'

    useEffect(() => {
        fetchData()
    }, []);

    useEffect(() => {
        if (isMainPage) return
        const friends = appStore?.referralInfo?.data?.loans?.length ?? 0
        if (appStore?.profile?.clubPageIsShown === false || !friends) {
            appStore.setIsReferralDrawerVisible(true)
            yandexMetrikaReachGoal('opisanie_100druzei')
        }
        //eslint-disable-next-line
    }, [appStore?.profile]);

    const closeDrawer = () => appStore.setIsReferralDrawerVisible(false)

    const fetchData = async () => {
        try {
            setLoading(true)
            await appStore.getReferralInfo()
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    if (loading) return null

    return <div className={[s.ap, padding ? s.padding : null].join(' ')}>

        {isMainPage ?
            <h2 className={["title", s.pageHeader].join(' ')}>
                Клуб 100 друзей
            </h2>
            :
            <h1 className={["title", s.pageHeader].join(' ')}>
                Клуб 100 друзей
            </h1>
        }
        <div className={s.statusCard__wrapper}>
            {!isMainPage ? <StatusCard/> : null}
        </div>
        <div className={s.cardWrapper}>
            <BalanceCard/>
            {!isMobile() || !isMainPage ? <OperationsCard/> : null}

        </div>
        {!isMainPage ? <FaqCard/> : null}
        <ClubDrawer
            visible={appStore.isReferralDrawerVisible}
            onClose={closeDrawer}
        />
    </div>
})

export default BaseClub
