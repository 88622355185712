import React, {useEffect, useState} from 'react'
import s from "./OnlineLoanParameters.module.scss";
import InputSlider from "../../atoms/InputSlider";
import {
    MIN_LOAN,
    MAX_LOAN,
    FIRST_LOAN_PERCENT,
    onlineLoanStepsArray
} from "../../../constants/onlineLoan";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import BaseTooltip from "../../atoms/BaseTooltip";
import {backReq, formatMoney} from "../../../helpers";
import BaseCurrency from "../../atoms/BaseCurrency";
import ErrorContainer from "../../molecules/ErrorContainer";
import useErrorContainer from "../../../hooks/useErrorContainer";
import MoreMoneyModal from "../../Repeater/RepeaterSettings/MoreMoneyModal";
import {calculateMonthlyPayment} from "../../../utils/onlineLoanMonthlyPayment";
import BaseMessage from "../../atoms/BaseMessage";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import onlineLoanStore from "../../../store/onlineLoanStore";
import NativeSelect from "../../molecules/NativeSelect";
import elementsStore from "../../../store/elementsStore";
import {errorsParser} from "../../../utils/errorsParser";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../../Repeater/helpers";
import {isMobile} from "../../../utils/isMobile";


const RegistrationParameters = () => {
    const [money, setMoney] = useState(MIN_LOAN)
    const [isShowMoneyModal, setIsShowMoneyModal] = useState(false)
    const [isSettingError, setIsSettingError, settingContainerRef] = useErrorContainer()
    const [duration, setDuration] = useState(2)
    const [goals, setGoals] = useState([])
    const [goal, setGoal] = useState('')
    const [monthlyPayment, setMonthlyPayment] = useState(0)
    const [placeholder, setPlaceholder] = useState('')

    useEffect(() => {
        async function fetchData(){
            await onlineLoanStore.refreshOfferExist()
        }
        fetchData().then(loadGoals).then(insertData)

        controlPlaceholder()

        window.addEventListener('resize', controlPlaceholder)
        return () => {
            window.removeEventListener('resize', controlPlaceholder)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const controlPlaceholder = () => {
        isMobile() ? setPlaceholder('Желаемая сумма') : setPlaceholder('Укажите желаемую сумму')
    }

    const loadGoals = async () => {
        try {
            const selectInfo = await backReq("online_loan:field_values")
            if (selectInfo.errors) {
                elementsStore.showSnackbar(errorsParser(selectInfo.errors))
                return selectInfo.errors
            }
            const selectInfoData = selectInfo.data
            setGoals(selectInfoData?.loanPurposeValues)
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e.message))
        }
    }

    const insertData = async () => {
        setGoal(onlineLoanStore.onlineLoanOrder.loanPurpose)
        setDuration(onlineLoanStore.onlineLoanOrder.loanTerm ?? duration)
        setMoney(onlineLoanStore.onlineLoanOrder.desiredAmount ?? money)
    }

    useEffect(() => {
        if (money => MIN_LOAN && money <= MAX_LOAN) {
            setMonthlyPayment(calculateMonthlyPayment(money, duration, FIRST_LOAN_PERCENT))
        }
    }, [money, duration])

    const validate = async () => {
        if(!goal) {
            setIsSettingError(true)
            elementsStore.showSnackbar(errorsParser('Выберите цель займа'))
            return false;
        }
        return (await nextStep())
    }

    async function updateSettings(){
        try {
            const response = await backReq("online_loan:loan_params", {
                "desiredAmount": money,
                "loanTerm": duration+'',
                "loanPurpose": goal
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return response.errors
            }
            return false
        } catch (e) {
            return e.message
        }
    }

    async function nextStep() {
        try {
            const stepError = await checkCurrentStep(onlineLoanStepsArray[0], onlineLoanStore)
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }
            const settingErrors = await updateSettings()
            if (settingErrors) return false;
            const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[1], onlineLoanStore)
            if (nextStepErrors){
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e.errors))
            return false
        }
    }

    return <div className={s.settings}>
        <ErrorContainer innerRef={settingContainerRef} hasError={isSettingError}>
            <div className={s.settings__group}>
                <div className={s.settings__title}>Параметры займа</div>
                <div className={s.settings__wrapper}>
                    <div className={s.settings__slider}>
                        <InputSlider
                            placeholder={placeholder}
                            onChange={(x) => setMoney(x)}
                            value={money}
                            min={MIN_LOAN}
                            max={MAX_LOAN}
                            step={5000}
                            disabled={onlineLoanStore.isDataOnCheck}
                        />
                    </div>
                    <div className={s.settings__info}>
                        <BaseMessage type="warning" shadow={true} centered={false} close={false}>
                            <div>
                                Точная сумма будет известна после оценки Вашего авто
                            </div>
                        </BaseMessage>
                    </div>
                    <div className={s.settings__more}
                         onClick={() => setIsShowMoneyModal(true)}
                    >
                        Хочу сумму больше
                    </div>
                </div>
                <div className={s.settings__duration}>
                    <p><b>Срок займа</b></p>
                    <div className={s.settings__checkboxWrapper}>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox
                                disabled={onlineLoanStore.isDataOnCheck}
                                inversion
                                value={duration === 2}
                                onChange={() => setDuration(2)}
                            >
                                2 месяца
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 2 месяца с возможностью
                                дальнейшей пролонгации.
                                График платежей строится исходя из оплаты только процентов, размер погашения основного
                                долга клиентом осуществляется самостоятельно. По окончании срока действия договора
                                клиент
                                может полностью погасить заём или же продлить его.
                            </BaseTooltip>
                        </div>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox
                                inversion
                                value={duration === 12}
                                onChange={() => setDuration(12)}
                                disabled={onlineLoanStore.isDataOnCheck}
                            >
                                12 месяцев
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 12 месяцев. Общая сумма
                                ежемесячных выплат остается
                                фиксированной до конца срока кредитования. Одна часть средств идет на погашение
                                основного долга,
                                другая на погашение процентов.
                            </BaseTooltip>
                        </div>
                        <div className={s.settings__checkbox}>
                            <BaseCheckbox
                                inversion
                                value={duration === 24}
                                onChange={() => setDuration(24)}
                                disabled={onlineLoanStore.isDataOnCheck}
                            >
                                24 месяца
                            </BaseCheckbox>
                            <BaseTooltip repeaterStyle={true}>Договор займа заключается на 24 месяца. Общая сумма
                                ежемесячных выплат остается фиксированной
                                до конца срока кредитования. Одна часть средств идет на погашение основного долга,
                                другая на погашение процентов.
                            </BaseTooltip>
                        </div>
                    </div>
                </div>
                <div className={s.settings__calc}>
                    <div>
                        <p>Ставка *</p>
                        <h4>8%</h4>
                    </div>
                    <div>
                        <p>Ежемесячный платеж *</p>
                        <h4>{formatMoney(monthlyPayment)}
                            <div className={s.svgWrapper}>&thinsp;
                                <BaseCurrency size={21} color={'dark'}/>
                            </div>
                        </h4>
                    </div>
                </div>
                <div className={s.settings__minor}>
                    * условия выдачи займа могут отличаться, исходя из анализа документов и состояния вашего автомобиля
                </div>
                <div className={s.settings__select}>
                    <NativeSelect
                        placeholder="Цель получения займа *"
                        defaultValue={goal}
                        options={goals}
                        onSearch={null}
                        onChanged={(x) => setGoal(x)}
                        listTop={true}
                        disabled={onlineLoanStore.isDataOnCheck}
                    />
                </div>
            </div>
        </ErrorContainer>
        <OnlineLoanButtons isDataOnCheck={onlineLoanStore.isDataOnCheck} callback={validate} store={onlineLoanStore}/>
        <MoreMoneyModal
            showModal={isShowMoneyModal}
            close={() => setIsShowMoneyModal(false)}/>
    </div>
}

export default RegistrationParameters
