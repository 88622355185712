import React, {useEffect, useState} from "react";
import Autocomplete from "../Autocomplete";
import {usePrevious} from "../../../hooks/usePrevious";


const DadataInput = ({defaultValue, value, placeholder='', disabled=false}) => {

    const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    const API_KEY = 'f96af4c2d1de6e4aad0e7923837cb808bd6f65b0'
    const options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + API_KEY
        },
    };

    const [localValue, setLocalValue] = useState(undefined)
    const [suggestions, setSuggestions] = useState([])
    const prevDefaultValue = usePrevious(defaultValue);

    useEffect(()=>{
       if (prevDefaultValue === undefined && defaultValue) setLocalValue(defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultValue])

    useEffect(() => {
     value(localValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[localValue])

    useEffect(() => {
     if (!localValue || !localValue.length) return;
        options.body = JSON.stringify({
            query: localValue,
            count: 4,
        })
        fetch(url, options)
            .then(response => response.json())
            .then(result => {
                if (!result.suggestions) setSuggestions([])
                const dadataArray = result.suggestions.map(res => res.value)
                setSuggestions(dadataArray)
            })
            .catch(error => console.log("error", error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[localValue])

    return <Autocomplete
        defaultValue={localValue}
        value={setLocalValue}
        suggestions={suggestions}
        placeholder={placeholder}
        disabled={disabled}
        remote={true}
    />

}
export default DadataInput
