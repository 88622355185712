import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { repeaterStepsArray } from "../../../constants/onlineLoan";
import { backReq } from "../../../helpers";
import useErrorContainer from "../../../hooks/useErrorContainer";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import elementsStore from "../../../store/elementsStore";
import repeatStore from "../../../store/repeatStore";
import { errorsParser } from "../../../utils/errorsParser";
import {
	loadImageSrc,
	uploadOnlineLoanPhoto,
} from "../../../utils/imageLoader";
import { personalPhotosTemplate } from "../../../utils/templates/photoArrayTemplates";
import { isItemSrcEmpty } from "../../../utils/validators";
import BasePhotoPreviewUpload from "../../atoms/BasePhotoPreviewUpload";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";
import {
	checkCurrentStep,
	moveToNextOnlineLoanStep,
	validateStep,
} from "../helpers";
import Example1 from "./../../../img/example1.svg";
import Example2 from "./../../../img/example2.svg";
import Example3 from "./../../../img/example3.svg";
import Idx from "./Idx";
import s from "./RepeaterConfirmation.module.scss";

const PASSPORT_MAIN = "passport_main";
const baseSrc = require("./../../../img/upload-cloud.svg");

const RepeaterConfirmation = observer(() => {
	const [loading, setLoading] = useState(false);
	const [personalPhotos, setPersonalPhotos] = useState(personalPhotosTemplate);
	const [passportMainSrc, setPassportMainSrc] = useState("");
	const [passportMainId, setPassportMainId] = useState(null);
	const [errorText, setErrorText] = useState("");

	const [
		isConfirmationError,
		setIsConfirmationError,
		confirmationContainerRef,
	] = useErrorContainer();
	const [isIdxContainerError, setIsIdxContainerError, idxContainerRef] =
		useErrorContainer();

	const addFile = async (event) => {
		const { blobURL, id } = await uploadOnlineLoanPhoto(
			PASSPORT_MAIN,
			event.target.files[0],
		);
		const { data } = await backReq("online_loan:get_passport_check_result");
		if (data.passportCheck) {
			setPassportMainSrc(blobURL);
			setErrorText("");
			setPassportMainId(id);
		} else {
			setPassportMainSrc("");
			setErrorText(
				"К сожалению документ не подошел по требованиям. Пожалуйста, загрузите корректный документ.",
			);
			// сразу удаляем
			await backReq("online_loan:delete_photo", { id });
		}
	};

	const removePhoto = async () => {
		const sendResponse = await backReq("online_loan:delete_photo", {
			id: passportMainId,
		});
		if (sendResponse.errors) {
			elementsStore.showSnackbar(errorsParser(sendResponse.errors));
		} else {
			setPassportMainSrc("");
			setPassportMainId(null);
		}
	};

	useEffect(() => {
		async function loadPhoto() {
			const photos = repeatStore.loanDetail.photos;
			if (!photos) {
				return;
			}
			const mainPassportInfo = photos.find(
				(item) => item.type === PASSPORT_MAIN,
			);
			if (!mainPassportInfo) return;
			const { data = {} } = await backReq(
				"online_loan:get_passport_check_result",
			);
			const { passportCheck = null } = data;
			if (passportCheck) {
				const blobUrl = await loadImageSrc(mainPassportInfo.url);
				setPassportMainId(mainPassportInfo.id);
				setPassportMainSrc(blobUrl);
			}
		}

		loadPhoto();
	}, []);

	useRefreshLoanDetail();

	const nextStep = async () => {
		try {
			setLoading(true);
			const stepError = await checkCurrentStep(repeaterStepsArray[2]);
			if (stepError) {
				elementsStore.showSnackbar(errorsParser(stepError));
				return false;
			}
			if (personalPhotos.some(isItemSrcEmpty) || !passportMainSrc) {
				setIsConfirmationError(true);
				elementsStore.showSnackbar(errorsParser("Загрузите фото"));
				return false;
			}
			const loanId = repeatStore.loanDetail.id;
			const { data } = await backReq(
				"online_loan:get_liveliness_check_result",
				{ id: loanId },
			);
			const { livelinessCheck } = data;
			if (!livelinessCheck) {
				setIsIdxContainerError(true);
				elementsStore.showSnackbar(errorsParser("Загрузите селфи"));
				return false;
			}

			const validateStepResponse = await validateStep("personal_confirm");
			if (validateStepResponse.errors) {
				elementsStore.showSnackbar(errorsParser(validateStepResponse.errors));
				return false;
			}
			const nextStepErrors = await moveToNextOnlineLoanStep(
				repeaterStepsArray[3],
			);
			if (nextStepErrors) {
				elementsStore.showSnackbar(errorsParser(nextStepErrors));
			}
		} catch (err) {
			return false;
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<ErrorContainer
				innerRef={confirmationContainerRef}
				hasError={isConfirmationError}
			>
				<div className={s.title}>Фотография паспорта</div>
				<p className={s.photoDescriptionTitle}>
					Загрузите фотографии паспорта в развернутом виде. Снимки должны быть:
				</p>
				<ul className={s.listWrapper}>
					<li>
						<span>Светлым и четким</span> (хорошее качество)
					</li>
					<li>
						<span>Необрезанными</span> (видны все углы документа)
					</li>
					<li>
						<span>Не сливаться с фоном</span> (фон должен быть контрастным по
						отношению к документу)
					</li>
					<li>
						Сфотографированы <span>без наклона</span>
					</li>
				</ul>
				<div className={s.imageWrapper}>
					<img src={Example1} alt="example1" />
					<img src={Example2} alt="example2" />
					<img src={Example3} alt="example3" />
				</div>
				<div className={s.confirmation}>
					<BasePhotoPreviewUpload
						src={passportMainSrc}
						baseSrc={baseSrc}
						title={"Основной разворот паспорта (2-3 страницы)"}
						disabled={false}
						errorText={errorText}
						onLoad={addFile}
						onDelete={removePhoto}
					/>
					<OnlineLoanPhotoArray
						photoArray={personalPhotos}
						updateFunction={setPersonalPhotos}
					/>
				</div>
			</ErrorContainer>
			<ErrorContainer innerRef={idxContainerRef} hasError={isIdxContainerError}>
				<div className={s.title}>Фотография лица</div>
				<p className={s.idxDescription}>
					Пройдите биометрическую проверку лица. От вас потребуется включить
					камеру и выполнить указанные действия. Для начала нажмите "Начать
					проверку"
				</p>
				<div className={s.idxWrapper}>
					<Idx />
				</div>
			</ErrorContainer>
			<OnlineLoanButtons loading={loading} callback={nextStep} />
		</div>
	);
});

export default RepeaterConfirmation;
