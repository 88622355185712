import React from "react";

import { inject, observer } from "mobx-react";
import { Link, Redirect } from "react-router-dom";

import IconChevron from "../../../img/chevron.svg";
import IconUser from "../../../img/user.svg";

import { ReactComponent as IconBonus } from "./img/bonus.svg";

import { backReq } from "../../../helpers";
import appStore from "../../../store/app";
import formattedMoney from "../../../utils/formattedMoney";
import { loadImageSrc } from "../../../utils/imageLoader";
import ConfirmModal from "../ConfirmModal";
import s from "./BaseUser.module.scss";

@inject("appStore")
@observer
class BaseUser extends React.Component {
	state = {
		dropdown: false,
		showLogoutModal: false,
		redirect: false,
		avatarIcon: "",
		currentIconUrl: "",
	};

	async componentDidMount() {
		await backReq("user").then((res) => appStore.setProfile(res.data));
		await this.getAvatar();
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.appStore?.profile?.icon !== prevState?.currentIconUrl) {
			await this.getAvatar();
		}
	}

	logout = () => {
		this.props.appStore.logout();

		return <Redirect to="/login" />;
	};

	getAvatar = async () => {
		const icon = appStore?.profile?.icon;
		if (!icon) return;
		this.setState(() => ({ currentIconUrl: appStore.profile.icon }));
		let blobURL = "";
		try {
			blobURL = await loadImageSrc(appStore.profile.icon, true);
		} catch (e) {
			console.warn(e);
		}
		this.setState(() => ({ avatarIcon: blobURL }));
	};

	render() {
		const { dropdown, avatarIcon, showLogoutModal } = this.state;
		const { referralInfo, profile } = this.props.appStore;

		const extra =
			referralInfo?.status && referralInfo?.bonusBalance ? (
				<Link to="/club" className={s.user__extra} onClick={this.props.onClick}>
					<span>{referralInfo.status}</span>
					<span className={s.green}>
						<IconBonus />
						{formattedMoney(referralInfo.bonusBalance)}
					</span>
				</Link>
			) : (
				""
			);

		const logout = (
			<div
				className={[s.user__logout, dropdown ? s.active : null].join(" ")}
				onMouseMove={() => this.setState({ dropdown: true })}
				onMouseLeave={() => this.setState({ dropdown: false })}
			>
				<img src={IconChevron} alt="" />
				<div onClick={() => this.setState({ dropdown: false })}>
					<p onClick={() => this.setState({ redirect: true })}>Настройки</p>
					<p onClick={() => this.setState({ showLogoutModal: true })}>Выход</p>
				</div>
			</div>
		);

		return (
			profile && (
				<div className={s.user}>
					<ConfirmModal
						center={true}
						title="Точно хотите выйти?"
						onOk={() => this.logout()}
						onClose={() => this.setState({ showLogoutModal: false })}
						showModal={showLogoutModal}
					/>
					<Link className={s.user__main} to="/profile">
						<div className={s.user__image}>
							{avatarIcon && (
								<img className={s.user__avatar} src={avatarIcon} alt="" />
							)}
							{!avatarIcon && (
								<img
									className={s.user__avatarPlaceholder}
									src={IconUser}
									alt=""
								/>
							)}
						</div>
						<div className={s.user__name}>
							Мой профиль
							{logout}
						</div>
					</Link>
					{extra}
				</div>
			)
		);
	}
}

export default BaseUser;
