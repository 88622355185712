
import s from "./Top.module.scss";
import React, { Component } from "react";
import IconOpened from "./img/opened.svg";
import IconClosed from "./img/closed.svg";
import IconOverdue from "./img/overdue.svg";
import {toJS} from "mobx";
import appStore from "../../../store/app";
import {observer} from "mobx-react";
import ContentContainer from "../../molecules/ContentContainer";
import {Link} from "react-router-dom";

@observer
class Top extends Component{
    render() {
        const loansList = toJS(appStore.loans)

        let loansListOpened = []
        let loansListClosed = []
        let loansListLate = []
        if (loansList && loansList.length) {
            loansListOpened = loansList.filter(loan => loan.active === 1)
            loansListClosed = loansList.filter(loan => loan.active === 0)
            loansListLate = loansListOpened.filter(loan => loan.statusLoan === 'Просроченный')
        }

        const {name} = this.props

        const Line = ({to, children}) => <ContentContainer>
            <Link to={`/loans?type=${to ? to : 'open'}`}>
                <article>
                    {children}
                </article>
            </Link>
        </ContentContainer>

        return (
            <div className={s.top}>
                <h1 className="title">Мой профиль</h1>
                {name ?  <p className="title">Здравствуйте, {name}</p> : ''}
                <div className={s.top__group}>
                    <Line>
                        <img src={IconOpened} alt=""/>
                        <p>
                            <span>Открытых займов</span>
                            <b>{loansListOpened.length}</b>
                        </p>
                    </Line>
                    <Line to="close">
                        <img src={IconClosed} alt=""/>
                        <p>
                            <span>Закрытых займов</span>
                            <b>{loansListClosed.length}</b>
                        </p>
                    </Line>
                    <Line>
                        <img src={IconOverdue} alt=""/>
                        <p>
                            <span>Просроченных займов</span>
                            <b>{loansListLate.length}</b>
                        </p>
                        {/*{ loansListLate.length > 0 &&*/}
                        {/*   <b>Срочно оплатите займ {loansListLate[0].ln}</b>*/}
                        {/*}*/}
                    </Line>
                </div>
            </div>
        )
    }
}

export default Top
