import React from 'react'
import { observer, inject } from 'mobx-react'
import { toJS } from 'mobx'
import ArrowIcon from './img/arrow.svg'
import s from './styles.module.scss'
import ModalContainer from '../../components/atoms/ModalContainer'
import BaseButton from '../../components/atoms/BaseButton'

class Cached extends React.PureComponent {
  state = { data: null, show: false };

  componentDidUpdate (prevProps) {
    if (this.props.data !== this.state.data && this.props.data !== null) {
      this.setState({ data: this.props.data });
    }
  }

  render () {
    const { data, children } = this.props;

    return children(data || this.state.data);
  }
}

@inject('appStore')
@observer
class LoanOrderModal extends React.PureComponent {
  state = { slideIndex: 0 };

  componentDidMount () {
    this.props.appStore.fetchOnboardingData();
  }

  componentDidUpdate() {
    if(this.props.appStore) {
      this.setState({show: !!this.props.appStore.onboardingData.length})
    }
  }

  back = () => {
    this.setState(({slideIndex}) => ({ slideIndex: slideIndex - 1 }));
  };

  next = () => {
    this.setState(({slideIndex}) => ({ slideIndex: slideIndex + 1 }));
  };

  close = (id) => {
    this.setState({show: false})
    this.props.appStore.dropOnboardingModal(id).then(() => {
      this.setState({ slideIndex: 0 });
    });
  };

  render () {
    const { slideIndex } = this.state;
    const { onboardingData, showUserAgreement } = this.props.appStore;
    const onboardingModal = toJS(onboardingData)[0] || null;

    const slide = onboardingModal !== null ? onboardingModal.slides[slideIndex] : null;
    const isLastSlide = onboardingModal !== null && slideIndex >= onboardingModal.slides.length - 1;


    return (
      <div>
        <ModalContainer
          showModal={onboardingModal !== null && showUserAgreement === false && this.state.show}
          closeModal={() => this.close(onboardingModal ? onboardingModal.id: 1)}
          title={ slide?.title ? slide?.title : '' }
        >
          {
            slide &&
            <Cached data={slide}>
              {(slide) => (
                <>
                  <div className={s.subtitle} dangerouslySetInnerHTML={{ __html: slide ? slide.text : '' }} />

                  <div className={s.image}>
                    <img src={slide.image} alt=''/>
                  </div>

                  <div className={s.buttons}>
                    <div className={s.back}>
                      {slideIndex > 0 && (
                        <div className={s.iconWrapper} onClick={() => this.back() }>
                          <img src={ArrowIcon} alt=""/>
                        </div>
                      )}
                    </div>

                    <div className={s.next}>
                      {!isLastSlide && (
                        <div className={s.iconWrapper} onClick={() => this.next() }>
                          <img className={s.rotate} src={ArrowIcon} alt=""/>
                        </div>
                      )}
                    </div>
                    <div className={s.dots}>
                      {  onboardingModal.slides.map((x, i) => {
                        return (
                          <div className={`${s.dot} ${this.state.slideIndex === i ? s.active : ''}`}/>
                        )
                      })}
                    </div>
                  </div>
                  <div className={s.footer}>
                    <BaseButton
                      type="green-gradient"
                      text="Понятно"
                      onClick={() => this.close(onboardingModal.id) }
                      height="45px"
                      style={{ marginTop: '22px', width: '334px'}}
                    />
                  </div>
                </>
              )}
            </Cached>
          }
        </ModalContainer>
      </div>
    )
  }
}

export default LoanOrderModal
