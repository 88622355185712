import React, {useEffect, useState} from "react";
import s from "./OnlineLoanConfirmation.module.scss";
import {errorsParser} from "../../../utils/errorsParser";
import {checkCurrentStep, moveToNextOnlineLoanStep, validateStep} from "../../Repeater/helpers";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {onlineLoanStepsArray} from "../../../constants/onlineLoan";
import elementsStore from "../../../store/elementsStore";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import onlineLoanStore from "../../../store/onlineLoanStore";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";
import {personalPhotosTemplate} from "../../../utils/templates/photoArrayTemplates";
import {isItemSrcEmpty} from "../../../utils/validators";
import {observer} from "mobx-react";

const RegistrationConfirmation = observer (() => {

    const [loading, setLoading] = useState(false)
    const [personalPhotos, setPersonalPhotos] = useState(personalPhotosTemplate)

    const [isConfirmationError, setIsConfirmationError, confirmationContainerRef ] = useErrorContainer()
    const [isSelfiePhotoConfirmationError, setIsSelfiePhotoConfirmationError, selfiePhotoConfirmationContainerRef ] = useErrorContainer()
    const [idxSrc, setIdxSrc] = useState('')

    useRefreshOnlineLoanDetail()

    useEffect(() => {
        // const accessToken ='vashinvestor-92625819d8c931aeafa6e00de6bda6f7fc450579'
        // const url = 'https://api.id-x.org/idx/api2/liveness/start'
        // const user = appStore.profile.id
        // const test = JSON.stringify({
        //     "accessKey": "vashinvestor-92625819d8c931aeafa6e00de6bda6f7fc450579",
        //     "secretKey": "8f328f274bcaa222211ff36cd5ef01b39b5fb36d",
        //     "code": '12345976758909'
        // })
        // console.warn('test', test)
        // async function getSrc(){
        //     const data = await fetch(url,{
        //         method: 'POST',
        //         body: test,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //     }).then(res => res.json())
        //     console.warn('data------------', data)
            setIdxSrc('https://api.id-x.org/idx/api2/liveness/start?key=vashinvestor-92625819d8c931aeafa6e00de6bda6f7fc450579&code=1234596789000991783&moment=2022-12-01T10%3A53%3A09%2B03%3A00&sign=d99ba7f809b41eb938ee05151d70b2f53e6a06dc720c4a75e22b0c2723661a74')

        // }
     // getSrc()
    },[])

    const nextStep = async () => {
        try {
            setLoading(true)
            const stepError = await checkCurrentStep(onlineLoanStepsArray[3])
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }
            if (personalPhotos.some(isItemSrcEmpty)){
                setIsConfirmationError(true)
                elementsStore.showSnackbar(errorsParser('Загрузите фото'))
            }
            const validateStepResponse = await validateStep("personal_confirm")
            if (validateStepResponse.errors) {
                setIsConfirmationError(true)
                elementsStore.showSnackbar(errorsParser(validateStepResponse.errors))
                return false
            }
            const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[4], onlineLoanStore)
            if (nextStepErrors){
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
        } catch (err){
            return false
        } finally {
            setLoading(false)
        }
    }

    const onLoad = () => {
        window.addEventListener("message", function(event) {
            if( event.origin === "https://liveness-test.iidx.ru") {
                eventHandler(event.data)
                return;
            }
        }, false);
    }

    const eventHandler = (messageType) =>{
        console.warn('messageType', messageType)
        switch (messageType){
            case 'verilive_start':
                return

            case 'verilive_success':
                elementsStore.showSnackbar('Вы прошли проверкку', 'success')
                return

            case 'verilive_fail':
                elementsStore.showSnackbar('Вы не прошли проверкку', 'error')
                return

            case 'verilive_error':
                elementsStore.showSnackbar('Ошибка', 'error')
                setIsSelfiePhotoConfirmationError(true)
                return

            default:
                return;
        }
    }
    const idxInside = <iframe
        title={'idx'}
        className={s.iframe}
        sandbox="allow-popups allow-forms allow-modals allow-presentation allow-same-origin allow-scripts"
        allow="autoplay *; camera *; microphone *; midi *"
        src={idxSrc}
        onLoad={onLoad}
        width="460"
        height="288"
    >
        {/*content*/}
    </iframe>

    return <div className={s.container}>
        <ErrorContainer innerRef={confirmationContainerRef} hasError={isConfirmationError}>
            <div className={s.title}>
                Фотография паспорта
            </div>
            <div className={s.info}>
                Загрузите фотографию паспорта для проверки. Убедитесь, что ваш документ не просрочен и не имеет физических повреждений. Фотография должна быть хорошего качества.
            </div>
            <div className={s.confirmation}>
                <OnlineLoanPhotoArray photoArray={personalPhotos} updateFunction={setPersonalPhotos}/>
            </div>
        </ErrorContainer>
        <ErrorContainer innerRef={selfiePhotoConfirmationContainerRef} hasError={isSelfiePhotoConfirmationError}>
            <div className={s.title}>
                Фотография лица
            </div>
            <div className={s.info}>
                Пройдите биометрическую проверку лица. От вас потребуется включить камеру и выполнить указанные действия. Для начала нажмите "Начать проверку"
            </div>
            <div className={s.idxWrapper}>
                { idxSrc ? idxInside : null}
            </div>
        </ErrorContainer>
        <OnlineLoanButtons loading={loading} callback={nextStep} store={onlineLoanStore}/>
    </div>
})

export default RegistrationConfirmation
