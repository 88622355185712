import React from "react";
import s from "./OnlineLoanPersonal.module.scss";
import BaseButton from "../../atoms/BaseButton";

const ConfirmChangeModal = ({confirmChange, closeModal}) => {
return <div className={s.onlineLoanPersonalModal}>
    <p>При изменении любых паспортных данных, ИНН, СНИЛС или даты рождения будет запущена дополнительная
        проверка. На время проверки переход на следующие шаги будет заблокированы</p>
    <div className={s.buttonWrapper}>
        <BaseButton
            type="border"
            size="default-change"
            text="Изменить"
            onClick={confirmChange}
        />
        <BaseButton
            type="green"
            size="default-change"
            text={"Отменить"}
            onClick={closeModal}
        />
    </div>
</div>
}
export default ConfirmChangeModal
