import React, { useEffect, useRef, useState } from "react";
import { isTablet } from "../../../utils/isTablet";
import { baseCloudSrc } from "../../../utils/templates/photoArrayTemplates";
import BaseLoader from "../BaseLoader";
import s from "./BasePhotoPreviewUpload.module.scss";
import UploadPenImg from "./img/edit.svg";
import RedCross from "./img/red-cross.svg";
import SuccessImg from "./img/success.svg";
import TrashImg from "./img/trash.svg";
import UploadCloudImg from "./img/upload-cloud.svg";

const BasePhotoPreviewUpload = ({
	src,
	baseSrc = baseCloudSrc,
	onLoad,
	onDelete,
	title,
	errorText = "",
	disabled = false,
	isCorrect = false,
}) => {
	const [loading, setLoading] = useState(false);
	const fileInput = useRef(null);
	const cardAction = useRef(null);
	const cardPhoto = useRef(null);
	const isUploaded = src && src !== baseSrc;

	useEffect(() => {
		isTablet() && document.addEventListener("click", clickOutside);

		return () => {
			isTablet() && document.removeEventListener("click", clickOutside);
		};
	});

	const clickOutside = (event) => {
		if (
			cardPhoto.current &&
			cardAction.current &&
			!cardPhoto.current.contains(event.target)
		) {
			cardAction.current.classList.remove(s.active);
		}
	};

	const tabletValidator = (event) => {
		if (!src) {
			event.stopPropagation();
			triggerInputEvent(event);
			return;
		}
		if (!isTablet() || !src) return;
		if (!cardAction.current.classList.contains(s.active)) {
			event.stopPropagation();
		}
		cardAction.current.classList.toggle(s.active);
	};

	const triggerInputEvent = (event) => {
		event.preventDefault();

		if (fileInput.current) {
			fileInput.current.click();
		}
	};

	async function onFileLoad(event) {
		event.stopPropagation();
		try {
			setLoading(true);
			await onLoad(event);
			// hint to avoid upload same file
			event.target.value = "";
		} catch (e) {
			console.warn("BasePhotoPreview upload photo error");
		} finally {
			setLoading(false);
		}
	}

	const onFileDelete = async (event) => {
		if (isCorrect) return;
		event.stopPropagation();
		try {
			setLoading(true);
			await onDelete();
		} catch (e) {
			console.warn("BasePhotoPreview upload photo error");
		} finally {
			setLoading(false);
		}
	};

	function renderPhoto() {
		if (loading) {
			return (
				<div style={{ position: "relative" }}>
					<BaseLoader />
				</div>
			);
		}
		if (isUploaded)
			return <img className={s.uploadCard__userPhoto} src={src} alt="" />;
		if (baseSrc.includes("cloud")) {
			return (
				<div onClick={triggerInputEvent} className={s.uploadCard__default}>
					<img
						className={s.uploadCard__userPhoto}
						src={UploadCloudImg}
						alt=""
					/>
					<span>Загрузить</span>
				</div>
			);
		}
		return (
			<img
				className={s.uploadCard__placeholder}
				onClick={triggerInputEvent}
				src={baseSrc}
				alt=""
			/>
		);
	}

	const renderPhotoActionsTemplate = () => {
		if (loading || disabled || !src) return null;

		return (
			<div ref={cardAction} className={s.uploadCard__action}>
				<div onClick={triggerInputEvent} className={s.uploadCard__actionBtn}>
					<img src={UploadPenImg} alt="" />
					<span className={s.changeButton}>Изменить</span>
				</div>
				{!isCorrect && (
					<div onClick={onFileDelete} className={s.uploadCard__actionBtn}>
						<img src={TrashImg} alt="" />
						<span className={s.deleteButton}>Удалить</span>
					</div>
				)}
			</div>
		);
	};

	const renderIcon = () => {
		if (src === baseCloudSrc) return null;
		return src ? (
			errorText ? (
				<img className={s.uploadCard__success} src={RedCross} alt="" />
			) : (
				<img className={s.uploadCard__success} src={SuccessImg} alt="" />
			)
		) : null;
	};

	return (
		<div className={s.uploadCard}>
			<div className={s.uploadCard__info}>
				<div
					className={`${s.uploadCard__photo} ${isUploaded ? "" : s.notUploaded} ${errorText ? s.errorFrame : ""}`}
					ref={cardPhoto}
					onClickCapture={tabletValidator}
				>
					{renderPhoto()}
					{renderIcon()}
					{renderPhotoActionsTemplate()}
				</div>
				<div>
					<span className={s.title}>{title}</span>
					{errorText && <span className={s.errorText}>{errorText}</span>}
				</div>
			</div>
			{errorText && <p className={s.errorText__mobile}>{errorText}</p>}
			{!disabled && <input ref={fileInput} onChange={onFileLoad} type="file" />}
		</div>
	);
};

export default BasePhotoPreviewUpload;
