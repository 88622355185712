import React, { Component } from 'react'

import s from './Footer.module.scss'

import {socials, links} from './links'

class Footer extends Component {
  render() {
    const map = (obj) => Object.entries(obj).map(([key, value]) => {
      return <a key={key} href={key} target='_blank' rel='noopener noreferrer'>
          {value}
      </a>
    })

    return (
      <div className={s.footer}>
        <div className={s.footer__socials}>{map(socials)}</div>
        <div className={s.footer__links}>{map(links)}</div>
      </div>
    )
  }
}

export default Footer
