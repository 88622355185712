import React from "react";
import s from "./OnlineLoanFinal.module.scss";
import EditImg from "./../../../img/edit.svg";
import repeatStore from "../../../store/repeatStore";

const FinalContainerHeader = ({title, step, store= repeatStore}) => {
  return  <div className={s.titleWrapper}>
        <div className={s.title}>{title}</div>
        <button type="button" className={s.actionBtn}>
            <img src={EditImg} alt=""/>
            <span
                className={s.actionBtn__text}
                onClick={() => store.setCurrentStep(step)}>
                Изменить
            </span>
        </button>
    </div>
}
export default FinalContainerHeader
