import { passwordStrength } from 'check-password-strength'

export const checkPasswordStrong = (password) => {
  if(!password.length) return
  return passwordStrength(password, customOptions).id
}

const customOptions = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 1
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 3
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 2,
    minLength: 5
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 2,
    minLength: 6
  }
]

