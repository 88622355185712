import * as React from "react"
const HomeComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            fill="#4B66C6"
            d="M14.79 7.89 8.354 1.46a.499.499 0 0 0-.707 0L1.211 7.89a1 1 0 0 0 .706 1.708h.679v4.59a.5.5 0 0 0 .5.5H7v-3.5h1.75v3.5h4.154a.5.5 0 0 0 .5-.5v-4.59h.678a1.001 1.001 0 0 0 .706-1.708Z"
        />
    </svg>
)
export default HomeComponent
