import React, { useEffect } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import {
    snackbarOptions,
    snackbarOptionsError,
    snackbarOptionsSuccess
} from "../../../constants/snackbarOptions";
import { observer } from "mobx-react";
import elementsStore from "../../../store/elementsStore";
import s from './BaseSnackbar.module.scss'
import CountdownCircle from "./CountdownCircle";
import { errorIcon, greenCheckIcon, hourglassIcon, redCrossIcon, rubIcon, yellowCheckIcon } from "./icons";

const BaseSnackbar = observer(() => {
    const [openSnackbar] = useSnackbar(snackbarOptions)
    const [openSuccessSnackbar] = useSnackbar(snackbarOptionsSuccess)
    const [openErrorSnackbar] = useSnackbar(snackbarOptionsError)

    useEffect(() => {
        if (elementsStore.snackbarIsOpen) {
            switch (elementsStore.snackbarMessageType) {
                case ('success'):
                    openSuccessSnackbar(
                        <div className={s.SnackbarMessage}>
                            <p className={s.messageWrapper}>
                                {elementsStore.snackbarMessage}
                            </p>
                        </div>, elementsStore.snackbarDuration)
                    return
                case ('error'):
                    openErrorSnackbar(<div className={s.SnackbarMessage}>
                        {errorIcon}
                        <p className={s.messageWrapper}>
                            {elementsStore.snackbarMessage}
                        </p>
                    </div>, elementsStore.snackbarDuration)
                    return
                case ('approved'):
                case ('signed'):
                case ('complete'):
                    openSnackbar(
                        <div className={s.SnackbarMessage}>
                            <CountdownCircle>
                                {greenCheckIcon}
                            </CountdownCircle>
                            <p className={s.messageWrapper}>
                                {elementsStore.snackbarMessage}
                            </p>
                        </div>, elementsStore.snackbarDuration)
                    return

                case ('needInfo'):
                case ('decreaseAmount'):
                case ('modifiedAmount'):
                case ('mistake'):
                    openSnackbar(<div className={s.SnackbarMessage}>
                        <CountdownCircle>
                            {yellowCheckIcon}
                        </CountdownCircle>
                        <p className={s.messageWrapper}>
                            {elementsStore.snackbarMessage}
                        </p>
                    </div>, elementsStore.snackbarDuration)
                    return

                case ('onCheck'):
                    openSnackbar(<div className={s.SnackbarMessage}>
                        <CountdownCircle>
                            {hourglassIcon}
                        </CountdownCircle>
                        <p className={s.messageWrapper}>
                            {elementsStore.snackbarMessage}
                        </p>
                    </div>, elementsStore.snackbarDuration)
                    return

                case ('impossible'):
                    openSnackbar(<div className={s.SnackbarMessage}>
                        <CountdownCircle>
                            {redCrossIcon}
                        </CountdownCircle>
                        <p className={s.messageWrapper}>
                            {elementsStore.snackbarMessage}
                        </p>
                    </div>, elementsStore.snackbarDuration)
                    return
                case ('inOffice'):
                    openSnackbar(<div className={s.SnackbarMessage}>
                        <CountdownCircle>
                            {rubIcon}
                        </CountdownCircle>
                        <p className={s.messageWrapper}>
                            {elementsStore.snackbarMessage}
                        </p>
                    </div>, elementsStore.snackbarDuration)
                    return
                case ('info'):
                case ('notification'):
                default:
                    openSnackbar(elementsStore.snackbarMessage, elementsStore.snackbarDuration)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementsStore.snackbarIsOpen])

    return (
        <></>
    )
})
export default BaseSnackbar
