import React, {useEffect, useState} from 'react'
import {observer, inject} from 'mobx-react'
import {toJS} from 'mobx'
import appStore from './../../store/app'
import {backReq} from "../../helpers";
import ServicesTable from "../organisms/ServicesTable";
import Subject from "./Subject";
import History from "./History";
import Payments from "./Payments";
import BaseSkeleton from "../atoms/BaseSkeleton";
import BaseLoansItem from "../organisms/BaseLoans/BaseLoansItem";
import BaseSkeletonInline from "../atoms/BaseSkeletonInline";
import LoanDocs from "./LoanDocs/LoanDocs";
import s from './PageLoanDetails.module.scss'
import saveLog from "../../utils/saveLog";

const DEFAULT_LIMIT = 5


const PageLoanDetails = inject("appStore")(observer((props) => {

    const [services, setServices] = useState([])
    const [archivedServices, setArchivedServices] = useState([],)
    const [history, setHistory] = useState(null)
    const [pagination, setPagination] = useState({
        total: 0,
        offset: 0,
        limit: DEFAULT_LIMIT
    })
    const [loadingMore, setLoadingMore] = useState(false)

    const splitPathname = props.location.pathname.split('/')
    // при проведении платежа по услуге c бека приходит "ЦФ00000019%3A00-000001" для формирования привильного запроса конец отрбрасывается,
    const loanId = decodeURI(splitPathname[splitPathname.length - 1]).replace(/%3a.*/gi, '')

    const getServices = async () => {
        const list = await backReq('amenities', {isActive: true, loanId})
        setServices(list.data.map(service => ({
            title: service.ServiceName,
            amount: service.ServiceSum,
            id: service.ServiceCode,
            sideLoan: service.ln,
            entityId: service.EntityId,
        })))
    }

    const getArchivedServices = async () => {
        const list = await backReq('amenities', {isActive: false, loanId})
        setArchivedServices(list.data.map(service => ({
            title: service.ServiceName,
            amount: service.ServiceSum,
            id: service.ServiceCode,
            sideLoan: service.ln,
            entityId: service.EntityId,
        })))
    }

    const fetchPayments = async () => {
        await appStore.getCurrentLoansPayments({
            loanId,  limit: appStore.currentLoanPaymentsPagination.limit
        })
    }

    const getPayHistory = () => {
        const {offset, limit} = pagination
        appStore.setHistoryFetch(true)
        backReq('get_pay_history', {
            loanId,
            offset,
            limit
        })
            .then(({data}) => {
                const {payments = [], total = 0} = data
                setHistory(payments)
                setPagination({...pagination, total})
            })
            .finally(() => {
                appStore.setHistoryFetch(false)
            })
    }

    const updateLoan = async () => {
        await appStore.updateCurrentLoan(loanId)
        return Promise.all([getServices(), getArchivedServices()])
    }

    const mounted = async () => {
        try {
            await appStore.fetchAutoPaymentStatus()
            await appStore.changeSidebarVisible(false)
            await fetchPayments()
            await getPayHistory()
            await updateLoan()
        } catch (e) {
            console.warn(e)
        }
    }

    useEffect(() => {
        mounted()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanId])

    useEffect(() => {
        if (!(appStore.loanDetailIdToUpdate && appStore.loanDetailIdToUpdate === loanId)) {
            return
        }
        mounted().then(() => appStore.setLoanDetailIdToUpdate(null))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStore.loanDetailIdToUpdate])

    const loadMore = () => {
        setLoadingMore(true)
        backReq('get_pay_history', {
            loanId,
            offset: history.length,
            limit: pagination.limit
        })
            .then(({data}) => {
                setHistory([...history, ...data.payments])
                setPagination({...pagination, total: data.total})
                setLoadingMore(false)
            })
    }

    const loadMorePayments = async () => {
        setLoadingMore(true)
        await appStore.getCurrentLoansPayments({
            loanId: props.match.params.number,
            offset: appStore.currentLoanPayments.length,
            limit: appStore.currentLoanPaymentsPagination.limit
        })
        setLoadingMore(false)
    }

    // render
    const {loanDetails, profile = {}, inPayment} = toJS(appStore)

    if (!loanDetails?.ln) return <BaseSkeleton/>

    const {ln, statusLoan, description, hideLoans} = loanDetails
    const isLoanOutDated = statusLoan === 'Просроченный'
    const isBlocked = inPayment.findIndex(x => x.entityId === ln) !== -1
    const disabled = isLoanOutDated || statusLoan === 'Закрыт' || hideLoans || isBlocked

    if (disabled && profile) {
        saveLog('page_loan_details:check_status', {
            loanId,
            action: 'открытие модального окна',
            isOutdated: isLoanOutDated,
            hideLoans: hideLoans,
            paymentProcessing: isBlocked,
            userPhone: profile ? profile.phone : 'Не найден'
        }).catch(error => {
            console.log('unable to connect to rollbar', error)
        })

    }

    const closed = appStore.loanDetails.active === 0

    return <div className="container">
        <h1 className={[s.detailsTitle, 'title'].join(' ')}>
            <BaseSkeletonInline loading={!ln}>
                {`Займ ${ln} ${statusLoan === 'Закрыт' ? '(Закрыт)' : ''}`}
            </BaseSkeletonInline>
        </h1>
        <BaseLoansItem item={loanDetails} isClickLink={false}/>
        <div className={s.docs}>
            <LoanDocs ln={loanId}/>
        </div>
        {services.length !== 0 && <ServicesTable
            services={services}
            archivedServices={archivedServices}
        />}
        {description && <Subject description={description}/>}
        <History
            history={history}
            loadingMore={loadingMore}
            pagination={pagination}
            loadMore={loadMore}
        />
        <Payments
            closed={closed}
            hideLoans={hideLoans}
            payments={appStore.currentLoanPayments}
            paginationPayments={appStore.currentLoanPaymentsPagination}
            loadingMore={loadingMore}
            loadMorePayments={loadMorePayments}
        />
    </div>
}))

export default PageLoanDetails
