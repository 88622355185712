import React, {useState} from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import BaseCheckbox from "../../../atoms/BaseCheckbox";
import repeatStore from "../../../../store/repeatStore";
import formattedMoney from "../../../../utils/formattedMoney";
import LoanDocs from "../../../molecules/LoanDocs";

const ModalApprovedInfo = ({loan}) => {

  const [accept, setAccept] = useState(false)

  const moveToNextStep = async () =>{
    repeatStore.setLoansRequestApproveStep(2)
  }

  return (
    <div className={s.loanModal}>
      <div className={s.modalTextWrapper}>
      <div className={s.modalText}>
        <p>Поздравляем! </p>
        <p>Вам одобрен займ на сумму <b>{formattedMoney(loan.desiredAmount)}</b> рублей </p>
        Далее, для получения денежных средств,
        Вам необходимо подписать простой электронной подписью пакет документов
        (договор займа) и мы переведем денежные средства на указанную Вами
        банковскую карту{loan?.card_number ? ` **** ${loan?.card_number}`: null}.
      </div>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <p className={s.modalText}>
          Договор займа №: <b>{loan?.loanId || loan?.offer?.offerId || ''}</b><br/>
          Продукт: <b>"Онлайн-займ"</b><br/>
        Сумма займа: <b>{formattedMoney(loan?.desiredAmount)} рублей</b><br/>
        </p>
      </div>
      <h4>Документы для подписи:</h4>
      <LoanDocs loan={loan} />
      <BaseCheckbox value={accept} onChange={() => setAccept(!accept)} inversion>
        Я внимательно изучил условия и положения Договора займа и представленных документов
      </BaseCheckbox>
      <BaseButton
        disabled={!accept}
        text={'Подписать'}
        onClick={moveToNextStep} />
    </div>
  )
}

export default ModalApprovedInfo
