import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import appStore from "store/app";

import BaseButton from "components/atoms/BaseButton";

import { loanStatuses } from "../../../constants/onlineLoan";
import repeatStore from "../../../store/repeatStore";
import formattedMoney from "../../../utils/formattedMoney";

import ModalContainer from "../../atoms/ModalContainer";

import ClubDrawer from "../BaseClub/NewClub/ClubDrawer";

import s from "./OnlineLoanCard.module.scss";
import GiftImg from "./img/gift.svg";
import ModalApproved from "./modal/ModalApproved";
import ModalApprovedFinal from "./modal/ModalApprovedFinal";
import ModalError from "./modal/ModalError";
import ModalIncrease from "./modal/ModalIncrease";
import ModalInfo from "./modal/ModalInfo";
import ModalNeedInfo from "./modal/ModalNeedInfo";
import ModalReduce from "./modal/ModalReduce";
import ModalReject from "./modal/ModalReject";
import ModalSigned from "./modal/ModalSigned";

const STATUSES = {
	in_process: {
		title: "На проверке",
		modalTitle: "Заявка в обработке",
	},
	on_check: {
		title: "На проверке",
		modalTitle: "Заявка в обработке",
	},
	approved: {
		title: "Одобрено",
		modalTitle: "Заявка одобрена",
	},
	need_info: {
		title: "Необходима дополнительная информация",
		modalTitle: "Необходима дополнительная информация",
	},
	complete: {
		//зеленый
		title: "Займ предоставлен",
		modalTitle: "Заявка по договору",
	},
	impossible: {
		//дохлый
		title: (
			<>
				Отказ.
				<br />К сожалению ваша заявка отклонена.
			</>
		),
		modalTitle: "Невозможно оформить Займ Онлайн",
	},
	decrease_amount: {
		title: "Условное одобрение с понижением суммы займа",
		modalTitle: "Займ одобрен со снижением суммы займа",
	},
	modified_amount: {
		title: "Условное одобрение с понижением суммы займа",
		modalTitle: "Займ одобрен со снижением суммы займа",
	},
	in_office: {
		title: "Оформление займа в офисе",
		modalTitle: "Невозможно оформить Займ Онлайн",
	},
	signed: {
		//зеленый
		title: "Идет формирование платежа",
		modalTitle: "Заявка обрабатывается",
	},
	mistake: {
		//красный
		title: "Ошибка при переводе",
		modalTitle: "Ошибка при переводе",
	},
};

const OnlineLoanCard = observer((loan) => {
	const [localLoan, setLocalLoan] = useState(loan);
	const [modalTitle, setModalTitle] = useState(undefined);
	const [dynamic, setDynamic] = useState(false);
	const [popup, setPopup] = useState(false);
	const [increasePopup, setIncreasePopup] = useState(false);
	const [onlineLoanSigningPopup, setOnlineLoanSigningPopup] = useState(false);

	useEffect(() => {
		setLocalLoan(loan);
		if (loan.status === loanStatuses.complete) {
			setModalTitle(`${STATUSES[loan.status].modalTitle} ${loan.loanId || ""}`);
			setDynamic(false);
			return;
		}
		if (
			loan.status === loanStatuses.approved ||
			loan.status === loanStatuses.modifiedAmount
		) {
			setDynamic(true);
		} else {
			setModalTitle(STATUSES[loan.status].modalTitle);
			setDynamic(false);
		}
	}, [loan]);

	useEffect(() => {
		if (popup) {
			return;
		}
		if (repeatStore.loansRequestApproveFinalStep) {
			setOnlineLoanSigningPopup(true);
			repeatStore.setLoansRequestApproveFinalStep(false);
		}
	}, [popup]);

	const renderComponent = (customRender) => {
		const value = customRender ?? loan.status;
		let component = null;
		repeatStore.setActiveLoan(loan);

		switch (value) {
			case loanStatuses.inProcess:
			case loanStatuses.onCheck:
				component = <ModalInfo loan={loan} onClose={() => setPopup(false)} />;
				break;

			case loanStatuses.approved:
				repeatStore.setLoansRequestApproveStep(1);
				component = <ModalApproved onClose={() => setPopup(false)} />;
				break;

			case loanStatuses.needInfo:
				component = (
					<ModalNeedInfo loan={loan} onClose={() => setPopup(false)} />
				);
				break;

			case loanStatuses.decreaseAmount:
				component = <ModalReduce loan={loan} onClose={() => setPopup(false)} />;
				break;

			case loanStatuses.modifiedAmount:
				if (localLoan.modifiedAmountType === "decrease") {
					component = (
						<ModalReduce loan={loan} onClose={() => setPopup(false)} />
					);
				}
				if (localLoan.modifiedAmountType === "increase") {
					component = (
						<ModalApproved loan={loan} onClose={() => setPopup(false)} />
					);
				}
				break;

			case loanStatuses.inOffice:
				component = <ModalReject loan={loan} onClose={() => setPopup(false)} />;
				break;

			case loanStatuses.complete:
			case loanStatuses.signed:
				component = <ModalSigned loan={loan} onClose={() => setPopup(false)} />;
				break;

			case loanStatuses.mistake:
				component = <ModalError loan={loan} onClose={() => setPopup(false)} />;
				break;
		}
		return component;
	};

	const renderStatusText = () => {
		let text = STATUSES[localLoan.status].title;

		if (localLoan.status === loanStatuses.modifiedAmount) {
			if (localLoan.modifiedAmountType === "decrease") {
				text = "Условное одобрение с понижением суммы займа";
			}
			if (localLoan.modifiedAmountType === "increase") {
				text = "Одобрено";
			}
		}

		return text;
	};

	const statusClasses = () => {
		const classes = [s.statusWrapper];
		switch (localLoan.status) {
			case loanStatuses.approved:
			case loanStatuses.complete:
			case loanStatuses.signed:
				classes.push(s["status--green"]);
				break;

			case loanStatuses.needInfo:
				classes.push(s["status--orange"]);
				break;

			case loanStatuses.modifiedAmount:
				if (localLoan.modifiedAmountType === "decrease") {
					classes.push(s["status--orange"]);
				}
				if (localLoan.modifiedAmountType === "increase") {
					classes.push(s["status--green"]);
				}
				break;

			case loanStatuses.inOffice:
			case loanStatuses.impossible:
			case loanStatuses.mistake:
				classes.push(s["status--red"]);
				break;
		}

		return classes;
	};

	const renderBtn = () => {
		let text = "Подробнее";
		let type = "inverted";
		let clickFunction = () => setPopup(true);

		if (
			[
				loanStatuses.inOffice,
				loanStatuses.mistake,
				loanStatuses.impossible,
			].includes(localLoan.status)
		) {
			return null;
		}

		switch (localLoan.status) {
			case loanStatuses.inProcess:
				clickFunction = openDrawer;
				break;
			case loanStatuses.onCheck:
				clickFunction = openDrawer;
				break;
			case loanStatuses.approved:
				text = "Получить деньги";
				type = "green";
				break;
			case loanStatuses.complete:
				break;
			case loanStatuses.signed:
				break;
			case loanStatuses.needInfo:
				text = "Предоставить информацию";
				type = "orange";
				break;
			case loanStatuses.modifiedAmount:
				if (localLoan.modifiedAmountType === "decrease") {
					text = "Изменить сумму займа";
					type = "orange";
				}
				if (localLoan.modifiedAmountType === "increase") {
					text = "Получить деньги";
					type = "green";
					clickFunction = () => setIncreasePopup(true);
				}
				break;
			case loanStatuses.inOffice:
				break;
			case loanStatuses.mistake:
				break;
			default:
				break;
		}

		return (
			<div className={s.btnWrapper}>
				{(localLoan.status === loanStatuses.inProcess ||
					localLoan.status === loanStatuses.onCheck) && (
					<div className={s.gift}>
						<img src={GiftImg} alt="gift" />
						<span>Дарим вам до 9000&nbsp;₽, пока ждёте одобрения!</span>
					</div>
				)}

				<BaseButton text={text} type={type} onClick={clickFunction} />
			</div>
		);
	};

	const loanSubject = () => {
		if (localLoan?.offer?.name || localLoan.name) {
			return localLoan?.offer?.name || localLoan.name;
		}

		if (localLoan.loanSubject) {
			return localLoan.loanSubject;
		}

		if (localLoan.carMark) {
			return `${localLoan.carMark} ${localLoan.carModel} ${localLoan.releaseYear}`;
		}
	};

	const offerNumber =
		localLoan.offer?.offerNumber ??
		localLoan.offer?.offerId ??
		localLoan.offerNumber ??
		localLoan.offerId ??
		localLoan.id;

	const openDrawer = () => {
		appStore.setIsReferralDrawerVisible(true);
		window.ym && window.ym(48781889, "reachGoal", "podrobnee_100druzei");
	};
	const closeDrawer = () => appStore.setIsReferralDrawerVisible(false);

	return (
		<>
			<div className={s.card}>
				<div className={s.cardInfo}>
					<div>
						<div>
							<span className={s.offerNumber}>Заявка № {offerNumber}</span>
						</div>
						<b>{formattedMoney(localLoan.desiredAmount)}&nbsp;₽</b>
					</div>
					<div>
						<div>
							<span className={s.cardLabel}>Авто:</span> {loanSubject()}
						</div>
						{localLoan.desiredAmount &&
							localLoan.loanTerm &&
							loan.status === "approved" && (
								<>
									<span className={s.cardLabel}>Платеж:</span>{" "}
									{formattedMoney(loan.monthly)}&nbsp;₽
								</>
							)}
						<div>
							<span className={s.cardLabel}>Срок:</span> {localLoan.loanTerm}{" "}
							мес.
						</div>
					</div>
				</div>
				<div className={statusClasses().join(" ")}>
					<div className={s.status}>
						<div>
							<span className={s.cardLabel}>Статус</span>
						</div>
						<span className={s.statusText}>{renderStatusText()}</span>
						{localLoan.status === loanStatuses.modifiedAmount &&
							localLoan.modifiedAmountType === "increase" && (
								<div className={s.increaseText}>
									Вам доступно увеличение суммы! Лимит:{" "}
									{localLoan.maxAmount.toLocaleString()}&nbsp;₽
								</div>
							)}
					</div>
					{renderBtn()}
				</div>
			</div>

			<ModalContainer
				showModal={popup}
				closeModal={() => setPopup(false)}
				size="small"
				dynamic={dynamic}
				getTitleFunc={() => repeatStore.loansRequestApproveStepTitle}
				title={modalTitle}
			>
				{renderComponent()}
			</ModalContainer>
			<ModalContainer
				showModal={increasePopup}
				closeModal={() => setIncreasePopup(false)}
				size="small"
				title={"Получить деньги"}
			>
				<ModalIncrease loan={loan} onClose={() => setIncreasePopup(false)} />
			</ModalContainer>
			<ModalContainer
				showModal={onlineLoanSigningPopup}
				closeModal={() => setOnlineLoanSigningPopup(false)}
				title={"Документы подписаны"}
			>
				<ModalApprovedFinal
					loan={loan}
					onClose={() => setOnlineLoanSigningPopup(false)}
				/>
			</ModalContainer>
			<ClubDrawer
				visible={appStore.isReferralDrawerVisible}
				onClose={closeDrawer}
			/>
		</>
	);
});

export default OnlineLoanCard;
