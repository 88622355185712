import React from "react";
import s from "./OnlineLoanPersonal.module.scss";
import BaseInput from "../../atoms/BaseInput";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import NativeSelect from "../../molecules/NativeSelect";
import PhoneInput from "../../atoms/PhoneInput";
import BaseMessage from "../../atoms/BaseMessage";
import appStore from "../../../store/app";
import LockedInput from "../../atoms/BaseInput/LockedInput";

const genderTemplate = ['Мужской', 'Женский']
const PersonalForm = ({personalForm, setPersonalForm, disabled=false, openChangeInfoWarningModal, isConfirmChange, isDataApproved}) => {
    const {
        surname,
        name,
        patronymic,
        prevSurname,
        prevName,
        prevPatronymic,
        isNameNotChanged=true,
        gender,
        birthDate,
        birthPlace,
        citizenship,
        additionalPhone,
        snils,
        inn,
        orderEmail,
    } = personalForm

    const phone = appStore?.profile?.phone || ''


    return <form autoComplete={'off'} className={[s.inputsGroup, s.inputsGroup__main].join(" ")}>
        <div onClick={openChangeInfoWarningModal}>
            <BaseInput
                placeholder="Фамилия *"
                defaultValue={surname}
                value={v => setPersonalForm({...personalForm, surname: v})}
                disabled={disabled}
            />
        </div>
        <div onClick={openChangeInfoWarningModal}>
            <BaseInput
                placeholder="Имя *"
                defaultValue={name}
                value={v => setPersonalForm({...personalForm, name: v})}
                disabled={disabled}
            />
        </div>
        <div onClick={openChangeInfoWarningModal}>
            <BaseInput
                placeholder="Отчество *"
                defaultValue={patronymic}
                value={v => setPersonalForm({...personalForm, patronymic: v})}
                disabled={disabled}
            />
        </div>

        <div className={`${s.row} ${s.reduceGap}`}>
            <div className={s.checkboxWrapper}>
                <BaseCheckbox
                    inversion
                    value={isNameNotChanged }
                    onChange={() => setPersonalForm({...personalForm, isNameNotChanged : !isNameNotChanged })}
                    disabled={disabled}
                >
                    ФИО не менялись
                </BaseCheckbox>
                {!isNameNotChanged  && <>
                    <BaseMessage type="warning" shadow={true} centered={false} close={false}>
                        Укажите прежние ФИО
                    </BaseMessage>
                </>}
            </div>
        </div>
        {!isNameNotChanged  && <>
            <div>
                <BaseInput
                    placeholder="Прежняя Фамилия *"
                    defaultValue={prevSurname}
                    value={v => setPersonalForm({...personalForm, prevSurname: v})}
                    disabled={disabled}
                />
            </div>
            <div>
                <BaseInput
                    placeholder="Прежнее Имя *"
                    defaultValue={prevName}
                    value={v => setPersonalForm({...personalForm, prevName: v})}
                    disabled={disabled}
                />
            </div>
            <div>
                <BaseInput
                    placeholder="Прежнее Отчество *"
                    defaultValue={prevPatronymic}
                    value={v => setPersonalForm({...personalForm, prevPatronymic: v})}
                    disabled={disabled}
                />
            </div>
        </>}
        <div>
            <NativeSelect
                placeholder="Пол *"
                options={genderTemplate}
                defaultValue={gender}
                onSearch={null}
                onChanged={v => setPersonalForm({...personalForm, gender: v})}
                disabled={disabled}
            />
        </div>
        <div onClick={openChangeInfoWarningModal}>
            <div style={{pointerEvents: isDataApproved && !isConfirmChange ? 'none' : ''}}>
                <BaseInput
                  placeholder="Дата рождения *"
                  type={'date'}
                  defaultValue={birthDate}
                  value={v => setPersonalForm({...personalForm, birthDate: v})}
                  disabled={disabled}
                />
            </div>
        </div>
        <div>
            <LockedInput
                placeholder="Мобильный телефон"
                value={phone}
                disabled
            />
        </div>
        <div>
            <BaseInput
                placeholder="Город рождения *"
                defaultValue={birthPlace}
                value={v => setPersonalForm({...personalForm, birthPlace: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Гражданство *"
                defaultValue={citizenship}
                value={v => setPersonalForm({...personalForm, citizenship: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <PhoneInput
                placeholder="Дополнительный телефон"
                defaultValue={additionalPhone}
                value={v => setPersonalForm({...personalForm, additionalPhone: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="СНИЛС"
                type={'snils'}
                defaultValue={snils}
                value={v => setPersonalForm({...personalForm, snils: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="ИНН"
                type={'inn'}
                defaultValue={inn}
                value={v => setPersonalForm({...personalForm, inn: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Email *"
                type={'email'}
                defaultValue={orderEmail}
                value={v => setPersonalForm({...personalForm, orderEmail: v})}
                disabled={disabled}
            />
        </div>
    </form>
}
export default PersonalForm
