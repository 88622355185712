import React from 'react'

import s from './BaseProgress.module.scss'

export class BaseProgress extends React.Component {
  render() {
      const {percent, red, small} = this.props
      const classes = [s.progress]
      if (red) classes.push(s.red)
      if (small) classes.push(s.small)

    return (
        <div className={classes.join(' ')}>
          <div className={s.progress__bar} style={{width: Math.round(percent) + '%'}}/>
        </div>
    )
  }
}

export default BaseProgress
