import React from 'react'

import s from './BaseInput.module.scss'
import ShowPwdIcon from './img/show-pwd-icon.svg'
import ShowPwdActiveIcon from './img/show-pwd-active-icon.svg'
import {isMobile} from "../../../utils/isMobile";
import {inputCardMask, inputEptsMask, inputInnMask, inputSnilsMask, inputUnitCodeMask, inputPassportSeries, inputRegistrationIndex} from "../../../utils/cardMask";

class BaseInput extends React.Component {
  constructor(props) {
    super();
    this.props = props
    this.input = React.createRef();
    this.initialType = this.props.type
    this.state = {
      inputField: this.props.defaultValue || '',
      selectedType: null,
      currentDate : null,
      typeForMobileKeyboard: ['card', 'passportSeries', 'snils', 'inn', 'unitCode', 'registrationIndex']
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type === 'number' || this.props.type === 'currency') {
      if (prevProps.defaultValue > this.props.max) {
        this.setState({
          inputField: this.props.max
        })
        this.props.value(this.props.max)
      }
    }
    if (this.props.type==='date' && this.props.defaultValue === 'null-null-null'){
      this.props.value('')
    }
    if (this.props.defaultValue !== prevProps.defaultValue) {
      if(this.props.type === 'passportSeries' && this.props.defaultValue) {
        this.setState({
          inputField: inputPassportSeries(String(this.props.defaultValue))
        })
        return
      }
      if(this.props.type === 'registrationIndex' && this.props.defaultValue) {
        this.setState({
          inputField: inputRegistrationIndex(String(this.props.defaultValue))
        })
        return
      }
      this.setState({
        inputField: this.props.defaultValue
      })
    }
  }

  componentDidMount() {
    this.props.value(this.state.inputField)
    if (this.props.type==='date'){
      this.setCurrentDate()
    }
  }

  setType(type) {
    this.setState({
      selectedType: type,
      inputField: this.state.inputField
    })
  }

  handleChange(value) {
    let formattedValue
    formattedValue = this.props.onlyLetters
      ? value.replace(/[0-9]/g, '')
      : value
    if (this.props.type === 'card') {
      formattedValue = value.replace(/\D/gi, '')
      formattedValue = inputCardMask(formattedValue)
    }
    if (this.props.type === 'number' || this.props.type === 'currency') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = formattedValue ? formattedValue : null
      if(value.length > this.props.maxlength) return
    }
    if (this.props.type === 'password') {
      formattedValue = value.replace(/[а-яА-ЯЁё]+/g, '')
    }
    if (this.props.type === 'epts') {
      formattedValue = value.replace(/\D/gi, '')
      formattedValue = inputEptsMask(formattedValue)
    }
    if (this.props.type === 'snils') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = inputSnilsMask(formattedValue)
    }
    if (this.props.type === 'inn') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = inputInnMask(formattedValue)
    }
    if (this.props.type === 'unitCode') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = inputUnitCodeMask(formattedValue)
    }
    if (this.props.type === 'date') {
      let year = parseFloat(value)
      const minYear = 1950
      const maxDate = Date.parse(new Date())
      if(year < minYear && String(year).length === 4) {
        formattedValue = '1950-01-01'
      }
      if(Date.parse(value) > maxDate) {
        formattedValue = this.state.currentDate
      }
    }
    if (this.props.type === 'passportSeries') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = inputPassportSeries(formattedValue)
    }
    if (this.props.type === 'registrationIndex') {
        // eslint-disable-next-line no-useless-escape
      formattedValue = value.replace(/[^0-9\.]+/g, '')
      formattedValue = inputRegistrationIndex(formattedValue)
    }

    this.setState({
      selectedType: this.state.selectedType,
      inputField: formattedValue && formattedValue.trim()
    })

    if(this.props.type === 'passportSeries' || this.props.type === 'registrationIndex') {
      this.props.value(formattedValue.trim().replace(/\s/g, ''))
      return
    }

    this.props.value(formattedValue)
  }

  onBlur(e) {
      // fix for number type - ignore e E + -  on input
      if (this.state.inputField === ' ' && this.props.type === 'number'){
          this.setState({
              inputField: ''
          })
      }
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
  }

  handleCurrencyChange(event, maskedvalue, floatvalue) {
    this.handleChange(floatvalue)
  }

  setCurrentDate() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    this.setState({currentDate: yyyy + '-' + mm + '-' + dd})
  }

  onCurrencyBlur(e) {
    const res = e.target.value.split(' ').join('')
    this.onBlur({target: {value: res}})
  }

  onFocus() {
    if (!this.state.inputField) {
      this.setState({
        inputField: '+7 '
      })
    }
  }

  cardType(){
    if (this.state.typeForMobileKeyboard.includes(this.initialType)){
      return 'tel'
    }
    return   this.state.selectedType ? this.state.selectedType : this.initialType
  }

  onClick = () => {
    if(this.input.current && this.initialType === 'date' && isMobile()) {
      this.input.current.focus()
      this.input.current.showPicker()
    }
  }

  render() {
    const wrapperClasses = [s.inputWrapper]
    if (this.state.inputField) wrapperClasses.push(s.activePlaceholder)
    if (this.props.size) wrapperClasses.push(s[this.props.size])
    if (this.props.disabled) wrapperClasses.push(s.disabledPlaceholder)
    if (this.props.type === 'date') wrapperClasses.push(s.activePlaceholder)

    return (
      <div className={wrapperClasses.join(' ')} style={this.props.style}>
        <div className={[s.placeholder, this.props.disabled ? s['placeholder--disabled'] : ''].join(' ')}>{this.props.placeholder}</div>
        <input
          type={this.cardType()}
          className={s.input}
          style={{height: this.props.height, fontSize: this.props.fontSize, fontWeight: this.props.fontWeight}}
          onChange={(e) => this.handleChange(e.target.value)}
          onInput={(e) => this.handleChange(e.target.value)}
          onClick={this.onClick}
          disabled={this.props.disabled}
          autoComplete="off"
          value={this.state.inputField}
          onBlur={(e) => this.onBlur(e)}
          min={this.props.type === 'date' ? '1950-01-01' : '0'}
          maxLength={this.props.maxlength}
          readOnly={this.props.readOnly}
          autoFocus={this.props.autofocus}
          ref={this.input}
        />
        {
          (this.initialType === 'password' && (!this.state.selectedType || this.state.selectedType === 'password'))
            ? <img className={s.icon} src={ShowPwdIcon} alt='' onClick={() => this.setType('text')}/>
            : this.initialType !== 'password'
              ? null
              : <img className={s.icon} src={ShowPwdActiveIcon} alt='' onClick={() => this.setType('password')}/>
        }
        {
          this.initialType === 'date' && isMobile() && <span onClick={() => this.input.current.click()} className={s.dateValue}>
            {this.props.defaultValue ? this.state.inputField.split('-').reverse().join('.') : 'дд.мм.гггг'}
          </span>
        }
      </div>
    )
  }
}

export default BaseInput
