import React, {useEffect, useState} from 'react'

import s from './ModalContainer.module.scss'
import BaseClose from "../BaseClose";
import {observer} from "mobx-react";
import BaseButton from "../BaseButton";

const ModalContainer = observer( ({btnBack, onClickBack, closeModal, showModal, size, title, getTitleFunc, dynamic, children, closeOnBlur= true, isTitle=true}) => {
  const [show, setShow] = useState(false)

  const close = () => {
    setShow(false)
    document.body.style.overflow = null
    //  timeout for render document.body.style.overflow = null
    setTimeout(() => {
      closeModal()
    }, 150)
  }

  useEffect(() => {
    if (!showModal) {
      document.body.style.overflow = 'auto'
      return
    }

    document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`
    document.body.style.overflow = 'hidden'

    const timer = setTimeout(setShow(true), 1);
    return () => clearTimeout(timer)
  }, [showModal])

  const classes = [s.modal]
  if (show) classes.push(s['modal--show'])

  const classesWrapper = [s.modal__wrapper]
  if (size) classesWrapper.push(s[`modal__wrapper--${size}`])

  if(!showModal) return null

  return <div className={classes.join(' ')}>
    <div className={s.modal__bg}
         style={{cursor: closeOnBlur ? 'pointer': 'default' }}
         onClick={ (e) => closeOnBlur ? close(e): ''}
    />
    <div className={classesWrapper.join(' ')}>
      <div className={s.modal__close} onClick={ (e) => close(e)}>
        <BaseClose type={'simple'}/>
      </div>
        <div>
        {btnBack && <div className={s.modal__top}><BaseButton
          text="Вернуться"
          type="bony"
          arrowLeft={true}
          onClick={onClickBack}
        /></div>}
          {isTitle && <div>
            { dynamic ?
              <div className={s.modal__top}><h2 dangerouslySetInnerHTML={{__html: getTitleFunc()}} /></div>
              :
              <div className={s.modal__top}><h2 dangerouslySetInnerHTML={{__html: title}} /></div>
            }
          </div>}
      </div>
      <div> { children }</div>
    </div>
  </div>
})

export default ModalContainer
