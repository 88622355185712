import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import {
	BrowserRouter,
	Prompt,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";

import { backReq } from "./helpers";
import appStore from "./store/app";

import PageRepeater from "components/PageRepeater";
import LoansRequestPage from "pages/loansRequest";
import Footer from "./components/Footer";
import Header from "./components/Header/Header";
import NotFound from "./components/NotFound";
import PageLoanDetails from "./components/PageLoanDetails/PageLoanDetails";
import PageLoans from "./components/PageLoans/PageLoans";
import PageLogin from "./components/PageLogin/PageLogin";
import PageProfile from "./components/PageProfile/PageProfile";
import Sidebar from "./components/Sidebar/Sidebar";
import PayModal from "./components/organisms/PayModal";
import PayStatusModal from "./components/organisms/PayStatusModal";
import ClubPage from "./pages/club";
import ContactsPage from "./pages/contacts";
import DashboardPage from "./pages/dashboard";
import ArticlesPage from "./pages/news";
import ArticlePage from "./pages/news/_id";
import OnlineLoanPage from "./pages/onlineLoan";
import SettingsPage from "./pages/settings";

import SnackbarProvider from "react-simple-snackbar";
import Notificator from "././containers/Notificator";
import ScrollToTop from "./ScrollToTop";
import RepeaterModeration from "./components/Repeater/RepeaterModeration";
import BaseSnackbar from "./components/atoms/BaseSnackbar";
import SignDocModal from "./components/organisms/SignDocModal";
import SuccessLoanModal from "./components/organisms/SuccessLoanModal";
import constants from "./constants/index";
import LoanOrderModal from "./containers/LoanOrderModal";
import NotificationsPage from "./containers/NotificationsPage";
import OnboardingModal from "./containers/OnboardingModal";
import UserAgreementModal from "./containers/UserAgreementModal";
import repeatStore from "./store/repeatStore";
import {
	ONLINE_LOAN_PROMO,
	getPromoInfo,
	isPromoExist,
} from "./utils/onlineLoanPromo";

const APPLICATION_TYPE = process.env.INSTANCE_TYPE;

@inject("appStore")
@observer
class App extends Component {
	state = {
		authorizedTrigger: false,
		isShutdown: false,
		shutdownText: null,
	};

	componentDidMount() {
		this.checkServiceShutdown();
		this.pwaInstall();

		const queryString = window?.location?.search;
		if (queryString) {
			localStorage.setItem("utm", window?.location);
		}
	}

	componentDidUpdate() {
		if (appStore.isLoggedIn) {
			this.updateProfileAndLoans();
		}
	}

	// Триггеруем обновление через setState после успешного логина
	triggerAuth = (_) => this.setState((_) => ({ authorizedTrigger: true }));

	updateProfileAndLoans = async (_) => {
		try {
			// Информация профиля
			await backReq("user", undefined, appStore.startLogoutCountdownTimer).then(
				(res) => appStore.setProfile(res.data),
			);

			// Список займов
			await appStore.fetchLoans();

			await backReq("online_loan:list").then((response) => {
				if (response?.data) {
					return repeatStore.setOnlineLoans(response?.data?.list);
				}
			});
		} catch (e) {
			console.warn(e);
		}
	};

	checkServiceShutdown = () => {
		backReq("status")
			.then((res) => {
				if (res.data.isShutdown) {
					appStore.logout();
					this.setState(() => ({
						isShutdown: true,
						shutdownText: res.data.message,
					}));
				} else if (appStore.isLoggedIn) {
					this.updateProfileAndLoans();
				}
				if (!res.data.isShutdown) {
					this.setState(() => ({ shutdownText: res.data.message }));
				}
			})
			.catch((error) => {
				console.warn(error);
			});
	};

	pwaInstall = () => {
		window.addEventListener("beforeinstallprompt", (e) => {
			e.preventDefault();
			const beforeInstallPromptEvent = e;
			if (!window.location.search) return;
			const params = new URL(window.location.href);
			const downloadPwa = params.searchParams.get("download_pwa");
			if (downloadPwa) {
				beforeInstallPromptEvent.prompt();
			} else {
				setTimeout(() => beforeInstallPromptEvent.prompt(), 60000);
			}
		});
	};

	renderLoginPage(callbackModalVisible, isLoggedIn, isShutdown, shutdownText) {
		const cfr = APPLICATION_TYPE === constants.application.mkk_cfr_vi;

		const policy = {
			text: cfr
				? "Займы предоставляет ООО МФК «ЦФР ВИ» ОГРН 1175476112646, регистрационный номер в государственном реестре микрофинансовых организаций: № 1803550008909."
				: "Займы предоставляет ООО МКК «Ваш инвестор» ОГРН 1135476100870, регистрационный номер в государственном реестре микрофинансовых организаций: № 651303550004037.",
			link: cfr
				? "https://vashinvestor.ru/company/useful/"
				: "https://mkk-vi.ru/#docs",
		};

		return (
			<PageLogin
				policy={policy}
				callbackModalVisible={callbackModalVisible}
				showCallbackModal={this.showCallbackModal}
				hideCallbackModal={this.hideCallbackModal}
				triggerAuth={this.triggerAuth}
				isLoggedIn={isLoggedIn}
				isShutdown={isShutdown}
				shutdownText={shutdownText}
			/>
		);
	}

	render() {
		const { isLoggedIn } = appStore;
		const { callbackModalVisible, isShutdown, shutdownText } = this.state;
		return (
			<BrowserRouter>
				<ScrollToTop />
				<Prompt
					message={(location) => {
						if (location.pathname.match(/^\/loans\/.*$/))
							appStore.setLoanDetails(null);
					}}
				/>
				<Route
					path="/login"
					render={() =>
						this.renderLoginPage(
							callbackModalVisible,
							isLoggedIn,
							isShutdown,
							shutdownText,
						)
					}
				/>
				<Route
					path="/"
					render={() =>
						!isLoggedIn || isShutdown ? (
							<Redirect
								to={
									window.location.search
										? `/login/${window.location.search}`
										: "/login"
								}
							/>
						) : (
							<div>
								<SnackbarProvider>
									<Sidebar />
									<div>
										<Header />
										<Switch>
											<Route exact path="/" component={DashboardPage} />
											<Route
												exact
												path="/notifications"
												component={NotificationsPage}
											/>
											<Route exact path="/loans" component={PageLoans} />
											<Route
												path="/loans/:number"
												component={PageLoanDetails}
												onLeave={this.clearLoanDetails}
											/>
											<Route
												path="/loans-request"
												component={LoansRequestPage}
											/>
											<Route path="/profile" component={PageProfile} />
											<Route path="/settings" component={SettingsPage} />
											<Route exact path="/news" component={ArticlesPage} />
											<Route
												path="/news/:slug"
												render={({ match }) => {
													const { slug } = match.params;
													return <ArticlePage slug={slug} />;
												}}
											/>
											<Route exact path="/club" component={ClubPage} />
											<Route exact path="/contacts" component={ContactsPage} />
											<Route
												exact
												path="/repeater"
												render={() => {
													if (!repeatStore.isOfferExist) {
														if (
															isPromoExist(ONLINE_LOAN_PROMO) &&
															getPromoInfo(ONLINE_LOAN_PROMO) === "repeater"
														)
															sessionStorage.removeItem(ONLINE_LOAN_PROMO);
														return <Redirect to="/" />;
													}
													return <PageRepeater />;
												}}
											/>
											<Route
												exact
												path="/online-loan"
												component={OnlineLoanPage}
											/>
											<Route component={NotFound} />
										</Switch>
										<Footer />
									</div>
									<PayModal />
									<PayStatusModal />
									<LoanOrderModal />
									<OnboardingModal />
									<UserAgreementModal />
									<SignDocModal />
									<SuccessLoanModal />
									<Notificator />
									<BaseSnackbar />
									<RepeaterModeration />
								</SnackbarProvider>
							</div>
						)
					}
				/>
			</BrowserRouter>
		);
	}
}

export default App;
