import React from "react";

import s from './ErrorContainer.module.scss'

const ErrorContainer = ({children, innerRef, hasError}) => {

    let className = s.errorContainer;
    if (hasError) {
        className += ' '+ s.hasError;
    }
    return <div ref={innerRef} className={className}>
        <div className={s.errorContainer__wrapper}>
            {children}
        </div>
    </div>
}

export default ErrorContainer
