import React from "react";
import s from "./OnlineLoanPersonal.module.scss";
import BaseInput from "../../atoms/BaseInput";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import DadataInput from "../../atoms/DadataInput";

const PassportForm = ({passportForm, setPassportForm, disabled = false, openChangeInfoWarningModal}) => {
    const {
        passportSeries,
        passportNumber,
        passportIssuedBy,
        passportDate,
        divisionCode,
        registration,
        registrationIndex,
        registrationIsActual,
        actualRegistration,
        actualRegistrationIndex,
        noCriminal,
        noProsecuted
    } = passportForm

    return <div className={[s.inputsGroup, s.inputsGroup__main].join(" ")}>
        <div className={s.inputsGroup__codeAndNumber}>
            <div onClick={openChangeInfoWarningModal}>
                <BaseInput
                    type={'passportSeries'}
                    placeholder="Серия *"
                    defaultValue={passportSeries}
                    value={v => setPassportForm({...passportForm, passportSeries: v})}
                    disabled={disabled}
                />
            </div>
            <div onClick={openChangeInfoWarningModal}>
                <BaseInput
                    type={'number'}
                    placeholder="Номер *"
                    defaultValue={passportNumber}
                    maxlength={6}
                    value={v => setPassportForm({...passportForm, passportNumber: v})}
                    disabled={disabled}
                />
            </div>
        </div>
        <div>
            <BaseInput
                placeholder="Дата выдачи *"
                type={'date'}
                defaultValue={passportDate}
                value={v => setPassportForm({...passportForm, passportDate: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Кем выдан *"
                defaultValue={passportIssuedBy}
                value={v => setPassportForm({...passportForm, passportIssuedBy: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Код подразделения *"
                type={'unitCode'}
                defaultValue={divisionCode}
                value={v => setPassportForm({...passportForm, divisionCode: v})}
                disabled={disabled}
            />
        </div>
        <div>
            <BaseInput
                type={'registrationIndex'}
                placeholder="Индекс места регистрации *"
                defaultValue={registrationIndex}
                value={v => setPassportForm({...passportForm, registrationIndex: v})}
                disabled={disabled}
            />
        </div>
        <div className={s.wideColumn}>
            <DadataInput
                placeholder="Адрес регистрации *"
                defaultValue={registration}
                value={v => setPassportForm({...passportForm, registration: v})}
                disabled={disabled}
            />
        </div>
        <div className={s.checkboxOne}>
            <BaseCheckbox
              value={registrationIsActual}
              onChange={() => setPassportForm({...passportForm, registrationIsActual: !registrationIsActual, actualRegistration: '', actualRegistrationIndex: ''})}
              disabled={disabled}
              inversion
            >
                Совпадает с фактическим адресом проживания
            </BaseCheckbox>
        </div>
        {
            !registrationIsActual && <>
                <div className={s.middleColumn}>
                    <DadataInput
                        placeholder="Адрес фактического проживания *"
                        defaultValue={actualRegistration}
                        value={v => setPassportForm({...passportForm, actualRegistration: v})}
                        disabled={disabled}
                    />
                </div>
                <div>
                    <BaseInput
                        type={'registrationIndex'}
                        placeholder="Индекс *"
                        defaultValue={actualRegistrationIndex}
                        value={v => setPassportForm({...passportForm, actualRegistrationIndex: v})}
                        disabled={disabled}
                    />
                </div>
            </>
        }
        <div className={s.checkboxGroup}>
            <div className={s.row}>
                <BaseCheckbox
                  inversion
                  value={noCriminal}
                  onChange={() => setPassportForm({...passportForm, noCriminal: !noCriminal})}
                  disabled={disabled}
                >
                    Не находился под судом или следствием
                </BaseCheckbox>
            </div>
            <div className={s.row}>
                <BaseCheckbox
                  inversion
                  value={noProsecuted}
                  onChange={() => setPassportForm({...passportForm, noProsecuted: !noProsecuted})}
                  disabled={disabled}
                >
                    Не привлекался к уголовной ответственности
                </BaseCheckbox>
            </div>
        </div>
    </div>
}
export default PassportForm
