import appStore from '../store/app'
import humps from 'humps'
import {backReq, under2camel} from "../helpers";
import elementsStore from "../store/elementsStore";
import {
    BACKEND_CHECKING_CLIENT_DATA_ERROR,
    loanStatuses,
    loanStatusesForUser,
    onlineLoanStepsArray
} from "../constants/onlineLoan";
import repeatStore from "../store/repeatStore";
import onlineLoanStore from "../store/onlineLoanStore";
import {moveToNextOnlineLoanStep} from "../components/Repeater/helpers";
import {errorsParser} from "../utils/errorsParser";
import _ from 'lodash'

const updateLoansInfo = _.throttle(() => {
    appStore.fetchLoans()
    appStore.updateCurrentLoan()
}, 3000)

const dispatchMessage = res => {
    switch (res.action) {

        // Смена статуса авторизации
        case 'login':
            break
        case 'notification:create':
            const actionType = res.data.action_type
            if (actionType === 'lkk_payment_received' ||
                actionType === 'lkk_payment_rejected' || actionType === 'lkk_payment_progress') {
                return
            }
            appStore.showNotification(true, res.data)
            break
        case 'payment':
            appStore.setPayments(humps.camelizeKeys(res.data))
            setPendingPayments()
            break
        case 'notification:list':
            appStore.setUnreadNotifications(res.data, res.data.length)
            break
        case 'online_loan:changed_status':
            informUser(res.data, res.type)
            appStore.refreshOnlineLoanOrderExist()
            break
        case 'check_client_data':
            onlineLoanHandler(res.data)
            break
        case 'client:change_email':
            backReq('user').then((res) => appStore.setProfile(res.data))
            break
        case 'loan_update':
        case 'doc_update':
            updateLoansInfo()
            break
        case 'schedule_update':
            const loanId = appStore.loanDetails?.ln ?? null
            if (!loanId) return;
            appStore.getCurrentLoansPayments({
                loanId, offset: 0, limit: 0
            })
            break
        case 'passport_check:result':
            console.log('passport_check:result', res.data)
            setTimeout(() => {
                repeatStore.setPassportCheck(res.data.result ?? false)
            }, 1000)
            break
        case 'liveliness:result':
            console.log('liveliness:result', res.data)
            repeatStore.setIsLivelinessCheckInProgress(false)
            /* res.data.result =
             "real" - Живость подтверждена
             "attack" - Живость не подтверждена
             "unfinished" - Проверка не завершена
            */
            repeatStore.setLivelinessCheck(res.data.result)
            break

        // Действие по умолчанию
        // eslint-disable-next-line no-fallthrough
        default:
            break

    }
}

async function setPendingPayments() {
    try {
        const result = await backReq('list_open_payments')
        appStore.setEntitiesInPayment(result.data)
        appStore.fetchLoans()
        appStore.updateCurrentLoan()
    } catch (e) {

    }
}

async function informUser(data) {
    let message = `Ваша заявка `
    const status = under2camel(data.status)
    const statusDescription = loanStatusesForUser[status]
    message += statusDescription ? `сменила статус на "${statusDescription}"` : 'изменила статус'
    elementsStore.showSnackbar(message, status)
    await repeatStore.setOnlineLoanNewStatus(true)
    if (status === loanStatuses.complete) {
        await repeatStore.setIsNotRatedOnlineLoans(true)
    }
}

export async function onlineLoanHandler({success = false}) {
    if (!success) {
        onlineLoanStore.setIsCheck1C(false)
        onlineLoanStore.setIsDataOnCheck(false)
        return
    }
    onlineLoanStore.setIsDataOnCheck(false)
    const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[2], onlineLoanStore)
    if (nextStepErrors) {
        const errorMessage = (errorsParser(nextStepErrors)).trim()
        if (errorMessage === BACKEND_CHECKING_CLIENT_DATA_ERROR) onlineLoanStore.setIsDataOnCheck(true);
        elementsStore.showSnackbar(errorMessage)
        return false
    }
}

export default dispatchMessage
