import React from "react";
import s from "./OnlineLoanPersonal.module.scss";
import BaseButton from "../../atoms/BaseButton";
import appStore from "../../../store/app";

const UserExistModal = () => {
    const buttonText = <div className={s.userExistButtonTitle}>
        <h4>Восстановить пароль</h4>
        <p>Произойдет выход из Личного кабинета</p>
    </div>
  return (<div className={s.onlineLoanPersonalModal}>
          <h3>Кажется клиент с такими данными уже есть...</h3>
          <p>Проверьте правильность указанных данных, либо попробуйте восстановить доступ к своему профилю (по ссылке произойдет выход из Личного кабинета).
              Если у Вас возникли сложности, свяжитесь по телефону горячей линии 8 (800) 700-63-31.</p>
          <div className={s.buttonWrapper}>
              <BaseButton
                  type="green"
                  size="default-change"
                  text={buttonText}
                  onClick={()=>appStore.logout()}
              />
          </div>
      </div>
  )
}
export default UserExistModal
