import React from 'react'

import s from './BaseHoneypot.module.scss'

export const BaseHoneypot = ({type, placeholder}) => {
  return <input
      className={s.hp}
      type={type ? type : 'text'}
      tabIndex={-1}
      placeholder={placeholder ? placeholder : 'Введите ваш основной телефон'}
  />
}

export default BaseHoneypot
