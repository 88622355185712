import React from 'react'
import s from '../BaseInput/BaseInput.module.scss'
import CurrencyInput from 'react-currency-input-field';
import BaseInput from "../BaseInput";

const BaseMoneyInput = ({
                            value,
                            onChange,
                            min,
                            max,
                            size,
                            placeholder,
                            disabled,
                            error,
                            errorMessage,
                        }) => {

    const onCurrencyBlur = () => {
        const res = parseFloat((+value ?? 0).toFixed(2))
        let x = !res || res < min
            ? min
            : res > max ? max : res

        onChange(x.toFixed(2))
    }
    const onFocus = e => {
        setTimeout(() => {
            if (!e.target.value.match(/^0\./)) return
            const index = e.target.value.indexOf('.')
            if (index !== -1) e.target.setSelectionRange(index, index);
        }, 0);
    }

    const wrapperClasses = [s.inputWrapper, s.activePlaceholder]
    if (size) wrapperClasses.push(s[size])

    return (
        <div>
            <div className={wrapperClasses.join(' ')}>
                <div className={s.placeholder}>{placeholder}</div>
                {window.Intl?.NumberFormat?.prototype?.formatRangeToParts ?
                    <CurrencyInput
                        value={value}
                        onValueChange={value => onChange(value)}
                        onFocus={e => onFocus(e)}
                        onBlur={onCurrencyBlur}
                        decimalSeparator={'.'}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        className={s.input}
                        disabled={disabled}
                        maxLength={10}
                    /> :
                    <BaseInput
                        type={'currency'}
                        placeholder="Сумма"
                        defaultValue={value}
                        value={value => onChange(value)}
                        onBlur={onCurrencyBlur}
                        disabled={disabled}
                    />
                }
            </div>
            {error && (
                <div className={s.red} style={{marginTop: '12px'}}>
                    {errorMessage}
                </div>
            )}
        </div>
    )
}

export default BaseMoneyInput
