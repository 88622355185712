import React from "react";
import s from "./BaseButton.module.scss";

import { ReactComponent as ArrowLeft } from "./img/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./img/arrow-right.svg";
import { ReactComponent as Chevron } from "./img/chevron.svg";
import { ReactComponent as Plus } from "./img/plus.svg";

class BaseButton extends React.Component {
	constructor(props) {
		super();
		this.props = props;
	}
	handleClick(e) {
		e.stopPropagation();
		this.props.onClick(e);
	}
	handleMouseDown(e) {
		const btnType = this.props.btnId;
		const isDisabled = e?.target?.parentElement?.disabled;
		if (
			this.props.onPaymentClick &&
			btnType &&
			typeof isDisabled === "boolean"
		) {
			this.props.onPaymentClick({ btnType, isDisabled });
		}
	}
	render() {
		const {
			size,
			type,
			disabled,
			loading,
			btnId,
			text,
			arrowLeft,
			arrowRight,
			plus,
			chevron,
			style = {},
			htmlType = "button",
		} = this.props;

		const classes = [s.btn, s[type ? type : "green"]];
		classes.push(size ? s[size] : s.default);
		if (loading)
			classes.push(
				s[`btn--${type === "green-pay" ? "loading-pay" : "loading"}`],
			);
		if (window.location.pathname.includes("repeater"))
			classes.push(s["btn--repeater"]);
		return (
			<button
				style={style}
				className={classes.join(" ")}
				disabled={disabled || loading}
				onClick={(e) => this.handleClick(e)}
				id={btnId}
				type={htmlType}
			>
				{plus && <Plus className={s.plusLeft} />}
				{arrowLeft && <ArrowLeft className={s.iconLeft} />}
				{text && <span>{text}</span>}
				{arrowRight && <ArrowRight className={s.iconRight} />}
				{chevron && !loading && <Chevron className={s.chevron} />}
			</button>
		);
	}
}

export default BaseButton;
