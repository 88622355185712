export const getQuery = () => {
    const list = {}

    if (window.location.search) {
        window.location.search.split('?')[1].split('&').forEach(param => {
            const [key, value] = param.split('=')
            list[key] = value
        })
    }

    return list
}
