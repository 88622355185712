import {useEffect, useState} from 'react';
import repeatStore from "../store/repeatStore";


function useRefreshLoanDetail(insertDataFunc) {
  const [errors, setErrors] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData(){
      try {
        setIsLoading(true)
        if (repeatStore.loanDetail.id)
          // don`t wait for better ux, we already have data
          repeatStore.refreshLoanDetail().then(insertDataFunc)
        else {
          await repeatStore.refreshLoanDetail()
        }
      } catch (e) {
        setErrors({errors: e.message})
        throw new Error('fetch data error')
      } finally {
        setIsLoading(false)
      }
    }
    fetchData().then(insertDataFunc).catch(e => console.warn(e))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return [errors, isLoading]
}

export default useRefreshLoanDetail
