import React from "react"
import {useHistory} from "react-router-dom"
import {observer} from "mobx-react"
import repeatStore from "store/repeatStore"

import ContentContainer from "../../molecules/ContentContainer"
import BaseButton from "../../atoms/BaseButton"

import {ReactComponent as PromoImage} from "./../../../img/repeater-promo.svg"

import s from "./RepeaterBanner.module.scss"
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";

const RepeaterBanner = observer(() => {
    let history = useHistory();
    const isNewClient = !repeatStore.onlineLoanOrder?.id
    const buttonText = isNewClient
    ? "Получить деньги"
    : "Продолжить оформление"

  const redirect = () => {
        if(isNewClient) {
            yandexMetrikaReachGoal('click_offer')
        } else {
            yandexMetrikaReachGoal('return_user')
        }
      history.push('/repeater')
  }

  return (
    <div className={s.banner}>
      <h2 className="title">Вам предодобрен займ</h2>
      <ContentContainer>
        <article>
          <PromoImage/>
          <div>
            <h3>Получите деньги без приезда в офис</h3>
            <div className="text">
              <ul>
                <li>Компания ценит Вас и ваше время</li>
                <li>Мы знаем, что Вы надежный клиент</li>
                <li>
                  Поэтому мы предоставляем возможность
                  получить деньги легко и быстро без посещения
                  офиса
                </li>
                <li>Деньги придут на вашу банковскую карту</li>
              </ul>
            </div>
            <BaseButton
              text={buttonText}
              type="green"
              size="default"
              onClick={redirect}
            />
          </div>
        </article>
      </ContentContainer>
    </div>
  )
})

export default RepeaterBanner
