import React from "react";
import s from './OnlineLoanSubject.module.scss'
import BaseInput from "../../atoms/BaseInput";

const SubjectForm = ({subjectForm, setSubjectForm}) => {
    const {
        carMark,
        carModel,
        releaseYear,
        type,
        colorBody,
        engineVolume,
        vin,
        bodyNumber,
        regNum
    } = subjectForm

    return <div className={s.inputsGroup}>
        <div>
            <BaseInput
                placeholder="Марка *"
                defaultValue={carMark}
                value={v => setSubjectForm({...subjectForm, carMark: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Модель *"
                defaultValue={carModel}
                value={v => setSubjectForm({...subjectForm, carModel: v})}
            />
        </div>
        <div>
            <BaseInput
                type={'number'}
                placeholder="Год *"
                maxlength={4}
                defaultValue={releaseYear}
                value={v => setSubjectForm({...subjectForm, releaseYear: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Тип транспортного средства (из ПТС) *"
                defaultValue={type}
                value={v => setSubjectForm({...subjectForm, type: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Цвет кузова (из ПТС) *"
                defaultValue={colorBody}
                value={v => setSubjectForm({...subjectForm, colorBody: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Объем двигателя, литров *"
                defaultValue={engineVolume}
                value={v => setSubjectForm({...subjectForm, engineVolume: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="VIN *"
                defaultValue={vin}
                value={v => setSubjectForm({...subjectForm, vin: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Номер кузова *"
                defaultValue={bodyNumber}
                value={v => setSubjectForm({...subjectForm, bodyNumber: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Гос. номер *"
                defaultValue={regNum}
                value={v => setSubjectForm({...subjectForm, regNum: v})}
            />
        </div>
        <div className={`${s.row} ${s.note}`}>
            * убедитесь, что предоставленная информация актуальна, и вы до сих пор являетесь собственником авто, в противном случае обратитесь в наш контакт-центр
        </div>

    </div>

}
export default SubjectForm
