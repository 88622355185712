var moment = require('moment-timezone');

const f = (string) => {
    if (string) {
        const [date, time = ''] = string.split('T')

        const [year, month, day] = date.split('-')

        const [hours = '', minutes = ''] = time.split(':')

        return {year, month, day, hours, minutes}
    }

    return {year: null, month: null, day: null, hours: null, minutes: null}
}

const getLocalHours = utcHours => {
    const hoursSubtraction = +utcHours - (new Date().getTimezoneOffset()) / 60

    if (hoursSubtraction < 10) {
        return `0${hoursSubtraction}`
    }
    if (hoursSubtraction > 23) {
        return hoursSubtraction - 24
    }
    return hoursSubtraction
}

const dateToDMYHM = string => {
    const {day, month, year, hours, minutes} = f(string)

    return `${day}.${month}.${year} ${getLocalHours(hours)}:${minutes}`
}

const dateToDMY = string => {
    const {day, month, year} = f(string)

    return `${day}.${month}.${year}`
}
const dateToYMDHyphenated = string => {
    const {day, month, year} = f(string)

    return `${year}-${month}-${day}`
}

const dateToHM = string => {
    if (!string) return;
    const {hours, minutes} = f(string)

    return `${getLocalHours(hours)}:${minutes}`
}

const dateToDM = string => {
    let {day, month} = f(string)

    month = month[0] === '0' ? month.replace(/0/, '') : month
    day = day[0] === '0' ? day.replace(/0/, '') : day

    const monthList = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

    return `${day} ${monthList[month - 1]}`
}

const renderRuLocaleDateTime = (date) => {
    if (!date) return ''
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    };
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date))
}
const renderRuMoscowDateTime = (date) => {
    if (!date) return ''
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false,
    };
    const moscowTime = moment(date).add(3, 'hours').format()
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(moscowTime))
}
const renderYYMMDDToDDMMYY = (date) => {
    if (!date) return ''
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour12: false
    };
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date))
}
export {
    dateToDMYHM,
    dateToDMY,
    dateToHM,
    dateToYMDHyphenated,
    renderRuLocaleDateTime,
    renderRuMoscowDateTime,
    renderYYMMDDToDDMMYY,
    dateToDM
}
