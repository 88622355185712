import BaseMessage from "../../atoms/BaseMessage";
import React from "react";
import {observer} from "mobx-react";
import appStore from "../../../store/app";

const PayModalMessage = observer (({isOverpay, partialPay}) => {

    const {currentEntityForPay, currentEntityForPayEarlyRepayment, isMfoVi} = appStore

    const feeText = currentEntityForPay.fee ?
        'Банк-эквайер взимает комиссию за транзакции (0,8-2%, но не менее 20-40 руб. в зависимости от суммы платежа). Внести платеж без комиссии Вы можете наличными <a target="_blank" style="color: #4CAF50;" href="/contacts">в любом офисе</a> нашей Компании'
        :
        'Оплата ежемесячного платежа (по займам, услугам) в Личном кабинете возможна только в полном объеме.'

    const partialText = `<p>При частичном погашении основного долга оплатите начисления по сегодняшний день, при успешном проведении операции произойдет смена даты платежа.</p>
                             <p>Мы подготовим Дополнительное соглашение об изменении условий по сумме займа и дате оплаты.</p>
                             <p>При сумме, свыше 1 000 000 ₽ оплату необходимо произвести двумя операциями.</p>`
    const overpayText = `<p>У вас имеется переплата по договору, для Частично-досрочного или полного гашения обратитесь в Контактный центр или в ближайший офис.</p>`
    const getAlertText = currentEntityForPayEarlyRepayment
        ? 'При досрочном гашении дата платежа по займу будет изменена в течении 1 суток. С новым графиком платежей можно ознакомиться на странице займа в вкладке График платежей.'
        : partialPay
            ? partialText :
            isOverpay
                ? overpayText
                : feeText
    if (!(isMfoVi || partialPay || isOverpay)){
        return null
    }

    return <BaseMessage html={true} type="warning"
                 size={'big'}>{getAlertText}</BaseMessage>
})
export default PayModalMessage
