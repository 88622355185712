import React from 'react'
import s from './FAQ.module.scss'
import BaseCollapse from "../atoms/BaseCollapse";
import {VI_OFFICE_PHONE} from "../../constants/phones";


const questions = [
  {
    q: 'Как войти в свой личный кабинет?',
    a: '<p>Для входа в Сервис введите номер телефона, который вы указали при заключении договора заема, далее ваш пароль (он поступает по SMS после заключения договора) и нажмите кнопку "Войти".</p>'
  },
  {
    q: 'Я забыл свой пароль, как восстановить пароль?',
    a: `
    <p>Воспользуйтесь опцией "Выслать пароль по SMS" — введите ваш номер телефона, который вы указали при заключении договора заема и нажмите кнопку "Восстановить". На ваш номер телефона поступит SMS с новым паролем для входа в Сервис.</p>
    <p>Проверьте, что вводите номер телефона, который вы указали при заключении договора.</p>
    <p>В случае не поступления SMS с новым паролем — запросите пароль повторно или обратитесь в Контакт-центр: <span style="white-space: nowrap">${VI_OFFICE_PHONE}.</span></p>
    `
  },
  {
    q: 'Как зарегистрироваться в личном кабинете?',
    a: '<p>Регистрация в Сервисе происходит автоматически при заключении договора в офисе компании, с отправкой на ваш номер телефона SMS сообщения с паролем для входа в Сервис.</p>'
  },
  {
    q: 'Что доступно в личном кабинете?',
    a: '<p>В Сервисе вам доступна подробная информация о договорах, информация о датах внесения платежей. Также вы можете внести очередной платеж по займу, платежи производятся с помощью банковской карты.</p>'
  },
  {
    q: 'Как внести платеж в личном кабинете?',
    a: `
      <p>Для внесения очередного платежа по займу:</p>

      <ol>
        <li>Войдите в Сервис.</li>
        <li>В вкладке "Мои займы" - "Открытые", нажмите на номер договора по которому хотите произвести очередной платеж, далее нажмите на кнопку "Внести платеж".</li>
        <li>Ознакомьтесь с суммой платежа и подтвердите переход к оплате нажав на кнопку "Внести платеж".</li>
        <li>На странице "Оплата заказа" введите реквизиты вашей банковской карты, email и нажмите кнопку "Продолжить", далее следуйте инструкциям платежной системы.</li>
        <li>Статус платежа отобразится в Сервисе, типовое время зачисления платежа - до 1 минуты.</li>
      </ol>

      <p>Для контроля списания денежных средств с банковской карты, мы рекомендуем подключить в Банке выдавшем вам карту, услугу SMS информирования об операциях по банковской карте (счету).</p>

      <p>В случае проблем с внесением платежа обратитесь в Контакт-центр: <span style="white-space: nowrap">${VI_OFFICE_PHONE}.</span></p>
    `
  },
  {
    q: 'Безопасность работы с личным кабинетом',
    a: `
    <p>Не сообщайте ваш пароль для входа в Сервис третьим лицам.</p>
    <p>В случае утери, замены номера телефона оперативно обратитесь в наш ближайший офис для обновления соответствующих данных (при себе необходимо иметь паспорт).</p>
    <p>Мы заботимся о безопасности ваших данных — работа с Сервисом и взаимодействие с платежной системой производится по защищенному каналу с надежным шифрованием.</p>
    `
  }
]



class FAQ extends React.Component {
  state = { openedQuestion: 0 }

  openQuestion = ({ currentTarget }) => this.setState(_ => ({ openedQuestion: +currentTarget.dataset.num }))

  render () {
    return (
      <div>
        {questions.map((qa, i) => (
            <div className={s.line}>
              <BaseCollapse head={qa.q}>
                { qa.a }
              </BaseCollapse>
            </div>
        ))}
      </div>
    )
  }
}

export default FAQ
