import React from 'react';
import s from './CardDoc.module.scss'
import {ReactComponent as IconDoc} from './file.svg';

const CardDoc = ({doc}) => {
  return (
    <a
      className={s.card}
      target='_blank'
      rel='noopener noreferrer'
      href={doc.url ?? '#'}
    >
      <div className={s.card__wrapper}>
        <IconDoc className={s.card__icon}/>
        <p className={s.card__text}>{doc.description ? doc.description : '#'}</p>
      </div>
    </a>
  );
};

export default CardDoc;
