import React from 'react'

import s from './PaymentsScheduleTable.module.scss'

import {dateToDMY} from "../../../utils/formattedDate";

import IconTime from '../../../img/time.svg';
import IconSuccess from '../../../img/success.svg';
import {ResizeContext} from "../../../providers/ResizeProvider";

const columns = [
  'Дата',
  'Основной долг',
  'Обслуживание займа',
  'Сумма платежа',
  'Назначение платежа',
  'Статус',
]

const PaymentsScheduleTable = ({ payments }) => {
  const renderStatus = (isCompleted) => {
    const status = isCompleted
      ? {icon: IconSuccess, text: 'Погашен', color: 'green'}
      : {icon: IconTime, text: 'Запланирован', color: 'orange'}

    const {icon, text, color } = status

    return <span className={s[color]}>
      <img src={icon} alt=""/>
      {text}
    </span>
  }

  const elements = payments.map((item, i) => {
    const formatList = [
      <span>{dateToDMY(item.date)}</span>,
      <span>{item.body} ₽</span>,
      <span>{item.maintenanceSum} ₽</span>,
      <span>{item.payment} ₽</span>,
      <span>{item.title}</span>,
      renderStatus(item.isGood)
    ]

    return <div key={i + item.date} className={s.payments__group}>
      <ResizeContext.Consumer>
        {({ desktop }) => (
            desktop
                ? formatList.map(sub => sub)
                : formatList.map((sub, i) => {
                  return <div key={i} className={s.payments__line}>
                    <span className={s.gray}>{columns[i]}</span>
                    {formatList[i]}
                  </div>
                })
        )}
      </ResizeContext.Consumer>
    </div>
  })

  if (!payments) return null

  return (
    <div>
      <div className={s.payments}>
        <ResizeContext.Consumer>
          {({ desktop }) => (
              desktop &&
                <div className={s.payments__head}>
                  {columns.map(sub => <span key={Math.random()} className={[s.small, s.gray].join(' ')}>{sub}</span>)}
                </div>
          )}
        </ResizeContext.Consumer>
        {elements}
      </div>
    </div>
  )
}

export default PaymentsScheduleTable
