import React, {useEffect, useState} from "react"

import EditImg from "./../../../img/edit.svg"
import s from "./RepeaterSubject.module.scss"
import {backReq} from "../../../helpers";
import BaseTabs from "../../atoms/BaseTabs";
import BaseInput from "../../atoms/BaseInput";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../helpers";
import BaseButton from "../../atoms/BaseButton";
import repeatStore from "../../../store/repeatStore";
import {errorsParser} from "../../../utils/errorsParser";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {repeaterStepsArray} from "../../../constants/onlineLoan";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import elementsStore from "../../../store/elementsStore";
import {inputEptsMask} from "../../../utils/cardMask";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import RepeaterSumInfo from "../../molecules/RepeaterSumInfo";
import OnlineLoanPhotoArray from "../../molecules/OnlineLoanPhotoArray";
import {
    carPhotosTemplate,
    ptsPhotosTemplate,
    stsPhotosTemplate
} from "../../../utils/templates/photoArrayTemplates";
import {isItemSrcEmpty} from "../../../utils/validators";

const carInfoTemplate = [
    {title: "Марка", value: ""},
    {title: "Модель", value: ""},
    {title: "Год", value: ""},
    {title: "VIN", value: ""},
    {title: "Номер кузова", value: ""},
    {title: "Гос. номер", value: ""},
]

const eptsPhotosTemplate = [
    {type: "e_pts", baseSrc: require(`./../../../img/upload-cloud.svg`), src: null,  title: "* Выписка электронного ПТС"},
]

const RepeaterSubject = () => {
    const [carInfo, setCarInfo] = useState(carInfoTemplate)
    const [carPhotos, setCarPhotos] = useState(carPhotosTemplate)
    const [ptsPhotos, setPtsPhotos] = useState(ptsPhotosTemplate)
    const [eptsPhotos, setEptsPhotos] = useState(eptsPhotosTemplate)
    const [stsPhotos, setStsPhotos] = useState(stsPhotosTemplate)

    const ptsTabs = {usual: "Обычный ПТС", electronic: "Электронный ПТС"}

    const [activeTab, setActiveTab] = useState("usual")
    const [electronicPts, setElectronicPts] = useState('')
    const [electronicPtsLoaded, setElectronicPtsLoaded] = useState(false)

    const [isCarPhotoError, setIsCarPhotoError, carPhotoContainerRef] = useErrorContainer()
    const [isCarPtsError, setIsCarPtsError, carPtsContainerRef] = useErrorContainer()
    const [isCarStsError, setIsCarStsError, carStsContainerRef] = useErrorContainer()
    const [fetchDataErrors] = useRefreshLoanDetail(setSubjectData)

    async function setSubjectData() {
        try {
            if (repeatStore.loanDetail.eptsNumber) {
                setElectronicPts(inputEptsMask(repeatStore.loanDetail.eptsNumber))
                setElectronicPtsLoaded(true)
                setActiveTab("electronic")
            }
            insertCarData(repeatStore.loanDetail)
        } catch (e) {
            console.warn(e)
        }
    }

    useEffect(() => {
        if (fetchDataErrors) elementsStore.showSnackbar(errorsParser(fetchDataErrors));
    }, [fetchDataErrors])


    function insertCarData(data) {
        setCarInfo([
            {title: "Автомобиль", value: data?.offer?.name},
            {title: "VIN", value: data.offer.vin},
            {title: "Номер кузова", value: data.offer.bodyNumber},
            {title: "Гос. номер", value: data.offer.regNum},
        ])
    }

    const sendEpts = async () => {
        try {
            let eptsNumber = electronicPts.replace(/\s/gi, '')
            const stepErrors = await checkCurrentStep(repeaterStepsArray[4])
            if (stepErrors) {
                elementsStore.showSnackbar(errorsParser(stepErrors))
                return false
            }
            const response = await backReq("online_loan:loan_subject", {
                eptsNumber
            })
            if (response.code === 200) {
                setElectronicPtsLoaded(true)
            } else {
                elementsStore.showSnackbar(errorsParser(response.errors))
            }
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e.message))
            console.warn(e)
        }
    }

    function changeEpts() {
        setElectronicPtsLoaded(false)
    }

    async function validate() {
        if (carPhotos.some(isItemSrcEmpty)) {
            setIsCarPhotoError(true)
            elementsStore.showSnackbar('Необходимо загрузить все фотографии автомобиля')
            return false
        }
        if (!((electronicPts && electronicPtsLoaded && !eptsPhotos.some(isItemSrcEmpty)) || !ptsPhotos.some(isItemSrcEmpty))) {
            setIsCarPtsError(true)
            elementsStore.showSnackbar('Необходимо загрузить фотографии ПТС или внести данные для ЭПТС')
            return false
        }
        if (stsPhotos.some(isItemSrcEmpty)) {
            setIsCarStsError(true)
            elementsStore.showSnackbar('Необходимо загрузить фотографии СТС')
            return false
        }
        return (await nextStep())
    }

    async function nextStep() {
        try {
            const stepError = await checkCurrentStep(repeaterStepsArray[4])
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }
            const nextStepErrors = await moveToNextOnlineLoanStep(repeaterStepsArray[5])
            if (nextStepErrors) {
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
        } catch (e) {
            return false
        }
    }

    return (
        <div>
            <ErrorContainer>
                <div className={s.title}>
                    Информация о предмете залога (автомобиле)
                </div>
                <div className={s.infoWrapper}>
                    {carInfo.map((item) =>
                        <RepeaterSumInfo
                            title={item.title}
                            value={item.value}
                            key={item.title + item.value}
                        />
                    )}
                </div>
                <button type="button" className={s.actionBtn}>
                    <img src={EditImg} alt=""/>
                    <span className={s.actionBtn__text}
                          onClick={() => repeatStore.setCurrentStep(0)}>
                        Изменить предмет залога
                    </span>
                </button>
            </ErrorContainer>

            <ErrorContainer innerRef={carPhotoContainerRef} hasError={isCarPhotoError}>
                <div className={s.title}>Фотографии автомобиля</div>
                <div className={s.photoWrapper}>
                    <OnlineLoanPhotoArray photoArray={carPhotos} updateFunction={setCarPhotos}/>
                </div>
            </ErrorContainer>

            <ErrorContainer innerRef={carPtsContainerRef} hasError={isCarPtsError}>
                <div className={s.title}>Фотографии ПТС</div>
                <div className={s.ptsTabs}>
                    <BaseTabs active={activeTab} list={ptsTabs} change={(x) => setActiveTab(x)}/>
                </div>
                { activeTab === "usual" ?
                    <div className={s.photoWrapper}>
                        <OnlineLoanPhotoArray photoArray={ptsPhotos} updateFunction={setPtsPhotos}/>
                    </div>
                    :
                    <div className={s.eptsNumber}>
                        <div className={s.photoWrapper}>
                            <OnlineLoanPhotoArray photoArray={eptsPhotos} updateFunction={setEptsPhotos}/>
                            <div className={s.eptsWrapper}>
                                <BaseInput
                                    defaultValue={electronicPts}
                                    placeholder={"Номер ЭПТС"}
                                    maxlength={17}
                                    type={'epts'}
                                    value={(x) => setElectronicPts(x)}
                                    disabled={electronicPtsLoaded}
                                />
                                <div className={s.buttonWrapper}>
                                    <BaseButton
                                        type={"green"}
                                        size="default-change"
                                        text={electronicPtsLoaded ? "Подтверждено" : "Подтвердить"}
                                        disabled={electronicPtsLoaded || electronicPts.length < 17}
                                        onClick={sendEpts}
                                    />
                                    {electronicPtsLoaded &&
                                        <p onClick={changeEpts}>Изменить</p>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                }
            </ErrorContainer>

            <ErrorContainer innerRef={carStsContainerRef} hasError={isCarStsError}>
                <div className={s.title}>Фотографии CТС</div>
                <div className={s.photoWrapper}>
                    <OnlineLoanPhotoArray photoArray={stsPhotos} updateFunction={setStsPhotos}/>
                </div>
            </ErrorContainer>
            <OnlineLoanButtons callback={validate}/>
        </div>
    )
}

export default RepeaterSubject
