import React, {useEffect } from "react";
import s from "./OnlineLoanRegistration.module.scss";
import {errorsParser} from "../../../utils/errorsParser";
import {observer} from "mobx-react";
import {isMobile} from "../../../utils/isMobile";
import elementsStore from "../../../store/elementsStore";
import repeatStore from "../../../store/repeatStore";
import {backReq} from "../../../helpers";
import {onlineLoanStepsArray, repeaterStepsArray} from "../../../constants/onlineLoan";

const OnlineLoanRegistration = observer(({steps, activityStore = repeatStore, gap }) => {

    // steps =
    // [ {
    //     title: "Параметры онлайн займа",
    //     tab: "Параметры<br/>займа",
    //     body: <RepeaterSettings/>
    // }, ]

    useEffect( () => {
        async function fetchData(){
            centerStep()
            await getLoanId()
            if (isMobile()) {
                const mainTitle = document.getElementsByClassName(s.main__title)
                if (mainTitle[0]){
                    mainTitle[0].style.width = document.documentElement.clientWidth+'px'
                }
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        centerStep()
    },[activityStore.backendCurrentStepNumber])

    useEffect(() => {
        const activeStep = document.getElementsByClassName(s["main__item--active"])
        if (activeStep.length && activeStep.length > activityStore.currentStep  ) activeStep[activityStore.currentStep].scrollIntoView({
            block:"center",
            inline: "center"
        })
    },[activityStore.currentStep])


    const getLoanId = async ()=> {
        const existResponse = await backReq("online_loan:exists")
        if (!(existResponse.data && existResponse.data.onlineLoanOrder)) return
        activityStore.setIsOfferExist(existResponse.data.isOfferExist ?? undefined)
        localStorage.setItem("loanId", existResponse.data?.onlineLoanOrder?.id)
        activityStore.setOnlineLoanOrder(existResponse.data?.onlineLoanOrder)
        if (existResponse.data?.onlineLoanOrder?.currentStep) {
            const stepArray = Object.is(activityStore, repeatStore) ? repeaterStepsArray : onlineLoanStepsArray
            const currentStep = stepArray.findIndex(item => item === existResponse.data.onlineLoanOrder.currentStep)
            if (currentStep !== -1) await activityStore.setCurrentStep(currentStep, true)
        }
    }

    const changeStep = async (event, index) => {
        if (index >= 2) {
            if(activityStore.nextFrom2Step) await activityStore.nextFrom2Step()
            if (activityStore.isCheckLimit) return
        }

        try {
            await activityStore.setCurrentStep(index)
            event.target.scrollIntoView({
                block: "nearest",
                inline: "center"
            })
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e.responseData ?? e.message))
        }
    }
    const centerStep = () =>{
        const activeStep = document.getElementsByClassName(s["main__item--active"])
        if (activeStep.length) activeStep[activeStep.length-1].scrollIntoView({
            block:"center",
            inline: "center"
        })
    }

    const setMargin = (index, array) => {
        if(index === array.length - 1) {
            return `0 0 0 ${gap}px`
        }
        if(index === 0) {
            return `0 ${gap}px 0 0 `
        }
        return `0 ${gap}px`
    }

    const stepNumber = activityStore.currentStep || 0
    const backendCurrentStepNumber = activityStore.backendCurrentStepNumber || 0
    return (
        <div className={s.main}>
            <div className={s.main__title}>{steps[stepNumber].title}</div>
            <div className={s.main__tabs}>
                {steps.map((step, index, array) => (
                    <div
                        style={{
                            margin: setMargin(index, array)
                        }}
                        className={[
                            s.main__item,
                            backendCurrentStepNumber >= index
                                ? s["main__item--active"]
                                : null,
                            stepNumber === index ? s["main__item--current"]
                                : null
                        ].join(" ")}
                        key={index}
                        onClick={(event) => changeStep(event, index)}
                    >
                        <span dangerouslySetInnerHTML={{__html: step.tab}}/>
                    </div>
                ))}
            </div>
            {steps[stepNumber].body}
        </div>
    )
})

export default OnlineLoanRegistration
