import React, { useState } from 'react'
import s from './AutoPayment.module.scss'
import { backReq } from '../../../helpers'
import BaseButton from '../../atoms/BaseButton'
import ModalContainer from '../../atoms/ModalContainer';
import {useHistory} from "react-router-dom";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import BaseCompleted from "../../atoms/BaseCompleted";
import {dateToDMY} from "../../../utils/formattedDate";
import BaseMessage from "../../atoms/BaseMessage";
import ConfirmModal from "../../atoms/ConfirmModal";
import CardItem from "../../organisms/BaseCards/CardList/CardItem";
import {ResizeContext} from "../../../providers/ResizeProvider";

const AutoPayment = ({ cards = [], autoPayment, fetchCardList, extra }) => {
  const withAutoPayment = cards.filter(c=>c.autopayment)
  const isAutoPaymentActive = autoPayment && Object.keys(autoPayment).length > 0
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showConfirmChange, tglShowConfirmChange] = useState(false)
  const [selectedToken, preselectToken] = useState('')
  // const [ showAlertModal, setVisible ] = useState(false)
  const [ showAlertModal, ] = useState(false)
  // const [ alertText, setAlertText ] = useState('')
  const [ alertText ] = useState('')
  const [ selectedCardToken, setSelectedCardToken ] = useState('')
  const toggleShowModal = () => {
    setShowModal(!showModal)
    setSelectedCardToken(cards.length > 0 ? cards[0].token : '')
  }

  const triggerAutoPayment = (token) => {
    setLoading(true)
    backReq('set_auto_payment', { token })
      .then(fetchCardList)
      .finally(() => {
        setLoading(false)
        preselectToken('')
        if(!curAutoPaymentToken && token.length > 0){
          setSelectedCardToken('')
          toggleShowModal()
        }
      })
  }
  const cancelChangeToken = () => {
    tglShowConfirmChange(false)
    preselectToken('')
    setSelectedCardToken('')
  }
  const confirmChangeToken = () => {
    tglShowConfirmChange(false)
    triggerAutoPayment(selectedToken)
  }
  const setSelectedCard = (token) => {
    setSelectedCardToken(token)
  }
  const closeModal = () => {
    setShowModal(false)
  }


  // let btnType = 'danger'
  // let btnTxt = 'Выключен'
  let curAutoPaymentToken = withAutoPayment[0] && withAutoPayment[0].token
  // if(curAutoPaymentToken){
  //   btnType = 'primary'
  //   btnTxt = 'Включен'
  // }
  let preSelectedCard = cards.filter(c=>c.token===selectedToken)[0]
  // let isCards = cards.length
  let display = <>
      <ModalContainer
        title={'Подключить автоплатеж'}
        showModal={ showModal}
        confirmLoading={loading}
        closeModal={() => closeModal()}
      >
        <div className={s.info}>
          <div className={s.info__head}>Выберите карту</div>
          <div className={s.info__list}>
              {
                  showModal && cards.map( x => (
                      <CardItem
                          name={x.name}
                          number={x.number}
                          checked={ selectedCardToken }
                          type={x.type}
                          token={x.token}
                          key={x.token}
                          onSelect={ (x) => setSelectedCard(x)}/>
                  ))
              }
          </div>
          <BaseButton type="green" size="big" onClick={() => triggerAutoPayment(selectedCardToken)} text="Подключить автоплатеж" disabled={ !selectedCardToken }/>
        </div>
      </ModalContainer>

    <ConfirmModal
        title={<span>Использовать карту {preSelectedCard && preSelectedCard.number} для автоплатежей по займам?</span>}
        onOk={ () => confirmChangeToken() }
        onClose = { () => cancelChangeToken() }
        showModal = { showConfirmChange }
    />
    </>;

  // if(!isCards){
  //   display = <BaseMessage
  //       centered={true}
  //     type='warning'
  //   >
  //       Чтобы воспользоваться функцией необходимо сделать платеж по займу с сохранением карты
  //   </BaseMessage>
  // }


  // new //
  const data = autoPayment

  const history = useHistory()

  const [checkbox, setCheckbox] = useState(true)

  const toggle = () => {
    setCheckbox(!checkbox)
  }

  const steps = () => {
    if (isAutoPaymentActive || !extra) return null

    const data = {
      'SMS': 'После включения Автоплатежа за 2 дня до очередной даты платежа Вам придет SMS уведомление о предстоящем платеже по соответствующему займу.',
      'Списание': 'За 1 день до очередной даты платежа произойдет автоматическое списание суммы платежа по соответствующему займу с выбранной банковской карты.',
      'Займы': 'Автоплатеж применяется к платежам по всем займам.',
    }

    return <React.Fragment>
      <hr/>
      {Object.entries(data).map(([key, value], i) => {
        const number = i + 1
        return <article key={key}>
          <img src={require(`./img/steps/${number}.svg`)} alt=""/>
          <div>
            <b><span>{number}.</span> {key}</b>
            <p>{value}</p>
          </div>
        </article>
      })}
      <ul>
        <li>
          Если Ваш Банк (выдавший карту) не поддерживает Автоплатеж и/или в случае нехватки денежный средств на вашей карте (и прочих ошибках) – Вы получите SMS уведомление в момент автоплатежа, в данном случае Вам необходимо внести платеж вручную в Личном кабинете.
        </li>
        <li>
          При включении Автоплатежа рекомендуем дополнительно контролировать остаток денежных средств на Вашей банковской карте и SMS сообщения от Компании «Ваш инвестор».
        </li>
      </ul>

      {
        !(curAutoPaymentToken && autoPayment && autoPayment.data && autoPayment.data.autopaymentDateIso) &&
            <BaseCheckbox value={checkbox} onChange={() => toggle()}>
              Я ознакомился с функцией Автоплатеж и готов ее включить.
            </BaseCheckbox>
      }
    </React.Fragment>

  }

  const classes = [s.payment, isAutoPaymentActive ? s['payment--active'] : extra ? s['payment--extra'] : s['payment--default']]

  return (
    <div className={classes.join(' ')}>
        {showAlertModal &&
            <BaseMessage type='success' shadow={true} centered={true} close={true}>
                {alertText}
            </BaseMessage>
        }
      <p>

        <ResizeContext.Consumer>
          {({ tablet }) => (
              tablet && <span>Статус</span>
          )}
        </ResizeContext.Consumer>
        {
          isAutoPaymentActive
              ? <b><BaseCompleted text="Подключено"/></b>
              : <b>Отключено</b>
        }
      </p>
      <p>
        <span>Карта</span>
        {
          isAutoPaymentActive
              ? <b>{data.name} {data.number}</b>
              : <b>-</b>
        }
      </p>
      <p>
        <span>Дата подключения</span>
        {
          isAutoPaymentActive
              ? <b>{dateToDMY(data.autopaymentDateIso)}</b>
              : <b>-</b>
        }
      </p>
      {steps()}
      <BaseButton
          size="default"
          disabled={!checkbox}
          type={isAutoPaymentActive ? 'border' : 'green' }
          onClick={() =>  !extra ? history.push('/settings') :isAutoPaymentActive ? triggerAutoPayment('') : toggleShowModal()}
          text= { isAutoPaymentActive ? 'Отключить' : 'Подключить' }
      />
      { display }
    </div>
  )
}

export default AutoPayment
