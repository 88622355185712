import React, {useEffect, useRef, useState} from 'react';
import s from './CollapsePayModal.module.scss'
import chevronIcon from '../img/chevron.svg'
import {dateToDMY} from "../../../../utils/formattedDate";
import {isMobile} from "../../../../utils/isMobile";
import {observer} from "mobx-react";
import {formatMoney} from "../../../../helpers";
import appStore from "../../../../store/app";

const nextItemContent = ['mainDebt', 'fines', 'percentDebt', 'rentGps', 'setGps', 'towcar', 'depositList', 'other']
const fields = {
    mainDebt:"Основной долг",
    fines:"Штрафы",
    percentDebt:"Проценты",
    rentGps:"Аренда оборудования",
    setGps:"Установка оборудования",
    towcar:"Эвакуатор",
    depositList:"Реестр залогов",
    other:"Прочее"

}
const CollapsePayModal = observer (({partialPay, setPartialPay, period= {}, isOverpay = false}) => {
    const [show, setShow] = useState(false)
    const [height, setHeight] = useState(0)
    const [mobile, setMobile] = useState(true)

    const wrapperRef = useRef()

    useEffect(() => {
        controlMobile()
        window.addEventListener('resize', controlMobile)
        return () => window.removeEventListener('resize', controlMobile)
    }, [])

    const controlMobile = () => isMobile() ? setMobile(true) : setMobile(false)

    const toggle = () => setShow(!show)

    const reRenderTable = () =>{
        // hack for opened collapse
        // case: open collapse -> change modal to part/full -> lost margins
        if (show){
            setShow(false)
            setTimeout(() => {
                setShow(true)
            },0)
        }
    }

    useEffect(() => {
     reRenderTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[period])

    useEffect(() => {
        setHeight(show ? wrapperRef.current.getBoundingClientRect().height + 'px' : 0)
    }, [show])

    const tableInfo = nextItemContent.filter((item)=>period[item]).map((item, index) => {
        return (<div
            className={[s.collapse__item, s[`collapse__item--${(index + 1) % 2 === 0 ? 'even' : 'odd'}`]].join(' ')}
            key={index}>
            {fields[item]}
            <span>{formatMoney(period[item] ?? 0)} ₽</span>
        </div>)
    })

    const isFullPartPaymentAvailable = appStore.currentEntityForPay.enableFullPartPayment && !partialPay && !isOverpay

    return (
        <div className={[s.collapse, show ? s['collapse--active'] : ''].join(' ')}>
            <div className={s.collapse__head}>
                <div onClick={toggle} className={s.collapse__headText}>
                    {appStore.currentEntityForPay.type === 'part' || appStore.currentEntityForPay.type === 'full'
                        ? <div>Состав платежа по графику при оплате <wbr/> {appStore.currentEntityForPayAmount} ₽ </div>
                        : <div>Состав платежа по графику</div>
                    }
                    <span>При оплате сегодня {dateToDMY(new Date().toISOString())}</span>
                </div>
                <img onClick={toggle} className={s.collapse__icon} src={chevronIcon} alt=""/>
                { !mobile && isFullPartPaymentAvailable  && <button onClick={setPartialPay} className={s.collapse__button}>
                    Частичное или полное&nbsp;погашение
                </button>
                }
            </div>
            <div className={s.collapse__content} style={{height: height}}>
                {show && <div ref={wrapperRef}>
                    {tableInfo}
                    <div className={s.collapse__result}>Итого: <b>{formatMoney(period.sum ?? period.userSum ?? period.minSum ??  period.fullSum ?? 0)} ₽</b></div>
                </div>}
            </div>
            { mobile && isFullPartPaymentAvailable && <button onClick={setPartialPay}
                                              className={[s.collapse__button, s['collapse__button--bottom']].join(' ')}>Частичное
                или полное&nbsp;погашение</button>}
        </div>
    );
});

export default CollapsePayModal;
