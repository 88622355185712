import React, {useEffect, useState} from 'react'
import BaseLink from "../../../components/atoms/BaseLink";
import s from './LastArticles.module.scss'
import placeholderImage from "./img/placeholder.jpg";

import {dateToDMY} from "../../../utils/formattedDate";
import {apiPublicURL} from "../../../config";
import BaseButton from "../../../components/atoms/BaseButton";
import NoData from "../../../components/atoms/NoData";

const Article = ({article}) => {
    const url = article?.image ?? placeholderImage
    return (
        <article key={article?.id}>
            {
                article && <React.Fragment>
                    <img src={url} alt={article.title} onError={(e) => e.target.src = placeholderImage}/>
                    <p>
                        <time className={s.date} dateTime={article.publishedDate}>
                            {dateToDMY(article.publication_date)}
                        </time>
                        <span> {article.title} </span>
                        {/*<span> {takeSymbols(article.shortDescription, 42)} </span>*/}
                        <BaseLink to={`/news/${article.slug}/`} text="Подробнее"/>
                    </p>
                </React.Fragment>
            }
        </article>
    )
}
const LastArticleList = ({full}) => {

    const [articles, setArticles] = useState([])
    const [limit, setLimit] = useState(6)
    const [count, setCount] = useState(6)


    useEffect(() => {
        async function fetchData() {
           await loadData(full ? 6 : 3)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const elements = articles.map(item => {
        return <Article key={item.id} article={item}/>
    })

    const loadData = async (loadLimit) => {
        let response = await (await fetch(`${apiPublicURL}lk_news_list/?limit=${loadLimit}`)).json()
        if (response.results && response.results.length) {
            setArticles(response.results)
            setCount(response.count || 0)
        } else {
            setArticles([])
        }
    }
    const loadMore = async () => {
        const updateLimit = limit + 6
        await setLimit(updateLimit)
        await loadData(updateLimit)
    }

    const Title = `h${full ? 1 : 2}`

    if (!articles.length) return <NoData text={'Нет данных'} />

    return (
        <div className={[s.articles, full ? s['articles--full'] : s['articles--single']].join(' ')}>
            <Title className="title">
                <span>Новости и спецпредложения</span>
                {!full && <BaseLink to="/news" text="Больше"/>}
            </Title>
            <div>
                {elements}
            </div>
            {full && (articles.length < count) ?
                <div className={s.buttonWrapper}>
                    <BaseButton
                        text="Загрузить еще"
                        onClick={loadMore}
                    />
                </div>
                : null
            }

        </div>
    )
}


export default LastArticleList
