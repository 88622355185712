import React from 'react'
import ReactDOM from "react-dom";
import s from './ConfirmModal.module.scss'
import ModalContainer from '../ModalContainer'
import BaseButton from '../BaseButton';

const ConfirmModal = ({onClose, onOk, showModal, title}) => {
  return ReactDOM.createPortal(<ModalContainer
      showModal={showModal}
      closeModal={() => onClose()}
  >
    <div className={s.confirm}>
      <h2 className="title">{ title }</h2>
      <BaseButton
          size="medium"
          text="Да"
          type="green"
          onClick={ () => onOk() }
      />
      <BaseButton
          size="medium"
          text="Нет"
          type="green"
          onClick={ () => onClose() }
      />
    </div>
  </ModalContainer>, document.getElementById('root-confirm'))
}

export default ConfirmModal
