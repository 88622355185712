import React from 'react'
import LastArticleList from "../dashboard/LastArticleList";

const ArticlesPage = () => {

    return <div className="container">
        <LastArticleList full={true}/>
    </div>
}

export default ArticlesPage
