import React, { useEffect, useState } from "react";
import { backReq, camel2under } from "../../../../helpers";
import useErrorContainer from "../../../../hooks/useErrorContainer";
import elementsStore from "../../../../store/elementsStore";
import { errorsParser } from "../../../../utils/errorsParser";
import { isItemSrcEmpty } from "../../../../utils/validators";
import { updateUploadPhotoArray } from "../../../Repeater/helpers";
import BaseButton from "../../../atoms/BaseButton";
import BaseInput from "../../../atoms/BaseInput";
import BaseLoader from "../../../atoms/BaseLoader";
import DadataInput from "../../../atoms/DadataInput";
import PhoneInput from "../../../atoms/PhoneInput";
import ErrorContainer from "../../../molecules/ErrorContainer";
import NativeSelect from "../../../molecules/NativeSelect";
import OnlineLoanPhotoArray from "../../../molecules/OnlineLoanPhotoArray";
import s from "./../OnlineLoanCard.module.scss";

const FIELDS_TITLES = {
	loanPurpose: "Цель получения займа",
	additionalPhone: "Дополнительный телефон",
	orderEmail: "Email",
	cardNumber: "Номер карты",
	passportMain: "Основной разворот паспорта (2-3 страницы)",
	passportRegistration:
		"Страница с текущим штампом вашего места регистрации (адрес прописки)",
	passportAdditional: "Дополнительное фото",
	registration: "Место регистрации",
	registrationFull: "Место регистрации",
	employmentStatus: "Статус трудоустройства",
	employmentPosition: "Должность",
	company: "Наименование компании",
	companyAddress: "Адрес компании",
	companyAddressFull: "Адрес компании",
	income: "Основной доход семьи",
	additionalIncome: "Дополнительный доход",
	additionalIncomeSource: "Источник доп. дохода",
	maritalStatus: "Семейное положение",
	carFront: "Фото авто со стороны капота",
	carBack: "Фото авто со стороны багажника",
	carLeft: "Фото авто с левого бока",
	carRight: "Фото авто с правого бока",
	carSelfie: "Ваше фото на фоне авто, чтобы был виден гос. номер",
	carVin: "Фото ВИН (идентификационной таблички)",
	carOdometer: "Фото одометра",
	ptsFront: "Лицевой разворот ПТС",
	ptsBack: "Внутренний разворот ПТС",
	ePts: "Выписка электронного ПТС",
	stsFront: "Лицевая сторона СТС",
	stsBack: "Оборотная сторона СТС",
	osago: "ОСАГО (страховка)",
	temporaryRegistration: "Временная регистрация",
	apartmentLeaseAgreement: "Договор аренды квартиры",
	employmentHistory: "Трудовая книжка (электронная)",
};

const ModalNeedInfo = ({ onClose, loan }) => {
	const [correctData, setCorrectData] = useState([]);
	const [additionalData, setAdditionalData] = useState({});
	const [correctPhoto, setCorrectPhoto] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);

	const [isInputDataError, setInputDataError, inputDataContainerRef] =
		useErrorContainer();
	const [isPhotoDataError, setIsPhotoDataError, photoDataContainerRef] =
		useErrorContainer();

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			const response = await backReq("online_loan:correcting_map", {
				id: loan.id,
			});
			if (response?.errors || !response?.data) {
				return false;
			}
			return response.data;
		}

		fetchData()
			.then(insertData)
			.then(insertLoadedPhoto)
			.then(() => {
				elementsStore.setPhotoArrayId(loan.id);
				setIsLoading(false);
			})
			.catch((e) => {
				setIsError(true);
				setIsLoading(false);
				console.warn("e", e);
			});
		return () => elementsStore.setPhotoArrayId(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const insertData = async (data) => {
		if (!data) return {};
		const dataArray = [];
		const photoArray = [];
		for (const key in data) {
			if (typeof data[key] === "string") {
				const inputType = await defineInputType(key);
				dataArray.push({
					type: key,
					title: FIELDS_TITLES[key] ?? key,
					error: data[key],
					value: "",
					inputType,
				});
			}
			if (key === "photos" && typeof data[key] === "object") {
				const photoData = data[key];
				for (const photoKey in photoData) {
					photoArray.push({
						type: camel2under(photoKey),
						errorText: photoData[photoKey],
						title: FIELDS_TITLES[photoKey] ?? photoKey,
						src: require("../img/upload-cloud.svg"),
					});
				}
			}
		}
		setCorrectData(dataArray);
		setCorrectPhoto(photoArray);
		return {
			photoArray,
			data,
		};
	};

	let data = undefined;

	const fieldValues = async () => {
		try {
			if (data) return data;
			const response = await backReq("online_loan:field_values");
			data = response.data;
			return data;
		} catch (e) {
			console.warn(e);
		}
	};
	const insertLoadedPhoto = async ({ photoArray = [], data = {} }) => {
		if (!data.existsPhotos) return;
		const normaliseData = [];
		for (const [key, value] of Object.entries(data.existsPhotos)) {
			normaliseData.push({
				type: camel2under(key),
				url: value ?? "",
				isCorrect: true,
			});
		}
		const loadedPhotoArray = await updateUploadPhotoArray(
			normaliseData,
			photoArray,
		);
		setCorrectPhoto(loadedPhotoArray);
	};

	const defineInputType = async (fieldType) => {
		if (fieldType === "loanPurpose" || fieldType === "employmentStatus") {
			const values = await fieldValues();
			setAdditionalData(values);
			return "select";
		}
		if (fieldType === "registration") {
			return "dadata";
		}
		if (fieldType === "additionalPhone") return "phone";
		if (fieldType === "orderEmail" || fieldType === "email") return "email";
		if (fieldType === "income" || fieldType === "additionalIncome")
			return "number";
		if (fieldType === "cardNumber") return "card";
		return "text";
	};

	const updateCorrectData = (value, type) => {
		const localState = JSON.parse(JSON.stringify(correctData));
		const updateItem = localState.find((field) => field.type === type);
		updateItem.value = value;
		if (value && updateItem.inputType === "select") {
			updateItem.error = "";
		}
		if (value && updateItem.inputType === "dadata") {
			updateItem.error = "";
		}
		setCorrectData(localState);
	};
	const removeErrorField = (value, type) => {
		const localState = JSON.parse(JSON.stringify(correctData));
		const updateItem = localState.find((field) => field.type === type);
		if (updateItem.value && updateItem.value !== "+7 (___) ___-__-__") {
			updateItem.error = "";
			setCorrectData(localState);
		}
	};

	const renderInputs = () => {
		return correctData.map((item) => (
			<div key={item.type} className={s.modalInput}>
				{renderInputByType(item)}
				<p className={s.errorText}>{item.error}</p>
			</div>
		));
	};

	const renderInputByType = (inputItem) => {
		switch (inputItem.inputType) {
			case "select":
				return (
					<NativeSelect
						placeholder={inputItem.title}
						defaultValue={inputItem.value}
						options={additionalData[`${inputItem.type}Values`]}
						onChanged={(e) => updateCorrectData(e, inputItem.type)}
						onSearch={null}
						onBlur={(e) => removeErrorField(e, inputItem.type)}
					/>
				);
			case "phone":
				return (
					<PhoneInput
						placeholder={inputItem.title}
						defaultValue={inputItem.value}
						value={(e) => updateCorrectData(e, inputItem.type)}
						onBlur={(e) => removeErrorField(e, inputItem.type)}
					/>
				);
			case "dadata":
				return (
					<DadataInput
						placeholder={inputItem.title}
						defaultValue={inputItem.value}
						value={(e) => updateCorrectData(e, inputItem.type)}
						onBlur={(e) => removeErrorField(e, inputItem.type)}
					/>
				);
			default:
				return (
					<BaseInput
						placeholder={inputItem.title}
						type={inputItem.inputType}
						defaultValue={inputItem.value}
						value={(e) => updateCorrectData(e, inputItem.type)}
						onBlur={(e) => removeErrorField(e, inputItem.type)}
					/>
				);
		}
	};

	const validate = async () => {
		if (correctData.some((item) => !item.value)) {
			setInputDataError(true);
			elementsStore.showSnackbar("Заполните все данные");
			return false;
		}
		if (correctPhoto.some(isItemSrcEmpty)) {
			elementsStore.showSnackbar("Загрузите все фотографии");

			setIsPhotoDataError(true);
			return false;
		}
		await sendCorrectData();
	};

	const sendCorrectData = async () => {
		try {
			const correctObject = {
				id: loan.id,
			};
			correctData.forEach((item) => (correctObject[item.type] = item.value));
			const correctingResponse = await backReq("online_loan:correcting", {
				...correctObject,
			});
			if (correctingResponse?.errors) {
				elementsStore.showSnackbar(errorsParser(correctingResponse?.errors));
				return;
			}
			const sendResponse = await backReq("online_loan:correcting_send", {
				id: loan.id,
			});
			if (sendResponse?.errors) {
				elementsStore.showSnackbar(errorsParser(sendResponse?.errors));
				return;
			}
			elementsStore.showSnackbar(
				"Изменения по заявке приняты. Ожидайте обновления статуса",
				"onCheck",
			);
			onClose();
		} catch (e) {
			elementsStore.showSnackbar(errorsParser(e.message));
		}
	};

	if (isLoading) {
		return (
			<div style={{ position: "relative" }}>
				<BaseLoader />
			</div>
		);
	}
	if (isError) {
		return (
			<div style={{ position: "relative" }}>
				Ошибка при получении данных, попробуйте позже{" "}
			</div>
		);
	}

	return (
		<div className={s.loanModal}>
			{correctData.length ? (
				<ErrorContainer
					innerRef={inputDataContainerRef}
					hasError={isInputDataError}
				>
					<div className={s.title}>Введите данные</div>
					<div>{renderInputs()}</div>
				</ErrorContainer>
			) : null}
			{correctPhoto.length ? (
				<ErrorContainer
					innerRef={photoDataContainerRef}
					hasError={isPhotoDataError}
				>
					<div className={s.title}>Загрузите фото</div>
					<div className={s.photosWrapper}>
						<OnlineLoanPhotoArray
							photoArray={correctPhoto}
							updateFunction={setCorrectPhoto}
						/>
					</div>
				</ErrorContainer>
			) : null}
			<div style={{ marginTop: "24px" }}>
				<BaseButton text={"Подтвердить"} onClick={validate} />
			</div>
		</div>
	);
};
export default ModalNeedInfo;
