import React, {useState} from 'react'
import BaseInput from "../../../atoms/BaseInput";
import BaseButton from "../../../atoms/BaseButton";
import elementsStore from "../../../../store/elementsStore";
import s from ".././RepeaterModeration.module.scss";
import {backReq} from "../../../../helpers";
import {observer} from "mobx-react";

const CodesTab = observer(() => {
    const [isRequestSend, setIsRequestSend] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [value, setValue] = useState('')
    const loanId = elementsStore.moderatedId

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(value.trim())
            elementsStore.showSnackbar('Скопировано', 'info')
        } catch (e) {
            elementsStore.showSnackbar('Ошибка', 'error')
        }
    }

    const sendRequest = async () => {
        try {
            setIsLoading(true)
            const response = await backReq('online_manager:get_code', {
                "id": loanId,
            })
            const value = response?.data?.code ?? ''
            setValue(value)
            await setIsRequestSend(true)
        } catch (e) {
            await setIsRequestSend(false)
        } finally {
            setIsLoading(false)
        }
    }

    return <div className={s.codeForm}>
        <p>Код для подтверждения SMS</p>
        <div className={s.codeForm__wrapper}>
            <BaseInput
                disabled={!isRequestSend}
                defaultValue={value}
                value={e => setValue(e)}
            />
            {isRequestSend ?
                <BaseButton
                    text={'Копировать'}
                    onClick={copyToClipboard}
                />
                :
                <BaseButton
                    text={'Запросить код'}
                    loading={isLoading}
                    onClick={sendRequest}
                />
            }
        </div>
    </div>
})
export default CodesTab
