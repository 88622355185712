import React, {useEffect, useState} from "react"

import BaseInput from "components/atoms/BaseInput"

import s from "./RepeaterPersonal.module.scss"
import {backReq} from "../../../helpers";
import LockedInput from "../../atoms/BaseInput/LockedInput";
import PhoneInput from "../../atoms/PhoneInput";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../helpers";
import appStore from "../../../store/app";
import {errorsParser} from "../../../utils/errorsParser";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {renderYYMMDDToDDMMYY} from "../../../utils/formattedDate";
import repeatStore from "../../../store/repeatStore";
import {observer} from "mobx-react";
import {repeaterStepsArray} from "../../../constants/onlineLoan";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import {validateEmail} from "../../../utils/validators";
import elementsStore from "../../../store/elementsStore";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import NativeSelect from "../../molecules/NativeSelect";
import {maritalStatusTemplate} from "../../../utils/templates/martialStatusTemplate";
import DadataInput from "../../atoms/DadataInput";
const RepeaterPersonal = observer (() => {
  const [fio, setFio] = useState("")
  const [prevFio, setPrevFio] = useState("")
  const [birthday, setBirthday] = useState("")
  const [citizenship, setCitizenship] = useState("")
  const [phone, setPhone] = useState("")
  const [additionalPhone, setAdditionalPhone] = useState("")
  const [orderEmail, setOrderEmail] = useState("")

  const [passSeries, setPassSeries] = useState("")
  const [passNumber, setPassNumber] = useState("")
  const [passIss, setPassIss] = useState("")
  const [passAuthority, setPassAuthority] = useState("")
  const [passCodeDepartment, setPassCodeDepartment] = useState("")
  const [passPlaceRegistration, setPassPlaceRegistration] = useState("")

  const [employmentStatusArray, setEmploymentStatusArray] = useState([])
  const [employmentStatus, setEmploymentStatus] = useState("")
  const [maritalStatus, setMaritalStatus] = useState("")
  const [additionalIncomeSource, setAdditionalIncomeSource] = useState("")
  const [workForm, setWorkForm] = useState({
    position: "",
    companyName: "",
    baseIncome: "",
    additionalIncome: ""
  })

  const [isPersonalDataError, setIsPersonalDataError, personalDataContainerRef ] = useErrorContainer()
  const [isPassportDataError, setIsPassportDataError, passportDataContainerRef ] = useErrorContainer()
  const [isIncomeDataError, setIsIncomeDataError, incomeDataContainerRef ] = useErrorContainer()

  const [fetchDataErrors] = useRefreshLoanDetail(setData)
  async function setData() {
    try {
      if (!repeatStore.loanDetail) return;
      setFio(repeatStore.loanDetail.offer.fio)
      setPrevFio(repeatStore.loanDetail.offer.prevFio)
      setBirthday(renderYYMMDDToDDMMYY(repeatStore.loanDetail?.offer?.birthDate))
      setCitizenship(repeatStore.loanDetail.offer.citizenship)
      setPhone(repeatStore.loanDetail.offer.phone)
      setAdditionalPhone(repeatStore.loanDetail.additionalPhone)
      setOrderEmail((repeatStore.loanDetail.orderEmail ?? appStore?.profile?.email) || '' )
      setPassSeries(repeatStore.loanDetail.offer.passportSeries)
      setPassNumber(repeatStore.loanDetail.offer.passportNumber)
      setPassCodeDepartment(repeatStore.loanDetail.offer.divisionCode)
      setPassIss(renderYYMMDDToDDMMYY(repeatStore.loanDetail.offer.passportDate))
      setPassAuthority(repeatStore.loanDetail.offer.passportIssuedBy)
      setPassPlaceRegistration(repeatStore.loanDetail.registration)
      setEmploymentStatus(repeatStore.loanDetail.employmentStatus)
      setWorkForm({
        position: repeatStore.loanDetail.employmentPosition || '',
        companyName: repeatStore.loanDetail.company || '',
        baseIncome: repeatStore.loanDetail.income || '',
        additionalIncome: repeatStore.loanDetail.additionalIncome || ""
      })
      setAdditionalIncomeSource(repeatStore.loanDetail.additionalIncomeSource)
      setMaritalStatus(maritalStatusTemplate.find(item => item.value === repeatStore.loanDetail.maritalStatus))
      const selectInfo = await backReq("online_loan:field_values")
      const selectInfoData = selectInfo.data
      setEmploymentStatusArray(selectInfoData.employmentStatusValues)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (fetchDataErrors) elementsStore.showSnackbar(errorsParser(fetchDataErrors));
  },[fetchDataErrors])

  async function validate(){
    const message = 'Заполните все поля'
    if (!orderEmail ) {
      setIsPersonalDataError(true)
      elementsStore.showSnackbar('Заполните поле "Email"', 'error', 5000)
      return false
    }
    if (!validateEmail(orderEmail)){
      setIsPersonalDataError(true)
      elementsStore.showSnackbar('Необходимо указать email в верном формате')
      return false
    }
    if (!passPlaceRegistration.trim()) {
      setIsPassportDataError(true)
      elementsStore.showSnackbar('Необходимо указать место регистрации')
      return false
    }
    if (!workForm || !maritalStatus ||
      !workForm.position.trim() || !workForm.companyName.trim() ||
      !workForm.baseIncome) {
      setIsIncomeDataError(true)
      elementsStore.showSnackbar(message)
      return false
    }
    return (await nextStep())
  }

  async function nextStep() {
    try {
      const stepError = await checkCurrentStep(repeaterStepsArray[3])
      if (stepError) {
        elementsStore.showSnackbar(errorsParser(stepError))
        return false
      }
      let response = await backReq("online_loan:personal_data", {
        additionalPhone: additionalPhone ? "+" + additionalPhone.replace(/\D/gi, "") : "",
        orderEmail: orderEmail,
        registration: passPlaceRegistration,
        employmentStatus: employmentStatus,
        employmentPosition: workForm.position,
        company: workForm.companyName,
        income: workForm.baseIncome,
        additionalIncome: workForm?.additionalIncome?.length ? workForm.additionalIncome : undefined,
        additionalIncomeSource: additionalIncomeSource,
        maritalStatus: maritalStatus.value
      })
      if (response?.errors) {
        elementsStore.showSnackbar(errorsParser(response.errors))
        return false
      }
      const nextStepErrors = await moveToNextOnlineLoanStep(repeaterStepsArray[4])
      if (nextStepErrors){
        elementsStore.showSnackbar(errorsParser(nextStepErrors))
      }
    } catch (e) {
      console.warn('err', e)
        return false
    }
  }

  return (
    <div className={s.personal}>
      <div className={s.head}>
        Проверьте ваши персональные (анкетные) данные:
      </div>
      <ErrorContainer innerRef={personalDataContainerRef} hasError={isPersonalDataError}>
        <div className={s.title}>Основные данные</div>
        <div className={[s.inputsGroup, s.inputsGroup__main, s.inputsGroup__common].join(" ")}>
          <div style={{gridArea: "fio"}}>
            <LockedInput
              placeholder="ФИО"
              value={fio}
              disabled
              style={{gridArea: "fio"}}
            />
          </div>
          <div style={{gridArea: "prevFio"}}>
            <LockedInput
              placeholder="Прежнее ФИО"
              value={prevFio}
              disabled
              style={{gridArea: "prevFio"}}
            />
          </div>
          <div style={{gridArea: "birthday"}}>
            <LockedInput
              placeholder="Дата рождения"
              value={birthday}
              disabled
              style={{gridArea: "birthday"}}
            />
          </div>
          <div style={{gridArea: "citizenship"}}>
            <LockedInput
              placeholder="Гражданство"
              value={citizenship}
              disabled
              style={{gridArea: "citizenship"}}
            />
          </div>
          <div style={{gridArea: "phone"}}>
            <LockedInput
              placeholder="Мобильный телефон"
              value={phone}
              disabled
              style={{gridArea: "phone"}}
            />
          </div>
          <div style={{gridArea: "additionalPhone"}}>
            <PhoneInput
              placeholder="Дополнительный телефон"
              defaultValue={additionalPhone}
              value={e => setAdditionalPhone(e)}
              style={{gridArea: "additionalPhone"}}
            />
          </div>
          <div style={{gridArea: "email"}}>
            <BaseInput
              placeholder="Email*"
              defaultValue={orderEmail}
              type={"email"}
              value={v => setOrderEmail(v)}
              style={{gridArea: "email"}}
            />
          </div>
        </div>
      </ErrorContainer>
      <ErrorContainer innerRef={passportDataContainerRef} hasError={isPassportDataError}>
        <div className={s.title}>Паспортные данные</div>
        <div className={[s.inputsGroup, s.inputsGroup__main, s.inputsGroup__passportData].join(" ")}>
          <div style={{gridArea: "passSeries"}}>
          <LockedInput
            placeholder="Серия"
            value={passSeries}
            disabled
          />
          </div>
          <div style={{gridArea: "passNumber"}}>
          <LockedInput
            placeholder="Номер"
            value={passNumber}
            disabled
          />
          </div>
          <div style={{gridArea: "passIss"}}>
          <LockedInput
            placeholder="Дата выдачи"
            value={passIss}
            disabled
          />
          </div>
          <div style={{gridArea: "passAuthority"}}>
          <LockedInput
            placeholder="Кем выдан"
            value={passAuthority}
            disabled
          />
          </div>
          <div style={{gridArea: "passCodeDepartment"}}>
          <LockedInput
            placeholder="Код подразделения"
            value={passCodeDepartment}
            disabled
          />
          </div>
          <div style={{gridArea: "passPlaceRegistration"}}>
          <DadataInput
              placeholder="Место регистрации*"
              defaultValue={passPlaceRegistration}
              value={ x => setPassPlaceRegistration(x)}
          />
          </div>
        </div>
      </ErrorContainer>
      <ErrorContainer innerRef={incomeDataContainerRef} hasError={isIncomeDataError}>
        <div className={s.title}>Сведение о работе и доходах</div>
        <div className={[s.inputsGroup, s.inputsGroup__main,s.inputsGroup__statusData ].join(" ")}>
          <div style={{gridArea: "employmentStatus"}}>
              <NativeSelect
                  placeholder="Статус трудоустройства*"
                  defaultValue={employmentStatus}
                  options={employmentStatusArray}
                  onChanged={(x) => setEmploymentStatus(x)}
                  onSearch={null}
              />
          </div>
          <div style={{gridArea: "position"}}>
          <BaseInput
            placeholder="Должность*"
            type={"text"}
            defaultValue={workForm.position}
            value={(x) => {
              setWorkForm({...workForm, position: x})
            }}
          />
          </div>
          <div style={{gridArea: "companyName"}}>
          <BaseInput
            placeholder="Наименование компании*"
            defaultValue={workForm.companyName}
            value={(x) => {
              setWorkForm({...workForm, companyName: x})
            }}
          />
          </div>
          <div style={{gridArea: "baseIncome"}}>
          <BaseInput
            placeholder="Основной доход семьи, ₽*"
            defaultValue={workForm.baseIncome}
            type={"number"}
            value={(x) => {
              setWorkForm({...workForm, baseIncome: x})
            }}
          />
          </div>
          <div style={{gridArea: "additionalIncome"}}>
          <BaseInput
            placeholder="Дополнительный доход, ₽"
            defaultValue={workForm.additionalIncome}
            type={"number"}
            value={(x) => setWorkForm({...workForm, additionalIncome: x})}
          />
          </div>
          <div style={{gridArea: "additionalIncomeSource"}}>
          <BaseInput
            placeholder="Источник доп. дохода"
            defaultValue={additionalIncomeSource || ""}
            value={(x) => setAdditionalIncomeSource(x)}
          />
          </div>
          <div style={{gridArea: "maritalStatus"}}>
              <NativeSelect
                  placeholder="Семейное положение*"
                  options={maritalStatusTemplate}
                  defaultValue={maritalStatus?.label || ""}
                  withLabel={true}
                  onSearch={null}
                  onChanged={(x) => setMaritalStatus(x)}
              />
          </div>
        </div>
      </ErrorContainer>
      <OnlineLoanButtons callback={validate}/>
    </div>
  )
})

export default RepeaterPersonal
