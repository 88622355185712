import React, {Component} from 'react'

import s from './BaseCards.module.scss'
import CardList from "./CardList";

export default class BaseCards extends Component{
    render() {
        const {cards, autoPayment, fetchData, updateCardName, deleteCard} = this.props

        return <div className={s.cards}>
            <h1 className="title">Карты</h1>
            <h2 className="title">Ваши карты</h2>
            <CardList
                fetchCardList={fetchData}
                cards={cards}
                autoPayment={autoPayment}
                onUpdatedName={updateCardName}
                onDeleted={deleteCard}
            />
        </div>
    }
}
