import React from "react";

const Star = ({filled}) => {
  let html
  filled ?
    html = (
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.9267 10.2018C27.7424 9.63126 27.2395 9.22736 26.6469 9.1734L18.5641 8.43501L15.3698 0.909709C15.1339 0.356833 14.5973 0 14 0C13.4027 0 12.8659 0.356833 12.6315 0.909709L9.43717 8.43501L1.35313 9.1734C0.760526 9.22843 0.258718 9.63234 0.0732908 10.2018C-0.111069 10.7723 0.0591914 11.398 0.507379 11.7935L6.61731 17.1845L4.81579 25.1685C4.68399 25.7556 4.91043 26.3626 5.39451 26.7147C5.65471 26.905 5.9604 27 6.26717 27C6.53079 27 6.79462 26.9295 7.03024 26.7876L14 22.5942L20.9685 26.7876C21.4797 27.095 22.1225 27.0669 22.6055 26.7147C23.0896 26.3626 23.316 25.7556 23.1842 25.1685L21.3827 17.1845L27.4927 11.7935C27.9406 11.398 28.1111 10.7736 27.9267 10.2018Z"
          fill="#4CAF50"/>
      </svg>)

    : html = (
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1907 10.3179L14.0007 2.80283L10.8107 10.3179L2.6977 11.0589L8.82908 16.4688L7.02697 24.4555L14.0001 20.26L20.9732 24.4563L19.171 16.4688L25.3024 11.0589L17.1907 10.3179ZM20.9685 26.7876L14 22.5942L7.03024 26.7876C6.79462 26.9295 6.53079 27 6.26717 27C5.9604 27 5.65471 26.905 5.39451 26.7147C4.91043 26.3626 4.68399 25.7556 4.81579 25.1685L6.61731 17.1845L0.507379 11.7935C0.0591914 11.398 -0.111069 10.7723 0.0732908 10.2018C0.258718 9.63234 0.760526 9.22843 1.35313 9.1734L9.43717 8.43501L12.6315 0.909709C12.8659 0.356833 13.4027 0 14 0C14.5973 0 15.1339 0.356833 15.3698 0.909709L18.5641 8.43501L26.6469 9.1734C27.2395 9.22736 27.7424 9.63126 27.9267 10.2018C28.1111 10.7736 27.9406 11.398 27.4927 11.7935L21.3827 17.1845L23.1842 25.1685C23.316 25.7556 23.0896 26.3626 22.6055 26.7147C22.1225 27.0669 21.4797 27.095 20.9685 26.7876Z" fill="#878B90"/>
      </svg>
    )
  return html
}
export default Star
