import React from 'react'

import BaseButton from "../../atoms/BaseButton";

const LoadMoreButton = ({ dataLength, onLoadMore, loadingMore, total }) => {
  if (dataLength >= total) return null

  const handleClick = (e) => {
    e.stopPropagation();
    onLoadMore()
  }

  return <BaseButton
      text="Показать еще"
      type="shadow"
      size="default"
      disabled={loadingMore}
      onClick={(e) => handleClick(e)}
      loading={ loadingMore }
  />
}

export default LoadMoreButton
