import React from "react";
import s from "./OnlineLoanSubject.module.scss";
import BaseInput from "../../atoms/BaseInput";

const EptsForm = ({eptsForm, setEptsForm}) => {

    const {eptsNumber, eptsDate, eptsIssued} = eptsForm

    return <div className={s.inputsGroup}>
        <div>
            <BaseInput
                placeholder="Номер ЭПТС *"
                defaultValue={eptsNumber}
                maxlength={15}
                value={v => setEptsForm({...eptsForm, eptsNumber: v})}
            />
        </div>
         <div>
            <BaseInput
                type={'date'}
                placeholder="Дата формирования выписки *"
                defaultValue={eptsDate}
                value={v => setEptsForm({...eptsForm, eptsDate: v})}
            />
        </div>
         <div>
            <BaseInput
                placeholder="Кем оформлен *"
                defaultValue={eptsIssued}
                value={v => setEptsForm({...eptsForm, eptsIssued: v})}
            />
        </div>
    </div>
}
export default EptsForm
