import React from 'react'

import {ReactComponent as IconArrow} from '../../../img/arrow.svg';

import s from './BaseLink.module.scss'
import {Link} from "react-router-dom";

export default class BaseLink extends React.Component {
  render() {
    return (
        <Link to={this.props.to} className={s.link}>
          {this.props.text}
          <IconArrow/>
        </Link>
    )
  }
}
