import React, {useEffect, useState} from "react";

import ContentContainer from "../../molecules/ContentContainer";
import ModalContainer from "../../atoms/ModalContainer";
import RepeaterMain from "../RepeaterMain";
import BaseButton from "../../atoms/BaseButton";
import {ReactComponent as Step1} from "./../../../img/steps/1.svg";
import {ReactComponent as Step2} from "./../../../img/steps/2.svg";
import {ReactComponent as Step3} from "./../../../img/steps/3.svg";

import s from "./RepeaterSection.module.scss";
import {backReq} from "../../../helpers";
import {repeaterExistsHandler} from "../helpers";
import repeatStore from "../../../store/repeatStore";
import {observer} from "mobx-react";
import {isPromoExist, ONLINE_LOAN_PROMO} from "../../../utils/onlineLoanPromo";
import {loanStatuses} from "../../../constants/onlineLoan";
import {useHistory} from "react-router-dom";
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";

const steps = [
  {
    image: <Step1/>,
    title: "Заполните заявку",
    lead: "Дополнительный текст если нужна краткая детализация"
  },
  {
    image: <Step2/>,
    title: "Сфотографируйте себя, авто и документы",
    lead: "Дополнительный текст если нужна краткая детализация и какие&nbsp;-&nbsp;нибудь подробности"
  },
  {
    image: <Step3/>,
    title: "Получите деньги на карту",
    lead: "Дополнительный текст если нужна краткая детализация"
  }
]

const list = [
  "Оформление <span>онлайн</span>",
  "Сумма займа <br><span>до 1&nbsp;000&nbsp;000 рублей</span>",
  `Процентная <span>ставка 7%</span><span class=${s.minor}>(только для займа онлайн)</span>`,
  "Перевод денег на вашу банковскую карту",
]

const RepeaterSection = observer(() => {
  const [popup, setPopup] = useState(false)
  const [isExist, setIsExist] = useState(false)

  let history = useHistory();

  const fetchData = async () => {
    try {
      if (isPromoExist(ONLINE_LOAN_PROMO)) {
            await onStartOnlineLoan()
            sessionStorage.removeItem(ONLINE_LOAN_PROMO)
      }
      const response = await backReq('online_loan:exists')
      const isValidStatuses = validateStatuses(response.data)
      if (isValidStatuses) {
        setIsExist(response.data.isOfferExist)
        await repeaterExistsHandler(response.data)
        await repeatStore.refreshLoanDetail()
      } else {
        if (isPromoExist(ONLINE_LOAN_PROMO)) sessionStorage.removeItem(ONLINE_LOAN_PROMO);
        history.push('/')
      }
    } catch (e) {
      console.warn('error', e)
    }
  }

  const validateStatuses = (data) => {
    if (!data) return false
    if (!data.isOfferExist) return false
    if (!data.onlineLoanOrder?.id) return true
    const validStatuses = [loanStatuses.inProcess, loanStatuses.decreaseAmount, loanStatuses.needInfo]
    return validStatuses.some(status => status === data.onlineLoanOrder?.status)
  }

  useEffect(() => {
    fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonText = repeatStore.onlineLoanOrder?.id ?
    repeatStore.onlineLoanOrder?.status === loanStatuses.inProcess
      ? "Продолжить оформление займа"
      : "Узнать статус заявки"
      : "Перейти к оформлению займа"

  const onClickFunction = async () => {
    if (repeatStore.onlineLoanOrder?.status && repeatStore.onlineLoanOrder.status !== loanStatuses.inProcess) {
      return history.push('loans-request');
    }
    if (repeatStore.onlineLoanOrder?.id) {
      return setPopup(true)
    } else {
      return await onStartOnlineLoan()
    }
  }
  const onStartOnlineLoan = async () => {
    try {
      if (!repeatStore.onlineLoanOrder?.id) {
        await backReq("online_loan:create")
        await repeatStore.refreshOfferExist()
        await repeatStore.refreshLoanDetail()
        yandexMetrikaReachGoal('start_oformlenie')
      }
      setPopup(true)
    } catch (e) {
      console.warn('error', e)
    }
  }

  return <section className={s.section}>
    {isExist ?
      <>
        <h1 className="title">Займ онлайн</h1>
        <div className={s.section__main}>
          <div className={s.section__line}>
            <h2 className="title">Получить займ просто как "Раз, два, три"</h2>
            <ContentContainer>
              {
                steps.map((step, index) => {
                  return <article key={index}>
                    {step.image}
                    <p>
                      <b dangerouslySetInnerHTML={{__html: step.title}}/>
                      {/*<span dangerouslySetInnerHTML={{__html: step.lead}}/>*/}
                    </p>
                  </article>
                })
              }
              <BaseButton
                text={buttonText}
                type="green"
                size="default-change"
                onClick={onClickFunction}
              />
            </ContentContainer>
          </div>
          <div className={s.section__line}>
            <h2 className="title">Одобренные условия</h2>
            <ul> {list.map((item, index) => <li key={index} dangerouslySetInnerHTML={{__html: item}}/>)}</ul>
          </div>
        </div>

        <ModalContainer
          showModal={popup}
          closeModal={() => setPopup(false)}
          size="large"
          closeOnBlur={false}
        >
          <RepeaterMain/>
        </ModalContainer>
      </>
      : null
    }
  </section>
})

export default RepeaterSection
