import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import appStore from '../../store/app'

import { getPlainPhone, backReq } from '../../helpers'

import RecoverModal from './RecoverModal/RecoverModal'
import FAQ from './FAQ'
import BaseInput from '../atoms/BaseInput';
import BaseButton from '../atoms/BaseButton';
import ModalContainer from '../atoms/ModalContainer';
import PhoneInput from '../atoms/PhoneInput'
import BaseLogo from "../atoms/BaseLogo";
import BaseMessage from "../atoms/BaseMessage";
import BaseHoneypot from "../atoms/BaseHoneypot";

import PaymentsIcon from './img/vmm.png'
import HelloIcon from './img/hello.svg'

import s from './PageLogin.module.scss'
import {links} from "../Footer/links";
import {addPromoInfo, ONLINE_LOAN_PROMO} from "../../utils/onlineLoanPromo";
import {VI_OFFICE_PHONE, VI_OFFICE_PHONE_URL} from "../../constants/phones";
import RegistrationModal from "./RegistrationModal/RegistrationModal";
import elementsStore from "../../store/elementsStore";
import {isMobile} from "../../utils/isMobile";
import PolicyAgreement from "../atoms/PolicyAgreement";
import {yandexMetrikaReachGoal} from "../../utils/yandex-metrika";

@observer
class PageLogin extends Component {
  state = {
    phone: '',
    password: '',
    phoneRecover: '', recoverResult: null,
    termsModalVisible: false,
    modalPhone: '',
    checked: false,
    sent: false,
    invalidAttempt: false,
    recoverModalVisible: false,
    registrationModalVisible: false,
    faqModalVisible: false,
    lkNotWorking: true,
    lkNotWorkingMessage: null,
    showAlert: true,
    installEvent: null,
    showInstallBanner: false,
    rawPhone: '',
    recoverModalStep: 1,
    registrationModalStep: 1, registrationResult: null,
    loading: false,
    checkConfirmCodeRegistration: null,
  }

  async componentDidMount() {
    const { loginStatus, logoutForRecoverPassword } = appStore
    if (window.location.search){
      const params = new URL(window.location.href)
      const token = params.searchParams.get('token')
      const action = params.searchParams.get('action')
      if (token && !action){
        addPromoInfo(ONLINE_LOAN_PROMO, 'repeater')
        await this.tokenAuth(token)
      }
      if(action && token) {
        this.state.recoverModalStep = 3
        this.showRecoverModal()
      }
    }
    if (logoutForRecoverPassword) {
      this.showRecoverModal()
    }
    if (loginStatus === 'tokenOutdate') {
      this.setState(() => {
        return {
          lkNotWorking: true,
          lkNotWorkingMessage: 'Отсутствует активность в Личном кабинете, в целях безопасности Ваш сеанс завершен.'
        }
      })
    }
    document.addEventListener("keydown", this.handleKeyPress, false);
    window.addEventListener('beforeinstallprompt', e => {
      this.setState({
        installEvent: e,
        showInstallBanner: true
      })
    })
  }

  componentWillUnmount () {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }
  installApp = () => {
    this.state.installEvent.prompt()
    this.setState({
      showInstallBanner: false
    })
    this.state.installEvent.userChoice.then(() => {
        this.setState({
          installEvent: null
        })
    })
  }
  changeInput = (name, value) => {
    if (name === 'phone') {
      const phone = getPlainPhone(value, { format: 'withPlus' })
      this.setState(_ => ({ phone }))
      this.setState({
        rawPhone: value
      })
    } else if (name === 'phoneRecover') {
      const phoneRecover = getPlainPhone(value, { format: 'withPlus' })
      this.setState(_ => ({ phoneRecover, phone: phoneRecover }))
    } else {
      this.setState(_ => ({ [name]: value }))
    }
  }

  hideTooltip = _ => this.setState(_ => ({ invalidAttempt: false }))

  login = (login=this.state.phone, password=this.state.password) => {
    document.body.style.position = ""
    const { isShutdown } = this.props
    if(isShutdown) return null


    backReq('login', { login, password:  password.trim() })
      .then(res => {
        if (res) {
          if (res.status === 'success') {
            appStore.login(res.data)
            this.props.triggerAuth()
             this.updateServiceWorker()
          }
          if (res.status === 'error') {
            this.setState({ invalidAttempt: true })
          }
        }
      })
  }

   updateServiceWorker = _ => {
       if (window.viws){
           window.viws.update()
       }
   }

   tokenAuth = async (token) => {
    const { isShutdown } = this.props
    if (isShutdown) return null

   await backReq('onetime_auth:token_auth', { token })
      .then(res => {
        if (res) {
          if (res.status === 'success') {
            appStore.login(res.data)
            this.props.triggerAuth()
          }

          if (res.status === 'error') {
            this.setState({ invalidAttempt: true })
          }
        }
      })
  }

  loginByEnter = ({ which }) => {
    if (which === 13 && this.state.phone && this.state.password)
      this.login()
  }

  // Видимость модалки персональных данных
  hideCallbackModal = e => {
    const classes = e.target.classList

    if (classes.contains('Modal_modal') || classes.contains('Modal_btnClose')) {
      this.setState(_ => ({ termsModalVisible: false }))
      this.props.hideCallbackModal()
    }
  }

  showTermsModal = _ => this.setState(_ => ({ termsModalVisible: true }))
  hideTermsModal = _ => this.setState(_ => ({ termsModalVisible: false }))


  // Методы для полей формы модалки обратного звонка
  changePhone = phone => this.setState(_ => ({ modalPhone: phone }))
  toggleCheckbox = _ => this.setState(state => ({ checked: !state.checked }))
  requestCallback = _ => this.setState(_ => ({ sent: true }))


  // Методы для восстановления пароля
  showRecoverModal = _ => {
    if(isMobile()) {
      document.body.style.position = "fixed"
    }
    this.hideTooltip()
    this.setState(state => ({
      recoverModalVisible: true,
      phoneRecover: state.phone
    }))
  }

  hideRecoverModal = _ => {
    document.body.style.position = ""
    this.setState(_ => ({ recoverModalVisible: false, recoverResult: {text: ''} }))
    if(this.state.recoverModalStep === 3) {
      elementsStore.smsInputRestoreDefault()
      this.setState(_ => ({ recoverModalStep: 1 }))
    }
  }

  showRegistrationModal = _ => {
    if(isMobile()) {
      document.body.style.position = "fixed"
    }
    this.hideTooltip()
    this.setState(() => ({
      registrationModalVisible: true
    }))
  }

  hideRegistrationModal = _ => {
    document.body.style.position = ""
    this.setState(_ => ({ registrationModalVisible: false, registrationResult: {text: ''} }))
    if(this.state.registrationModalStep === 3) {
      elementsStore.smsInputRestoreDefault()
      this.setState(_ => ({ registrationModalStep: 1 }))
    }
  }

  nextStepRecoverModal = () => {
    this.setState((state) => ({recoverModalStep: state.recoverModalStep + 1}))
  }

  prevStepRecoverModal = () => {
    this.setState((state) => ({recoverModalStep: state.recoverModalStep - 1}))
    elementsStore.smsInputRestoreDefault()
  }

  nextStepRegistrationModal = () => {
    this.setState((state) => ({registrationModalStep: state.registrationModalStep + 1}))
  }

  prevStepRegistrationModal = () => {
    this.setState((state) => ({registrationModalStep: state.registrationModalStep - 1}))
  }

  registration = (method, {phone, code, permToProcess, permToNews, newPassword}) => {
    this.setState({
      loading: true
    })
    if(method === 'send_phone_code') {
      this.setState({
        isSendConfirmCode: true
      })
      backReq(method, { phone, forCreateClient: true })
        .then(res => {this.setState(_ => ({ loading: false, isSendConfirmCode: false }))
          if (res.status === 'success') {
              elementsStore.startSmsCountdown(res.data.seconds)
            this.setState(_ => ({
              registrationResult: {
                result: 'success'
              }
            }))
            this.state.registrationModalStep === 1 && this.nextStepRegistrationModal()
          }
          if (res.status === 'error') {
            if(this.state.registrationModalStep === 1) {
              this.setState(_ => ({
                registrationResult: {
                  result: 'error'
                }
              }))
            } else {
              elementsStore.setSmsCodeError(res.errors.detail)
            }
          }
        })
    }
    if(method === 'check_phone_code') {
      elementsStore.setCheckingSmsCode(true)
      backReq(method, { phone, code })
        .then(res => {this.setState(_ => ({ loading: false, isSendConfirmCode: false }))
          if (res.status === 'success') {
            elementsStore.setCheckingSmsCode(false)
            elementsStore.setSmsCodeConfirmed(true)
            elementsStore.setSmsCodeError('')
            this.setState(_ => ({registrationResult: {result: 'success'}}))
            setTimeout(() => {this.nextStepRegistrationModal()}, 500)
          }
          if (res.status === 'error') {
            elementsStore.setCheckingSmsCode(false)
            elementsStore.setSmsCodeError(res.errors.detail)
          }
        })
    }
    if(method === 'create_client') {
      backReq(method, { phone, code, permToProcess, permToNews })
        .then(() => {})
    }
    if(method === 'restore_with_code') {
      backReq(method, { phone, code, newPassword })
        .then(() => {this.setState(_ => ({ loading: false }))
          this.nextStepRegistrationModal()
          setTimeout(() => {
            this.login(phone, newPassword)
            elementsStore.smsInputRestoreDefault()
          },1000)
        })
    }
  }

  recover = (method, {phone, token, code, newPassword, email}) => {
    this.setState({
      loading: true,
    })
    if(method === 'send_phone_code') {
      elementsStore.startSmsCountdown(60)
      backReq('send_phone_code', { phone })
        .then(res => {this.setState(_ => ({ loading: false }))
          if (res.status === 'success') {
            this.state.recoverModalStep === 1 && this.nextStepRecoverModal()
          }
          if (res.status === 'error') {
            if(this.state.recoverModalStep === 1) {
              this.setState(_ => ({
                recoverResult: {
                  text: `<div>${res.errors.detail}</div>`,
                  result: 'error'
                }
              }))
            } else {
              elementsStore.setSmsCodeError(res.errors.detail)
            }
          }
      })
    }
    if(method === 'captcha_send_phone_code') {
        elementsStore.startSmsCountdown(60)
        backReq('captcha_send_phone_code', { phone, captchaToken: token })
          .then(res => {this.setState(_ => ({ loading: false }))
            if (res.status === 'success') {
              this.state.recoverModalStep === 1 && this.nextStepRecoverModal()
            }
            if (res.status === 'error') {
              if(this.state.recoverModalStep === 1) {
                this.setState(_ => ({
                  recoverResult: {
                    text: `<div>${res.errors.detail}</div>`,
                    result: 'error'
                  }
                }))
              } else {
                elementsStore.setSmsCodeError(res.errors.detail)
              }
            }
        })
      }
    if(method === 'check_phone_code') {
      elementsStore.setCheckingSmsCode(true)
      backReq(method, { phone, code })
        .then(res => {this.setState(_ => ({ loading: false}))
          if (res.status === 'success') {
            elementsStore.setCheckingSmsCode(false)
            elementsStore.setSmsCodeConfirmed(true)
            elementsStore.setSmsCodeError('')
            setTimeout(() => {this.nextStepRecoverModal()}, 500)
          }
          if (res.status === 'error') {
            elementsStore.setCheckingSmsCode(false)
            elementsStore.setSmsCodeError(res.errors.detail)
          }
        })
    }
    if(method === 'restore_with_code') {
      backReq(method, { phone, code, newPassword })
        .then(() => {this.setState(_ => ({ loading: false}))
          this.nextStepRecoverModal()
          setTimeout(() => {
            this.login(phone, newPassword)
            elementsStore.smsInputRestoreDefault()
          },1000)
        })
    }
    if(method === 'restore_with_mail') {
      backReq(method, { email })
        .then(res => {this.setState(_ => ({ loading: false }))
          if (res.status === 'success') {
            this.setState(_ => ({
              recoverResult: {
                result: 'success'
              }
            }))
            this.nextStepRecoverModal()
          }

          if (res.status === 'error') {
            this.setState(_ => ({
              recoverResult: {
                text: `<div>${res.errors.detail}</div>`,
                result: 'error'
              }
            }))
          }
        })
    }
    if(method === 'change_password_link') {
      const params = new URL(window.location.href)
      const token = params.searchParams.get('token')
      backReq(method, { newPassword, token })
        .then(res => {this.setState(_ => ({ loading: false }))
          if (res.status === 'success') {
            this.setState(_ => ({
              recoverResult: {
                result: 'success'
              }
            }))
            this.nextStepRecoverModal()
            setTimeout(() => appStore.setToken({sessionToken: token}),5000)
          }
          if (res.status === 'error') {
            this.setState(_ => ({
              recoverResult: {
                text: `<div>${res.errors.detail}</div>`,
                result: 'error'
              }
            }))
          }

        })
    }
  }

  showRecoveryFromRegistration = (phone) => {
    this.hideRegistrationModal()
    this.setState(_ => ({ phone: phone }))
    this.showRecoverModal()
  }

  // Модалка вопросов-ответов
  showFAQModal = _ => {
    this.hideTooltip()
    this.setState(_ => ({ faqModalVisible: true }))
  }

  onVash = () => {
    yandexMetrikaReachGoal('lk_zayavka_zaym')
    window.location.href = 'https://on.vashinvestor.ru/'
  }
  hideFAQModal = _ => this.setState(_ => ({ faqModalVisible: false }))


  closeAlert() {
    this.setState({
      showAlert: false
    })
  }
  handleKeyPress = (e) => {
    if(!this.state.recoverModalVisible && !this.state.registrationModalVisible) {
      if(e.key === 'Enter'){
        this.login()
      }
    }
  }

  render() {
    if (this.props.isLoggedIn) {
      return <Redirect to='/'/>
    }

    const {
      phone, password, lkNotWorking, lkNotWorkingMessage,
      recoverModalVisible, phoneRecover, recoverResult,
      faqModalVisible, invalidAttempt, showAlert, recoverModalStep, registrationModalStep,
      loading, checkConfirmCodeRecover, isSendConfirmCode, registrationModalVisible,
      registrationResult, checkConfirmCodeRegistration
    } = this.state

    const { isShutdown, shutdownText } = this.props

    let alert = null
    let text = isShutdown
      ? shutdownText
      : shutdownText ? shutdownText : lkNotWorkingMessage

    if (showAlert && lkNotWorking && text && text !== '') {
      alert = <BaseMessage type="warning" shadow={true} centered={true} close={true}>
        <div dangerouslySetInnerHTML={{__html: text}}/>
      </BaseMessage>
    }

    return <main className={s.login}>
      <div className={s.wrapper}>
        <div className={s.spacer}>
          <div className={s.logo}>
            <BaseLogo big={true}/>
          </div>
        </div>
        <div className={s.part}>
          <div className={s.form}>
            <div className={s.spacer}>
              <h2 className="title">Вход в кабинет</h2>
              <p>Введите номер телефона, указанный Вами в договоре и пароль, нажмите «Войти».</p>
              <BaseHoneypot type="text" />
              <BaseHoneypot type="password"/>
              <div className={s.input}>
                <PhoneInput
                    type="number"
                    value={(x) => this.changeInput('phone', x) }
                    placeholder="Номер телефона"
                />
              </div>
              <div className={s.input}>
                <BaseInput
                    type="password"
                    value={(x) => this.changeInput('password', x) }
                    placeholder="Пароль"
                />
              </div>
              { invalidAttempt
                  ? <div className={ s.errorMessage }>
                    Вы ввели неверный номер телефона или пароль. Необходимо ввести номер телефона, который указан в договоре заема.
                    Если Вы поменяли номер телефона - позвоните в Контакт-центр <span style={{ whiteSpace: 'nowrap'}}>{VI_OFFICE_PHONE}</span> (бесплатно)
                  </div>
                  : null
              }
              <div className={s.button}>
                <BaseButton
                    type="green"
                    size="big"
                    text="Войти"
                    onClick={() => this.login()}
                    disabled={isShutdown || !phone.match(/^\+7\d\d\d\d\d\d\d\d\d\d$/) || !password}
                />
              </div>
              <div className={s.policyAgreement}>
                  <PolicyAgreement buttonName={'Войти'}/>
              </div>
              <div className={s.loginFooter}>
                <BaseButton
                  type="bony"
                  text="Забыли пароль?"
                  onClick={ () => this.showRecoverModal() }
                />
              </div>
            </div>
          </div>
          <div className={s.hello}>
            <div className={s.spacer}>
              <img src={HelloIcon} alt=""/>
              <h1 className="title">Приветствуем!</h1>
              <p className="text">
                Для работы с личными кабинетом мы рекомендуем удобные и безопасные браузеры: <a href="https://browser.yandex.by/" target="_blank" rel="noopener noreferrer">Яндекс.Браузер</a> или <a href="https://www.google.com/intl/ru/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a>.
              </p>
              <div className={s.button}>
                <BaseButton
                    type="shadow"
                    size="medium"
                    text="Заявка на займ"
                    onClick={() => {this.onVash()}}
                />
              </div>
              <div className={s.button}>
                <BaseButton
                    type="shadow"
                    text="Другие вопросы"
                    size="medium"
                    onClick={ () => this.showFAQModal() }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.policy}>
          <div className={s.spacer}>
            <p>{this.props.policy.text}</p>
            <p>Горячая линия: <a href={VI_OFFICE_PHONE_URL}>{VI_OFFICE_PHONE}</a> (бесплатно)</p>
            <div className={s.flex}>
              <div className={s.links}>
                {
                  Object.entries(links).map(([key, value]) => {
                    return <a key={key} href={key} target='_blank' rel='noopener noreferrer'>
                      {value}
                    </a>
                  })
                }
              </div>
              <div className={s.reverse}>
                <address>© «Ваш инвестор» 2018-{new Date().getFullYear()} Новосибирск</address>
                <img src={PaymentsIcon} alt=''/>
              </div>
            </div>
          </div>
        </div>
        <div className={s.message}>
          { alert }
        </div>
      </div>
      <RecoverModal
          recoverModalVisible={recoverModalVisible}
          phoneRecover={phoneRecover}
          recoverResult={recoverResult}
          hideRecoverModal={this.hideRecoverModal}
          changeInput={(x) => this.changeInput('phoneRecover', x)}
          recover={this.recover}
          recoverModalStep={recoverModalStep}
          prevStepRecoverModal={this.prevStepRecoverModal}
          loading={loading}
          isSendConfirmCode={isSendConfirmCode}
          checkConfirmCode={checkConfirmCodeRecover}
      />
      <RegistrationModal
        registrationModalVisible={registrationModalVisible}
        hideRegistrationModal={this.hideRegistrationModal}
        loading={loading}
        isSendConfirmCode={isSendConfirmCode}
        checkConfirmCode={checkConfirmCodeRegistration}
        registration={this.registration}
        registrationResult={registrationResult}
        nextStepRegistrationModal={this.nextStepRegistrationModal}
        prevStepRegistrationModal={this.prevStepRegistrationModal}
        registrationModalStep={registrationModalStep}
        showRecoveryFromRegistration={this.showRecoveryFromRegistration}
      />
      <ModalContainer
          size="big"
          title="Помощь"
          showModal={ faqModalVisible }
          closeModal={ () => this.hideFAQModal() }
      >
        <FAQ />
      </ModalContainer>
    </main>
  }
}

export default PageLogin
