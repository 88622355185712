import React from "react";
import BaseLoader from "../../atoms/BaseLoader";

const PersonalDataCheck = () => {
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: '-1',
    gridColumnEnd: '-3',
    justifyContent: 'center'
  }}>
    <BaseLoader color={'black'}/> Проверка данных
  </div>
}
export default  PersonalDataCheck
