import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
import {toJS} from 'mobx'
import appStore from '../../store/app'
import LoanList from "../organisms/LoanList";
import {getQuery} from "../../utils/getQuery";
import BaseSkeleton from "../atoms/BaseSkeleton";

@inject('appStore')
@observer
class PageLoans extends Component {
    state = {
        activeTab: 'loansOpened',
        sortedInfo: null
    }

    componentDidMount() {
        appStore.changeSidebarVisible(false)
        this.props.appStore.fetchAutoPaymentStatus();

        // UNSAFE_componentWillMount
        const queryParams = getQuery()
        const reference = queryParams.reference
        if (reference) {
            const [loanId] = reference.split(':')
            if (loanId) {
                this.props.history.push(`/loans/${loanId}${queryParams.error === '127' ? '?is_cancel_by_user=true' : ''}`)
            }
        }
    }

    render() {
        const isLoansLoading = toJS(appStore.isLoansLoading)
        return isLoansLoading
            ? <BaseSkeleton/>
            : <div className="container">
                <LoanList/>
            </div>
    }
}

export default PageLoans
