import React from 'react'
import {backReq} from "../../../../helpers";
import ButtonsLayout from "../ButtonsLayout";
import {loanStatuses} from "../../../../constants/onlineLoan";
import elementsStore from "../../../../store/elementsStore";
import {observer} from "mobx-react";

const FinalTab = observer(() => {
    const loanId = elementsStore.moderatedId
    const finalTabButtons = [
        {
            text: 'Сменить статус на "Займ предоставлен"',
            request: ()=> backReq('online_manager:change_status', {
                "id": loanId,
                "status": loanStatuses.complete
            })
        },
        {
            text: 'Сменить статус на "Оформление займа в офисе"',
            request: ()=> backReq('online_manager:change_status', {
                "id": loanId,
                "status": loanStatuses.inOffice
            })
        },
        {
            text: 'Сменить статус на "Невозможно оформления займа в офисе"',
            request: ()=> backReq('online_manager:change_status', {
                "id": loanId,
                "status": loanStatuses.impossible
            })
        },
    ]

    return <React.Fragment >
      <ButtonsLayout buttons={finalTabButtons}></ButtonsLayout>
    </React.Fragment>
})
export default FinalTab
