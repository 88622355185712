import React from 'react'
import PropTypes from 'prop-types'

import s from './PaymentCardSelect.module.scss'

const props = {
    name: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    number: PropTypes.string,
    onClick: PropTypes.func,
}

const lastDigitsCard = (cardNum) => {
    if (cardNum) {
        return `**** ${cardNum.split('******').pop()}`
    }
}

// const PaymentCardSelectElement = ({ name, token, image, number, onClick, isOption, type }) => {
const PaymentCardSelectElement = ({ name, number, onClick, isOption, type }) => {
    return (
        <div className={`${s.cardWrapper} ${ isOption ? s.option: ''}`} onClick={(e) => onClick(e)}>
            <img src={require(`../../../img/cards/${type && type !== '' ? type : 'card-placeholder'}.svg`)} alt=""/>
            <span>{name} {lastDigitsCard(number)}</span>
        </div>
    )
}

PaymentCardSelectElement.propTypes = props

export default PaymentCardSelectElement
