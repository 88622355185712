import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import s from './ServicesTable.module.scss'
import ContentContainer from '../../molecules/ContentContainer'
import BaseButton from "../../atoms/BaseButton";
import appStore from '../../../store/app'
import constants from '../../../constants'
import {Link} from "react-router-dom";
import BaseTabs from "../../atoms/BaseTabs";
import {ResizeContext} from "../../../providers/ResizeProvider";

const DEFAULT_LIMIT = 5

@inject('appStore')
@observer
class ServicesTable extends Component {
    state = {
        pagination: {
            total: 0,
            offset: 0,
            limit: DEFAULT_LIMIT
        },
        loadingMore: false,
        tabsActive: 'actual'
    }

    pay(item, e) {
        e.stopPropagation();
        const payload = {
            paymentTitle: "Оплатить услугу",
            paymentText: `${item.title} (${item.id})`,
            immutableInput: true,
            id: item.id,
            ctx: {
                loanId: item.sideLoan,
            },
            ...item,
            nextPaymentSum: item.amount,
            type: constants.payment.type.service,
        }
        appStore.openPayModalFor(payload)
    }

    render() {
        const {tabsActive} = this.state
        const {services, archivedServices} = this.props

        if (!services) return null

        const servicesToRender = { actual: services }
        const tabs = { actual: 'Актуальные' }

        if (archivedServices.length) {
            servicesToRender.archive = archivedServices
            tabs.archive = 'Архивные'
        }

        // const inPayment = appStore.inPayment

        const listHead = [
            'Название',
            'Сумма',
            'Связанный займ',
        ]

        const list = <ResizeContext.Consumer>
            {({ tablet }) => (
                <div>
                    {
                        tablet &&
                        <div className={[s.services__line, s['services__line--head']].join(' ')}>
                            {listHead.map((item, index) => <span key={index}>{item}</span>)}
                        </div>
                    }
                    {
                        servicesToRender[tabsActive].map((item, index) => {
                            return <React.Fragment key={index}>
                                {index > 0 && <hr/>}
                                <div className={s.services__line}>
                                    <p>{item.title}</p>
                                    {!tablet && <span>{listHead[1]}</span>}
                                    {!tablet && <span>{listHead[2]}</span>}
                                    <p>{item.amount} ₽</p>
                                    <p className={s.green}>{item.sideLoan}</p>
                                    <BaseButton
                                        type={item.ServiceDelay ? 'orange' : 'green'}
                                        text={item.ServiceDelay ? 'В обработке' : 'Оплатить'}
                                        disabled={item.ServiceDelay || tabsActive === 'archive' }
                                        onClick={(e) => this.pay(item, e)}
                                        btnId="pay-modal-open"
                                    />
                                    <Link to={`/loans/${item.sideLoan}`}/>
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
            )}
        </ResizeContext.Consumer>
        return (
            <div className={s.services}>
                <h2 className="title">Мои услуги</h2>
                <BaseTabs active={this.state.tabsActive} list={tabs} change={(value) => this.setState({tabsActive: value})}/>
                <div className={s.services__group}>
                    <ContentContainer>
                        {list}
                    </ContentContainer>
                </div>
            </div>
        )
    }
}

export default ServicesTable;
