import React, {useState} from 'react'
import ModalContainer from "../../components/atoms/ModalContainer";
import {useHistory} from "react-router-dom";
import BaseSkeleton from "../../components/atoms/BaseSkeleton";
import OnlineLoanRegistration from "../../components/organisms/OnlineLoanRegistration";
import onlineLoanStore from "../../store/onlineLoanStore";
import RegistrationSubject from "../../components/PageOnlineLoan/OnlineLoanSubject";
import RegistrationParameters from "../../components/PageOnlineLoan/OnlineLoanParameters";
import RegistrationPersonal from "../../components/PageOnlineLoan/OnlineLoanPersonal";
import RegistrationPrivacy from "../../components/PageOnlineLoan/OnlineLoanPrivacy";
import RegistrationConfirmation from "../../components/PageOnlineLoan/OnlineLoanConfirmation";
import RegistrationIncome from "../../components/PageOnlineLoan/OnlineLoanIncome";
import RegistrationCard from "../../components/PageOnlineLoan/OnlineLoanCard";
import RegistrationFinal from "../../components/PageOnlineLoan/OnlineLoanFinal";
const steps = [
    {
        title: "Параметры займа",
        tab: "Параметры<br/>займа",
        body: <RegistrationParameters/>
    },
    {
        title: "Персональные данные",
        tab: "Персональные<br/>данные",
        body: <RegistrationPersonal/>
    },
    {
        title: "Соглашения",
        tab: "Соглашения",
        body: <RegistrationPrivacy/>
    },
    {
        title: "Подтверждение личности",
        tab: "Подтверждение<br/>личности",
        body: <RegistrationConfirmation/>
    },
    {
        title: "Сведения о доходах",
        tab: "Сведения&nbsp;о доходах",
        body: <RegistrationIncome/>
    },
    {
        title: "Предмет залога",
        tab: "Предмет залога",
        body: <RegistrationSubject/>
    },
    {
        title: "Банковская карта для получения займа",
        tab: "Банковская<br/>карта",
        body: <RegistrationCard/>
    },
    {
        title: "Отправка заявки на займ",
        tab: "Отправка<br/>заявки",
        body: <RegistrationFinal/>
    },

]
export const OnlineLoanPage = () => {
    const [popup, setPopup] = useState(true)
    const history = useHistory();
    const closePopup = () => {
        history.push('/')
        setPopup(false)
    }
    return (
        <div className="container">
            { !popup && <BaseSkeleton/>}
            <ModalContainer
                showModal={popup}
                closeModal={closePopup}
                size="large"
                closeOnBlur={false}
                isTitle={false}
            >
                <OnlineLoanRegistration steps={steps} activityStore={onlineLoanStore} gap={10}/>
            </ModalContainer>
        </div>
    )
}

export default OnlineLoanPage
