import {action, computed, observable, runInAction} from "mobx";
import {onlineLoanStepsArray as steps} from "../constants/onlineLoan"
import {backReq} from "../helpers";

class OnlineLoanStore {
    // Online Loan
    @observable isOfferExist = true
    @observable onlineLoanOrder = null
    @observable loanDetail = {}
    @observable onlineLoans = []
    @observable endOfRegistrationModal = false
    @observable loanInfoLoader = false
    @observable isDataOnCheck = false
    @observable isCheck1C = true
    @observable isCheckLimit = false
    @observable showCheckLimitModal = false
    @observable nextFrom2Step = ''

    @action setIsOfferExist = (value) => (this.isOfferExist = value)
    @action setOnlineLoanOrder = (order) => (this.onlineLoanOrder = order)
    @action setOnlineLoans = (loans) => (this.onlineLoans = loans)
    @action setLoanDetail = (detail) => (this.loanDetail = detail)
    @action setEndOfRegistrationModal = (value) => (this.endOfRegistrationModal = value)
    @action setIsDataOnCheck = (bool) => (this.isDataOnCheck = bool)
    @action setIsCheck1C = (bool) => (this.isCheck1C = bool)
    @action setIsCheckLimit = (bool) => (this.isCheckLimit = bool)
    @action setShowCheckLimitModal = (bool) => (this.showCheckLimitModal = bool)
    @action setNextFrom2Step = (val) => (this.nextFrom2Step = val)

    @action refreshOfferExist = async () => {
        try {
            const response = await backReq('online_loan:exists')
            if (response.errors) {
                new Error('online_loan:exists error')
            }
            this.setIsOfferExist(!!response.data?.onlineLoanOrder?.id ?? undefined)
            localStorage.setItem("loanId", response.data?.onlineLoanOrder?.id)
            this.setOnlineLoanOrder(response.data?.onlineLoanOrder)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }
    @action refreshLoanDetail = async () => {
        try {
            this.loanInfoLoader = true
            const response = await backReq('online_loan:detail')
            if (response.errors) {
                new Error('online_loan:detail')
            }
            localStorage.setItem("loanId", response?.data?.id)
            this.setLoanDetail(response.data)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }
    @action refreshLoanList = async () => {
        try {
            const response = await backReq('online_loan:list')
            if (response?.errors) return;
            this.setOnlineLoans(response.data)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }

    @computed get openedOnlineLoans() {
        if (!this.onlineLoans) return []
        return this.onlineLoans.filter((loan) => loan.status !== 'complete')
    }

    @observable currentStep = 0
    @observable currentStepTitle = ''
    @observable stepsArray = steps

    @computed get backendCurrentStepNumber() {
        if (this.isCheckLimit) return 1
        if (!this.loanDetail?.currentStep) return 0
        const currentStepNumber = this.stepsArray.findIndex(step => step === this.loanDetail.currentStep)
        return currentStepNumber === -1 ? 0 : currentStepNumber
    }


    @action setCurrentStep = async (value, isChecked = false) => {
        // init value
        if (this.currentStep === undefined || isChecked) {
            this.currentStep = value
            return
        }
        // step back
        if (value < this.currentStep) {
            this.currentStep = value
            return
        }
        //  avoid error
        if (!this.loanDetail?.currentStep) return;
        // if walking through the previous steps
        let backCurrentStepNumber = this.stepsArray.findIndex((step) => step === this.loanDetail?.currentStep)
        if (this.isCheckLimit) backCurrentStepNumber = 1
        if (value <= backCurrentStepNumber) {
            this.currentStep = value
        } else {
            // ask permission
            const response = await backReq('online_loan:change_step', {
                "step": this.stepsArray[value]
            })
            if (response.code === 200) {
                runInAction(() => this.currentStep = value)
            } else {
                const err = new Error('step error')
                err.responseData = response.errors
                throw err
            }
        }
    }
}

const repeatStore = new OnlineLoanStore()
export default repeatStore
