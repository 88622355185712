import React, {useEffect, useRef, useState} from 'react'

import s from '../BaseInput/BaseInput.module.scss'
import Arrow from './img/arrow.svg'
import {isMobile} from "../../../utils/isMobile";
import BaseCheckbox from "../BaseCheckbox";

const AutoSelect = ({
                      placeholder,
                      options,
                      theme,
                      disabled,
                      withLabel,
                      customLabel,
                      defaultValue,
                      onSearch,
                      onChanged,
                      multiple,
                      listTop=false,
                      onBlur= ()=>({})
}) => {
  const [showItems, setShowItems] = useState(false)
  const [localOptions, setLocalOptions] = useState(options ? JSON.parse(JSON.stringify(options)) : [])
  const [selected, setSelected] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [selectOption, setSelectOption] = useState()

  const selectRef = useRef()
  const selectWrapper = useRef()

  useEffect(() => {
    const opt = options ? JSON.parse(JSON.stringify(options)) : []
    setLocalOptions(opt)
  },[options])

  useEffect(() => {
    if (defaultValue || defaultValue === '') {
      setSearchValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    document.addEventListener('click', clickOutside)
    if (multiple && defaultValue) {
      setSelected(defaultValue)
    }
    if (multiple && defaultValue === undefined) {
      setSelected([])
    }

    return () => {
      document.removeEventListener('click', clickOutside)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (multiple) {
      setSearchValue(selected)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (multiple) {
      onChanged(selected)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOption])


  const clickOutside = (event) => {
    if (selectWrapper.current && !selectWrapper.current.contains(event.target)) {
      setShowItems(false)
    }
  }

  const toggleItems = (event, chosenOption) => {
    if (disabled) return
    setSelectOption(chosenOption)
    if (!showItems){
      handleSearch(event)
    }
    if(multiple) {
      event.target === selectRef.current && setShowItems(!showItems)
    } else {
      setShowItems(!showItems)
    }
    if (showItems) {
      selectRef.current.blur()
    }
    if (!chosenOption && !showItems){
      onChanged(defaultValue)
      return;
    }
    if(multiple) {
      if(chosenOption) {
        if(selected.includes(chosenOption)) {
          let copySelected = [...selected]
          copySelected.splice(copySelected.indexOf(chosenOption), 1)
          setSelected(copySelected)
          return
        }
        if(selected.length === 0) {
          setSelected(() => [chosenOption])
        } else {
          setSelected((prevVal) => [...prevVal, chosenOption])
        }
        onChanged(selected)
      }
      return
    }
    if (_validateValue(chosenOption)) {
      setSelected(chosenOption)
      setSearchValue(withLabel ? customLabel ? chosenOption[customLabel] : chosenOption.label : chosenOption)
      onChanged(chosenOption)

    } else {
      const def = _isSelectedHasValidValue() ? selected : withLabel ? {label: '', [customLabel] : ''} : ''
      setSearchValue(withLabel ? customLabel ? def[customLabel] : def.label : def || '')
      onChanged(def)
    }
  }

  function _validateValue(chosenOption){
    try {
      if (withLabel) {
        return options.some(option => {
          const optionValue = customLabel ? option[customLabel] : option.label
          const chosenOptionValue = customLabel ? chosenOption[customLabel] : chosenOption.label
          return optionValue === chosenOptionValue
        })
      }
      return options.some(option => option === chosenOption);
    } catch (e) {
      return false
    }
  }

  const _isSelectedHasValidValue = () => {
    if (typeof selected === 'object'){
      return Object.keys(selected).length !== 0
    }
    if (typeof selected === 'string'){
      return selected.length
    }

    return false
  }
  const handleSearch = event => {
    if (onSearch === null) {
      return
    }
    const { value } = event.target
    setSearchValue(value)
    if (value === ''){
      setLocalOptions(options)
    }
    const filtered = options.filter(option => {
      if (withLabel){
        const optionValue = customLabel ? option[customLabel] : option.label
        return optionValue.includes(value)
      }
      return option.includes(value) ?? false
    })
    setLocalOptions(filtered)

    if (typeof onSearch === 'function') onSearch(value)

  }

  const setItem = (chosenOption, event) => {
    event.stopPropagation()
    toggleItems(event, chosenOption)
  }

  const onClickOption = (event) => {
    if(multiple) return
    toggleItems(event)
  }

  const classes = [s.inputWrapper, s['inputWrapper--auto'], s[listTop ? 'inputWrapper--top' : '']]
  if (theme) classes.push(s[theme])
  if (showItems || (searchValue && searchValue.length > 0) || (selected && selected.length > 0)) classes.push(s.activePlaceholder)
  if (showItems) classes.push(s.activeIcon)

  return <div className={ classes.join(' ') } ref={selectWrapper}>
    <div className={s.placeholder}>{placeholder}</div>
    <div>
      <input
        style={{caretColor: !(onSearch === null || isMobile()) ? 'default' : 'transparent'}}
        ref={selectRef}
        className={s.input}
        value={ Array.isArray(selected) ? selected.join(', ') : searchValue }
        onInput={handleSearch}
        onClick={ (event) => toggleItems(event) }
        disabled={ disabled }
        onBlur={onBlur}
      />
      <img className={[s.icon, s['icon--chevron']].join(' ')} src={Arrow} alt=''/>
    </div>
    <div className={s.selectWrapper}>
      {
        showItems && // options.length > 0 ?
        <div className={`${s.select} ${localOptions?.length === 1 ? s.border : ''}`} onClick={(event) => onClickOption(event)}>
          { localOptions.map((x, index) => (
            multiple
              ? <div className={s.option} key={index}>
                <BaseCheckbox
                  value={selected && selected.includes(x)}
                  onChange={e => setItem(x, e)}
                  inversion
                >
                  { x }
                </BaseCheckbox>
              </div>
              : <div className={s.option} key={index} onClick={ e => setItem(x, e)}>{ withLabel ? customLabel ? x[customLabel] : x.label : x }</div>
          ))}
        </div>
      }
    </div>

  </div>
}

export default AutoSelect
