import s from "./BaseLoans.module.scss";
import {dateToDMY} from "../../../utils/formattedDate";
import closedIcon from "./img/closed.svg";
import React from "react";

const BaseLoansClosed = ({closeDate}) => {
  return <>
      <div className={s.loans__dateClosed}>
          <div className={s.loans__dateClosedWrapper}>
              <span className={s.loans__grayText}>Дата закрытия</span>
              <div className={s.loans__dateClosedDate}>{dateToDMY(closeDate)}</div>
          </div>

          <img className={s.loans__closedIcon} src={closedIcon} alt="icon"/>
      </div>
  </>
}
export default BaseLoansClosed
