import React from 'react'

import s from './BaseTabs.module.scss'

export default class BaseTabs extends React.Component {
    render() {
        return this.props.list && (
            <div className={[
              s.tabs,
              s['divider'],
              s[`divider--${this.props.dividerColor ? this.props.dividerColor : 'white'}`],
              s[`size--${this.props.size ? this.props.size : 'default'}`],
              s[`viewActive--${this.props.viewActive ? this.props.viewActive : 'default'}`]
            ].join(' ')}>
                <div>
                    {
                        Object.entries(this.props.list).map(([key, value]) => (
                            <div key={key}
                                className={[s.tabs__item, key === this.props.active && s.active].join(' ')}
                                onClick={() => this.props.change(key)}
                                dangerouslySetInnerHTML={{__html: value}}
                            />
                        ))
                    }
                </div>
            </div>
        )
    }
}
