import React from "react";
import {ReactComponent as VkIcon} from "../../img/social/vk.svg";
// import {ReactComponent as FbIcon} from "../../img/social/fb.svg";
// import {ReactComponent as IgIcon} from "../../img/social/inst.svg";
import constants from "../../constants";

const cfr = process.env.INSTANCE_TYPE === constants.application.mkk_cfr_vi

const baseUrl = window.location.origin.toString() + '/docs'
export const terms = baseUrl +  (cfr ? '/Пользовательское_соглашение_ЛК_CFR.pdf' : '/terms_LK_mkk-vi_VI.184e3ca9.pdf')
export const privacyPolicy = baseUrl + '/Политика_ООО_МФК_ЦФР_перс_данные_от_01_06_2023.pdf'
const payments = baseUrl + (cfr ? '/payments-security-policy-CFR.5689cbc6.pdf' :  '/payments-security-policy-VI.ac3dddd0.pdf')
const useful = cfr ? 'https://vashinvestor.ru/useful/' : 'https://mkk-vi.ru/#docs'
const electronic = baseUrl + '/Соглашение_от_электронном_взаимодействии_2023.pdf'
const pep = baseUrl + '/Соглашение о признания ПЭП внутри Компании ver 4.pdf'

export const socials = {
    'https://vk.com/vashinvestor': <VkIcon/>,
    // 'https://www.facebook.com/ooovashinvestor': <FbIcon/>,
    // 'https://www.instagram.com/vash_investor': <IgIcon/>,
}
export const links = {
    [terms]: 'Пользовательское соглашение',
    ...(cfr && {[electronic]: 'Соглашение об электронном взаимодействии'}),
    [payments]: 'Политика безопасности платежей',
    [useful]: 'Документы и свидетельства',
    [pep]: 'Соглашение о признания ПЭП'
}

