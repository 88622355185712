import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GoBack from "../../components/atoms/GoBack";
import {dateToDMY} from "../../utils/formattedDate";
import ContentContainer from "../../components/molecules/ContentContainer";
import s from "./News.module.scss"
import BaseShare from "../../components/atoms/BaseShare";
import placeholderImage from "../dashboard/LastArticleList/img/placeholder.jpg";
import {apiPublicURL} from "../../config";

const props = {
  articleId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ])
}

class ArticlePage extends Component {
  state = {
    article: null,
    loading: true
  }

  componentDidMount() {
    this.fetchArticle(this.props.slug)
  }

  fetchArticle = async (slug) => {
    this.setState({ loading: true })

    await (await fetch(`${apiPublicURL}lk_news_detail/${slug}/`)).json()
      .then((response) => {
        this.setState({
          loading: false,
          article: response
        })
      })
  }

  onImageError = (e) => {
    e.target.src = placeholderImage
  }

  render() {
    const { article, loading } = this.state

    if (loading) return <div className="skeleton"/>
    if (!article) return null

    const { title, text, image } = article
    return (
        <section className={s.news}>
          <div className="container">
            <GoBack to='/news'/>
            <article>
              <ContentContainer>
                <span>{dateToDMY(article?.publication_date)}<span className={s.green}> / Новости</span></span>
                <h2 className="title">{ title }</h2>
                <img src={ image ?? placeholderImage } alt={title} onError={(e) => this.onImageError(e)} />
                <p className="text" dangerouslySetInnerHTML={{__html: text}}/>
                <div className={s.news__share}>
                  <BaseShare link={window.location.href} title={title} description="Ваш инвестор" image={image}/>
                </div>
              </ContentContainer>
            </article>
          </div>
        </section>
    )
  }
}

ArticlePage.propTypes = props

export default ArticlePage
