import { observer } from "mobx-react";
import React from "react";

import { loanStatuses } from "../../../../constants/onlineLoan";
import { backReq } from "../../../../helpers";
import elementsStore from "../../../../store/elementsStore";

import ButtonsLayout from "../ButtonsLayout";

const StatusTab = observer(() => {
	const loanId = elementsStore.moderatedId;
	const statusTabButtons = [
		{
			text: 'Сменить статус на "В процессе"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.inProcess,
				}),
		},
		{
			text: 'Сменить статус на "На проверке"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.onCheck,
				}),
		},
		{
			text: 'Сменить статус на "Необходима дополнительная информация"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.needInfo,
				}),
		},
		{
			text: 'Сменить статус на "Условное одобрение с понижением суммы займа"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.decreaseAmount,
				}),
		},
		{
			text: 'Сменить статус на "Одобрено"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.approved,
				}),
		},
		{
			text: 'Сменить статус на "Документы подписаны пользователем"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.signed,
				}),
		},
		{
			text: "Получить подписанный документ из 1С",
			request: () => backReq("online_manager:doc_offer", { id: loanId }),
		},
		{
			text: "Получить подтверждение, что все документы подписаны организацией",
			request: () => backReq("online_manager:docs_signed", { id: loanId }),
		},
		{
			text: 'Сменить статус на "Ошибка перевода средств"',
			request: () =>
				backReq("online_manager:change_status", {
					id: loanId,
					status: loanStatuses.mistake,
				}),
		},
	];

	return (
		<React.Fragment>
			<ButtonsLayout buttons={statusTabButtons} />
		</React.Fragment>
	);
});
export default StatusTab;
