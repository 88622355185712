import React, {useState} from 'react'
import BaseButton from "../../atoms/BaseButton";
import elementsStore from "../../../store/elementsStore";
import s from "./RepeaterModeration.module.scss";

const ButtonsLayout = ({buttons=[]}) => {
    // buttons = [
    //     {
    //         text:'string text',
    //         request: funcToCall
    //     },
    // ]
    const [isLoading, setIsLoading] = useState(false)

    const sendRequest = async (request) => {
        try {
            setIsLoading(true)
            await request()
            elementsStore.showSnackbar('Данные обновлены, пожалуйста перезагрузите страницу', 'success')
        } catch (e) {
            elementsStore.showSnackbar('Ошибка при обработке запроса')
        } finally {
            setIsLoading(false)
        }
    }

    return <div className={s.buttonsLayout}>
        {buttons.map(button =>
            <BaseButton
                key={button.text+ button.request}
                text={button.text}
                loading={isLoading}
                onClick={()=>sendRequest(button.request)}
            />
        )}
    </div>
}
export default ButtonsLayout
