import React from "react"

import RepeaterSettings from "../RepeaterSettings"
import RepeaterPrivacy from "../RepeaterPrivacy"
import RepeaterConfirmation from "../RepeaterConfirmation"
import RepeaterPersonal from "../RepeaterPersonal"
import RepeaterSubject from "../RepeaterSubject"
import RepeaterCard from "../RepeaterCard"
import RepeaterFinal from "../RepeaterFinal"

import OnlineLoanRegistration from "../../organisms/OnlineLoanRegistration";

const steps = [
  {
    title: "Параметры онлайн займа",
    tab: "Параметры<br/>займа",
    body: <RepeaterSettings/>
  },
  {
    title: "Соглашение об электронном взаимодействии",
    tab: "Соглашение",
    body: <RepeaterPrivacy/>
  },
  {
    title: "Подтверждение личности",
    tab: "Подтверждение<br/>личности",
    body: <RepeaterConfirmation/>
  },
  {
    title: "Персональные данные",
    tab: "Персональные<br/>данные",
    body: <RepeaterPersonal/>
  },
  {
    title: "Предмет залога (автомобиль)",
    tab: "Предмет<br/>залога",
    body: <RepeaterSubject/>
  },
  {
    title: "Банковская карта для получения займа",
    tab: "Банковская<br/>карта",
    body: <RepeaterCard/>
  },
  {
    title: "Отправка заявки на займ",
    tab: "Отправка<br/>заявки",
    body: <RepeaterFinal/>
  }
]

class RepeaterMain extends React.Component {
  render() {
    return (
        <OnlineLoanRegistration steps={steps} gap={20}/>
    )
  }
}

export default RepeaterMain
