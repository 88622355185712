import s from "./BaseLoans.module.scss";
import successIcon from "./img/success.svg";
import errorIcon from "./img/error.svg";
import React from "react";
import * as PropTypes from "prop-types";
import {observer} from "mobx-react";
import appStore from "../../../store/app";
import {useHistory} from "react-router-dom";

const BaseLoansTags = observer(({typePayment, isClosed, gps}) => {

    const auto = appStore.isAutoPaymentOn

    const history = useHistory()

    const localisedTypePayment = typePayment ? typePayment === 'dif' ? 'Дифференцированный' : 'Аннуитетный' : false

    const gotoToAutoPaymentPage = (e) => {
        e.stopPropagation()
        e.preventDefault()
        history.push('/settings')
    }
    return <>
        {localisedTypePayment &&
            <div className={[s.loans__prop, s["loans__prop--width"]].join(" ")}>{localisedTypePayment}</div>}
        {!isClosed && <>
            {gps && <div className={s.loans__prop}>
                <img src={successIcon} alt=""/>
                GPS-трекер
            </div>}
            <div className={s.loans__auto} onClick={gotoToAutoPaymentPage}>
                <img src={auto ? successIcon : errorIcon} alt=""/>
                Автоплатеж
                {auto
                    ? <><span style={{color: "#4CAF50"}} className={s.loans__textMobile}>&nbsp;вкл.</span>
                        <span style={{color: "#4CAF50"}} className={s.loans__textDesktop}>&nbsp;включен</span>
                    </>
                    : <>
                        <span style={{color: "#FF3F42"}} className={s.loans__textMobile}>&nbsp;выкл.</span>
                        <span style={{color: "#FF3F42"}} className={s.loans__textDesktop}>&nbsp;выключен</span>
                    </>
                }
            </div>
        </>}
    </>;
})
BaseLoansTags.propTypes = {
    typePayment: PropTypes.any,
    closed: PropTypes.bool,
    gps: PropTypes.any,
    currentRate: PropTypes.any,
    percent: PropTypes.any,
    red: PropTypes.bool,
    string: PropTypes.any,
    parts: PropTypes.any
};
export default BaseLoansTags
