import React, {useEffect, useState} from 'react'
import s from "./styles.module.scss";
import BaseInput from "../../components/atoms/BaseInput";
import PhoneInput from "../../components/atoms/PhoneInput";
import {observer} from "mobx-react";
import appStore from "../../store/app";

const PersonaInfo = observer (() =>{

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        const {name='', surname='', phone=''} = appStore.profile || ''
        setName(`${surname} ${name}`)
        setPhone(phone)
    },[])

    useEffect(() => {
     appStore.updateLoanOrderModalData('name', name)
     appStore.updateLoanOrderModalData('phone', phone)
    },[name, phone])

    return (
        <>
            <div className={s.form__label}>Основные данные</div>
            <div className={s.form__line}>
                <BaseInput
                    onlyLetters={true}
                    placeholder="ФИО"
                    type="text"
                    maxlength={100}
                    defaultValue={name}
                    value={ x => setName(x)}
                />
            </div>
            <div className={s.form__line}>
                <PhoneInput
                    placeholder="Номер телефона"
                    type="number"
                    defaultValue={phone}
                    value={ x => setPhone(x)}
                />
            </div>
        </>
    )
})

export default PersonaInfo
