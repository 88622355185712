import React from "react";
import s from "./OnlineLoanSubject.module.scss";
import BaseInput from "../../atoms/BaseInput";

const StsForm = ({stsForm, setStsForm}) => {

    const {stsSeries, stsNumber, stsDate, stsIssued} = stsForm
    return <div className={s.inputsGroup}>
        <div className={s.stsWrapper}>
            <BaseInput
                placeholder="Серия *"
                defaultValue={stsSeries}
                value={v => setStsForm({...stsForm, stsSeries: v})}
            />
            <BaseInput
                placeholder="Номер *"
                type={'number'}
                defaultValue={stsNumber}
                value={v => setStsForm({...stsForm, stsNumber: v})}
            />
        </div>
        <div>
            <BaseInput
                type={'date'}
                placeholder="Дата выдачи *"
                defaultValue={stsDate}
                value={v => setStsForm({...stsForm, stsDate: v})}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Кем выдан *"
                defaultValue={stsIssued}
                value={v => setStsForm({...stsForm, stsIssued: v})}
            />
        </div>
    </div>

}
export default StsForm
