import React from "react";

const WithdrawalBonusesModal = ()=> {
    return (
        <div>
            <div>
                Вы можете обменять бонусы на рубли в кассах наших офисов.
            </div>
            <div>1 бонус = 1 рубль</div>
            <br/>
            <div>Рабочее время офисов:</div>
            <div>пн-пт 09:30–19:00</div>
            <div>сб-вс 10:00–17:00</div>
        </div>
    )
}

export default WithdrawalBonusesModal
