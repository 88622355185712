import React, {Component} from 'react'

import s from './HeaderBurger.module.scss'

class HeaderBurger extends Component {
    render() {
        const classes = [s.burger]
        if (this.props.active) classes.push(s.active)

        return (
            <button className={classes.join(' ')}>
                <span/>
                <span/>
                <span/>
            </button>
        )
    }
}

export default HeaderBurger
