import { action, computed, observable } from 'mobx'
import { backReq, getPlainPhone, getGeolocationPermission, sendGeolocation, getGeolocation } from '../helpers'
import api from '../api'
import { FRONT_TOKEN_LIFETIME } from "../constants/frontTokenLifetime";
import { MAX_CFR_PAY_AMOUNT } from "../components/organisms/PayModal/constants";
import { loanStatuses } from "../constants/onlineLoan";
import referralStatus from "../constants/referralStatus";

// let $id = 0
// const buildRandomNotification = () => ({
//     id: $id++,
//     type: ['success', 'info', 'warning', 'error'][Math.floor(Math.random() * 4)],
//     date: new Date(),
//     text: shuffle((new Array(3).fill('lorem ipsum dolor sit amet lorem ipsum dolor sit amet').join(' ')).split(' ')).join(' '),
//     read: false,
// })

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

const addComputedFieldToLoan = loan => {
    const {
        nextPaymentDate, gracePeriodEndDate, mfo,
        docs = [], statusLoan, typePayment, nextPaymentSum
    } = loan

    const isMonthOverPaid = nextPaymentSum <= 0

    return {
        ...loan,
        isGracePeriod: (Date.parse(nextPaymentDate) < new Date().getTime() && new Date().getTime() <= Date.parse(gracePeriodEndDate)),
        isOverduePayment: Date.parse(gracePeriodEndDate) < new Date().getTime(),
        isSign: !docs.length ?? true,
        isTaxLate: statusLoan === 'Просроченный' && mfo === 'vi',
        isClosed: statusLoan === 'Закрыт',
        isBlocked: statusLoan === 'Заблокирован',
        isMonthOverPaid,
        isExtensionAgreementNeed: isMonthOverPaid ? mfo === 'cfr' && typePayment === 'dif' : false
    }
}

class AppModel {
    // Авторизация
    @observable token = localStorage.getItem('sessionToken')
    @observable loginStatus = 'notAuthorized' // 'notAuthorized', 'authorized', 'credentialsError', 'tokenOutdate'
    @observable logoutCountdownTimerInterval = null
    @observable logoutCountdownActivityTime = new Date().getTime()
    @observable logoutForRecoverPassword = false

    @action setToken = ({ sessionToken }) => {
        localStorage.setItem('sessionToken', sessionToken)
        this.token = sessionToken
    }
    @action clearToken = () => {
        localStorage.removeItem('sessionToken')
        this.token = null
    }
    @action setLogoutCountdownActivityTime = () => {
        this.logoutCountdownActivityTime = new Date().getTime()
    }

    @action setLogoutCountdownTimerInterval = (value) => this.logoutCountdownTimerInterval = value

    @action startLogoutCountdownTimer = () => {
        if (this.logoutCountdownTimerInterval !== null) {
            return
        }
        const interval = setInterval(() => {
            const inactivityTime = new Date().getTime() - this.logoutCountdownActivityTime
            if (inactivityTime > FRONT_TOKEN_LIFETIME) {
                this.logout()
            }
        }, 1000)
        this.setLogoutCountdownTimerInterval(interval)
    }

    @action clearData = () => {
        localStorage.removeItem('paidLoan')
        localStorage.removeItem('paidStatus')
        localStorage.removeItem('paidLoans')
        this.profile = null
        this.loans = []
        this.loanDetails = null
        this.allowPayment = true
        this.cards = []
        this.currentEntityForPay = null
        this.notificationData = {}
        this.payments = []
        this.unreadNotifications = []
        this.unreadNotificationsCount = 0
        this.notifications = []
    }
    @action changeLoginStatus = (status) => (this.loginStatus = status)

    @action login = (authData) => {
        this.setToken(authData)
        this.changeLoginStatus('authorized')
        this.sendGeolocationAction()
    }
    @action logout = (forRecover = false) => {
        this.clearToken()
        this.clearData()
        clearInterval(this.logoutCountdownTimerInterval)
        this.setLogoutCountdownTimerInterval(null)
        this.changeLoginStatus('notAuthorized')
        this.logoutForRecoverPassword = forRecover
    }
    @action updateSession = (token) => {
        this.setToken(token)
    }
    @action outdateSession = () => {
        this.clearToken()
        this.changeLoginStatus('tokenOutdate')
    }

    @computed get isLoggedIn() {
        return !!this.token
    }

    // Сайдбар

    @observable sidebarVisible = false
    @action changeSidebarVisible = (status) => (this.sidebarVisible = status)

    // Данные профиля
    @observable profile = null
    @action setProfile = (profile) => {
        this.setShowUserAgreement(!profile.userAgreementAccepted)
        this.profile = { ...profile }
    }

    // Список займов
    @observable loans = []
    @observable isLoansLoading = true
    @action setIsLoansLoading = async (bool) => this.isLoansLoading = bool;

    @action fetchLoans = async () => {
        try {
            await this.setIsLoansLoading(true)
            const { data } = await backReq('loans_list')
            await this.setLoans(data)
        } catch (e) {
            console.warn(e)
        } finally {
            await this.setIsLoansLoading(false)
        }
    }
    @action setLoans = async (loans) => {
        const sideLoans = loans
            .filter((loan) => loan.lns)
            .map((loan) => {
                return loan.lns.map((sideLoan) => ({
                    ...sideLoan,
                    parentLoanId: loan.ln
                }))
            })
            .flat(2)
        let localLoans = loans.concat(
            sideLoans.map((loan) => ({ ...loan, isSideLoan: true }))
        )
        localLoans = await this.fetchDocList(localLoans)
        localLoans = localLoans.map(loan => addComputedFieldToLoan(loan))
        this.loans = localLoans
    }

    @computed get openedLoans() {
        if (!this.loans) return []
        return this.loans.filter((loan) => loan.active === 1)
    }

    @computed get closedLoans() {
        if (!this.loans) return []
        return this.loans.filter((loan) => loan.active === 0)
    }

    // Список документов на подпись
    @observable docsForSignModal = []

    @action fetchDocList = async (loans) => {
        return Promise.all(loans.map(async (loan) => {
            loan.docs = await backReq('client_loan_docs', { ln: loan.ln })
                .then(res => res.data?.length ? res.data : [])
            return loan
        }))
    }
    @action setDocsForSignModal = async docs => this.docsForSignModal = [...docs]

    //Модалка подписания документов
    @observable isShowSignDocModal = false

    @action showSignDocModal = async () => this.isShowSignDocModal = true
    @action closeSignDocModal = () => this.isShowSignDocModal = false

    // Текущий займ
    @observable loanDetails = null
    @action setLoanDetails = (details) => {
        this.loanDetails = {
            ...details
        }
    }
    @action updateCurrentLoan = async (newLn) => {
        const loanId = newLn ?? this.loanDetails?.ln ?? null
        if (!loanId) return
        const res = await backReq('loan', { loanId })
        if (res.status === 'error') {
            this.setLoanDetails({})
            return
        }
        const { ln } = res.data
        if (!ln) {
            this.setLoanDetails({})
            return
        }
        const { data: docs = [] } = await backReq('client_loan_docs', { ln })
        const enrichedLoan = addComputedFieldToLoan({
            ...res.data,
            docs
        })
        this.setLoanDetails(enrichedLoan)

    }
    DEFAULT_LIMIT = 5
    @observable currentLoanPayments = []
    @observable currentLoanPaymentsTotal = 0
    @observable currentLoanPaymentsPagination = {
        total: 0,
        offset: 0,
        limit: this.DEFAULT_LIMIT
    }

    @observable getCurrentLoansPayments = async ({ limit, offset, loanId }) => {
        const res = await backReq('get_schedule', {
            loanId, limit, offset
        })
        if (res?.data?.payments) {
            this.currentLoanPayments = offset ? [...this.currentLoanPayments, ...res.data.payments] : res.data.payments
            this.currentLoanPaymentsTotal = res?.data?.total ?? 0
            this.currentLoanPaymentsPagination = res?.data?.total || offset ? {
                ...this.currentLoanPaymentsPagination,
                total: res.data.total
            } : {
                total: 0,
                offset: 0,
                limit: this.DEFAULT_LIMIT
            }
        } else {
            this.currentLoanPayments = []
            this.currentLoanPaymentsTotal = 0
            this.currentLoanPaymentsPagination = {
                total: 0,
                offset: 0,
                limit: this.DEFAULT_LIMIT
            }
        }
    }

    // Cards
    @observable cards = []
    @action setCards = (cards) => (this.cards = cards)

    // Global Pay Modal
    @observable isShowPayModal = false
    @observable isMfoVi = false
    @observable currentEntityForPay = null
    @observable currentEntityForPayAmount = 0
    @observable currentEntityForPayEarlyRepayment = false
    @observable currentEntityForPayMinAmount = 0
    @observable currentEntityForPayMaxAmount = MAX_CFR_PAY_AMOUNT

    @action setCurrentEntityForPay = async (value) => this.currentEntityForPay = value
    @action setCurrentEntityForPayAmount = (value) => {
        const floatedValue = parseFloat(value)
        if (isNaN(floatedValue)) this.currentEntityForPayAmount = 0
        else this.currentEntityForPayAmount = floatedValue
    }
    @action setCurrentEntityForPayEarlyRepayment = (bool) => this.currentEntityForPayEarlyRepayment = bool
    @action setCurrentEntityForPayMinAmount = (num) => this.currentEntityForPayMinAmount = num
    @action setCurrentEntityForPayMaxAmount = (num) => this.currentEntityForPayMaxAmount = num

    @computed get currentEntityForPayAmountInRange() {
        return this.currentEntityForPayAmount >= this.currentEntityForPayMinAmount &&
            this.currentEntityForPayAmount <= this.currentEntityForPayMaxAmount
    }

    @action openPayModalFor = (entity) => {
        this.currentEntityForPay = entity
        this.isShowPayModal = true
        this.currentEntityForPayAmount = entity.nextPaymentSum ?? 0
        this.currentEntityForPayEarlyRepayment = false
        this.isMfoVi = entity.mfo === 'vi'
    }
    @action closePayModal = () => {
        this.currentEntityForPay = null
        this.isShowPayModal = false
        this.currentEntityForPayAmount = 0
        this.currentEntityForPayEarlyRepayment = false
        this.currentEntityForPayEmail = ''
    }

    /* Модалка "Заявка на займ" */

    @observable loanOrderModalVisible = false
    @observable loanOrderModalName = null
    @observable loanOrderModalAmount = null
    @observable loanOrderModalModel = null
    @observable loanOrderModalYear = null
    @observable loanOrderModalPhone = null
    @observable loanOrderModalIsPhoneValid = false

    @action setLoanOrderModalVisible = (loanOrderModalVisible) => {
        if (loanOrderModalVisible) {
            this.loanOrderModalVisible = loanOrderModalVisible
        } else {
            this.loanOrderModalVisible = loanOrderModalVisible
            this.clearLoanOrderModalData()
        }
    }
    @action clearLoanOrderModalData = () => {
        this.loanOrderModalName = null
        this.loanOrderModalAmount = null
        this.loanOrderModalModel = null
        this.loanOrderModalYear = null
        this.loanOrderModalPhone = null
    }

    @action updateLoanOrderModalData = (field, payload) => {
        if (field === 'phone' && payload) {
            this.loanOrderModalIsPhoneValid = getPlainPhone(payload, { format: 'withPlus' }).length === 12
        }
        const fieldMame = 'loanOrderModal' + capitalize(field)
        this[fieldMame] = payload
    }

    @observable notificationData = {}
    @observable showNotificationModal = false
    @action showNotification = (showNotificationModal, notificationData) => {
        this.showNotificationModal = showNotificationModal
        this.notificationData = notificationData
    }

    /* Уведомления об оплате */
    @observable fetchLoanHistory = false
    @action setHistoryFetch = (bool) => {
        this.fetchLoanHistory = bool
    }
    @observable inPayment = []
    @action setEntitiesInPayment = (data) => {
        if (data && Array.isArray(data)) {
            this.inPayment = data
        }
    }
    @observable payments = []
    @action setPayments = (data) => {
        this.setHistoryFetch(true)
        this.payments.push(data)
    }
    @action setRead = (id) => {
        const payments = []
        for (const x of this.payments) {
            if (x.orderId !== id) {
                payments.push(x)
            }
        }
        this.payments = payments
    }
    @observable loanDetailIdToUpdate = null
    @action setLoanDetailIdToUpdate = (loanId) => this.loanDetailIdToUpdate = loanId

    @observable isNeedUpdatePaymentsStatus = false
    @action setIsNeedUpdatePaymentsStatus = (bool) => this.isNeedUpdatePaymentsStatus = bool

    /* Уведомления */

    @observable unreadNotifications = []
    @observable unreadNotificationsCount = 0
    @observable notifications = []
    @observable notificationsLoading = false
    @observable notificationsError = null
    @observable hasNotificationsLeft = true

    /* Подгружаем непрочитанные уведомления для Dropdown */
    @action fetchUnreadNotifications = () => api.notifications
        .get(2, 0, true)
        .then(({ notifications, total }) => {
            this.unreadNotifications = notifications
            this.unreadNotificationsCount = total
        })

    @action setUnreadNotifications = (notifications, total) => {
        this.unreadNotifications = notifications
        this.unreadNotificationsCount = total
    }

    /* Подгружаем N уведомлений с бэкенда */
    @action fetchNotifications = () => {
        this.notificationsLoading = true
        return api.notifications
            .get(30, this.notifications.length, false)
            .then(({ notifications, total }) => {
                this.notificationsLoading = false
                this.notifications = this.notifications.concat(notifications)
                this.hasNotificationsLeft = this.notifications.length < total
            })
            .catch((error) => {
                this.notificationsLoading = false
                this.notificationsError = error
            })
    }

    /* Отмечаем уведомление как прочитанное (удаляем из стора) */
    @action markAsReaded = (id) => {
        /* Оптимистично обновляем данные */
        this.unreadNotifications = this.unreadNotifications.filter(
            (notification) => notification.id !== id
        )
        this.unreadNotificationsCount--

        /* Реально обновляем */
        return api.notifications.read([id]).then(() => {
            return this.fetchUnreadNotifications()
        })
    }

    @action markAllAsReaded = () => api.notifications
        .read([])
        .then(() => {
            return this.fetchUnreadNotifications()
        })
        .then(() => {
            this.unreadNotifications = []
            this.unreadNotificationsCount = 0
        })


    /* Скрываем ошибку уведомлений */
    @action dismissNotificationsError = () => (this.notificationsError = null)

    /* Автоплатёж (включен/выключен) */
    @observable isAutoPaymentOn = null
    @action fetchAutoPaymentStatus = async () => {
        await backReq('get_auto_payment').then((response) => {
            this.isAutoPaymentOn = response.data.autopayment
        }).catch((error) => {
            console.warn(error)
            this.isAutoPaymentOn = undefined
        })
    }

    /* Onboarding stuff */
    @observable remoteOnboardingData = []
    @observable onboardingData = []
    @action dropOnboardingModal = (modalId) => {
        return backReq('create_onboarding_view', { modalId }).then(() => {
            this.remoteOnboardingData.push(modalId)
            this.onboardingData = this.onboardingData.filter(
                ({ id }) => !this.remoteOnboardingData.includes(id)
            )
        })
    }
    @action fetchOnboardingData = () => {
        return backReq('onboarding_list').then(({ data }) => {
            this.onboardingData = data
        }).catch(error => {
            console.warn(error)
            this.onboardingData = []
        })
    }
    @observable showUserAgreement = null
    @action setShowUserAgreement = (bool) => {
        this.showUserAgreement = bool
    }
    // Заявки на займ. Возможность создания заявки и список заявок
    @observable onlineLoanOrderExist = null
    @observable openLoanRequests = []
    @observable closedLoanRequests = []
    @action refreshOnlineLoanOrderExist = async () => {
        try {
            const response = await backReq('online_loan:exists')
            if (response.errors) {
                new Error('online_loan:exists error')
            }
            if (response.data?.onlineLoanOrder?.id) {
                localStorage.setItem("loanId", response.data?.onlineLoanOrder?.id)
            }
            this.onlineLoanOrderExist = response.data
        } catch (e) {
            throw e
        }
    }

    @action getOpenLoanRequests = async () => {
        try {
            const openStatuses = [loanStatuses.inProcess, loanStatuses.onCheck, loanStatuses.approved, loanStatuses.needInfo, loanStatuses.decreaseAmount, loanStatuses.modifiedAmount, loanStatuses.signed, loanStatuses.mistake]
            const openLoansData = await backReq('online_loan:list', {
                status: openStatuses
            })
            if (openLoansData?.data?.list) {
                this.openLoanRequests = (openLoansData.data.list);
            } else {
                this.openLoanRequests = []
            }
        } catch (e) {
            this.openLoanRequests = []
        }
    }
    @action getClosedLoanRequests = async () => {
        try {
            const closeStatuses = [loanStatuses.impossible, loanStatuses.complete, loanStatuses.inOffice]
            const closedLoansData = await backReq('online_loan:list', {
                status: closeStatuses
            })
            if (closedLoansData?.data?.list) {
                this.closedLoanRequests = (closedLoansData.data.list);
            } else {
                this.closedLoanRequests = []
            }
        } catch (e) {
            this.closedLoanRequests = []
        }
    }

    /* Реферальная программа */

    @observable referralInfo = {
        link: '',
        data: {
            loans: []
        },
        status: referralStatus.BRONZE,
        bonusBalance: 0,
    }

    @action getReferralInfo = async () => {
        const getStatusByLength = (length) =>
            length < 5
                ? referralStatus.BRONZE
                : length < 10
                    ? referralStatus.SILVER
                    : referralStatus.GOLD

        try {
            const { data: referralInfo } = await backReq('referral:info')
            if (referralInfo.data) {
                const clearData = JSON.parse(referralInfo.data)
                let bonusBalance = 0
                clearData.loans.forEach(loan => {
                    loan.transactions.forEach(transaction => {
                        bonusBalance += transaction.amount
                    })
                })
                this.referralInfo = {
                    ...referralInfo,
                    data: {
                        ...clearData
                    },
                    status: getStatusByLength(clearData?.loans?.length ?? 0),
                    bonusBalance
                }
            } else {
                this.referralInfo = {
                    ...referralInfo,
                    data: {
                        loans: [],
                    },
                    status: referralStatus.BRONZE,
                    bonusBalance: 0

                }
            }
        } catch (e) {
            this.referralInfo = {}
        }
    }

    @observable isReferralDrawerVisible = false
    @action setIsReferralDrawerVisible = async (bool) => {
        this.isReferralDrawerVisible = bool
        if (bool && this.profile?.clubPageIsShown === false) {
            await backReq('show_club_page')
            await backReq('user').then((res) => this.setProfile(res.data))
        }

    }

    // geolocation
    @action sendGeolocationAction = async () => {
        getGeolocationPermission()
            .then((perm) => {
                if (perm.state === 'granted' || perm.state === 'prompt') {
                    getGeolocation()
                        .then((pos) => {
                            sendGeolocation({
                                longitude: pos.coords.longitude,
                                latitude: pos.coords.latitude
                            });
                        });
                }
            });
    }
}

// Создаем и экспортируем стор

const appStore = new AppModel()

export default appStore
