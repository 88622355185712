// $red: #FF3F42;
export const snackbarOptions = {
    position: 'top-right',
    style: {
        backgroundColor: 'white',
        border: '1px solid #FFFFFF',
        color: 'black',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)'
    },
    closeStyle: {
        color: 'black',
        fontSize: '16px',
    },
}

export const snackbarOptionsSuccess = {
    position: 'top-center',
    style: {
        backgroundColor: 'rgba(76, 175, 80, 0.95)',
        color: 'white',
        boxShadow: 'none',
        border: '1px solid rgba(76, 175, 80, 0.95)',
    },
    closeStyle: {
        color: 'white',
        fontSize: '16px',
    },
}

export const snackbarOptionsError = {
    position: 'top-center',
    style: {
        backgroundColor: '#FFECEC',
        border: '1px solid #FF3F42',
        boxShadow: 'none',
        color: 'black',
    },
    closeStyle: {
        fontSize: '16px',
        color: 'black',
    },
}
