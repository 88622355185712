export const ONLINE_LOAN_PROMO = 'onlineRepeater'

export const addPromoInfo = (title= ONLINE_LOAN_PROMO, promoPage) => {
  sessionStorage.setItem(title, promoPage)
}

export const getPromoInfo = (title= ONLINE_LOAN_PROMO, value) => {
  return sessionStorage.getItem(title)
}

export const isPromoExist = (title= ONLINE_LOAN_PROMO) => {
  return !!sessionStorage.getItem(title)
}
