import React from 'react'
import PropTypes from 'prop-types'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

import s from './YMap.module.scss'

const props = {
  officies: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired
  }))
}

const YMap = ({ officies }) => {
  if (!officies || !officies.length) return null

  const placemarks = officies.map((office, i) => {
    return (
      <Placemark
        key={i}
        geometry={[office.lat, office.lng]}
        properties={{balloonContent: office.address}}
        options={{iconColor: '#76b84c'}}
        modules={['geoObject.addon.balloon']}
      />
    )
  })


  const mapData = () => {
    const { lat, lng } = officies[0]

    return {
      center: [lat, lng],
      zoom: 10
    }
  }

  return (
    <div className={s.wrapper}>
      <YMaps>
        <Map state={mapData()} width={'100%'} height={420}>
          {placemarks}
        </Map>
      </YMaps>
    </div>
  )
}

YMap.propTypes = props

export default YMap
