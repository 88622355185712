import React from 'react'
import s from './BaseInput.module.scss'

const LockedInput = ({placeholder, disabled, value, currency}) => {
  const wrapperClasses = [s.inputWrapper]
  wrapperClasses.push(s.activePlaceholder)
  return (
    <div className={wrapperClasses.join(' ') + ' active'}>
      <div  className={[s.placeholder, disabled ? s['placeholder--disabled'] : ''].join(' ')}>{placeholder}</div>
      <input
        className={s.input}
        disabled={disabled}
        defaultValue={currency ? value + ' ₽' : value}
        readOnly={true}
      />
    </div>
  )
}

export default LockedInput
