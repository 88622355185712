import React from 'react'

import s from './BaseLoader.module.scss'

export default class BaseLoader extends React.Component {
  render() {
    const classes = [s.loader, s[`loader--${this.props.color}`]]

    return (
        <div className={classes.join(' ')}>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
    )
  }
}

BaseLoader.defaultProps = {color: 'green'}
