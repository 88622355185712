import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { backReq, getFormattedPhone } from "../../../../helpers";
import appStore from "../../../../store/app";
import elementsStore from "../../../../store/elementsStore";
import repeatStore from "../../../../store/repeatStore";
import { errorsParser } from "../../../../utils/errorsParser";

import ConfirmCodeInput from "../../../atoms/ConfirmCodeInput/ConfirmCodeInput";

import s from "./../OnlineLoanCard.module.scss";

const ModalApprovedConfirmation = observer(({ onClose, loan }) => {
	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, []);

	async function fetchData() {
		const response = await elementsStore.callSmsAction(() =>
			backReq("online_loan:get_code", { id: loan.id }),
		);
		if (response.errors) {
			elementsStore.showSnackbar(errorsParser(response.errors));
		}
	}

	const confirm = async () => {
		elementsStore.setCheckingSmsCode(true);
		const currentDate = new Date();
		const offsetInMinutes = currentDate.getTimezoneOffset();
		const localDate = new Date(currentDate.getTime() - offsetInMinutes * 60000);
		const isoDateStringWithTimezone = localDate.toISOString().slice(0, -1);

		try {
			const response = await backReq("online_loan:signing", {
				code: elementsStore.smsCode,
				id: loan.id,
				loanAgreementTimestamp: isoDateStringWithTimezone,
			});
			if (response.errors) {
				elementsStore.showSnackbar(errorsParser(response.errors));
				return;
			}
			elementsStore.setSmsCodeConfirmed(true);
			localStorage.removeItem("loanId");
			await repeatStore.setLoansRequestApproveFinalStep(true);
			onClose();
			elementsStore.smsInputRestoreDefault();
		} catch (e) {
			console.warn(e);
		} finally {
			elementsStore.setCheckingSmsCode(false);
		}
	};

	return (
		<div className={s.loanModal}>
			<p className={s.modalText}>
				Для получения денежных средств вам необходимо подписать пакет документов
				простой электронной подписью. Для этого вам необходимо ввести код из
				SMS, который отправлен на телефон&nbsp;
				<span style={{ whiteSpace: "nowrap" }}>
					{getFormattedPhone(appStore.profile.phone)}.
				</span>
			</p>
			<div className={s.modalHalfSpacer} />
			<ConfirmCodeInput onEntered={confirm} onSend={fetchData} />
		</div>
	);
});

export default ModalApprovedConfirmation;
