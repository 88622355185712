import React, { useEffect } from 'react'
import {observer, inject} from 'mobx-react'
import s from './Header.module.scss'
import NotificationsBar from '../../containers/NotificationsBar';
import '../../ws/connection.js'
import {wsLogIn} from '../../ws/messageSender'
import {backReq} from '../../helpers'
import BaseLogo from "../atoms/BaseLogo";
import {NavLink} from "react-router-dom";
import HeaderBurger from "./HeaderBurger/HeaderBurger";
import BaseUser from "../atoms/BaseUser";
import LoanOrderModal from "../../containers/LoanOrderModal";

const Header = inject("appStore")(observer((props) => {
    const {loanOrderModalVisible, setLoanOrderModalVisible, sidebarVisible, changeSidebarVisible, setEntitiesInPayment, profile} = props.appStore

    useEffect(() => {
        wsLogIn()
        setEntitiesInPayment(backReq('list_open_payments').data)
    }, [profile, setEntitiesInPayment])

    return <header className={s.header}>
        <NavLink to="/" className={s.header__logo} onClick={() => changeSidebarVisible(false)}>
            <BaseLogo/>
        </NavLink>
        <div className={s.header__wide}>
            <div/>
            <div className={s.header__right}>
                <NotificationsBar/>
                <div className={[s.header__last, s['header__last--mobile']].join(' ')}  onClick={() => changeSidebarVisible(!sidebarVisible)}>
                    <HeaderBurger active={sidebarVisible}/>
                </div>
                <div className={[s.header__last, s['header__last--tablet']].join(' ')}>
                    <BaseUser/>
                </div>
            </div>
        </div>
        <LoanOrderModal
            showModal={ loanOrderModalVisible }
            closeModal={ () => setLoanOrderModalVisible(false)}
        />
    </header>
}))

export default Header
