import React from 'react';

import {observer} from "mobx-react";
import repeatStore from "../../../../store/repeatStore";
import ModalApprovedInfo from "./ModalArrovedInfo";
import ModalApprovedConfirmation from "./ModalApprovedConfirmation";
import ModalApprovedFinal from "./ModalApprovedFinal";

const ModalApproved = observer ( ({onClose}) => {

  function renderStep(){
    let component
    const loan = repeatStore.activeLoan
    switch (repeatStore.loansRequestApproveStep){
      case 1:{
        component = <ModalApprovedInfo loan={loan} onClose={onClose} />
        break
      }
       case 2:{
        component = <ModalApprovedConfirmation loan={loan} onClose={onClose} />
         break
      }
       case 3:{
        component = <ModalApprovedFinal loan={loan} onClose={onClose} />
         break
      }
      default:{
        component = <ModalApprovedInfo loan={loan} onClose={onClose} />
      }
    }
    return component
  }
  return (
    <div >
      {renderStep()}
    </div>
  )
})

export default ModalApproved
