import React from "react";
import ModalContainer from "../../atoms/ModalContainer";
import BaseButton from "../../atoms/BaseButton";
import s from "./RepeaterSettings.module.scss"
import ReactDOM from "react-dom";
const ChangeOfferModal = ({showModal, change, close, sentencePhoto}) => {
  return ReactDOM.createPortal(
    <ModalContainer
      title={"Изменение предмета залога"}
      closeModal={close}
      showModal={showModal}
    >
      <p>
        При изменении данных по предмету залога, максимальная сумма займа тоже может измениться. <br/>
        {sentencePhoto && 'Также загруженные Вами фотографии по предмету залога удалятся и нужно будет их загрузить заново.'}
      </p>
      <div className={s.changeOfferModal}>
        <BaseButton onClick={change} text={"Изменить"}/>
        <BaseButton onClick={close} text={"Закрыть"}/>
      </div>

    </ModalContainer>
  , document.getElementById("root-modal"));
};
export default ChangeOfferModal;
