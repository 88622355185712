import React from 'react'

import s from './BaseSkeletonInline.module.scss'

export const BaseSkeletonInline = ({loading, children}) => {
  return loading
      ? <span className={s.skeletline}>
          {children}
        </span>
      : <React.Fragment>
        {children}
      </React.Fragment>
}

export default BaseSkeletonInline
