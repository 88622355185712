import React from 'react'

import s from './PaymentCardSelect.module.scss'
import Arrow from '../../../img/chevron.svg'
import PaymentCardSelectElement from "./PaymentCardSelectElement";

class PaymentCardSelect extends React.Component {
    constructor(props) {
        super();
        this.props = props
        this.initialType = this.props.type
        const selected = this.props.options.find(x => x.token === this.props.default)
        this.state = {
            showItems: false,
            selected: selected ? selected: { name: 'Новая карта', token: 'Empty'}
        };
    }
    close() {
        this.setState({showItems: false})
    }
    toggle() {
        this.setState(prevState => ({
            showItems: !prevState.showItems
        }))
    }
    setSelected(x) {
        this.setState({
            selected: {...x}
        })
        this.close()
        this.props.onChanged(x)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.default !== this.props.default) {
            const selected = this.props.options.find(x => x.token === this.props.default)
            this.setState({
                showItems: false,
                selected: selected ? selected : {name: 'Новая карта', token: 'Empty'}
            })
        }
    }

    render() {
        return (
            <div className={ s.wrapper } tabIndex="0" onBlur={ () => this.close() } style={this.props.style}>
                <div className={s.titleWrapper}  onClick={ () => this.toggle() }>
                    <div className={s.title} >
                        { this.state.selected && Object.keys(this.state.selected) ? <PaymentCardSelectElement
                            name={this.state.selected.name}
                            token={this.state.selected.token}
                            image={this.state.selected.image}
                            number={this.state.selected.number}
                            type={this.state.selected.type}
                            onClick={ () => this.close() }
                        /> : 'Новая карта' }
                    </div>
                    <div className={s.label}>Карта</div>
                    <div className={`${s.imageWrapper} ${this.state.showItems ? s.rotate: ''}`}>
                        <img src={Arrow} alt='' />
                    </div>
                </div>
                {
                    this.state.showItems ?
                        <div className={s.items}>
                            { this.props.options.map((x) => (
                                <PaymentCardSelectElement
                                    key={x.token}
                                    name={x.name}
                                    token={x.token}
                                    image={x.image}
                                    number={x.number}
                                    type={x.type}
                                    onClick={ () => this.setSelected(x)}
                                    isOption={true}
                                />
                            ))}
                            <PaymentCardSelectElement
                                name={'Новая карта'}
                                token={'Empty'}
                                onClick={ () => this.setSelected({name: 'Новая карта', token: 'Empty'})}
                                isOption={true}
                            />
                        </div> : null
                }
            </div>
        )
    }
}

export default PaymentCardSelect
