import React from 'react'
import {withRouter} from 'react-router-dom'
import {observer, inject} from 'mobx-react'
import s from './BaseLoans.module.scss'
import NoData from '../../atoms/NoData'
import ContentContainer from '../../molecules/ContentContainer'
import BaseLoansItem from "./BaseLoansItem";

@inject('appStore')
@observer
class BaseLoans extends React.PureComponent {

    componentDidMount() {
        this.props.appStore.fetchAutoPaymentStatus()
    }

    render() {
        const {loans, appStore} = this.props
        if (appStore.isLoansLoading)
            return null
        if (!loans.length)
            return (
                <ContentContainer>
                    <NoData
                        title={'Нет займов'}
                        subtitle={'У вас пока нет активных займов или услуг'}
                        icon={'smail'}
                    />
                </ContentContainer>
            )

        const elements = loans.map((item) => <BaseLoansItem key={item.ln} item={item} />)

        return <div className={s.loans}>{elements}</div>
    }
}

export default withRouter(BaseLoans)
