import React from 'react';
import s from "../BaseLoans/BaseLoans.module.scss";
import CollapseLoanCard from "../../atoms/CollapseLoanCard/CollapseLoanCard";
import {Link} from "react-router-dom";
import BaseTag from "../../atoms/BaseTag";
import BaseLoansClosed from "../BaseLoans/BaseLoansClosed";
import BaseLoansProgress from "../BaseLoans/BaseLoansProgress";
import appStore from "../../../store/app";
import {toJS} from "mobx";
import LoanActionsInfo from "./LoanActionsInfo";
import saveLog from "../../../utils/saveLog";
import LoanAttributesInfo from "./LoanAttributesInfo";

const BaseLoansItem = ({item, isClickLink = true}) => {
    const {
        ln, mfo, hideLoans, closeDate, description, paymentAllowed,
        isGracePeriod, isOverduePayment, isSign, isTaxLate, isClosed,
        isExtensionAgreementNeed,
    } = item

    const {profile = {}} = toJS(appStore)

    const [car, tag] = description ? description.split(',') : ''

    if (isTaxLate || hideLoans || !paymentAllowed) {
        saveLog('opened_loans:check_status', {
            loanId: ln,
            action: 'открытие модального окна',
            isOutdated: isTaxLate,
            hideLoans,
            paymentProcessing: !paymentAllowed,
            userPhone: profile ? profile.phone : 'Не найден'
        }).catch(e => console.log('unable to connect to rollbar', e))
    }

    const isShowCollapse = () => {
        if (isClosed) return false
        if (mfo === 'vi' && !hideLoans) return isTaxLate
       return isGracePeriod || hideLoans || isOverduePayment || isExtensionAgreementNeed
    }

    const CardInfo = <>
        <div className={[s.loans__top, !isSign ? s['loans__top--higher'] : ''].join(' ')}>
            <div className={s.loans__subject}>
                <span className={s.loans__carInfo}>{car || 'no data'}</span>
                <BaseTag text={tag || 'no data'}/>
            </div>
            {isClosed ?
                <BaseLoansClosed closeDate={closeDate}/>
                :
                <LoanActionsInfo loan={item}/>
            }
        </div>
        <BaseLoansProgress loan={item}/>
        <LoanAttributesInfo loan={item}/>
    </>

    return (
        <div className={s.loans__item}>
            {isShowCollapse() &&
                <CollapseLoanCard
                    isGracePeriod={isGracePeriod}
                    isTaxLate={isTaxLate}
                    hideLoans={hideLoans}
                    isExtensionAgreementNeed={isExtensionAgreementNeed}
                />}
            <div
                className={[s.loans__container, isShowCollapse() ? s['loans__container--withCollapse'] : ''].join(' ')}>
                <div className={s.loans__info}>
                    {isClickLink
                        ? <Link to={`/loans/${ln}`}>{CardInfo}</Link>
                        : <>{CardInfo}</>
                    }
                </div>
            </div>
        </div>
    );
};

export default BaseLoansItem;
