import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import s from './TooltipWrapper.module.scss'
import {isTouchDevice} from "../../../utils/isTouchDevice";
import {isMobile} from "../../../utils/isMobile";

const TooltipWrapper = ({children, content, placement='top' || 'bottom', active}) => {
  const [show, setShow] = useState()
  const [top, setTop] = useState(null)
  const [left, setLeft] = useState(null)

  const childrenRef = useRef()
  const tooltipRef = useRef()

  const Portal = ({children}) => ReactDOM.createPortal(children, document.getElementById('root-tooltip'))

  useEffect(() => {
    window.addEventListener('scroll', hideTooltip)
    return () => window.removeEventListener("scroll", hideTooltip)
  }, [])

  const toggleTooltip = () => {
    if(!active) return
    setShow(!show)
    setTimeout(() => {setPosition()}, 1)
  }

  const showTooltip = () => {
    if(!active) return
    setShow(true)
    setTimeout(() => {setPosition()}, 1)
  }

  const hideTooltip = () => {
    setShow(false)
  }

  const setPosition = () => {
    if (childrenRef.current && tooltipRef.current) {
      const childrenRect = childrenRef.current.getBoundingClientRect()
      const {left, bottom, width, top} = childrenRect
      const tooltipHeight = tooltipRef.current.getBoundingClientRect().height
      setTop(placement === 'top' ? top - tooltipHeight -  12: bottom + 12)
      let componentWidth = 0
      if (isMobile()) componentWidth =  (width- 60)
      setLeft(left - width + 40 + componentWidth)
    }
  }

  return (
    <>
      {isTouchDevice ? <div
        className={s.wrapperChildren}
        ref={childrenRef}
        onClick={toggleTooltip}
      >
        {children}
      </div> : <div
        className={s.wrapperChildren}
        ref={childrenRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>}
      {show && <Portal>
        <div
          className={[s.tooltip, s[`tooltip--${placement}`]].join(' ')}
          style={{top: `${top}px`, left: `${left}px`}}
          ref={tooltipRef}
        >

          <div className={s.tooltip__content}>
            <i className={s.tooltip__arrow}/>
            {content}
          </div>
        </div>
      </Portal>}
    </>
  );
};

export default TooltipWrapper;
