import {sendPhotoToDatabase} from "../helpers";

async function loadImageSrc(url, isNeedHeaders) {
    if (!url) return
    if (isNeedHeaders) {
        const sessionToken = localStorage.getItem("sessionToken")
        return await fetch(url, {
            method: "GET",
            headers: {Authorization: sessionToken,}
        })
            .then(_createImageSrcFromResponse)
    }
    return await fetch(url)
        .then(_createImageSrcFromResponse)

}

const _createImageSrcFromResponse = async (res) => res.blob().then(imageBlob => URL.createObjectURL(imageBlob))

const uploadOnlineLoanPhoto = async (type, file) => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("file",file);
    let blobURL = ""
    try {
        const data = await sendPhotoToDatabase(formData)
        blobURL = await loadImageSrc(data.url)
        return {blobURL, id: data.id}
    } catch (e) {
       throw new Error('uploadOnlineLoanPhoto error')
    }
}

export {
    uploadOnlineLoanPhoto,
    loadImageSrc
}
