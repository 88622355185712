import React, {} from "react";

import s from './BaseCollapse.module.scss'

export default class BaseCollapse extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            active: false,
            height: 0
        }

        this.wrapper = React.createRef();

        this.toggle = this.toggle.bind(this);
        this.setHeight = this.setHeight.bind(this);
    }

    toggle (event) {
      if (event.target.classList.contains(s.collapse__wrapper)){
        return
      }
        this.setState(prevState => ({
            active: !prevState.active
        }))
        this.timeout = setTimeout(() => {
            this.setHeight()
            clearTimeout(this.timeout);
        }, 1);
    }

    setHeight () {
        this.setState({
            height: this.state.active
                ? this.wrapper.current.getBoundingClientRect().height + 'px'
                : 0
        })
    }

    render() {
        const classes = [s.collapse, s[`collapse--${this.props.chevron ? 'chevron' : 'default'}`]]
        if (this.state.active) classes.push(s.active)

        return (
            <div className={classes.join(' ')} onClick={this.toggle}>
                <div className={s.collapse__head}>{this.props.head}</div>
                <div className={s.collapse__content} style={{height: this.state.height}}>
                    {typeof this.props.children === 'string' ?
                        <div
                            ref={this.wrapper}
                            className={s.collapse__wrapper}
                            dangerouslySetInnerHTML={{__html: this.props.children}}
                        />
                        : <div
                            ref={this.wrapper}
                            className={s.collapse__wrapper}
                        >
                            {this.props.children}
                        </div>
                    }
                </div>
            </div>
        )
    }
}
