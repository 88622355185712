import React from 'react'
import {observer} from "mobx-react";
import s from "./BannerClub.module.scss";
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";
import {Link} from "react-router-dom";
import Coins from './img/coins.png'
import GreenRect from './img/green_rect.svg'

const BannerClub = observer(() => {
    return <div className={s.bannerClub}>
        <div className={s.mobileWrapper}>
            <div className={s.imageBackground}>
                <img className={s.imageRect} src={GreenRect} alt=""/>
            </div>
            <img className={s.imageMobile} src={Coins} alt=""/>
        </div>
        <div className={s.bannerInfo}>
            <h2 className={s.title}>Зарабатывайте до 9000 ₽</h2>
            <p className={s.info}>за каждую сделку, рекомендуя нас друзьям и знакомым</p>
            <div onClick={() => yandexMetrikaReachGoal('podrobnee_100druzei')}>
                <Link className={s.buttonWrapper} to="/club">
                    Подробнее
                </Link>
            </div>
        </div>
        <div className={s.wrapper}>
            <div className={s.imageBackground}>
            </div>
        </div>
        <img className={s.image} src={Coins} alt=""/>
    </div>
})
export default BannerClub
