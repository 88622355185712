import React from 'react'
import s from './BaseSnackbar.module.scss'

const CountdownCircle = ({children}) =>{
    return <div className={s.countdownCircle}>
    <div className={s.circleProgress_wrapper}>
        <div className={[s.wrapper, s.right].join(' ')}>
            <div className={[s.circleProgress, s.rightcircle].join(' ')}></div>
        </div>
        <div className={[s.wrapper, s.left].join(' ')}>
            <div className={[s.circleProgress, s.leftcircle].join(' ')}></div>
        </div>
    </div>
        {children}
    </div>
}
export default CountdownCircle
