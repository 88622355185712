import React from 'react';
import s from './../OnlineLoanCard.module.scss'
import BaseButton from "../../../atoms/BaseButton";
import {renderRuMoscowDateTime} from "../../../../utils/formattedDate";
import formattedMoney from "../../../../utils/formattedMoney";
import {VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL} from "../../../../constants/phones";
const ModalInfo = ({onClose, loan}) => {

  function addHour(date){
    if (!date) return
    const updatedDate = new Date(date)
    updatedDate.setHours(updatedDate.getHours()+1)
    return updatedDate
  }

  return (
    <div className={s.loanModal}>
      <p className={s.modalText}>Мы максимально быстро подготовим пакет документов и сообщим Вам об изменении статуса заявки (в Личном кабинете и по SMS).</p>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Дата и время отправки заявки</span>
        <p className={s.modalInfoDate}>{renderRuMoscowDateTime(loan?.changeStatusDate ?? loan.offer?.created)} (МСК)</p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Ориентировочное время окончания проверки</span>
        <p className={s.modalInfoDate}>{renderRuMoscowDateTime(addHour(loan?.changeStatusDate ?? loan.offer?.created))} (МСК)</p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Продукт</span>
        <p>Наименование: <b>"Онлайн-займ"</b></p>
        <p> Сумма займа: <b>{formattedMoney(loan?.desiredAmount)} рублей</b></p>
        <p> Срок займа: <b>{loan?.loanTerm} {loan?.loanTerm === 12 ? 'месяцев' : 'месяца'}</b></p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Предмет залога</span>
        <p>Авто: <b>{loan?.offer?.name ?? loan.name}</b></p>
        <p>ВИН: <b>{loan?.offer?.vin ?? loan.vin}</b></p>
        <p>Гос. номер: <b>{loan?.offer?.regNum ?? loan.regNum}</b></p>
      </div>
      <div className={s.modalInfoTextWrapper}>
        <span className={s.modalTitle}>Способ получения займа</span>
        <p>На банковскую карту: <b> **** {loan?.cardNumber}</b></p>
      </div>
      <div>
        <p className={s.modalPhone}>Телефон горячей линии:<br/>
          <a href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a></p>
      </div>
      <BaseButton text={"Закрыть"} onClick={onClose}/>
    </div>
  )
}

export default ModalInfo
