import { backReq } from './helpers';

const getErrorFromErrorsObject = (errors) => {
  if (typeof errors !== 'object')
    return null;

  if (Object.keys(errors).length === 0)
    return null;

  const errorKey = Object.keys(errors)[0];

  if (!errorKey)
    return null;

  return errors[errorKey] || null;
};

const GENERIC_ERROR_MESSAGE = 'Неизвестная ошибка';

const safeRequestWrap = (endpoint, data) =>
  backReq(endpoint, data).then((response) => {
    if (!response || !response.status) {
      return Promise.reject(GENERIC_ERROR_MESSAGE);
    }

    if (response.status === 'error') {
      return Promise.reject(getErrorFromErrorsObject(response.errors) || GENERIC_ERROR_MESSAGE);
    }

    return Promise.resolve(response.data);
  });

/**
 * Api Library
 */
export default {
  notifications: {
    get: (limit = 10, offset = 0, onlyUnread = false, ) =>
      safeRequestWrap('notification_history', { offset, limit, all: onlyUnread ? 'no' : 'yes' }),
    read: (ids = []) =>
      safeRequestWrap('notification_is_read', { read: ids }),
  },
};
