import React from "react";
import s from "./OnlineLoanIncome.module.scss";
import BaseInput from "../../atoms/BaseInput";
import PhoneInput from "../../atoms/PhoneInput";
import DadataInput from "../../atoms/DadataInput";

const ContactPersonForm = ({contactForm, setContactForm}) => {

    const { contact, fio, phone, address } = contactForm

    return <div className={s.inputsGroup}>
        <div>
            <BaseInput
                placeholder="Кем является"
                defaultValue={contact}
                value={(x) => setContactForm({...contactForm,contact: x })}
            />
        </div>
        <div>
            <BaseInput
                placeholder="Фамилия Имя Отчество"
                defaultValue={fio}
                value={(x) => setContactForm({...contactForm, fio:x})}
            />
        </div>
        <div>
            <PhoneInput
                placeholder="Номер телефона"
                defaultValue={phone}
                value={(x) => setContactForm({...contactForm, phone:x})}
            />
        </div>
        <div className={s.wideColumn}>
            <DadataInput
                placeholder="Адрес проживания"
                defaultValue={address}
                value={(x) => setContactForm({...contactForm, address:x})}
            />
        </div>

    </div>
}
export default ContactPersonForm
