import React from 'react'

import s from './InputSlider.module.scss'
import SliderBottom from "./SliderBottom";
import formattedMoney from "../../../utils/formattedMoney";
import BaseCurrency from "../BaseCurrency";

const InputSlider = ({value, onChange, placeholder, min, max, step, disabled = false}) => {

    const onInput = value => {
        if (disabled) return;

        value = formatValue(value)

        let emitValue
        if (!value) {
            emitValue = ''
        } else {
            emitValue = +value
        }
        onChange(emitValue)
    }
    const onBLur = value => {

        value = formatValue(value)

        let emitValue = +value

        if (min >= emitValue) {
            emitValue = min
        }

        if (max <= emitValue) {
            emitValue = max
        }
        onChange(emitValue)
    }

    const formatValue = value => {
        if(typeof value === 'string') {
            value = Number(value.replace(/[\s,]/g, ''))
        }
        return value
    }

    return <label className={s.slider}>
        <div className={s.slider__main}>
            <input className={s.slider__input}
                   value={formattedMoney(value)}
                   onInput={e => onInput(e.target.value)}
                   onBlur={e => onBLur(e.target.value)}
                   placeholder={placeholder}
                   type="text"
                   id="slider-calculator"
                   name="slider-calculator"
                   aria-labelledby="slider-calculator"
                   disabled={disabled}
            />
            <div className={s.slider__placeholder}>
                {placeholder}
            </div>
            <div className={s.slider__currency}>
                <BaseCurrency  size={13} color={'dark'}/>
            </div>
            <SliderBottom
                onChange={x => onInput(x)}
                value={value}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
            />
        </div>
        <div className={s.slider__description}>
            <p>{`от ${formattedMoney(min)}`}&thinsp;
                <BaseCurrency size={10} color={'grey'}/>
            </p>
            <p>{`до ${formattedMoney(max ?? min)}`}&thinsp;
                <BaseCurrency size={10} color={'grey'}/>
            </p>
        </div>
    </label>
}

export default InputSlider
