import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {backReq} from '../../helpers'
import ServicesTable from '../../components/organisms/ServicesTable'
import LastArticleList from './LastArticleList'
import LoanList from '../../components/organisms/LoanList'
import BaseClub from '../../components/organisms/BaseClub'
import BaseSkeleton from '../../components/atoms/BaseSkeleton'
import {getPromoInfo, isPromoExist, ONLINE_LOAN_PROMO} from "../../utils/onlineLoanPromo";
import {Redirect} from "react-router-dom";
import {when} from "mobx";
import ModalApprovedFinal from "../../components/organisms/OnlineLoanCard/modal/ModalApprovedFinal";
import ModalContainer from "../../components/atoms/ModalContainer";
import Banner from "./banner";
import BannerClub from "./BannerClub";
import appStore from "../../store/app";
import BaseAutoPayment from "../../components/organisms/BaseAutoPayment";
import LoansRequestPage from "../loansRequest";
import s from './Dashboard.module.scss'

@inject('appStore', 'repeatStore')
@observer
class DashboardPage extends Component {
    state = {
        loading: true,
        showModal: false,
        services: [],
        archivedServices: [],
        onlineLoanOrder: false,
        onlineLoanSigningPopup: false,
        loan: JSON.parse(JSON.stringify(this.props.repeatStore.activeLoan)),
        onlineLoanOffer: {},
        isOfferExist: false
    }

    componentDidMount() {
        this.fetchData()
        when(
            () => this.props.repeatStore.onlineLoanNewStatus,
            () => {
                this.props.repeatStore.refreshOfferExist().then(() => this.props.repeatStore.setOnlineLoanNewStatus(false))
            }
        );
        when(() => this.props.repeatStore.loansRequestApproveFinalStep,
            () => {
                const loan = JSON.parse(JSON.stringify(this.props.repeatStore.activeLoan))
                this.setState({
                    loan,
                    onlineLoanSigningPopup: true,
                })
            })
    }

    componentDidUpdate() {
        when(
            () => this.props.repeatStore.onlineLoanNewStatus,
            () => {
                this.props.repeatStore.refreshOfferExist().then(() => this.props.repeatStore.setOnlineLoanNewStatus(false))
            }
        );
    }

    fetchData() {
        Promise.all([
            appStore.fetchLoans(),
            backReq('get_auto_payment'),
            backReq('amenities'),
            backReq('amenities', {isActive: false}),
            appStore.refreshOnlineLoanOrderExist(),
            backReq('online_loan:list', {status: ['complete']}),
            appStore.getOpenLoanRequests(),
            appStore.getClosedLoanRequests(),
            backReq('online_loan:exists')
        ]).then((res) => {
            this.completeLoanModalHandler(res[5]?.data || undefined)
            this.setState({
                loading: false,
                autoPayment: res[1]?.data,
                services: res[2]?.data.map((service) => ({
                    title: service.ServiceName,
                    amount: service.ServiceSum,
                    id: service.ServiceCode,
                    sideLoan: service.Ln,
                    entityId: service.EntityId
                })),
                archivedServices: res[3]?.data.map((service) => ({
                    title: service.ServiceName,
                    amount: service.ServiceSum,
                    id: service.ServiceCode,
                    sideLoan: service.Ln,
                    entityId: service.EntityId
                })),
                isOfferExist: res[8]?.data?.isOfferExist || false
            })
        })
    }

    completeLoanModalHandler(data) {
        if (!(data && data.list)) return []
        const notRated = data.list.filter(loan => loan.isRateModalWasShown === false)
        if (!notRated.length) return
        this.props.repeatStore.setNotRatedOnlineLoans(notRated)
        this.props.repeatStore.setIsNotRatedOnlineLoans(true)
    }

    closeSigningPopup() {
        this.setState({
            onlineLoanSigningPopup: false
        })
    }

    render() {
        const {loading, autoPayment, services, archivedServices, isOfferExist} =
            this.state
        if (loading || this.props.appStore.isLoansLoading) return <BaseSkeleton/>
        if (isPromoExist(ONLINE_LOAN_PROMO)) {
            return <Redirect to={getPromoInfo(ONLINE_LOAN_PROMO)}/>
        }
        return (
            <div className="container">
                <div className={s.promoWrapper}>
                    {isOfferExist ? <Banner/> : <BannerClub/>}
                </div>
                <LoansRequestPage/>
                <LoanList
                    autoPaymentStatus={autoPayment?.autopayment}
                    padding={true}
                />
                {services.length !== 0 && <ServicesTable
                    services={services}
                    archivedServices={archivedServices}
                    loanLink={true}
                />}
                <BaseAutoPayment autoPayment={autoPayment}/>
                <BaseClub padding={true}/>
                <LastArticleList/>
                <ModalContainer
                    showModal={this.state.onlineLoanSigningPopup}
                    closeModal={() => this.closeSigningPopup()}
                    title={'Документы подписаны'}
                >
                    <ModalApprovedFinal loan={this.state.loan} onClose={() => this.closeSigningPopup()}/>
                </ModalContainer>
            </div>
        )
    }
}

export default DashboardPage
