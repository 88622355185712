export const errorsParser = (errors) => {
  if (typeof errors === 'string') return errors;
  if (errors.errors) return errorsParser(errors.errors);

  let errorText = ''
  for (let [key, value] of Object.entries(errors)) {
    if (key !== 'detail') {
      errorText += `${_keyValidation(key)} : ${_valueValidation(value)} \n`
    } else {
      errorText += ` ${_valueValidation(value)} \n`
    }
  }
  return errorText.replace(/<[^>]+>/g,'')
}

function _keyValidation(key) {
  return ErrorKeyField[key] ?? key
}

function _valueValidation(value) {
  if (Array.isArray(value)) {
    return value.join(' ')
  }
  if (value && typeof value === 'object') {
    let values = ''
    for (let [key, innerValue] of Object.entries(value)) {
      values += `${_keyValidation(key)} : ${_valueValidation(innerValue)}`
    }
    return values
  }
  return ErrorValuesField[value] ?? value
}

const ErrorKeyField = {
  currentStep: 'Текущий шаг',
  electronicInteractionAgreement: 'Соглашения об электронном взаимодействии',
  personalDataAgreement: 'Согласие на обработку персональных данных',
  creditHistoryAgreement: 'Согласие на предоставление и получение информации в Бюро кредитных историй',
  desired_amount: 'Сумма займа',
  loanTerm: 'Длительность',
  loanPurpose: 'Цель займа',
  additionalPhone: 'Дополнительный телефон',
  email: 'Электронная почта',
  orderEmail: 'Электронная почта',
  registration: 'Место регистрации',
  employmentStatus: 'Статус трудоустройства',
  employmentPosition: 'Должность',
  company: 'Наименование компании',
  income: 'Основной доход семьи',
  additionalIncome: 'Дополнительный доход',
  additionalIncomeSource: 'Источник доп. дохода',
  maritalStatus: 'Семейное положение',
  cardNumber: 'Номер карты',
  detail: 'Детали',
  photos: 'Фотографии',
  passportMain: 'Паспорт основной разворот (2:3 страницы)',
  passportSelfie: 'Паспорт селфи с разворотом на 2:3 странице',
  passportRegistration: 'Паспорт прописка',
  passportAdditional: 'Паспорт дополнительное фото',
  carFront: 'Авто со стороны капота',
  carBack: 'Авто со стороны багажника',
  carLeft: 'Авто с левого бока',
  carRight: 'Авто с правого бока',
  carSelfie: 'Авто селфи',
  carVin: 'Авто ВИН',
  carOdometer: 'Авто одометр',
  ptsFront: 'ПТС (фронтальная сторона)',
  ptsBack: 'ПТС (оборотная сторона)',
  stsFront: 'СТС (фронтальная сторона)',
  stsBack: 'СТС (оборотная сторона)',
  desiredAmount: 'Сумма займа',
  passportCheckPassed: 'Основной разворот паспорта',
  livelinessCheckPassed: 'Загрузите сэлфи',
}
const ErrorValuesField = {
  params: 'Параметры займа',
  agreements: 'Соглашение',
  personal_confirm: 'Подтверждение личности',
  personal_data: 'Персональные данные',
  loan_subject: 'Предмет залога',
  card: 'Банковская карта',
  summary: 'Отправка заявки',
}
