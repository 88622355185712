import React, {Component} from 'react'

import BaseTag from "../../atoms/BaseTag";
import BaseTooltip from "../../atoms/BaseTooltip";

import s from './AutoPayment.module.scss'
import ContentContainer from "../../molecules/ContentContainer";
import AutoPayment from "../../molecules/AutoPayment";

export default class BaseAutoPayment extends Component{
    render() {
        const {autoPayment, extra, fetchCardList, cards} = this.props
        return (
            <div className={s.ap}>
                <h2 className="title">Автоплатеж</h2>
                <div className={s.ap__tag}>
                    <BaseTag text="BETA"/>
                </div>
                {
                    !extra && <div className={s.ap__tooltip}>
                        <BaseTooltip>
                            Подключите автоплатеж для автоматических платежей по займам
                        </BaseTooltip>
                    </div>
                }
                {
                    <div className={s.ap__block}>
                        <ContentContainer>
                            <AutoPayment autoPayment={autoPayment} fetchCardList={fetchCardList} cards={cards} extra={extra}/>
                        </ContentContainer>
                    </div>
                }
            </div>
        )
    }
}
