import React, { useState, useRef } from 'react'
import s from './CardItem.module.scss'
import DeleteIcon from './img/delete.svg'
import ConfirmModal from '../../../../atoms/ConfirmModal';

const CardItem = ({ name, number, type, onUpdatedName, onDeleted, onSelect, token, checked }) => {

  const [editingName, setEditingName] = useState(false)
  const [ showModal, setVisible ] = useState(false)
  const inputName = useRef()

  const toggleEdit = () => {
    setEditingName(!editingName)
    if (!editingName) {
      setTimeout(() => inputName.current.focus(), 100)
    }
  }

  const onKeyUpValue = (e) => {
    if (e.key === 'Enter') {
      saveName(e)
    }
  }

  const saveName = (e) => {
    const value = e.target.value.trim()
    toggleEdit()

    if (value) {
      onUpdatedName(value)
    }
  }

  const showConfirmModal = () => {
    setVisible(true)
  }
  const deleteCard = () => {
    onDeleted()
    setVisible(false)
  }

  return <React.Fragment>
    <div className={s.card}>
      <div>
        <img src={require(`../../../../../img/cards/${type}.svg`)} alt='' />
        <p>
          {editingName ? (
              <input
                  ref={inputName}
                  placeholder='Введите имя карты'
                  onKeyUp={(e) => onKeyUpValue(e)}
                  onBlur={saveName}
              />
          ) : (
              <b onClick={toggleEdit}>{name}</b>
          )}
          <span>{number}</span>
        </p>
      </div>
      {onDeleted && <img src={DeleteIcon} alt='' onClick={() => showConfirmModal()}/>}
      {onSelect &&
        <label>
          <input type="radio" id={token} name="cardType" value={token} checked={token === checked} onChange={(e) => onSelect(e.target.value, e.target.checked)}/>
        </label>
      }
    </div>

    <ConfirmModal
        title='Вы уверены ?'
        onOk={ () => deleteCard() }
        onClose = { () => setVisible(false) }
        showModal = { showModal }
    />
  </React.Fragment>
}

export default CardItem
