import React, { useEffect, useState } from "react";
import s from "./RepeaterModeration.module.scss";
import BaseClose from "../../atoms/BaseClose";
import BaseTabs from "../../atoms/BaseTabs";
import LoanTab from "./tabs/LoanTab";
import StatusTab from "./tabs/StatusTab";
import FinalTab from "./tabs/FinalTab";
import CodesTab from "./tabs/CodesTab";
import AutoSelect from "../../atoms/AutoSelect";
import elementsStore from "../../../store/elementsStore";
import appStore from "../../../store/app";

const ModerationTabs = ({ close }) => {
    const [activeTab, setActiveTab] = useState("Loan")
    const [moderatedId, setModeratedId] = useState();
    const [moderatedIdList, setModeratedIdList] = useState([]);
    
    //eslint-disable-next-line
    useEffect(async () => {
        const isOnlineLoanAdmin = appStore?.profile?.isOnlineLoanAdmin
        if (!isOnlineLoanAdmin) return null;
        const list = await getModeratedIds()
        elementsStore.setModeratedIdList(list)
        setModeratedIdList(list)
        //eslint-disable-next-line
    }, [appStore?.profile?.isOnlineLoanAdmin])

    useEffect(() => {
        setModeratedIdList(elementsStore.moderatedIdList)
        //eslint-disable-next-line
    }, [elementsStore.moderatedIdList]);

    const getModeratedIds = async () => {
        const openLoanRequestsId = appStore.openLoanRequests.map(item => item.id)
        return [...openLoanRequestsId]
    }

    useEffect(() => elementsStore.setModeratedId(moderatedId),
        [moderatedId])

    const tabs = {
        Loan: "Управление заявкой",
        Status: "Управление промежуточными статусами",
        Final: "Управление финальными статусами",
        Codes: "Коды из SMS",
    }

    const currentTab = () => {
        switch (activeTab) {
            case 'Loan':
                return <LoanTab />
            case 'Status':
                return <StatusTab />
            case 'Final':
                return <FinalTab />
            case 'Codes':
                return <CodesTab />
            default:
                return <LoanTab />
        }
    }

    return <div className={[s.moderationTabs, s.repeaterModeration].join(' ')}>
        <div className={s.moderationClose} onClick={close}>
            <BaseClose />
        </div>
        <div className={s.moderationSelect}>
            <AutoSelect
                placeholder="id заявки"
                defaultValue={moderatedId}
                options={moderatedIdList}
                onChanged={(x) => setModeratedId(x)}
                onSearch={null}
            />
        </div>
        <div className={s.moderationTabs__tab}>
            <BaseTabs active={activeTab} list={tabs} change={(x) => setActiveTab(x)} />
            <div className={s.moderationTabs__tabWrapper}>
                {currentTab()}
            </div>
        </div>
    </div>
}
export default ModerationTabs
