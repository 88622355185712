import React from 'react'
import { observer, inject } from 'mobx-react'
import s from './styles.module.scss'
import ModalContainer from '../../components/atoms/ModalContainer';
import BaseButton from '../../components/atoms/BaseButton'
import BaseCheckbox from "../../components/atoms/BaseCheckbox";

import {terms} from "../../components/Footer/links";
import {backReq} from "../../helpers";

@inject('appStore')
@observer
class LoanOrderModal extends React.PureComponent {

  state = {
    accepted: true
  }
  closeModal = () => {
      this.props.appStore.setShowUserAgreement(false)
  }
  accept = async () => {
    const result = await backReq('accept_user_agreement')
    if (result.code === 200) {
      this.props.appStore.setShowUserAgreement(false)
    }
  }

  render () {
    const { showUserAgreement } = this.props.appStore
    return (
      <ModalContainer
        showModal={ showUserAgreement }
        closeModal={ () => this.closeModal()}
        title="Пользовательское соглашение"
      >
        <div className={s.userAgreement}>
            <div className="text">Для использования Личного кабинета необходимо принять <a href={terms} target="_blank" rel="noopener noreferrer">пользовательское соглашение</a></div>
            <div>
                <div className={s.checkboxWrapper}>
                    <BaseCheckbox value={this.state.accepted} onChange={() => this.setState({accepted: !this.state.accepted})}>
                        Я принимаю пользовательское соглашение
                    </BaseCheckbox>
                </div>
                <div className={s.btnWrapper}>
                    <BaseButton
                        type="green"
                        text="Подтвердить"
                        onClick={() => this.accept() }
                        disabled={!this.state.accepted}
                    />
                </div>
            </div>
        </div>
      </ModalContainer>
    )
  }
}

export default LoanOrderModal
