import React from "react";
import s from "./Subject.module.scss";
import ContentContainer from "../../molecules/ContentContainer";
import CarIcon from "../img/car.svg";

const Subject = ({description}) => {
    return <section className={s.subject}>
        <h2 className="title">Предмет залога</h2>
        <ContentContainer>
            <article>
                <img src={CarIcon} alt=''/>
                <p>
                    <span>Марка</span>
                    <b>{description?.split(',').join(', ')}</b>
                </p>
                <p>
                    <span>Тип</span>
                    <b>Иностранный автомобиль</b>
                </p>
            </article>
        </ContentContainer>
    </section>
}

export default Subject
