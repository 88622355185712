import React, {useEffect, useState} from 'react';

import {checkPasswordStrong} from "../../../utils/checkPasswordStrong";

import s from './StrongPasswordLine.module.scss'

const StrongPasswordLine = ({text, value}) => {

  const [fill, setFill] = useState({width: '0%', background: 'transition'})

  useEffect(() => {
    changeFill()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const changeFill = () => {
    if(value) {
        // eslint-disable-next-line default-case
      switch (checkPasswordStrong(value.trim())) {
        case 0:
          setFill({width: '25%', background: '#FF4D4F'})
          break;
        case 1:
          setFill({width: '50%', background: '#FFB800'})
          break;
        case 2:
          setFill({width: '75%', background: '#b5dc0b'})
          break;
        case 3:
          setFill({width: '100%', background: '#4CAF50'})
          break;
      }
    } else {
      setFill({width: '0%', background: 'transition'})
    }
  }

  return (
    <div>
      <div className={s.baseLine}>
        <div style={fill} className={s.fillLine}/>
      </div>
      <div className={s.text}>{text}</div>
    </div>
  );
};

StrongPasswordLine.defaultProps = {
  text: 'Пароль должен содержать одну букву, одну цифру и быть длиной не менее 6 символов'
}

export default StrongPasswordLine;
