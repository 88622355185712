import React, {useEffect, useState} from "react"
import BaseInput from "components/atoms/BaseInput"
import BaseButton from "components/atoms/BaseButton"

import s from "./RepeaterCard.module.scss"
import {backReq} from "../../../helpers";
import LockedInput from "../../atoms/BaseInput/LockedInput";
import {errorsParser} from "../../../utils/errorsParser";
import useErrorContainer from "../../../hooks/useErrorContainer";
import repeatStore from "../../../store/repeatStore";
import {observer} from "mobx-react";
import {checkCurrentStep, moveToNextOnlineLoanStep} from "../helpers";
import {repeaterStepsArray} from "../../../constants/onlineLoan";
import useRefreshLoanDetail from "../../../hooks/useRefreshLoanDetail";
import elementsStore from "../../../store/elementsStore";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";

const RepeaterCard = observer(() => {
  const [card, setCard] = useState('')
  const [repeatCard, setRepeatCard] = useState('')
  const [isCardLoaded, setIsCardLoaded] = useState(false)
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [ isCardsValid, setIsCardsValid] = useState(false)

  const [isCardDataError, setIsCardDataError, cardDataContainerRef] = useErrorContainer()

  const [fetchDataErrors] = useRefreshLoanDetail(setCardData)
  async function setCardData() {
    try {
      setEmail(repeatStore.loanDetail.orderEmail)
      if (repeatStore.loanDetail.cardNumber) {
        setCard(`**** ${repeatStore.loanDetail.cardNumber}`)
        setRepeatCard(`**** ${repeatStore.loanDetail.cardNumber}`)
        setIsCardLoaded(true)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    setIsCardsValid(
      (card.length >= 19 && repeatCard.length >= 19)
      && (card === repeatCard))
  },[card, repeatCard, isCardLoaded])

  useEffect(() => {
    if (fetchDataErrors) elementsStore.showSnackbar(errorsParser(fetchDataErrors));
  },[fetchDataErrors])

  async function checkStatus() {
    if (card !== repeatCard) {
      elementsStore.showSnackbar("Номера карт не совпадают")
      setIsCardDataError(true)
      return
    }
    try {
      setLoading(true)
      const stepError = await checkCurrentStep(repeaterStepsArray[5])
      if (stepError) {
        elementsStore.showSnackbar(errorsParser(stepError))
        return false
      }
      const response = await backReq("online_loan:card", {
        "cardNumber": card
      })
      if (response.code === 200) {
        setIsCardLoaded(true)
      } else {
        elementsStore.showSnackbar(errorsParser(response?.errors) || JSON.stringify(response))
        setIsCardDataError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  function changeCard() {
    setCard("")
    setRepeatCard("")
    setIsCardLoaded(false)
  }

  async function validate() {
    if ((!card || !repeatCard) && !isCardLoaded) {
      setIsCardDataError(true)
      elementsStore.showSnackbar('Введите карту')
      return false
    }
    if (!isCardLoaded) {
      setIsCardDataError(true)
      elementsStore.showSnackbar('Подтвердите карту')
      return false
    }
    return (await nextStep())
  }

  async function nextStep() {
    try {
      setLoading(true)
      const nextStepErrors = await moveToNextOnlineLoanStep(repeaterStepsArray[6])
      if (nextStepErrors){
        elementsStore.showSnackbar(errorsParser(nextStepErrors))
      }
    } catch (e) {
      console.warn(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <ErrorContainer innerRef={cardDataContainerRef} hasError={isCardDataError}>
        <div className={s.card}>
          <h2 className="title">Банковская карта для получения займа</h2>

          <div className={s.textWrapper}>
            <p>
              В поля ниже внимательно введите номер новой банковской карт
              (обычно это от 16 до 19 цифр на лицевой стороне карты).
            </p>
          </div>

          <div className={s.inputsWrapper}>
            <BaseInput
              placeholder="Номер карты"
              type={"card"}
              defaultValue={card}
              value={(x) => setCard(x)}
              disabled={isCardLoaded}
            />
            <BaseInput
              placeholder="Повторите номер карты"
              type={"card"}
              defaultValue={repeatCard}
              value={(x) => setRepeatCard(x)}
              disabled={isCardLoaded}
            />
            <LockedInput
              placeholder="Email *"
              value={email}
              disabled={true}
            />
            <div className={s.infoText}>
              <span>* На Email придет квитанция об оплате</span>
            </div>
          </div>
          <div className={s.buttonWrapper}>
            <BaseButton
              loading={loading}
              text={isCardLoaded ? "Подтверждено" : "Подтвердить"}
              size="default-change"
              type={'green'}
              onClick={checkStatus}
              disabled={isCardLoaded || !isCardsValid}
            />
            {isCardLoaded &&
              <p onClick={changeCard}>Изменить</p>
            }
          </div>
        </div>
      </ErrorContainer>
      <OnlineLoanButtons callback={validate}/>
    </div>
  )
})

export default RepeaterCard
