import React from "react";
import { privacyPolicy } from "../../Footer/links";
import s from './PolicyAgreement.module.scss'
const PolicyAgreement = ({ buttonName }) => {
    //eslint-disable-next-line
    return <div className={s.policy}> Нажимая кнопку «{buttonName ?? 'Продолжить'}», вы подтверждаете, что согласны на обработку <a href="https://vashinvestor.ru/usloviya-obrabotki-pd/" target="_blank">персональных данных</a>
        {/* eslint-disable-next-line */}
        {' '}на основании <a href={privacyPolicy} target="_blank">политики конфиденциальности</a>
    </div>
}
export default PolicyAgreement
