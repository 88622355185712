import React, {useEffect} from 'react';
import ModalContainer from "../../atoms/ModalContainer";
import {inject, observer} from "mobx-react";
import s from './SignDocModal.module.scss'
import CardDoc from "./CardDoc";
import {backReq, getFormattedPhone} from "../../../helpers";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";
import elementsStore from "../../../store/elementsStore";
import appStore from "../../../store/app";
import {errorsParser} from "../../../utils/errorsParser";

const SignDocModal = inject('appStore')(
    observer((props) => {
        const {
            isShowSignDocModal,
            closeSignDocModal,
            docsForSignModal,
            profile,
            currentEntityForPay,
            fetchLoans,
            updateCurrentLoan
        } = props.appStore

        useEffect(() => {
            if (isShowSignDocModal) makeRequest();
            else elementsStore.smsInputRestoreDefault();
        }, [isShowSignDocModal])

        const signDocuments = async () => {
            elementsStore.setCheckingSmsCode(true)
            const response = await backReq('client_loan_docs_signed', {
                ln: currentEntityForPay.ln,
                code: elementsStore.smsCode
            })
            if (response.errors){
                elementsStore.setSmsCodeError(errorsParser(response.errors))
                elementsStore.setCheckingSmsCode(false)
                return false
            }
            elementsStore.setSmsCodeError('')
            elementsStore.setSmsCodeConfirmed(true)
            elementsStore.setCheckingSmsCode(false)
            fetchLoans()
            updateCurrentLoan()
            setTimeout(()=>{
                closeSignDocModal()
            }, 500)

        }

        const makeRequest = async () => {
            await elementsStore.callSmsAction(()=>backReq("send_phone_code", {
                phone: appStore.profile.phone,
            }))
        }

        return (<>
            <ModalContainer
                showModal={isShowSignDocModal}
                closeModal={() => closeSignDocModal()}
                title={'Подписание дополнительных соглашений'}
            >
                <p className={s.text}>
                    Подписание дополнительных соглашений необходимо для пролонгации договора, если он закончился, но
                    Вы желаете его продлить, а также для смены даты платежа, суммы платежа или уменьшения основного
                    долга при частичном погашении займа.
                </p>
                <div className={s.cardDoc}>
                    {docsForSignModal.map(doc => (
                      <CardDoc doc={doc} key={doc.url}/>
                    ))}
                </div>
                <div className={s.textCode}>
                    <p>Мы отправили код доступа на номер <span
                        className={s.phone}>{profile?.phone ? getFormattedPhone(profile.phone) : ''}.</span></p>
                    <p>Введите код из SMS:</p>
                </div>
                <ConfirmCodeInput onEntered={signDocuments} onSend={makeRequest}/>
            </ModalContainer>
    </>)
    }))

export default SignDocModal;
