import React from 'react'

import s from './BaseCompleted.module.scss'
import successIcon from '../../../img/success.svg'

const BaseCompleted = ({text}) => {
    return <span className={s.autoPayment}>
        <img src={successIcon} height="16px" width="16px" alt=''/>
        <span>{text}</span>
    </span>
}

export default BaseCompleted
