import React, { useEffect, useState } from 'react';
import s from './../OnlineLoanCard.module.scss'
import { Slider, InputNumber } from 'antd';
import { backReq } from "../../../../helpers";
import { errorsParser } from "../../../../utils/errorsParser";
import formattedMoney from "../../../../utils/formattedMoney";
import elementsStore from "../../../../store/elementsStore";
import BaseButton from 'components/atoms/BaseButton';

const ModalIncrease = ({ onClose, loan }) => {
    const [sum, setSum] = useState(loan.maxAmount)

    useEffect(() => {
        if (loan?.maxAmount) setSum(loan.maxAmount);
    }, [loan])

    async function agreedToIncrease() {
        try {
            const response = backReq("online_loan:modify_amount", {
                desiredAmount: sum,
                id: loan.id
            })
            if (response.errors) {
                elementsStore.showSnackbar(errorsParser(response.errors))
                return false
            }
            elementsStore.showSnackbar("Изменения по заявке приняты. Ожидайте обновления статуса", "onCheck")
            onClose()
        } catch (e) {
            elementsStore.showSnackbar(errorsParser(e))
            return false
        }
    }

    const loanSubject = () => {
        if (loan?.offer?.name || loan.name) {
            return loan?.offer?.name || loan.name
        }

        if (loan.loanSubject) {
            return loan.loanSubject
        }

        if (loan.carMark) {
            return loan.carMark + ' ' + loan.carModel + ' ' + loan.releaseYear
        }
    }

    const marks = {
        [loan.minAmount]: {
            label: formattedMoney(loan.minAmount)
        },
        [loan.maxAmount]: {
            label: formattedMoney(loan.maxAmount)
        }
    }

    return (
        <div className={`${s.loanModal} ${s.increaseModal}`}>
            <div>
                <div>
                    <span className={s.modalLabel}>Авто:</span> {loanSubject()}
                </div>
                <div>
                    <span className={s.modalLabel}>Срок:</span> {loan.loanTerm} мес.
                </div>
                <span className={s.modalLabel}>Платеж от:</span> {formattedMoney(sum*0.075)}&nbsp;₽
            </div>
            <div className={s.sliderWrapper}>
                <div>Cумма к получению</div>
                <InputNumber
                    className={s.sliderInput}
                    min={loan.minAmount}
                    max={loan.maxAmount}
                    step={1000}
                    onChange={v => setSum(v)}
                    value={sum}
                    defaultValue={loan.maxAmount}
                    formatter={v => `${v.toLocaleString()} ₽`}
                />
                <Slider
                    className={s.slider}
                    min={loan.minAmount}
                    max={loan.maxAmount}
                    step={1000}
                    onChange={v => setSum(v)}
                    value={sum}
                    defaultValue={loan.maxAmount}
                    marks={marks}
                    tipFormatter={null}
                />
            </div>
            <BaseButton text={`Получить ${sum} ₽`} onClick={agreedToIncrease}/>
        </div>
    )
}

export default ModalIncrease
