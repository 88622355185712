export const isDev = ["stage", "development", "staging"].includes(
	process.env.NODE_ENV,
);

export const paymentUrl = isDev
	? "https://test.best2pay.net"
	: "https://pay.best2pay.net";

export const apiURL = isDev
	? "https://core-stage.vashinvestor.ru/api/v1"
	: "https://core.vashinvestor.ru/api/v1";
export const apiURLv2 = isDev
	? "https://core-stage.vashinvestor.ru/api/v2"
	: "https://core.vashinvestor.ru/api/v2";
export const apiPhotoURL = isDev
	? "https://core-stage.vashinvestor.ru/api/v2/online_loan/upload_photo"
	: "https://core.vashinvestor.ru/api/v2/online_loan/upload_photo";
export const apiAvatarPhotoURL = isDev
	? "https://core-stage.vashinvestor.ru/api/v2/upload_avatar"
	: "https://core.vashinvestor.ru/api/v2/upload_avatar";
export const apiPassportPhotoURL = isDev
	? "https://core-stage.vashinvestor.ru/api/v2/online_loan/upload_new_passport_photo"
	: "https://core.vashinvestor.ru/api/v2/online_loan/upload_new_passport_photo";
export const apiPublicURL = isDev
	? "https://stage.vashinvestor.ru/api/v1/"
	: "https://vashinvestor.ru/api/v1/";
export const webSocketURL = isDev
	? "wss://core-stage.vashinvestor.ru/ws_client"
	: "wss://core.vashinvestor.ru:8443/ws_client";
