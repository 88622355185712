import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'

import BaseTabs from 'components/atoms/BaseTabs'
import OnlineLoanList from 'components/organisms/OnlineLoanList'

import s from './LoansRequest.module.scss'
import ModalContainer from "../../components/atoms/ModalContainer";
import repeatStore from "../../store/repeatStore";
import BaseButton from "../../components/atoms/BaseButton";
import formattedMoney from "../../utils/formattedMoney";
import appStore from "../../store/app";

const LoansRequestPage = observer(() => {
    const [activeTab, setActiveTab] = useState('open')
    const isMainPage = window.location.pathname === '/'
    const {closedLoanRequests: closedLoans, openLoanRequests: openLoans} = appStore

    useEffect(() => {
        async function fetchData() {
            await fetchLoanLists()
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (repeatStore.onlineLoanNewStatus) {
            fetchLoanLists()
                .then(() => repeatStore.setOnlineLoanNewStatus(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repeatStore.onlineLoanNewStatus])

    const fetchLoanLists = async () => {
        await appStore.getOpenLoanRequests()
        await appStore.getClosedLoanRequests()
    }

    const loansTabs = () => {
        return {
            open: `<span>Открытые<b>${openLoans.length || 0}</b></span>`,
            closed: `<span>Закрытые<b>${closedLoans.length || 0}</b></span>`
        }
    }

    if (isMainPage && openLoans.length === 0) {
        return null
    }

    return (
        <div className={isMainPage ? s.wrapper : 'container'}>
            <div className={s.loans}>
                {isMainPage ?
                    <h2 className="title">Заявки на займ</h2>
                    :
                    <h1 className="title">Заявки на займ</h1>
                }
                <div className={s.loans__tabs}>
                    <BaseTabs
                        active={activeTab}
                        list={loansTabs()}
                        change={(value) => setActiveTab(value)}
                    />
                </div>
                <OnlineLoanList loanList={activeTab === 'open' ? openLoans : closedLoans}/>
            </div>
            <ModalContainer
                title="Поздравляем"
                showModal={repeatStore.endOfRegistrationModal}
                closeModal={() => repeatStore.setEndOfRegistrationModal(false)}
            >
                <div>
                    <p className={s.modalText}>
                        Заявка на
                        займ {repeatStore.loanDetail?.desiredAmount ? `${formattedMoney(repeatStore.loanDetail?.desiredAmount)} рублей` : null} успешно
                        оправлена. В ближайшее время мы ее рассмотрим и свяжемся с Вами
                    </p>
                    <BaseButton text={"Отлично!"} onClick={() => repeatStore.setEndOfRegistrationModal(false)}/>
                </div>
            </ModalContainer>
        </div>
    )
})

export default LoansRequestPage
