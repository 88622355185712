import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react";
import s from "./styles.module.scss";
import BaseLoader from "../../components/atoms/BaseLoader";
import {formatMoney} from "../../helpers";
import BaseTooltip from "../../components/atoms/BaseTooltip";
import appStore from "../../store/app";
import {getCarModels, getCarVendors, getLoanLimit, YEARS} from "./helpers";
import NativeSelect from "../../components/molecules/NativeSelect";


const CarInfo = observer (() =>{
    const defaultValue = () => ({label:''})

    const [carVendor, setCarVendor] = useState(defaultValue())
    const [vendors, setVendors] = useState([])
    const [carModel, setCarModel] = useState(defaultValue())
    const [models, setModels] = useState([])
    const [carYear, setCarYear] = useState(defaultValue())

    const [loadingCarAmount, setLoadingCarAmount] = useState(false)
    const [carAmount, setCarAmount] = useState(undefined)
    const [error, setError] = useState(false)

    const parseError = (error) => {
        if (error === 'Не удалось рассчитать стоимость') {
            return 'Не удалось определить среднерыночную стоимость автомобиля - укажите желаемую сумму займа и отправьте заявку нашим менеджерам.';
        }
        return 'Мы не смогли обработать Ваш запрос.';
    };

    async function fetchData() {
        const vendors = await getCarVendors('')
        setVendors(vendors)
    }
    useEffect( () => {
        fetchData()
    },[])

    const chooseVendor = async (vendor) => {
        setCarModel(defaultValue())
        appStore.updateLoanOrderModalData('model', null)
        if (!vendor){
            return
        }
        setCarYear(defaultValue())
        appStore.updateLoanOrderModalData('year', null)
        setModels([])
        const models  = await getCarModels(vendor.value, '')

        setModels(models)
        setCarVendor(vendor)
    }

    const chooseModel = (model) => {
        model ? setCarModel(model) : setCarModel(defaultValue())
        setCarYear(defaultValue())
        appStore.updateLoanOrderModalData('year', null)
    }
    const chooseYear = async (year) => {
        if (!year){
            setCarYear(defaultValue())
            appStore.updateLoanOrderModalData('year', null)
            return
        }
         setCarYear(year)
       await fetchCarStats(year.value)
    }

    const fetchCarStats = (year) => {
        setCarAmount(null)
        if (!(carModel && carVendor)) return
        appStore.updateLoanOrderModalData('model', carModel.value)
        appStore.updateLoanOrderModalData('year', year)
        setLoadingCarAmount(true)
        setError(null);
        // getLoanLimit(model, year).then(({ avgPrice, approximateAmountFrom, approximateAmountTo }) => {

        getLoanLimit(carModel.value, year)
            .then(({ approximateAmountTo }) => setCarAmount(approximateAmountTo))
            .catch( error => setError(error))
            .finally(() => setLoadingCarAmount(false));
    }

   return (
       <React.Fragment>
           <div className={[s.form__label, s['form__label--mt']].join(' ')}>Авто</div>
           <div className={s.form__line}>
               <div className={s.form__half}>
                   <NativeSelect
                       placeholder="Марка"
                       defaultValue={carVendor.label}
                       onSearch={null}
                       options={vendors}
                       withLabel={true}
                       onChanged={chooseVendor}
                   />
               </div>
               <div className={s.form__half}>
                   <NativeSelect
                       placeholder="Модель"
                       defaultValue={carModel.label}
                       onSearch={null}
                       options={models}
                       withLabel={true}
                       onChanged={chooseModel}
                       disabled={!carVendor.label}
                   />
               </div>
           </div>
           <div className={s.form__line}>
               <div className={s.form__half}>
                   <NativeSelect
                       placeholder="Год"
                       defaultValue={carYear.label}
                       onSearch={null}
                       options={YEARS}
                       withLabel={true}
                       onChanged={chooseYear}
                       disabled={!(carVendor.label && carModel.label)}
                   />
               </div>
               <div className={s.form__amount}>
                   { loadingCarAmount &&
                       <div className={s.form__loading}>
                           <BaseLoader color="green"/>
                       </div>
                   }
                   { carAmount &&
                       <div>
                         <div className={s.form__sum}>
                           {formatMoney(carAmount)} ₽
                         </div>
                         <span className={s['form__half--text']}>
                            Предварительный лимит займа
                         </span>
                       </div>
                   }
                   <div style={{marginLeft:'auto', alignSelf: 'center'}}>
                     <BaseTooltip>
                       Предварительный лимит займа рассчитывается исходя из среднерыночной стоимости автомобиля (предмета залога). Лимит займа для заемщиков физических лиц - 1 000 000 руб.
                     </BaseTooltip>
                   </div>
               </div>
           </div>
           { error && <div className={s.form__error}>{ parseError(error) }</div>}
       </React.Fragment>
   )
})
export default CarInfo
