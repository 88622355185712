// counter init on index.html
import {isDev} from "../config";

export const YANDEX_METRIKA_COUNTER = 48781889

export const yandexMetrikaReachGoal = (goal) => {
    if (isDev) {
        console.log('тут будет вызов яндекс метрики, цель: ', goal)
        return;
    }
    if (!window.ym) {
        console.log('!window.ym), нет метрики')
        return;
    }
    window.ym(YANDEX_METRIKA_COUNTER, 'reachGoal', goal)
}
