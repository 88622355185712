import React from "react";
import { useState, useEffect } from 'react';

const getSize = () => {
    const { innerWidth } = window;
    return {
        mobile: innerWidth < 768,
        tablet: innerWidth >= 768,
        tabletLand: innerWidth >= 1024,
        notebook: innerWidth >= 1240,
        desktop: innerWidth >= 1440
    }
}

export const ResizeContext = React.createContext({
    mobile: true,
    tablet: false,
    tabletLand: false,
    notebook: false,
    desktop: false,
});

export default function ResizeProvider ({children}) {
    const [size, setSize] = useState(getSize());

    useEffect(() => {
        function handleResize() {
            setSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <ResizeContext.Provider value={size}>{children}</ResizeContext.Provider>
}
