import React, {useEffect, useState} from "react";
import {backReq} from "../../../helpers";
import {loadPdfSrc} from "../../../utils/pdfLoader";


const LoanDocs = ({loan}) => {
    const [docs, setDocs] = useState([])

    useEffect( () => {
        async function fetchData() {
            const response = await backReq('online_loan:docs', {id: loan.id})
            return response?.data ?? []
        }
        fetchData().then(generateUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const generateUrl = async (docsArray) => {
        const requests = docsArray.map(item => loadPdfSrc(item.url));
        console.log(requests)
        const res = await Promise.all(requests)
        const docsWithGeneratedHref = docsArray.map( (item, index) => ({
            ...item,
            generatedHref: res[index]
        }))
        setDocs(docsWithGeneratedHref)
    }

    const renderDocs = () =>{
        return docs.map(item =>
            <li key={item.url+item.name}>
                <a target="_blank" rel="noopener noreferrer" href={item.generatedHref}>{decodeURI(item.name)} </a>
            </li>
        )
    }
    return (
        <ul>
            {renderDocs()}
        </ul>
    )
}
export default LoanDocs
