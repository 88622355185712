// polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'mobx-react'

import './styles/index.scss'

import './fonts/muller.regular.woff2'
import './fonts/muller.regular.woff'

import App from "./App";
import appStore from './store/app'
import ResizeProvider from "./providers/ResizeProvider";
import repeatStore from "./store/repeatStore";
import elementsStore from "./store/elementsStore";

const render = Component => {
    return ReactDOM.render(
        <Provider appStore={appStore} repeatStore={repeatStore} elementsStore={elementsStore}>
            <ResizeProvider>
                <Component />
            </ResizeProvider>
        </Provider>,
        document.getElementById('root')
    )
}

render(App)

if (module.hot) {
  module.hot.accept(App, _ => {
    const NextApp = App.default
    render(NextApp)
  })
}
