import React from 'react';
import ReactDOM from "react-dom";
import ModalContainer from "../ModalContainer";

const ModalPortal = ({closeModal, showModal, size, title, children}) => {
  return ReactDOM.createPortal(
    <ModalContainer
      title={title}
      showModal={showModal}
      closeModal={closeModal}
      size={size}
    >
      {children}
    </ModalContainer>,
    document.getElementById('root-modal'))
}
export default ModalPortal
