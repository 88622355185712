import {action, computed, observable, runInAction} from "mobx";
import {repeaterStepsArray as steps} from "../constants/onlineLoan"
import {backReq} from "../helpers";

class RepeatStore {
    // Online Loan
    @observable isOfferExist = true
    @observable onlineLoanOrder = null
    @observable loanDetail = {}
    @observable onlineLoans = []
    @observable endOfRegistrationModal = false
    @observable loanInfoLoader = false
    @observable isLivelinessCheckInProgress = null
    @observable livelinessCheck = null
    @observable passportCheck = null

    @action setIsOfferExist = (value) => (this.isOfferExist = value)
    @action setOnlineLoanOrder = (order) => (this.onlineLoanOrder = order)
    @action setOnlineLoans = (loans) => (this.onlineLoans = loans)
    @action setLoanDetail = (detail) => (this.loanDetail = detail)
    @action setEndOfRegistrationModal = (value) => (this.endOfRegistrationModal = value)
    @action setLivelinessCheck = (val) => {
        /* val =
             "real" - Живость подтверждена
             "attack" - Живость не подтверждена
             "unfinished" - Проверка не завершена
        */
        this.livelinessCheck = val
    }
    @action setIsLivelinessCheckInProgress = (bool) => (this.isLivelinessCheckInProgress = bool)
    @action setPassportCheck = (bool) => (this.passportCheck = bool)

    @action refreshOfferExist = async () => {
        try {
            const response = await backReq('online_loan:exists')
            if (response.errors) {
                new Error('online_loan:exists error')
            }
            this.setIsOfferExist(response.data.isOfferExist ?? undefined)
            localStorage.setItem("loanId", response.data?.onlineLoanOrder?.id)
            this.setOnlineLoanOrder(response.data?.onlineLoanOrder)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }
    @action refreshLoanDetail = async () => {
        try {
            this.loanInfoLoader = true
            const response = await backReq('online_loan:detail')
            if (response.errors) {
                new Error('online_loan:detail')
            }
            localStorage.setItem("loanId", response.data.id)
            this.setLoanDetail(response.data)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }
    @action refreshLoanList = async () => {
        try {
            const response = await backReq('online_loan:list')
            if (response?.errors) return;
            this.setOnlineLoans(response.data)
        } catch (e) {
            throw e
        } finally {
            this.loanInfoLoader = false
        }
    }

    @computed get openedOnlineLoans() {
        if (!this.onlineLoans) return []
        return this.onlineLoans.filter((loan) => loan.status !== 'complete')
    }

    // steps
    @observable currentStep = 0
    @observable currentStepTitle = ''
    @observable stepsArray = steps

    @computed get backendCurrentStepNumber() {

        if (!this.loanDetail?.currentStep) return 0
        const currentStepNumber = this.stepsArray.findIndex(step => step === this.loanDetail.currentStep)
        return currentStepNumber === -1 ? 0 : currentStepNumber
    }

    @action setCurrentStep = async (value, isChecked = false) => {
        // init value
        if (this.currentStep === undefined || isChecked) {
            this.currentStep = value
            return
        }
        // step back
        if (value < this.currentStep) {
            this.currentStep = value
            return
        }
        //  avoid error
        if (!this.loanDetail?.currentStep) return;
        // if walking through the previous steps
        const backCurrentStepNumber = this.stepsArray.findIndex((step) => step === this.loanDetail?.currentStep)
        if (value <= backCurrentStepNumber) {
            this.currentStep = value
        } else {
            // ask permission
            const response = await backReq('online_loan:change_step', {
                "step": this.stepsArray[value]
            })
            if (response.code === 200) {
                runInAction(() => this.currentStep = value)
            } else {
                const err = new Error('step error')
                err.responseData = response.errors
                throw err
            }
        }
    }

    // loans Request Approve
    @observable activeLoan = {}
    @observable loansRequestApproveStep = 1
    @observable loansRequestApproveFinalStep = false
    @observable onlineLoanNewStatus = false
    @observable isNotRatedOnlineLoans = false
    @observable notRatedOnlineLoans = []
    @action setActiveLoan = (value) => this.activeLoan = value
    @action setLoansRequestApproveStep = (stepNumber) => this.loansRequestApproveStep = stepNumber
    @action setLoansRequestApproveFinalStep = (bool) => this.loansRequestApproveFinalStep = bool
    @action setOnlineLoanNewStatus = (bool) => this.onlineLoanNewStatus = bool
    @action setIsNotRatedOnlineLoans = (bool) => this.isNotRatedOnlineLoans = bool
    @action setNotRatedOnlineLoans = (arr) => this.notRatedOnlineLoans = arr

    @computed get loansRequestApproveStepTitle() {
        return {
            0: 'Займ одобрен со снижением суммы займа',
            1: 'Заявка одобрена',
            2: 'Подписание заявки',
            3: 'Документы подписаны',
        }[this.loansRequestApproveStep] || ''
    }
}

const repeatStore = new RepeatStore()
export default repeatStore
