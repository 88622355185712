import React from 'react'
import BaseClub from "../../components/organisms/BaseClub";

const ClubPage = () => {
    return <div className="container">
        <BaseClub/>
    </div>
}

export default ClubPage
