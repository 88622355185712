export default {
    payment: {
        status: {
            success: "success",
            error: "error",
            pending: "pending",
        },
        statusCode: {
            redirect: 0,
            process: 10,
            success: 20,
            error: 30,
        },
        type: {
            loan: "loan",
            service: "service",
            monthly: "monthly",
            part : "part",
            full : "full",
        }
    },
    application: {
        mkk_vi: "mkk_vi",
        mkk_cfr_vi: "mkk_cfr_vi"
    }
}
