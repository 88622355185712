import React from "react";
import s from "./OnlineLoanButtons.module.scss";
import BaseButton from "../../atoms/BaseButton";
import {observer} from "mobx-react";
import repeatStore from "../../../store/repeatStore";
import {VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL} from "../../../constants/phones";
import PersonalDataCheck from "./PersonalDataCheck";
import {yandexMetrikaReachGoal} from "../../../utils/yandex-metrika";

const OnlineLoanButtons = observer(({
        disabled = false,
        callback,
        loading = false,
        store = repeatStore,
        isDataOnCheck= false
    }) => {
    async function nextStep() {
        try {
            await callback()
            informYandexMetrika()
        } catch (e) {
            console.warn(e)
        }
    }

    const informYandexMetrika = () => {
        if (store.currentStep < store.backendCurrentStepNumber) return
        const LOCAL_STORAGE_KEY = 'onlineLoanMaxStep'
        const maxStep = Number(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? 0
        if (maxStep >= store.currentStep) return;
        try {
            const step = `step${store.currentStep}`
            yandexMetrikaReachGoal(step)
            localStorage.setItem(LOCAL_STORAGE_KEY, store.currentStep.toString())
        }
        catch (e) {
            console.warn(e)
        }
    }

    function previousStep() {
        if (store.currentStep <= 0) return
        store.setCurrentStep(store.currentStep - 1)
    }

    const isMiddleStep = store.currentStep > 0 && store.currentStep < store.stepsArray.length - 1
    const isFinalStep = store.currentStep === store.stepsArray.length - 1

    return (
        <div className={s.onlineLoanButtons}>
            <div
                className={[
                    s.onlineLoanButtons__buttons,
                    isMiddleStep ?
                        s["onlineLoanButtons__buttons--double"]
                        : s["onlineLoanButtons__buttons--once"],
                    isFinalStep ? s["onlineLoanButtons__buttons--final"] : ''
                ].join(" ")}
            >
                {(store.currentStep !== 0 && !isFinalStep) && (
                    <BaseButton
                        type="border"
                        size="default-change"
                        text="Вернуться"
                        onClick={previousStep}
                        arrowLeft={true}
                    />
                )}
                <BaseButton
                    type="green"
                    size="default-change"
                    text={isFinalStep ? "Оформить заявку" : "Далее"}
                    disabled={disabled || store.loanInfoLoader}
                    onClick={nextStep}
                    loading={loading}
                    arrowRight={!isFinalStep}
                />
                { isDataOnCheck && <PersonalDataCheck /> }
            </div>
            <div className={s.onlineLoanButtons__phone}>
                <span>Помощь:</span>
                <img src={require(`./img/phone.svg`)} alt={"phone icon"}/>
                <a onClick={()=>yandexMetrikaReachGoal('click_phone_step')} href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a>
            </div>
        </div>
    );
});

export default OnlineLoanButtons;
