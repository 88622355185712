import {repeaterStepsArray} from "../../../constants/onlineLoan";
import repeatStore from "../../../store/repeatStore";
import {backReq} from "../../../helpers";
import {loadImageSrc} from "../../../utils/imageLoader";

export async function updateUploadPhotoArray(loadedPhotoObjectArray, localPhotoObjectsArray) {
    /* photoObjectsArray = [ { type: "test_type_photo", src: require(./img/1.jpg), title: "testTitle" } ] */
    const templateArray = JSON.parse(JSON.stringify(localPhotoObjectsArray))
    for (const item of templateArray) {
        const res = loadedPhotoObjectArray.find((photo) => photo.type === item.type)
        if (res) {
            try {
                item.src = await loadImageSrc(res.url)
                item.id = res.id
                item.isCorrect = res.isCorrect ?? false
            } catch (e) {
                console.warn(e.message)
            }
        }
    }
    return templateArray
}

export async function repeaterExistsHandler(data) {
    if (!data) return
    repeatStore.setIsOfferExist(data.isOfferExist ?? undefined)
    localStorage.setItem("loanId", data?.onlineLoanOrder?.id)
    repeatStore.setOnlineLoanOrder(data?.onlineLoanOrder)
    repeatStore.refreshLoanList()
    if (data?.onlineLoanOrder?.currentStep) {
        const currentStep = repeaterStepsArray.findIndex(item => item === data.onlineLoanOrder.currentStep)
        if (currentStep !== -1) await repeatStore.setCurrentStep(currentStep, true)
    }
}

export async function validateStep(step) {
    try {
        return await backReq("online_loan:validate_step", {
            step
        })
    } catch (e) {
        return {errors: e.message}
    }
}

// return errors or false
export async function checkCurrentStep(step, store=repeatStore, noRefresh = false) {
    let response
    try {
        if (store.loanDetail?.currentStep !== step) {
            response = await backReq("online_loan:change_step", {step})
            if (response.errors) {
                return response.errors
            }
            if(!noRefresh) {
                await store.refreshLoanDetail()
            }

        }
        return false
    } catch (e) {
        return e.message
    }
}

// return errors or false
export async function moveToNextOnlineLoanStep(step, store=repeatStore) {
    try {
        const response = await backReq("online_loan:change_step", {step})
        if (response.errors) {
            return response.errors
        }
        await store.setCurrentStep(store.currentStep + 1, true)
        return false
    } catch (e) {
        return e.message
    }
}


