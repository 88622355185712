import React, { useEffect, useState } from 'react';
import s from './SuccessPaymentModal.module.scss';

import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import elementsStore from "../../../store/elementsStore";
import { backReq } from "../../../helpers";
import { renderRuLocaleDateTime } from "../../../utils/formattedDate";
import { yandexMetrikaReachGoal } from "../../../utils/yandex-metrika";
import formattedMoney from "../../../utils/formattedMoney";
import StarRating from "../../atoms/StarRating";
import BaseInput from "../../atoms/BaseInput";
import BaseButton from "../../atoms/BaseButton";
import repeatStore from "../../../store/repeatStore";
import ModalContainer from "../../atoms/ModalContainer";
import { VI_OFFICE_REPEATER_PHONE, VI_OFFICE_REPEATER_PHONE_URL } from "../../../constants/phones";

const SuccessLoanModal = observer(() => {

    const [feedback, setFeedback] = useState()
    const history = useHistory()

    useEffect(() => {
        if (repeatStore.isNotRatedOnlineLoans) {
            yandexMetrikaReachGoal('convert_povtornik')
            yandexMetrikaReachGoal('online_final_money')
            updateData().then(() => repeatStore.setIsNotRatedOnlineLoans(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repeatStore.isNotRatedOnlineLoans])

    const updateData = async () => {
        return await backReq('online_loan:list', { status: ['complete'] }).then(data => completeLoanModalHandler(data))
    }

    const completeLoanModalHandler = ({ data }) => {
        if (!(data && data.list)) {
            repeatStore.setNotRatedOnlineLoans([])
            return
        }
        const notRated = data.list.filter(loan => loan.isRateModalWasShown === false)
        notRated.length ?
            repeatStore.setNotRatedOnlineLoans([...notRated])
            :
            repeatStore.setNotRatedOnlineLoans([])
    }

    const sendRate = async (loan) => {
        if (!(elementsStore.stars || feedback)) {
            await closeCompleteLoanModal(loan)
            return
        }
        try {
            await backReq('online_loan:rate', {
                id: loan.id,
                rate: elementsStore.stars,
                rateText: feedback
            })
            elementsStore.showSnackbar('Благодарим за отзыв. Вы помогаете нам стать лучше', 'success')
            await closeCompleteLoanModal(loan)
        } catch (e) {
            console.warn(e)
        }
    }

    const closeCompleteLoanModal = async (loan) => {
        try {
            await backReq('online_loan:rate_modal_complete', {
                id: loan.id,
                "isRateModalWasShown": true
            })
            const updatedArray = repeatStore.notRatedOnlineLoans.filter(item => item.id !== loan.id)
            repeatStore.setNotRatedOnlineLoans([...updatedArray])
            loan.isRateModalWasShown = true
        } catch (e) {
            console.warn(e)
        }
    }

    const moveToLoanPage = async (loan) => {
        if ((elementsStore.stars || feedback)) {
            await sendRate(loan)
        } else {
            await closeCompleteLoanModal(loan)
        }
        if (loan.loanId) {
            history.push(`/loans/${loan.loanId}`)
        } else {
            history.push(`/loans/`)
        }
    }

    return (
        <>
            {
                repeatStore.notRatedOnlineLoans.map((loan, index) => (
                    <ModalContainer
                        showModal={!loan.isRateModalWasShown}
                        closeModal={() => closeCompleteLoanModal(loan)}
                        size="small"
                        title={'Перевод прошел успешно'}
                        key={loan.id + index}
                    >
                        <div className={s.loanModal}>
                            <p className={s.modalTitle}>{renderRuLocaleDateTime(loan?.offer?.created ?? loan?.changeStatusDate)}</p>
                            <div className={s.modalTextWrapper}>
                                <div className={s.modalText}>
                                    <p>Денежные средства {formattedMoney(loan?.desiredAmount)} рублей по Договору
                                        займа {loan?.loanId || loan?.offer?.offerId || ''} от {renderRuLocaleDateTime(loan?.offer?.created ?? loan?.changeStatusDate)} перечислены
                                        на Вашу банковскую карту **** {loan?.cardNumber}.</p>
                                    <p>Проверьте зачисление денежных средств. Внесение платежей по займу доступно в Личном кабинете.</p>
                                    <p>Мы всегда рады помочь!</p>
                                </div>
                            </div>
                            <p className={s.modalText}>Горячая линия: <a href={VI_OFFICE_REPEATER_PHONE_URL}>{VI_OFFICE_REPEATER_PHONE}</a></p>
                            <hr />
                            <h4>Как всё прошло?</h4>
                            <p className={`${s.modalText} ${s.modalSpacer}`}>Оцените процедуру получения Займа Онлайн - удобство,
                                скорость, прозрачность: от 1 до 5. </p>
                            <StarRating />
                            <div className={s.modalInput}>
                                <BaseInput
                                    placeholder={'Предложения по улучшению'}
                                    default={feedback}
                                    value={e => setFeedback(e)}
                                />
                            </div>
                            <div className={s.buttonWrapper}>
                                <BaseButton onClick={() => sendRate(loan)} text={'Отправить отзыв'} />
                                <BaseButton onClick={() => moveToLoanPage(loan)} text={'К странице займа'} />
                            </div>
                        </div>
                    </ModalContainer>))

            }
        </>
    )

})
export default SuccessLoanModal
