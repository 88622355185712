import React, {useRef, useState} from "react";

import s from "./OnlineLoanPrivacy.module.scss";
import BaseCheckbox from "../../atoms/BaseCheckbox";
import {observer} from "mobx-react";
import BaseButton from "../../atoms/BaseButton";
import BaseCollapse from "../../atoms/BaseCollapse";
import {backReq, getFormattedPhone} from "../../../helpers";
import appStore from "../../../store/app";
import {errorsParser} from "../../../utils/errorsParser";
import ModalPortal from "../../atoms/ModalPortal";
import elementsStore from "../../../store/elementsStore";
import {checkCurrentStep, moveToNextOnlineLoanStep, validateStep} from "../../Repeater/helpers";
import useErrorContainer from "../../../hooks/useErrorContainer";
import {onlineLoanStepsArray} from "../../../constants/onlineLoan";
import successIcon from "../../../img/success.svg";
import ErrorContainer from "../../molecules/ErrorContainer";
import OnlineLoanButtons from "../../molecules/OnlineLoanButtons";
import PublicOfficialCollapse, {relativeSelect} from "./PublicOfficialCollapse";
import onlineLoanStore from "../../../store/onlineLoanStore";
import useRefreshOnlineLoanDetail from "../../../hooks/useRefreshOnlineLoan";
import ConfirmCodeInput from "../../atoms/ConfirmCodeInput/ConfirmCodeInput";

const RegistrationPrivacy = (observer(() => {

    const profile = appStore?.profile
    const [publicOfficialForm, setPublicOfficialForm] = useState({})
    const [notPublicPerson, setNotPublicPerson] = useState(true)
    const [creditHistoryAgreement, setCreditHistoryAgreement] = useState(true)
    const [electronicInteractionAgreement, setElectronicInteractionAgreement] = useState(true)
    const [personalDataAgreement, setPersonalDataAgreement] = useState(true)
    const [noThirdParties, setNoThirdParties] = useState(true)
    const [modal, setModal] = useState(false)

    const [isNotPublicPersonError, setIsNotPublicPersonError, notPublicPersonRef] = useErrorContainer()
    const [isCreditHistoryAgreementError, setIsCreditHistoryAgreementError, creditHistoryAgreementRef] = useErrorContainer()
    const [isElectronicInteractionAgreementError, setIsElectronicInteractionAgreementError, electronicInteractionAgreementErrorRef] = useErrorContainer()
    const [isPersonalDataAgreementError, setIsPersonalDataAgreementError, personalDataAgreementRef] = useErrorContainer()
    const [isNoThirdPartiesError, setIsNoThirdPartiesError, noThirdPartiesRef] = useErrorContainer()

    const collapseRef = useRef();

    const isPublicToggle = (event) => {
        if (notPublicPerson && collapseRef.current.state.active) {
            collapseRef.current.toggle(event)
        }
        setNotPublicPerson(!notPublicPerson)
    }

    const collapseTabs = [
        {
            head: <BaseCheckbox
                inversion
                value={electronicInteractionAgreement}
                onChange={() => setElectronicInteractionAgreement(!electronicInteractionAgreement)}
                color="primary"
                sizeText='big'
            >
                <span>
                    Условия соглашения об электронном взаимодействии
                </span>
            </BaseCheckbox>
            ,
            text: <span>
                Настоящее Соглашение определяет условия осуществления обмена информацией в
                электронной форме между Сторонами, в том числе при заключении договора займа
                и договора залога в виде электронного документа, а также требования к конфиденциальности.
                <a href='./docs/Соглашение_об_электронном_взаимодействии_30_11_2021.pdf' target='_blank'> Соглашение ПЭП</a>
            </span>,
            hasError: isElectronicInteractionAgreementError,
            innerRef: electronicInteractionAgreementErrorRef
        },
        {
            head: <BaseCheckbox
                inversion
                value={creditHistoryAgreement}
                onChange={() => setCreditHistoryAgreement(!creditHistoryAgreement)}
                color="primary"
                sizeText='big'
            >
                <span>
                    Предоставления и получения информации в Бюро кредитных историй
                </span>
            </BaseCheckbox>,
            text: <span>
                Кредитные истории включают информацию о платежной дисциплине (срок и сумма погашения кредита/займа, наличие просроченной задолженности).
                <a href='./docs/Согласие на НБКИ.pdf' target='_blank'> Согласие на предоставление в Бюро кредитных историй</a>
            </span>,
            hasError: isCreditHistoryAgreementError,
            innerRef: creditHistoryAgreementRef
        },
        {
            head: <BaseCheckbox
                inversion
                value={personalDataAgreement}
                onChange={() => setPersonalDataAgreement(!personalDataAgreement)}
                color="primary"
                sizeText='big'
            >
                <span>Соглашением об использовании ПЭП</span>
            </BaseCheckbox>,
            text: <span>
                Настоящее соглашение закрепляет договоренности сторон об использовании простой электронной подписи в юридически значимых
                документах и признание простой электронной подписи равнозначной собственноручной подписи.
                <a href='./docs/Соглашение_об_электронном_взаимодействии_30_11_2021.pdf' target='_blank'> Соглашение ПЭП</a>
            </span>,
            hasError: isPersonalDataAgreementError,
            innerRef: personalDataAgreementRef
        },
        {
            head: <BaseCheckbox
                inversion
                value={notPublicPerson}
                onChange={isPublicToggle}
                color="primary"
                sizeText='big'
            >
                Не являюсь должностным лицом
            </BaseCheckbox>,
            text: <PublicOfficialCollapse
                publicOfficialForm={publicOfficialForm}
                setPublicOfficialForm={setPublicOfficialForm}
                notPublicPerson={notPublicPerson}/>,
            hasError: isNotPublicPersonError,
            innerRef: notPublicPersonRef,
            collapseRef,
        },
        {
            head: <BaseCheckbox
                inversion
                value={noThirdParties}
                onChange={() => setNoThirdParties(!noThirdParties)}
                color="primary"
                sizeText='big'
            >
                <span>Действие от своего имени, без поручения третьих лиц (бенефициаров)</span>
            </BaseCheckbox>
            ,
            text: "Бенефициарный владелец - физическое лицо, которое в конечном счете прямо или косвенно (через третьих лиц) имеет возможность контролировать действия клиента",
            hasError: isNoThirdPartiesError,
            innerRef: noThirdPartiesRef
        },
    ]
    const [isAgreementsError, setIsAgreementsError, agreementsContainerRef] = useErrorContainer()
    useRefreshOnlineLoanDetail(setOfferSmsInfo)

    async function setOfferSmsInfo() {
        // try {
        //     if (!onlineLoanStore.loanDetail && !onlineLoanStore.loanDetail.stepsDone) {
        //         // elementsStore.setSmsCodeConfirmed(false)
        //         return
        //     }
        //     const isConfirmed = toJS(onlineLoanStore.loanDetail).stepsDone.find(step => step === onlineLoanStepsArray[2])
        //     // elementsStore.setSmsCodeConfirmed(!!isConfirmed)
        // } catch (e) {
        //     console.warn(e)
        // }
    }

    const sendCodeHandler = async () => {
        const isValid = validateFields()
        if (!isValid) return

        await sendSms()
    }
    const validateFields = () => {
        if (!electronicInteractionAgreement) {
            setIsElectronicInteractionAgreementError(true)
            return false
        }
        if (!creditHistoryAgreement) {
            setIsCreditHistoryAgreementError(true)
            return false
        }
        if (!personalDataAgreement) {
            setIsPersonalDataAgreementError(true)
            return false
        }
        if (!noThirdParties) {
            setIsNoThirdPartiesError(true)
            return false
        }
        const isPublicFormValid = validatePublicOfficialForm()
        if (!isPublicFormValid) {
            setIsNotPublicPersonError(true)
            return false
        }
        return true

    }

    async function sendSms() {
        const response = await elementsStore.callSmsAction(()=>backReq("online_loan:get_code"))
        if (response.errors){
            elementsStore.showSnackbar(errorsParser(response.errors))
            return
        }
        setModal(true)
    }

    async function confirmAgreements() {
        elementsStore.setCheckingSmsCode(true)
        try {
            const stepError = await checkCurrentStep(onlineLoanStepsArray[2], onlineLoanStore)
            if (stepError) {
                elementsStore.showSnackbar(errorsParser(stepError))
                return false
            }
            const {
                publicPersonCategory,
                relativePublicPerson,
                relativePublicPersonCategory,
                relativePublicPersonRelationship
            } = publicOfficialForm
            const isRelativePublicPerson = relativePublicPerson === relativeSelect[0]

            const response = await backReq("online_loan:confirm_agreements", {
                "code": elementsStore.smsCode,
                // always should be true
                creditHistoryAgreement,
                electronicInteractionAgreement,
                personalDataAgreement,
                noThirdParties,
                //additional field
                notPublicPerson: notPublicPerson,
                ...(!notPublicPerson && {
                    publicPersonCategory,
                    relativePublicPerson: isRelativePublicPerson
                }),
                ...((!notPublicPerson && isRelativePublicPerson) && {
                    relativePublicPersonCategory,
                    relativePublicPersonRelationship
                })
            })
            if (response.code === 200) {
                elementsStore.setSmsCodeConfirmed(true)
                setTimeout(() => {
                    setModal(false)
                }, 300)
            } else {
                elementsStore.showSnackbar(errorsParser(response?.errors || JSON.stringify(response)))
                return false
            }
        } catch (e) {
            console.warn("error", e.message)
            return false
        } finally {
            elementsStore.setCheckingSmsCode(false)
        }
    }

    const validatePublicOfficialForm = () => {
        if (notPublicPerson) return true
        let isValid = true
        const {
            publicPersonCategory,
            relativePublicPerson,
            relativePublicPersonCategory,
            relativePublicPersonRelationship
        } = publicOfficialForm
        if (!publicPersonCategory || !relativePublicPerson) isValid = false
        if (relativePublicPerson === relativeSelect[1]) return isValid;
        if (!relativePublicPersonCategory || !relativePublicPersonRelationship) isValid = false;
        return isValid
    }

    async function nextStep() {
        try {
            const validateStepResponse = await validateStep('agreements')
            if (validateStepResponse.errors) {
                elementsStore.showSnackbar("Необходимо подписать соглашения")
                setIsAgreementsError(true)
                return false
            }
            const nextStepErrors = await moveToNextOnlineLoanStep(onlineLoanStepsArray[3], onlineLoanStore)
            if (nextStepErrors) {
                elementsStore.showSnackbar(errorsParser(nextStepErrors))
            }
            elementsStore.smsInputRestoreDefault()
        } catch (e) {
            return false
        }
    }

    return <div className={s.privacy}>
        <div className={s.privacy__head}>Нажимая «Далее», я соглашаюсь с:</div>
        {
            collapseTabs.map((line, index) => {
                const head = typeof line.head === 'string' ?
                    <BaseCheckbox
                        inversion
                        value={true}
                        disabled={true}
                        sizeText='big'
                        color="primary"
                    >
                        {line.head}
                    </BaseCheckbox>
                    : line.head
                return <ErrorContainer hasError={line.hasError} innerRef={line.innerRef} key={index}>
                    <BaseCollapse
                        ref={line.collapseRef}
                        chevron={true}
                        head={head}>
                        {line.text}
                    </BaseCollapse>
                </ErrorContainer>
            })
        }
        <ErrorContainer hasError={isAgreementsError} innerRef={agreementsContainerRef}>
            <div className={s.privacy__signature}>
                <h6>Подписание соглашений через простую электронную подпись</h6>
                <p>Для подписания вам необходимо ввести код из SMS. SMS будет отправлено
                    на {profile?.phone ? getFormattedPhone(profile.phone) : "номер, который привязан к вашему аккаунту"}</p>
                {onlineLoanStore.loanDetail.electronicInteractionAgreement || elementsStore.smsCodeConfirmed ?
                    <div className={s.privacy__signed}>
                        <img src={successIcon} alt="successIcon"/>
                        Подписано
                    </div>
                    : <BaseButton
                        text={"Получить код"}
                        size="slim"
                        onClick={sendCodeHandler}
                    />
                }
            </div>
        </ErrorContainer>
        <ModalPortal
            title="Подписание соглашений"
            showModal={modal}
            closeModal={() => setModal(false)}>
            <div className={s.privacy__modal}>
                <div className={s.privacy__modal_wrapper}>
                    <p>Мы отправили код доступа на номер <span style={{whiteSpace: 'nowrap'}}>{getFormattedPhone(appStore?.profile?.phone)}.</span></p>
                    <p>Введите код из SMS:</p>
                </div>
                <ConfirmCodeInput onEntered={confirmAgreements} onSend={sendSms}/>
            </div>
        </ModalPortal>
        <OnlineLoanButtons callback={nextStep} store={onlineLoanStore}/>
    </div>
}))

export default RegistrationPrivacy
