import React, {useEffect, useRef, useState} from "react";

import s from './SliderBottom.module.scss'

const SliderBottom = ({value, min, max, onChange, step, disabled}) => {
    const rangeSlide = useRef(null)

    const [move, setMove] = useState(false)
    const [progressStyle, setProgressStyle] = useState({
        transform: 'scaleX(0)',
        transitionDuration: '0.25s'
    })
    const [buttonStyle, setButtonStyle] = useState({
        left: '0',
        transitionDuration: '0.25s'
    })

    const currentSteps = max - min

    useEffect(() => { // only mounted
      if (value <= min) {
        setStyles(min)
        return
      }
      if (value >= max) {
        setStyles(max)
        return;
      }
        setStyles(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const changeTransitionDuration = (sec) =>  {
        setProgressStyle({...progressStyle, transitionDuration: `${sec}s`})
        setButtonStyle({...buttonStyle, transitionDuration: `${sec}s`})
    }

    const startChangeValue = () => {
        changeTransitionDuration(0)
        setMove(true)
    }

    const finishChangeValue = () => {
        changeTransitionDuration(0.25)
        setMove(false)
    }

    const onMove = (e) => {
        if (move) {
            changeValue(e)
        }
    }

    const changeValue = (e) => {
        const rect = rangeSlide.current.getBoundingClientRect()
        const pageX = e.pageX || e.changedTouches[0].pageX

        const path = pageX - rect.left
        const fullPath = rect.width

        let newValue = Math.round((path / fullPath) * currentSteps + min)
        if (pageX <= rect.left || pageX >= rect.right) {
            if (newValue <= min) {
                newValue = min
            }

            if (newValue >= max) {
                newValue = max
            }

        }
        if (step){
          newValue = _steppedValue(newValue, max)
        }
        onChange(newValue)

        setStyles(newValue)
    }

    const _steppedValue  = (value, max) => {
      let steppedValue = Math.ceil(value / step) * step
      return  steppedValue < max ? steppedValue : max
    }
    const setStyles = (newValue) => {
      const progressCount = (newValue - min) / ( isNaN(currentSteps) ? 1 : currentSteps)
      setProgressStyle({...progressStyle, transform: `scaleX(${progressCount})`})
      setButtonStyle({...buttonStyle, left: `calc(${progressCount * 100}% - 9px)`})
    }
    const rangeProgressClasses = [s.range__progress]
    const rangeButtonClasses = [s.range__button]
    if (disabled) {
        rangeProgressClasses.push(s.disabled)
        rangeButtonClasses.push(s.disabled)
    }
    return <div className={s.range}
        ref={rangeSlide}
        onMouseDown = { () => startChangeValue() }
        onTouchStart = { () => startChangeValue() }
        onMouseUp = { () => finishChangeValue() }
        onTouchEnd = { () => finishChangeValue() }
        onMouseMove = { (e) => onMove(e) }
        onTouchMove = { (e) => onMove(e) }
        onClick = { (e) => changeValue(e) }
    >
        <div className={rangeProgressClasses.join(' ')} style={progressStyle}/>
        <button className={rangeButtonClasses.join(' ')}
            aria-label = "Ползунок для изменения значения"
            style = { buttonStyle }
        />
    </div>
}

export default SliderBottom
