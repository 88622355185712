import React from 'react'
import PropTypes from 'prop-types'

import s from './CardList.module.scss'

import CardItem from './CardItem'
import BaseMessage from "../../../atoms/BaseMessage";

const props = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,

  onUpdatedName: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  fetchCardList: PropTypes.func.isRequired,
}

const CardList = ({ cards, onUpdatedName, onDeleted }) => {
  const elements = cards.map(item => {
    return <CardItem key={item.token}
        {...item}
        onUpdatedName={(value) => onUpdatedName(value, item.token)}
        onDeleted={() => onDeleted(item.token)}
    />
  })

  return (
    <div className={s.cards}>
      {
        elements.length > 0
            ? elements
            : <BaseMessage
                type='warning'
              >
                У Вас еще нет привязанных карт
              </BaseMessage>
      }
    </div>
  )
}

CardList.propTypes = props

export default CardList
