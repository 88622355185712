import s from "./StatusCard.module.scss";
import {ReactComponent as StatusIcon} from "../../img/status.svg";
import React from "react";
import ClubStatus from "../ClubStatus";
import BaseProgress from "../../../../atoms/BaseProgress";
import appStore from "../../../../../store/app";
import {observer} from "mobx-react";
import referralStatus from "../../../../../constants/referralStatus";
import HelpIcon from "../../../../../img/help.svg";
import {yandexMetrikaReachGoal} from "../../../../../utils/yandex-metrika";
import {isMobile} from "../../../../../utils/isMobile";

const StatusCard = observer(() => {

    const status = appStore?.referralInfo?.status ?? referralStatus.BRONZE
    const friends = appStore.referralInfo?.data?.loans?.length ?? 0

    const getFromNextStatus = () => {
        if (friends < 5) {
            return 5 - friends
        }
        if (friends < 10) {
            return 10 - friends
        }
        return 0
    }

    const getPercent = () => {
        if (friends === 0) return 0
        if (friends < 5) {
            return friends * 20
        }
        if (friends < 10) {
            return friends * 10
        }
        return 100
    }

    const openDrawer = () => {
        appStore.setIsReferralDrawerVisible(true)
        yandexMetrikaReachGoal('opisanie_100druzei')
    }

    return (
        <article className={s.card}>
            <div className={s.card__title}>
                {isMobile() ?
                    <div className={s.card__headerMobile}>
                        <div className={s.card__titleText}>Ваш статус</div>
                        <div className={s.card__questionIcon} onClick={openDrawer}>
                            <img
                                src={HelpIcon}
                                alt=""
                            />
                        </div>
                        <div className={s.card__statusMobile}>
                            <ClubStatus/>
                        </div>
                    </div>
                    :
                    <div className={s.card__header}>
                        <div className={s.card__titleText}>Ваш статус:</div>
                        <div className={s.card__status}>
                            <div>
                                <ClubStatus/>
                            </div>
                            <div className={s.card__questionIcon} onClick={openDrawer}>
                                <img
                                    src={HelpIcon}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                status !== referralStatus.GOLD
                && <div className={s.progress__wrapper}>
                    <div className={s.card__cell}>
                        <div className={s.card__cellKey}>
                            <p>До статуса {status === referralStatus.BRONZE
                                ? `“${referralStatus.SILVER}”`
                                : `“${referralStatus.GOLD}”`}
                            </p>
                        </div>
                        <div className={s.card__cellValue}>
                            <p className={s.card__cellValueText}>
                                {getFromNextStatus()}
                            </p>
                            <div className={s.card__cellIcon}>
                                <StatusIcon style={{width: '24px', height: '24px'}}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <BaseProgress percent={getPercent()} small/>
                    </div>
                </div>
            }
        </article>
    )
})
export default StatusCard
