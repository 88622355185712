import React from 'react'

import s from './BaseCurrency.module.scss'

export const BaseCurrency = ({color='green', size=12}) => {

  return  <svg className={s.currency} width={size} height={size} viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={s[color]} d="M4.65598 35V0.728027H13.68C17.872 0.728027 20.976 1.57603 22.992 3.27203C25.04 4.96803 26.064 7.43203 26.064 10.664C26.064 12.84 25.568 14.712 24.576 16.28C23.584 17.816 22.112 19 20.16 19.832C18.24 20.664 15.856 21.08 13.008 21.08H8.97598V35H4.65598ZM0.47998 28.472V25.352H16.896V28.472H0.47998ZM0.47998 21.08V17.432H11.472V21.08H0.47998ZM12.384 17.432C14.336 17.432 15.984 17.224 17.328 16.808C18.704 16.392 19.76 15.704 20.496 14.744C21.232 13.784 21.6 12.472 21.6 10.808C21.6 8.63203 20.928 7.01603 19.584 5.96003C18.24 4.90403 16.144 4.37603 13.296 4.37603H8.97598V17.432H12.384Z"/>
  </svg>


}

export default BaseCurrency
