import React from 'react'
import { observer, inject } from 'mobx-react'
import BaseMessage from "../../components/atoms/BaseMessage";
import ModalContainer from "../../components/atoms/ModalContainer";

@inject('appStore')
@observer
class Notificator extends React.PureComponent {

  closeLoanOrderModal = () => {
    this.props.appStore.showNotification(false, {})
  }

  render () {
    const { showNotificationModal, notificationData } = this.props.appStore
    return <ModalContainer
        showModal={ showNotificationModal }
        closeModal={ () => this.closeLoanOrderModal()}
    >
      <BaseMessage type={ notificationData.type } centered={true}>
        { notificationData.text }
      </BaseMessage>
    </ModalContainer>
  }
}

export default Notificator
