import React from "react";
import s from "./ButtonPreloader.module.scss"

class ButtonPreloader extends React.Component {
    state = {
        x1: 120,
        y1: 6,
        x2: 129,
        y2: 17,
        opacity: 0
    };

    componentDidMount() {
        setInterval(() => {
            if (this.state.x1 < 135) {
                this.setState({
                    x1: this.state.x1 + 0.2,
                    opacity: this.state.opacity + 0.02
                })
            } else if (this.state.y1 < 60) {
                this.setState({y1: this.state.y1 + 0.9})
            } else if (this.state.x2 < 200) {
                this.setState({
                    x2: this.state.x2 + 1.25,
                    opacity: this.state.opacity - 0.05
                })
            } else {
                setTimeout(() => {
                    this.setState({
                        x1: 120,
                        y1: 6,
                        x2: 129,
                        y2: 17,
                        opacity: 0
                    })
                }, 1000)
            }
        }, 8);
    }

    render() {
        return (
            <div className={s.wrapper}>
                <svg className={s.animatedSvg} style={{opacity: this.state.opacity.toFixed(2)}} viewBox="0 0 263 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="261" height="49" rx="24.5" fill="white"/>
                    <rect x="1" y="1" width="261" height="49" rx="24.5" stroke="url(#paint0_linear_0_5)" strokeWidth="2"/>
                    <defs>
                        <linearGradient id="paint0_linear_0_5" x1={this.state.x1.toFixed(3)} y1={this.state.y1.toFixed(3)} x2={this.state.x2.toFixed(3)} y2={this.state.y2.toFixed(3)} gradientUnits="userSpaceOnUse">
                            <stop stopColor="#6CBD02"/>
                            <stop offset="1" stopColor="#6CBD02" stopOpacity="0"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className={s.text}>{this.props.children}</div>
            </div>
        );
    }
}

export default ButtonPreloader;
