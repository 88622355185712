import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import BaseMoneyInput from "../../../atoms/BaseMoneyInput";
import {MAX_VI_PAY_AMOUNT, MIN_PAY_AMOUNT} from "../constants";
import appStore from "../../../../store/app";

const AmountInput = observer(() => {
    const [payAmountInput, setPayAmountInput] = useState((appStore.currentEntityForPay?.nextPaymentSum || 0).toFixed(2))
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        defineMin()
        defineMax()
    }, [])

    useEffect(() => {
        setError(false)
        const digitPayAmountInput = parseFloat(payAmountInput)
        const {currentEntityForPayMinAmount, currentEntityForPayMaxAmount} = appStore
        let hasError = false
        if (digitPayAmountInput > currentEntityForPayMaxAmount) {
            setError(true)
            hasError = true
            setErrorMessage(`Сумма платежа не может быть выше ${currentEntityForPayMaxAmount}`)
        }
        if (digitPayAmountInput < currentEntityForPayMinAmount) {
            hasError = true
            setError(true)
            setErrorMessage(`Сумма платежа не может быть ниже ${currentEntityForPayMinAmount}`)
        }
        if (!hasError) {
            appStore.setCurrentEntityForPayAmount(digitPayAmountInput)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payAmountInput, appStore.currentEntityForPayMaxAmount, appStore.currentEntityForPayMinAmount])

    const defineMin = () => {
        const {nextPaymentSum = 0, sumBalance = 0} = appStore.currentEntityForPay
        let min = 0
        if (nextPaymentSum === 0) {
            //  appStore.currentEntityForPay?.nextPaymentSum - для кейса vi и тип платежа оффлайн
            min = Math.min(MIN_PAY_AMOUNT, sumBalance, nextPaymentSum)
            appStore.setCurrentEntityForPayMinAmount(min)
            return;
        }
        if (nextPaymentSum < MIN_PAY_AMOUNT) {
            appStore.setCurrentEntityForPayMinAmount(nextPaymentSum)
            return
        }
        min = Math.round(appStore.currentEntityForPay?.nextPaymentSum * 0.5)
        appStore.setCurrentEntityForPayMinAmount(Math.max(MIN_PAY_AMOUNT, min))
    }

    const defineMax = () => {
        const {currentEntityForPay} = appStore
        if (!currentEntityForPay) return
        let max = currentEntityForPay.isOnline ?
            Math.min(MAX_VI_PAY_AMOUNT, currentEntityForPay.sumBalance)
            :
            currentEntityForPay.nextPaymentSum
        appStore.setCurrentEntityForPayMaxAmount(max)
    }

    return (
        <BaseMoneyInput
            placeholder="Сумма"
            size="large"
            onChange={(x) => setPayAmountInput(x)}
            value={payAmountInput}
            min={appStore.currentEntityForPayMinAmount}
            max={appStore.currentEntityForPayMaxAmount}
            disabled={appStore.currentEntityForPay.immutableInput ?? false}
            error={error}
            errorMessage={errorMessage}
        />
    )
})
export default AmountInput
